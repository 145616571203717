<template>
  <div class="faq">

    <v-card>
      <v-card-title class="d-block">
        <router-link to="/athletesfaq" class="px-4 text-center avidocolor--text d-block d-md-inline" >Athletes FAQ</router-link>
        <router-link to="/coachfaq" class="px-4 text-center avidocolor--text d-block d-md-inline" >Coach FAQ</router-link>
        <router-link to="/instructionfaq" class="px-4 text-center avidocolor--text d-block d-md-inline " >Instructional Videos</router-link>
      </v-card-title>
      
      <v-card-text  class="subtitle-1">
        For additional questions please email us and we would be happy to help you:
      </v-card-text>
      <v-card-title class="d-block">
        <a class="text-center avidocolor--text" :href="encodedEmailLink" target="_blank">support@myavido.com</a>
      </v-card-title>
    </v-card>

    <v-expansion-panels focusable class="mt-3">
      <v-expansion-panel
        v-for="(model, index) in faqitems"
        :key="index"
        v-model="faqmodel"
      >
        <v-expansion-panel-header>
         {{ model.header}}
        </v-expansion-panel-header>
        <v-expansion-panel-content  class="text-left align-self-start">
            <div class="px-2 pt-4"><span v-html="model.value"></span></div>
            <div class="px-2 pt-4" ><span v-html="model.value_second"></span></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
  </v-expansion-panels>

  </div>
</template>



<script>
export default {
  name: "faq",
  data () {
    return {
      encodedEmailLink: "mailto:" + encodeURIComponent("support@myavido.com"),
      faqmodel:[],
      faqitems: [
        {
          header: 'What is Avido?',
          value: 'Avido is a strength training system that creates professional sport-specific training programs designed around the actual start date of the season. While most other systems stop there, Avido goes the extra distance by further customizing exercises, and workouts to the unique individual needs of each athlete. This is why Avido athletes don’t just get stronger is the gym, they perform stronger in competition.',
        },
        {
          header: 'How does Avido work?',
          value: 'Programing for Success: Based on when an athlete or team’s season begins and finishes, Avido designs a custom periodized training program focusing on the demands of that sport. These programs progress through different cycle of training, each building off the previous cycle, culminating in a shift to In-Season workouts allowing athletes to maintain their strength gains throughout their competitions.  Every week you will be given detailed workouts including exercise videos with recommended sets, repetitions and rest time. AVIDO does all the thinking for you, so you can focus on training each day!',
        },
        {
          header: 'How are programs/workouts created?',
          value: 'When you create an account or change your training preferences, Avido considers the number of days an athlete or team has to train each week, as well as the equipment they have available to train with.  Next, Avido considers each athlete’s position, and any target muscles or body parts they want to focus on then makes any adjustments before delivering the the final workout. Training is created using this info, to ensure that athletes see the maximum return out of every minute they invest in their training.',
        },
        {
          header: 'Can an athlete join more than one team?',
          value: 'Yes, an athlete can join an unlimited number of teams. At any time they can simply switch training calendars to the current season’s team.',
        },
        {
          header: 'What is a Training Cycle?',
          value: "A Training Cycle is a 2-6 week period of time that focuses on a specific training goal. There are many different types of Cycles such as, 'Hypertrophy', 'Strength', 'Power', and more. While constructing a maximally effective strength and conditioning program, it is essential that there is a proper progression or your results will suffer. Avido ensures that you are training the right way at the right time.",
        },
        {
          header: 'Off-Season versus In-Season?',
          value: 'Off-season training is designed to build maximal strength, power and explosiveness in preparation for your upcoming season. Each cycles is placed into a progression designed to peak your performance at the conclusion of the final cycle so you are ready to compete at the highest level.',
          value_second: 'In-season training is designed to maintain your strength, power and explosiveness throughout the season. The in-season of any sport requires time intensive practice, drills and competitor analysis. This, combined with the need for rest surrounding competition leaves very little time for strength training. Avido In-Season is a minimally time-intensive maintenance program designed to ensure you do not lose any of your hard earned off-season gains.',
        },
        {
          header: "Won't I be too sore if I train during the In-Season?",
          value: 'No, In-season workouts see a huge reduction in weight, sets, reps, and overall intensity. In-Season workouts are intentionally shorter and designed to take only 25-30min. These workouts are the only thing that allow you to be as strong at the end of the season as you are at the start, this is why every professional athlete does them.',
        },
        {
          header: "Can I create my own workouts",
          value: 'Yes, if you know what you want to do you can create your own workouts using the Avido exercise library.  You can also use the exercise library to create your own warm ups, finishers, and cool downs to add to these workouts.  Additionally you can create your own cycles and programs from these workouts then add them to your training calendar or the calendar of any team you coach.',
        },
        {
          header: "Who is the AVIDO team?",
          value: "Avido, LLC is a company dedicated to developing training tools which assist athletes in reaching their full potential. Our team is composed of Certified Strength and Conditioning Specialists, Doctors of Physical Therapy and Exercise Science Professionals who use a collaborative approach. Our team combines it's experience with today's cutting edge research. Avido takes the guesswork out of proper training. Before you can exceed your competition, you must first Exceed Yourself.",
        },
        {
          header: "How can I delete my account?",
          value: "Step 1:  Log into your AVIDO account. <br/><br/>Step 2:  Open the Account Settings page through the Menu.<br/><br/>Step 3:  At the bottom of the Account Settings Page tap “Delete Account”.<br/><br/>Warning: your account, personal information and all records will be permanently deleted, and can not be recovered.",
        },
      ],
    }
  },
};
</script>