<template>
<div class="joinOrganization">
    
    <v-card flat class="pa-8">
      <v-form
        ref="joinOrganizationform"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
          
        <v-text-field
          v-model="organization_number"
          class="pa-md-4"
          name="organization_number"
          label="Enter Organization Number"
          color="avidocolor"
          :rules="[(v) => !!v || 'Organization Number is required']"
        ></v-text-field>

        <v-text-field
          v-model="organization_join_code"
          class="pa-md-4"
          name="organization_join_code"
          label="Enter Organization Join Code"
          color="avidocolor"
          :rules="[(v) => !!v || 'Organization Join Code is required']"
        ></v-text-field>

        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor avidosolidtextcolor--text"> Join </v-btn>
        </v-card-actions>

      </v-form> 

    </v-card>  

  </div>
</template>




<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "JoinOrganization",
  data: () => ({
    valid: false,
  }),
  computed:{
    ...mapFields('organizationProfile',{
      organization_number:'organization_number',
      organization_join_code:'organization_join_code',
    })
  },
  methods: {
    ...mapActions('organizationProfile',{
      joinOrganization:'joinOrganization',      
    }),
    onSubmit: function () {
      if (this.$refs.joinOrganizationform.validate()) {
        this.joinOrganization();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  beforeDestroy(){
    this.$vuetify.theme.themes.dark.avidocolor = (typeof(localStorage.getItem("darkTextColor")) != "undefined" && localStorage.getItem("darkTextColor") !== null) ? localStorage.getItem("darkTextColor") : "#52F60C"
    this.$vuetify.theme.themes.dark.avidosolidtextcolor = (typeof(localStorage.getItem("solidTextColor")) != "undefined" && localStorage.getItem("solidTextColor") !== null) ? localStorage.getItem("solidTextColor") : "#000000" 
  }
};
</script>