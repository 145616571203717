import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    allex_time_frame_option: [
      {
        title: 'All Time',
        value: 'ALL'
      },
      {
        title: 'Month',
        value: 'YYYY-MM'
      },
    ],
    oneex_time_frame_option: [
      {
        title: 'Monthly',
        value: 'MONTHLY'
      },
      {
        title: 'Daily',
        value: 'DAILY'
      },
    ],
    team_time_frame_option: [
      {
        title: 'All Time',
        value: 'ALL'
      },
      {
        title: 'Month',
        value: 'YYYY-MM'
      },
    ],
    result_type_items: [
      {
        title: '1 Rep Max (estimate)',
        value: 'ONEREPMAX'
      },
      {
        title: 'Max Reps in 1 Set',
        value: 'MAXREPS'
      },   
      {
        title: 'Max Weight Lifted',
        value: 'MAXWEIGHT'
      },
      {
        title: 'Total Reps Performed',
        value: 'TOTALREPS'
      },      
      {
        title: 'Total Weight Lifted',
        value: 'TOTALWEIGHT'
      },
    ],
    user_id: localStorage.getItem('user_id'),
    team_id: 0,
    selected_exercises: [],
    result_type: 'ONEREPMAX', // ONEREPMAX,MAXREPS,MAXWEIGHT,TOTALREPS,TOTALWEIGHT
    time_frame: 'ALL', // ALL,YYYY-MM,MONTHLY,DAILY
    month_time:  new Date().toISOString().substr(0, 7),
    chartOptions: [],
    y_chart_array:[],
    result_exercise_array:[],
    result_count:0,
  },

  getters: {
    getField,
  },

  mutations: {
    reInitResultModel(state) {
      state.result_exercise_array = []
      state.selected_exercises = []
      state.result_type = 'ONEREPMAX'
      state.time_frame = 'ALL'
      state.month_time = new Date().toISOString().substr(0, 7)
    }, 
    reset_chart_data(state){
      state.result_count = 0
      state.chartOptions = {}
      state.y_chart_array = []
    },    
    set_exercise_array(state,resp_data){
      if(state.team_id==0 && state.time_frame=="ALL"){
        for (let exercises of Object.values(resp_data.result)) {
          state.result_exercise_array.push({'exercise_id':exercises.selectid, 'exercise_name':exercises.title, 'exercise_image_thumbnail':exercises.exer_image}) 
        }
        state.selected_exercises=state.result_exercise_array
      }      
    },
    set_chart_data(state,resp_data){

      let maltipler=40
      state.result_count= (resp_data.result.length>0) ? (resp_data.result.length>=5) ? resp_data.result.length * maltipler : 150 : 0

      let x_array=[]
      let y_array=[]
      for (let value of Object.values(resp_data.result)) {
        x_array.push(value.title)
        y_array.push(value.amount)
      }

      state.y_chart_array = [{
        data: y_array
      }],
      state.chartOptions= {
        chart: {
          type: 'bar',
          toolbar:{
            show: false
          },
        },
        grid: {
          show: false
        },
        plotOptions: {
          bar: {
            barHeight: '85%',
            borderRadius: 15,
            borderRadiusApplication: "end", // "around" / "end" 
            borderRadiusWhenStacked: "last", // "all"/"last"
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        // colors: [`linear-gradient(145deg, #121212 20%, ${this.state.lightBackgroundColor} 50%, ${this.state.darkBackgroundColor} 60%, #121212 75%)`],
        // colors: ['#121212',this.state.lightBackgroundColor,this.state.lightBackgroundColor,'#121212'],
        fill: {
          type: 'gradient',
          gradient: {
            colorStops: [
              {
                offset: 5,
                color: this.state.lightBackgroundColor,
                opacity: 1
              },
              {
                offset: 50,
                color: this.state.darkBackgroundColor,
                opacity: 1
              },
            ]
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#ffffff']
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        },
        stroke: {
          width: 0,
          colors: ['#fff']
        },
        xaxis: {
          categories:  x_array,
        },
        yaxis: {
          labels: {
            show: false,
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function () {
                return ''
              }
            }
          }
        }
      }
      


      /* state.chartOptions= {
        chart: {
          type: 'bar',
          toolbar:{
            show: false
          },
          events: {
            click: function(chart, w, e) {
               console.log(chart, w, e)
            }
          }
        },
        colors: ['#52F60C'],
        plotOptions: {
          bar: {
            distributed: true,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
            horizontal: true,
          },

        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false
        },
        tooltip: {
            theme: 'dark'
        },
        xaxis: {
          categories:  x_array,
          labels: {
            style: {
              fontSize: '12px',
              colors: '#ffffff',
            }
          }
        },       
        yaxis: {
          labels: {
            style: {
              show: false
            }
          }
        }
      },
      state.y_chart_array = [{
        data: y_array
      }] */
    },
    updateField,
  },

  actions: {

    getModel({ commit, dispatch }){ 
      commit("reset_chart_data");
      let timeframe=this.state.results.time_frame;
      if(this.state.results.time_frame=='YYYY-MM'){
        timeframe=this.state.results.month_time;
      }

      let call_api=true
      let selected_exer_length=this.state.results.selected_exercises.length
      let selected_exer = (selected_exer_length==1) ? this.state.results.selected_exercises[0] : 0  

      // Cheack for team result
      if(this.state.results.team_id>0 && selected_exer_length==0){
        call_api=false
        //call avido exercise list
        if(this.state.getDataList.avido_exercise_list.length==0){
          dispatch("getDataList/getExerciseList", {}, { root: true });
        }
      }

      if(timeframe!=null && call_api==true){
        return new Promise((resolve,reject) => {
          let api_name='get_results' 
          let api_data={
            exercise_id: selected_exer,
            user_id: (this.state.results.team_id>0) ? 0 : (this.state.results.user_id==null) ? localStorage.getItem('user_id') : this.state.results.user_id,
            team_id: this.state.results.team_id,
            result_type: this.state.results.result_type,
            time_frame: timeframe,
          };
          axios({
            method: 'GET',
            url: api_name,
            params:api_data
          }).then(resp => {
              const resp_code = resp.status
              if (resp_code == 200) {
                const response_data = resp.data.response
                commit('set_exercise_array', response_data);
                commit('set_chart_data', response_data);
                resolve(resp)
              }
            })
            .catch(error => {
              const resp_code = error.response.status
              if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
                const error_mesg = error.response.data.errors
                dispatch("setNotification", {
                  display: true,
                  text: error_mesg,
                  alertClass: "error"
                }, { root: true });
                reject(error)
              }
            })
        })
      }
    },
    
  },

};