<template>
  <div class="coachfaq">
   
    <v-expansion-panels focusable class="mt-3">
      <v-expansion-panel
        v-for="(model, index) in cfaqitems"
        :key="index"
        v-model="cfaqmodel"
      >
        <v-expansion-panel-header>
        {{ model.header}}
        </v-expansion-panel-header>
        <v-expansion-panel-content  class="text-left align-self-start">
            <div class="px-2 pt-4" v-if="model.value!=undefined">{{ model.value }}</div>
            <div class="px-2 pt-4" v-if="model.stepone!=undefined">{{ model.stepone }}</div>
            <div class="px-2 pt-4" v-if="model.steptwo!=undefined">{{ model.steptwo }}</div>
            <div class="px-2 pt-4" v-if="model.stepthree!=undefined">{{ model.stepthree }}
              <div class="px-2 pt-4" v-if="model.stepthreesub1!=undefined">{{ model.stepthreesub1 }}</div>
              <div class="px-2 pt-4" v-if="model.stepthreesub2!=undefined">{{ model.stepthreesub2 }}</div>
            </div>
            <div class="px-2 pt-4" v-if="model.stepfour!=undefined">{{ model.stepfour }}</div>
            <div class="px-2 pt-4" v-if="model.stepfive!=undefined">{{ model.stepfive }}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>



<script>
export default {
  name: "coachfaq",
  data () {
    return {
      cfaqmodel:[],
      cfaqitems: [
        {
          header: 'How to create an account (iPhone)',
          stepone: 'Step 1: sign up by entering your enter name, email and date of birth',
          steptwo: 'Step 2: select a goal based program or sport specific program',
          stepthree: 'Step 3: select up to 2 muscles/areas to target in workouts. (target areas are optional and may be left blank)',
          stepfour: 'Step 4: choose how many time you want to workout each week and the equipment you want to use.',
          stepfive: 'Step 5: upload a profile picture, enter bio/goals, height, weight, and gender (this info is all optional and my be left blank. Your height, weight and gender will never be shown to anyone)',
        },
        {
          header: 'How to create an account (Android)',
          stepone: 'Step 1: sign up by entering your enter name, email and date of birth',
          steptwo: 'Step 2: select a goal based program or sport specific program',
          stepthree: 'Step 3: select up to 2 muscles/areas to target in workouts. (target areas are optional and may be left blank)',
          stepfour: 'Step 4: choose how many time you want to workout each week and the equipment you want to use.',
          stepfive: 'Step 5: upload a profile picture and enter bio/goals (this info is all optional and my be left blank)',
        },
        {
          header: 'Upgrade to a coach account',
          stepone: 'Step 1: go to the menu and tap “Account Settings”',
          steptwo: 'Step 2: scroll down then tap "Upgrade to Coach.',
          stepthree: 'Step 3: enter your school Organization Number and Join Code to be upgraded to a coach account.',
        },
        {
          header: 'Become coach of an existing team',
          stepone: 'Step 1: from your profile screen tap the search icon in the top left corner',
          steptwo: 'Step 2: search for your team by name, then tap on the one you wish to join to go to that team’s profile.',
          stepthree: 'Step 3: from the teams profile page:',
          stepthreesub1: 'for iPhone: tap “+” button on the team profile picture',
          stepthreesub2: 'for Android: tap “Join Team” at bottom of the screen',
          stepfour: 'Step 4: choose to join as a coach, then enter the coach join code.',
        },
        {
          header: 'Edit or create a team (iPhone)',
          stepone: 'Step 1: from the “My Training” screen go to the menu and tap “Manage Teams”',
          steptwo: 'Step 2: select the team you wish to edit or tap the “+” button to create a new team.',
          stepthree: 'Step 3: edit/enter the team profile including: name, organization, description and picture (if no picture is uploaded the organization’s logo will be used)',
          stepfour: 'Step 4: edit/enter team roster including: codes for athletes or coaches to join the team. Tapping “Manage Roster” will allow you to see who is on the team and to remove athletes or coaches.',
          stepfive: 'Step 5: edit/enter training program including: the sport or goal, the equipment to include in workouts and how many times train each week.  You may also choose when the program begins, as well as when the in-season starts and ends.  (If you have already created your own workouts and program from scratch in your training library you may add them here by selecting “Library” under “Program Type”)',
        },
        {
          header: 'Create a team (Android)',
          stepone: 'Step 1: from the “My Workouts” screen go to the menu and tap “My Teams & Athletes”',
          steptwo: 'Step 2: tap the “+” button to create a new team.',
          stepthree: 'Step 3: enter team info including: name, organization, description and picture (if no picture is uploaded the organization’s logo will be used)',
          stepfour: 'Step 4: enter codes for athletes or coaches to join the team.',
          stepfive: ' edit/enter training program including: the sport or goal, the equipment to include in workouts and how many times train each week.  You may also choose when the program begins, as well as when the in-season starts and ends.  (If you have already created your own workouts and program from scratch in your training library you may add them here by selecting “Library” under “Program Type”)',          
        },
        {
          header: 'Edit a team’s training (Android)',
          stepone: 'Step 1: from the “My Workouts” screen go to the menu and tap “My Teams & Athletes”',
          steptwo: 'Step 2: find the team you wish to edit then tap “Manage Training”',
          stepthree: 'Step 3: edit the team’s training program including: the sport or goal, the equipment to include in workouts and how many times train each week.  You may also choose when the program begins, as well as when the in-season starts and ends.  (If you have already created your own workouts and program from scratch in your training library you may add them here by selecting “Library” under “Program Type”)',
        },
        {
          header: "Edit a team profile (Android)",
          stepone: 'Step 1: from the “My Workouts” screen go to the menu and tap “My Teams & Athletes”',
          steptwo: 'Step 2: find the team you wish to edit then tap “Team Settings”',
          stepthree: 'Step 3: edit the team profile including: name, organization, goals/description and picture (if no picture is uploaded the organization’s logo will be used)',
        },
        {
          header: "Track team attendance (iPhone)",
          stepone: 'Step 1: from the “My Training” screen go to the menu and tap “Manage Teams” then select a team.',
          steptwo: 'Step 2: scroll down to "Reports/Roster then tap “Generate Report”',
          stepthree: 'Step 3: this team report will show what each athelte has completed in the last 7 days and in the last 30 days.  You have the option of looking at total: workouts, sets, reps, and weight. (Tip: total workouts and total sets are great ways to know who is actually putting the work in)',
        },
        {
          header: "Track athlete attendance (android)",
          stepone: 'Step 1: from the “My Workouts” screen go to the menu and tap “My Teams & Athletes”',
          steptwo: 'Step 2: find the team you want then tap “Team Report”',
          stepthree: 'Step 3: this team report will show what each athelte has completed in the last 7 days and in the last 30 days.  You have the option of looking at total: workouts, sets, reps, and weight. (Tip: total workouts and total sets are great ways to know who is actually putting the work in)',
        },
        {
          header: "View team results",
          stepone: 'Step 1: go to the teams profile page then tap "Results"',
          steptwo: 'Step 2: search for the exercise you want to see results on',
          stepthree: 'Step 3: choose the time frame in which you want to view the results',
        },
      ],
    }
  },
};
</script>