<template>
<div class="Clients">
    <v-card flat class="pa-8">
      <v-form
        ref="clientform"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >

        <v-text-field
          v-model="client_first_name"
          class="pa-md-4"
          prepend-icon="mdi-account"
          name="client_first_name"
          label="First Name"
          color="avidocolor"
          :rules="[(v) => !!v || 'First Name is required']"
        ></v-text-field>

        <v-text-field
          v-model="client_last_name"
          class="pa-md-4"
          prepend-icon="mdi-account"
          name="client_last_name"
          label="Last Name"
          color="avidocolor"
          :rules="[(v) => !!v || 'Last Name is required']"
        ></v-text-field>

        <v-text-field
          v-model="email"
          class="pa-md-4"
          prepend-icon="mdi-email"
          name="email"
          label="Email"
          color="avidocolor"
          :rules="[(v) => !!v || 'Email is required']"
        ></v-text-field>

        <v-select
          v-model="organization_id"
          :items="organization_option"
          item-text="name"
          item-value="id"
          item-color="avidocolor"
          label="Client's Organization"
          color="avidocolor"
          class="pa-md-4"
          prepend-icon="mdi-home-modern"
          :rules="[(v) => !!v || 'Client\'s Organization is required']"
        ></v-select>

        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor"> <v-icon>mdi-content-save</v-icon>save  </v-btn>
        </v-card-actions>


      </v-form> 
    </v-card>  

  </div>
</template>



<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Team",
  data: () => ({
    valid: false,
  }),
  computed:{
    ...mapFields('client',{
      client_first_name:'client_first_name',
      client_last_name:'client_last_name',
      email:'email',
      organization_id:'organization_id',
    }),
    ...mapFields('team',{
      organization_option:'organization_option',
    })
  },
  methods: {
    ...mapActions('team',{
      getCoachOrganization:'getCoachOrganization',
    }),  
    ...mapActions('client',{
      createClientModel:'createClientModel',
    }),
    onSubmit: function () {
      if (this.$refs.clientform.validate()) {
          this.createClientModel();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {
    this.$store.commit("client/unsetclient");
    this.getCoachOrganization();
  },
};
</script>