<template>
  <div class="setallexerciseset">
    <v-card>
        <v-row>
          <v-col cols="12">
            <h2 class="mb-3 d-inline">
              <span class="avidocolor--text">Set All Exercise</span>
            </h2>
          </v-col>
        </v-row>

        <v-list color="black" class="pa-0">
          <v-divider></v-divider>
          <v-row>
            <v-col cols="6">
              <v-list-item-action class="ma-0 align-center">
                <div class="text-body-1 pt-2 grey--text">Tempo</div>
                <v-dialog v-model="tempoDialog" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on" min-width="0px">
                      <span
                        :class="(allSetsTempo != null) ? 'text-h5 avidocolor--text' : 'text-h5'">
                        {{ tempoSDisplay }}
                      </span>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Edit Tempo</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="avidocolor" label="Tempo" clearable
                              v-model="allSetsTempo"
                              v-on:change="data_change == false ? data_change = !data_change : data_change"
                              type="number"
                              :rules="[(v) => (v === null || v.length <= 4) || 'Max 4 characters allow.']"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions
                      v-if="allSetsTempo == null || allSetsTempo.length <= 4">
                      <v-btn color="red" text @click="tempoDialog = false">
                        close
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="avidocolor" text @click="tempoDialog = false">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-list-item-action>
            </v-col>

            <v-col cols="6">
              <v-list-item-action class="ma-0 align-center">
                <div class="text-body-1 py-2 grey--text">Rest Time</div>
                <v-row align-items="center" justify="center">
                  <v-col cols="5" class="pa-0 pt-2">
                    <v-select :items="rest_time_minitems" v-model="allSetsRestTimeMin" label="MM" dense outlined
                      color="avidocolor" v-on:change="onchangeRestTimeMin"></v-select>
                  </v-col>
                  <v-col cols="5" class="pa-0 pt-2">
                    <v-select :items="rest_time_secitems" v-model="allSetsRestTimeSec" label="SS" dense outlined
                      color="avidocolor" v-on:change="onchangeRestTimeSec"></v-select>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-list>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th class="text-center font-weight-bold">SETS</th>
                <th class="pa-0">
                  <div class="text-center">
                    <v-btn id="reps_type_menu" color="avidocolor" class="font-weight-bold" text>
                      <div class="avidocolor--text">{{ allSetsRepsType }}</div>
                    </v-btn>
                    <v-menu offset-y activator="#reps_type_menu" open-on-hover>
                      <v-list>
                        <v-list-item v-for="(item, index) in reps_type_options" :key="index" :value="item.reps_type"
                          @click="changeRepsType(item)">
                          <v-list-item-title>{{ item.display_reps_type }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </th>
                <th class="pa-0">
                  <div class="text-center">
                    <v-btn id="weight_type_menu" color="avidocolor" class="font-weight-bold" text>
                      <div class="avidocolor--text">{{ allSetsDisplayWeightType }} </div>
                    </v-btn>
                    <v-menu offset-y activator="#weight_type_menu" open-on-hover>
                      <v-list>
                        <v-list-item v-for="(item, index) in weight_type_options" :key="index" :value="item.weight_type"
                          @click="changeWeightType(item)">
                          <v-list-item-title>{{ item.display_weight_type }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in allSets" :key="item.id">
                <td>
                  <v-btn icon color="red" dark @click.stop.prevent="
                  deleteSet(index)
                  ">
                    <v-icon color="red">mdi-minus-circle</v-icon>
                  </v-btn>
                </td>

                <td>{{ index + 1 }}</td>

                <td>
                  <v-text-field placeholder="" v-on:focus="repsFocus(index)" v-on:change="repsChange(index, $event)"
                    :value="(allSets[index].reps == 0) ? 'max' : allSets[index].reps"
                    :type="(allSets[index].reps == 0) ? 'text' : 'number'" :id="'allreps_' + index" hide-spin-buttons
                    color="avidocolor" class="setinput" min=0>
                  </v-text-field>
                </td>

                <td>
                  <v-text-field :value="(allSets[index].weight == 0) ? 'max' : allSets[index].weight" placeholder=""
                    v-on:focus="weightFocus(index)" v-on:change="weightChange(index, $event)"
                    :type="(allSets[index].weight == 0) ? 'text' : 'number'" :id="'allweight_' + index" hide-spin-buttons
                    color="avidocolor" class="setinput" min=0>
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
    </v-card>

   <!--  <v-dialog v-model="setall_dialog" max-width="300">
      <v-card>
        <v-card-text>
          This will overwrite the sets for all exercises in this workout and can not be reversed.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text  @click="setall_dialog = false">
            Cancel
          </v-btn>

          <v-btn color="avidocolor" text @click.stop.prevent="conformdeloadingworkout()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->


    <div class="clear"></div>
    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>
        <v-btn @click="addSets()" class="my-2 avidocolor--text" text>
          <v-icon>mdi-plus</v-icon> Set
        </v-btn>
      </v-row>
    </v-footer>

  </div>
</template>


<style>
.v-application p {
  margin-bottom: 0px !important;
}

.pv-controls {
  padding: 5px;
}
</style>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "SetAllExerciseSet",
  data() {
    return {
      allstartingValue: null,
      tempoDialog: false,
      rest_time_minitems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59],
      rest_time_secitems: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
    }
  },
  computed: {
    ...mapFields('libraryExercise', [
      'reps_type_options',
      'weight_type_options',
    ]),
    ...mapFields('setAllExerciseSet', [
      'allSets',
      'allSetsRepsType',
      'allSetsWeightType',
      'allSetsDisplayWeightType',
      'allSetsRestTime',
      'allSetsRestTimeMin',
      'allSetsRestTimeSec',
      'allSetsTempo',
    ]),
    tempoSDisplay() {
      return (this.allSetsTempo != null) ? ((this.allSetsTempo.length <= 4) ? this.allSetsTempo : '----') : '----'
    }
  },
  methods: {
    addSets() {
      let current_set_count = this.allSets.length
      let last_resp = 0
      let last_weight = 0
      let last_checkmark_status = false
      let last_percentage = 0
      let last_rpe_level = 0
      if (current_set_count > 0) {
        last_resp = this.allSets[current_set_count - 1].reps
        last_weight = this.allSets[current_set_count - 1].weight
        last_checkmark_status = this.allSets[current_set_count - 1].checkmark_status
        last_percentage = this.allSets[current_set_count - 1].percentage
        last_rpe_level = this.allSets[current_set_count - 1].rpe_level
      }

      let new_set = {
        checkmark_status: last_checkmark_status,
        reps: last_resp,
        weight: last_weight,
        percentage: last_percentage,
        rpe_level: last_rpe_level,
      }

      this.allSets.push(new_set)
    },
    deleteSet(remove_index) {
      this.allSets.splice(remove_index, 1);
    },
    repsFocus(setIndex) {
      this.$el.querySelector("input#allreps_" + setIndex).type = "number"
      this.allstartingValue = this.allSets[setIndex].reps
      this.allSets[setIndex].reps = null
      this.$el.querySelector("input#allreps_" + setIndex).placeholder = (this.allSets[setIndex].reps != null) ? this.allSets[setIndex].reps.toString() : ''
    },
    repsChange(setIndex, content) {
      this.allSets[setIndex].reps = content
      if (content == null && this.allstartingValue != null) {
        this.allSets[setIndex].reps = this.allstartingValue
      }
      this.$el.querySelector("input#allreps_" + setIndex).type = "text"
    },
    weightFocus(setIndex) {
      this.$el.querySelector("input#allweight_" + setIndex).type = 'number'
      this.allstartingValue = this.allSets[setIndex].weight
      this.allSets[setIndex].weight = null
      this.$el.querySelector("input#allweight_" + setIndex).placeholder = (this.allSets[setIndex].weight != null) ? this.exercise_sets[setIndex].weight.toString() : ''
    },
    weightChange(setIndex, content) {
      this.data_change = true
      this.allSets[setIndex].weight = content
      if (content == null && this.allstartingValue != null) {
        this.allSets[setIndex].weight = this.allstartingValue
      }
      this.$el.querySelector("input#allweight_" + setIndex).type = "text"
    },
    changeRepsType(selected_repsType) {
      this.allSetsRepsType = selected_repsType.reps_type
    },
    changeWeightType(selected_weightType) {
      this.allSetsWeightType = selected_weightType.weight_type
      this.allSetsDisplayWeightType = selected_weightType.display_weight_type
    },
    onchangeRestTimeMin(minutes) {
      let new_min = minutes * 60
      let new_sec = this.allSetsRestTimeSec
      let new_rest_time = parseInt(new_min) + parseInt(new_sec)
      this.allSetsRestTime = new_rest_time
    },
    onchangeRestTimeSec(seconds) {
      let new_min = this.allSetsRestTimeMin * 60
      let new_sec = seconds
      let new_rest_time = parseInt(new_min) + parseInt(new_sec)
      this.allSetsRestTime = new_rest_time
    },
  },
  created() {
    this.$store.commit("setAllExerciseSet/reSetModelState");
  }
};
</script>
