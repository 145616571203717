import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    email: null,
    token: null,
    password: null,
    password_confirmation: null,
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    unsetForgotPassword(state) {
      state.email=null
    },     
    unsetResetPassword(state) {
      state.email=null
      state.token=null
      state.password=null
      state.password_confirmation=null
    }, 
  },

  actions: {

    submitForgotPassword({ dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          url: 'forget-password',
          method: 'POST',
          data: {email:this.state.forgotPassword.email}
        })
        .then(resp => {
          const resp_code = resp.status
          if (resp_code == 200) {
            const resp_message = resp.data.message
            dispatch("setNotification", {
              display: true,
              text: resp_message,
              alertClass: "success"
            }, { root: true });              
            router.push({ path: '/login' });
            resolve(resp)
          } else {
            dispatch("setNotification", {
              display: true,
              text: this.app_error_mesg,
              alertClass: "error"
            }, { root: true });
          }
        })
        .catch(error => {
          const resp_code = error.response.status
          if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })
      })
    },

    submitResetPassword({ dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          url: 'reset-password',
          method: 'POST',
          data: {
            email:this.state.forgotPassword.email,
            token:this.state.forgotPassword.token,
            password:this.state.forgotPassword.password,
            password_confirmation:this.state.forgotPassword.password_confirmation,
          }
        })
        .then(resp => {
          const resp_code = resp.status
          if (resp_code == 200) {
            const resp_message = resp.data.message
            dispatch("setNotification", {
              display: true,
              text: resp_message,
              alertClass: "success"
            }, { root: true });              
            router.push({ path: '/login' });
            resolve(resp)
          } else {
            dispatch("setNotification", {
              display: true,
              text: this.app_error_mesg,
              alertClass: "error"
            }, { root: true });
          }
        })
        .catch(error => {
          const resp_code = error.response.status
          if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })
      })
    },

  },

};