import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import AndroidHome from '../views/AndroidHome.vue'
import Athlete from '../views/Athlete.vue'
import Coaches from '../views/Coaches.vue'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Register from '../views/Register.vue'
import Changepassword from '../views/Changepassword.vue'
import Contactus from '../views/Contactus.vue'
import FAQ from '../views/FAQ.vue'
import AthletesFAQ from '../views/AthletesFAQ.vue'
import CoachFAQ from '../views/CoachFAQ.vue'
import InstructionFAQ from '../views/InstructionFAQ.vue'
import Termsofuse from '../views/Termsofuse.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import Trainingpreferences from '../views/Trainingpreferences.vue'
import Accountsettings from '../views/Accountsettings.vue'
import Profilesettings from '../views/Profilesettings.vue'
import ExerciseInstruction from '../views/ExerciseInstruction.vue'
import WeekSummary from '../views/UserWorkout/WeekSummary.vue'
import CycleDetails from '../views/CycleDetails.vue'
import WorkoutExercise from '../views/UserWorkout/WorkoutExercise.vue'
import EndWorkout from '../views/UserWorkout/EndWorkout.vue'
import EditWorkoutExercise from '../views/UserWorkout/EditWorkoutExercise.vue'
import WorkoutWFC from '../views/UserWorkout/WorkoutWFC.vue'
import WorkoutEquipment from '../views/UserWorkout/WorkoutEquipment.vue'
import WorkoutExerciseSet from '../views/UserWorkout/WorkoutExerciseSet.vue'
import CopyWorkout from '../views/UserWorkout/CopyWorkout.vue'
import PersonalWorkout from '../views/UserWorkout/PersonalWorkout.vue'
import MyTeamTrainers from '../views/MyTeamTrainers.vue'
import Team from '../views/Team/Team.vue'
import TeamReport from '../views/Team/TeamReport.vue'
// import ViewLeaderBoard from '../views/Team/ViewLeaderBoard.vue'
// import EditLeaderBoard from '../views/Team/EditLeaderBoard.vue'
import Client from '../views/Client.vue'
import Organization from '../views/Organization/Organization.vue'
import JoinTeam from '../views/Team/JoinTeam.vue'
// import JoinTrainer from '../views/JoinTrainer.vue'
// import JoinCoachTrainer from '../views/JoinCoachTrainer.vue'
import JoinOrganization from '../views/Organization/JoinOrganization.vue'
import UserProfile from '../views/UserProfile.vue'
import UserSearch from '../views/UserSearch.vue'
import TeamSearch from '../views/TeamSearch.vue'
import TeamProfile from '../views/Team/TeamProfile.vue'
import OrganizationProfile from '../views/Organization/OrganizationProfile.vue'
import TeamTrainingPreferences from '../views/Team/TeamTrainingPreferences.vue'
import ExerciseNotes from '../views/ExerciseNotes/AllNotes.vue'
import NoteDetails from '../views/ExerciseNotes/NoteDetails.vue'
import EditNotes from '../views/ExerciseNotes/EditNotes.vue'
import Library from '../views/Library/Library.vue'
import CopyLibrary from '../views/Library/CopyLibrary.vue'
import LibraryExercise from '../views/Library/LibraryExercise.vue'
import LibraryExerciseSet from '../views/Library/LibraryExerciseSet.vue'
import EditLibraryWorkoutExercise from '../views/Library/EditLibraryWorkoutExercise.vue'
import LibraryForm from '../views/Library/LibraryForm.vue'
import ViewWFC from '../views/Library/ViewWFC.vue'
import SearchExercise from '../views/SearchScreens/SearchExercise.vue'
import SearchTeamandTmembers from '../views/SearchScreens/SearchTeamandTmembers.vue'
import SearchLibraryWorkout from '../views/SearchScreens/SearchLibraryWorkout.vue'
// import SearchLibraryCycle from '../views/SearchScreens/SearchLibraryCycle.vue'
import SearchLibraryProgram from '../views/SearchScreens/SearchLibraryProgram.vue'
import SetAllExerciseSet from '../views/SetAllExerciseSet.vue'
import Results from '../views/Results.vue'
import store from '../store/index';
import axios from 'axios'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiredAuth: false }, 
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','');
      store.commit('changeComponent','HOME');
      next();
    }
  },  
  {
    path: '/athlete',
    name: 'Athlete',
    component: Athlete,
    meta: { requiredAuth: false }, 
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','');
      store.commit('changeComponent','ATHLETE');
      next();
    }
  }, 
  {
    path: '/coaches',
    name: 'Coaches',
    component: Coaches,
    meta: { requiredAuth: false }, 
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','');
      store.commit('changeComponent','COACHES');
      next();
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiredAuth: false },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Login');
      store.commit('changeComponent','LOGIN');
      next();
    } 
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: Register,
    meta: { requiredAuth: false },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','SIGN UP');
      store.commit('changeComponent','REGISTER');
      next();
    } 
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { requiredAuth: false },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Forgot Password ?');
      store.commit('changeComponent','FORGOTPASSWORD');
      next();
    } 
  },  
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { requiredAuth: false },
    beforeEnter (to, from, next){
      if(to.query.fot!=undefined && to.query.fot!=null){
        store.commit('changeHeaderTitle','Reset Password');
        store.commit('changeComponent','RESETPASSWORD');
        next();
      }else{
        next('/');
      }
    } 
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter (to, from, next){
      delete axios.defaults.headers.common['Authorization']
      localStorage.clear();
      sessionStorage.clear();
      store.commit('user/logout');      
      next('/login');
      store.replaceState({});
      location.reload();
      store.commit('changeComponent','LOGOUT');
    }
  },
  {
    path: '/results',
    name: 'Results',
    component: Results,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      let workoutFor='My'

      if(store.state.results.team_id!=undefined && store.state.results.team_id>0){
        workoutFor='Team'
      }
      let ruser_id=(store.state.results.team_id>0) ? 0 : (store.state.results.user_id==null) ? 0 : store.state.results.user_id
      if(ruser_id>0 && ruser_id!=localStorage.getItem('user_id')){
        workoutFor=store.state.userProfile.first_name+"'s"
      }
      store.commit('changeHeaderTitle',workoutFor+' Results');
      store.commit('changeComponent','RESULTS');
      next();
    }  
  }, 
  {
    path: '/week-summary',
    name: 'WeekSummary',
    component: WeekSummary,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      let workoutFor='My'
      if(to.query.tid!=undefined && to.query.tid>0){
        workoutFor='Team'
      }
      if(to.query.uid!=undefined && to.query.uid>0){
        workoutFor=store.state.userProfile.first_name+"'s"
      }
      store.commit('changeHeaderTitle',workoutFor+' Workouts');
      store.commit('changeComponent','WORKOUTSUMMARY');
      next();
    }    
  }, 
  {
    path: '/personal-workout',
    name: 'PersonalWorkout',
    component: PersonalWorkout,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Add Blank Workout');
      store.commit('changeComponent','PERSONALWORKOUT');
      next();
    }   
  },
  {
    path: '/copy-workout',
    name: 'CopyWorkout',
    component: CopyWorkout,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','COPY WORKOUT');
      store.commit('changeComponent','COPYWORKOUT');
      next();
    }   
  }, 
  {
    path: '/cycle-details',
    name: 'CycleDetails',
    component: CycleDetails,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle',store.state.workoutCalendar.current_cycle_name);
      store.commit('changeComponent','CYCLEDETAILS');
      next();
    }    
  }, 
  {
    path: '/workoutexercise',
    name: 'WorkoutExercise',
    component: WorkoutExercise,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle',store.state.workoutCalendar.current_workout_name);
      store.commit('changeComponent','WORKOUTEXERCISE');
      next();
    }   
  },   
  {
    path: '/endworkout',
    name: 'EndWorkout',
    component: EndWorkout,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle',store.state.workoutCalendar.current_workout_name);
      store.commit('changeComponent','ENDWORKOUT');
      next();
    }   
  },  
  {
    path: '/editworkoutexercise',
    name: 'EditWorkoutExercise',
    component: EditWorkoutExercise,
    meta: { requiredAuth: true },
    beforeEnter: function (to, from, next){
      store.commit('changeHeaderTitle',store.state.workoutCalendar.current_workout_name);
      if(store.state.workoutCalendar.is_workout_editable){
        store.commit('changeComponent','EDITWORKOUTEXERCISE');
        next();
      }
    }   
  },  
  {
    path: '/edit-lwe',
    name: 'EditLibraryWorkoutExercise',
    component: EditLibraryWorkoutExercise,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      if(store.state.library.library_name!=null)
        store.commit('changeHeaderTitle',store.state.library.library_name);
      else
        store.commit('changeHeaderTitle','CREATE '+store.state.library.library_tab);
      store.commit('changeComponent','EDITLIBRARYWORKOUTEXERCISE');
      next();
    }   
  },  
  {
    path: '/workoutequipment',
    name: 'WorkoutEquipment',
    component: WorkoutEquipment,
    meta: { requiredAuth: true } ,
    beforeEnter: function (to, from, next){
      if(store.state.workoutCalendar.is_workout_editable){
        store.commit('changeHeaderTitle','Update Equipment');
        store.commit('changeComponent','WORKOUTEQUIPMENT');
        next();
      }
    }  
  }, 
  {
    path: '/workoutexerciseset',
    name: 'WorkoutExerciseSet',
    component: WorkoutExerciseSet,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle',store.state.workoutCalendar.current_workout_name);
      store.commit('changeComponent','WORKOUTEXERCISESET');
      next();
    }      
  },   
  {
    path: '/setallexerciseset',
    name: 'SetAllExerciseSet',
    component: SetAllExerciseSet,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      if(store.state.setAllExerciseSet.setall_workout_type=="CALENDAR"){
        store.commit('changeHeaderTitle',store.state.workoutCalendar.current_workout_name);
      }
      if(store.state.setAllExerciseSet.setall_workout_type=="LIBRARY"){
        store.commit('changeHeaderTitle',store.state.library.library_name);
      }

      store.commit('changeComponent','SETALLEXERCISESET');
      next();
    }      
  },   
  {
    path: '/workoutwfc',
    name: 'WorkoutWFC',
    component: WorkoutWFC,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeComponent','WORKOUTWFC');
      next();
    }    
  },  
  {
    path: '/trainingpreferences',
    name: 'Trainingpreferences',
    component: Trainingpreferences,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Training Preferences');
      store.commit('changeComponent','TRAININGPREFRENCES');
      next();
    }   
  }, 
  {
    path: '/profilesettings',
    name: 'Profilesettings',
    component: Profilesettings,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Profile Settings');
      store.commit('changeComponent','PROFILESETTINGS');
      next();
    }    
  }, 
  {
    path: '/exercise-instruction',
    name: 'ExerciseInstruction',
    component: ExerciseInstruction,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Exercise Info');
      store.commit('changeComponent','EXERCISEINSTRUCTION');
      next();
    }     
  }, 
  {
    path: '/edit-note',
    name: 'EditNotes',
    component: EditNotes,
    meta: { requiredAuth: true } ,
    beforeEnter: function (to, from, next){
        store.commit('changeHeaderTitle',(store.state.exerciseNotes.updateNote.note_id>0 ? 'Edit' : 'Add')+' Note');
        store.commit('changeComponent','EDITNOTES');
        next();
    }  
  },  
 /*  {
    path: '/edit-leaderboard',
    name: 'EditLeaderBoard',
    component: EditLeaderBoard,
    meta: { requiredAuth: true } ,
    beforeEnter: function (to, from, next){
        store.commit('changeHeaderTitle','Leader Board');
        store.commit('changeComponent','EDITLEADERBOARD');
        next();
    }  
  },   */
  /* {
    path: '/view-leaderboard',
    name: 'ViewLeaderBoard',
    component: ViewLeaderBoard,
    meta: { requiredAuth: true } ,
    beforeEnter: function (to, from, next){
        store.commit('changeHeaderTitle','Team Leader Board');
        store.commit('changeComponent','VIEWLEADERBOARD');
        next();
    }  
  }, */
  {
    path: '/accountsettings',
    name: 'Accountsettings',
    component: Accountsettings,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Account Settings');
      store.commit('changeComponent','ACCOUNTSETTINGS');
      next();
    } 
  },
  {
    path: '/changepassword',
    name: 'Changepassword',
    component: Changepassword,
    meta: { requiredAuth: true }, 
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Change Password');
      store.commit('changeComponent','CHANGEPASSWORD');
      next();
    } 
  },
  {
    path: '/contactus',
    name: 'Contactus',
    component: Contactus,
    meta: { requiredAuth: false },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Contact Us');
      store.commit('changeComponent','CONTACTUS');
      next();
    }  
  },  
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    meta: { requiredAuth: false },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','FAQ');
      store.commit('changeComponent','FAQ');
      next();
    }  
  },
  {
    path: '/athletesfaq',
    name: 'Athletes FAQ',
    component: AthletesFAQ,
    meta: { requiredAuth: false },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Athletes FAQ');
      store.commit('changeComponent','ATHLETESFAQ');
      next();
    }  
  },
  {
    path: '/coachfaq',
    name: 'Coach FAQ',
    component: CoachFAQ,
    meta: { requiredAuth: false },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Coach FAQ');
      store.commit('changeComponent','COACHFAQ');
      next();
    }  
  },  
  {
    path: '/instructionfaq',
    name: 'InstructionFAQ',
    component: InstructionFAQ,
    meta: { requiredAuth: false },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Instructional Videos');
      store.commit('changeComponent','INSTRUCTIONFAQ');
      next();
    }  
  },
  {
    path: '/termsofuse',
    name: 'Termsofuse',
    component: Termsofuse,
    meta: { requiredAuth: false },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Terms Of Use');
      store.commit('changeComponent','Termsofuse');
      next();
    }  
  }, 
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: { requiredAuth: false },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Privacy Policy');
      store.commit('changeComponent','PrivacyPolicy');
      next();
    }  
  }, 
  {
    path: '/myteamtrainers',
    name: 'MyTeamTrainers',
    component: MyTeamTrainers,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','My Teams');
      store.commit('changeComponent','MYTEAMTRAINERS');
      next();
    }    
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Team Profile Settings');
      store.commit('changeComponent','TEAM');
      next();
    }  
  }, 
  {
    path: '/team-report',
    name: 'TeamReport',
    component: TeamReport,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Team Training Report');
      store.commit('changeComponent','TEAMREPORT');
      next();
    }  
  },
  {
    path: '/client',
    name: 'Client',
    component: Client,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Create New Client');
      store.commit('changeComponent','CLIENTS');
      next();
    }   
  },
  {
    path: '/organization',
    name: 'Organization',
    component: Organization,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle',store.state.organization.name);
      store.commit('changeComponent','ORGANIZATION');
      next();
    }     
  },
  {
    path: '/jointeam',
    name: 'JoinTeam',
    component: JoinTeam,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Join '+store.state.teamProfile.team_name+' Team');
      store.commit('changeComponent','JOINTEAM');
      next();
    }    
  },
  {
    path: '/teamtrainingpreferences',
    name: 'TeamTrainingPreferences',
    component: TeamTrainingPreferences,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Team Program Settings');
      store.commit('changeComponent','TEAMTRAINGINGPREFRENCES');
      next();
    }   
  },
  /* {
    path: '/join-coach-trainer',
    name: 'JoinCoachTrainer',
    component: JoinCoachTrainer,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Join Coach/Trainer');
      store.commit('changeComponent','JOINCOACHTRAINER');
      next();
    }   
  },
  {
    path: '/join-trainer',
    name: 'JoinTrainer',
    component: JoinTrainer,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Join Trainer');
      store.commit('changeComponent','JOINTRAINER');
      next();
    }   
  }, */
  {
    path: '/join-organization',
    name: 'JoinOrganization',
    component: JoinOrganization,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Join '+store.state.organizationProfile.organization_name+' Organization');
      store.commit('changeComponent','JOINORGANIZATION');
      next();
    }    
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      let temp_component='MYPROFILE'
      if(to.query.uid!=undefined && to.query.uid>0){
        temp_component='USERPROFILE'
      }
      store.commit('changeComponent',temp_component);
      next();
    }       
  },  
  {
    path: '/user-search',
    name: 'UserSearch',
    component: UserSearch,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeComponent','USERSERACH');
      next();
    }       
  }, 
  {
    path: '/team-search',
    name: 'TeamSearch',
    component: TeamSearch,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeComponent','TEAMSERACH');
      store.commit('changeHeaderTitle','Search Team');
      next();
    }       
  },
  {
    path: '/team-profile',
    name: 'TeamProfile',
    component: TeamProfile,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeComponent','TEAMPROFILE');
      store.commit('changeHeaderTitle',store.state.teamProfile.team_name);
      next();
    }    
  },
  {
    path: '/organization-profile',
    name: 'OrganizationProfile',
    component: OrganizationProfile,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeComponent','ORGANIZATIONPROFILE');
      store.commit('changeHeaderTitle',store.state.organizationProfile.organization_name);
      next();
    }     
  },
  {
    path: '/exercisenotes',
    name: 'exerciseNotes',
    component: ExerciseNotes,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Exercise Notes');
      store.commit('changeComponent','EXERCISENOTES');
      next();
    }   
  },  
  {
    path: '/notedetails',
    name: 'noteDetails',
    component: NoteDetails,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','Exercise Note');
      store.commit('changeComponent','NOTEDETAILS');
      next();
    }   
  },
  {
    path: '/library',
    name: 'library',
    component: Library,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle',store.state.library.library_tab+' LIBRARY');
      store.commit('changeComponent','LIBRARY');
      next();
    }   
  }, 
  {
    path: '/copy-library',
    name: 'CopyLibrary',
    component: CopyLibrary,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle','COPY '+ store.state.library.library_tab);
      store.commit('changeComponent','COPYLIBRARY');
      next();
    }   
  },  
  {
    path: '/library-exercise',
    name: 'LibraryExercise',
    component: LibraryExercise,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle',store.state.library.library_name);
      store.commit('changeComponent','LIBRARYEXERCISE');
      next();
    }   
  },  
  {
    path: '/library-exercise-set',
    name: 'LibraryExerciseSet',
    component: LibraryExerciseSet,
    meta: { requiredAuth: true },
    beforeEnter (to, from, next){
      store.commit('changeHeaderTitle',store.state.library.library_name);
      store.commit('changeComponent','LIBRARYEXERCISESET');
      next();
    }    
  },  
  {
    path: '/library-form',
    name: 'LibraryForm',
    component: LibraryForm,
    meta: { requiredAuth: true }
  }, 
  {
    path: '/view-wfc',
    name: 'ViewWFC',
    component: ViewWFC,
    meta: { requiredAuth: true } 
  }, 
  {
    path: '/search-library-workout',
    name: 'SearchLibraryWorkout',
    component: SearchLibraryWorkout,
    meta: { requiredAuth: true },
    beforeEnter: function (to, from, next){
      // if(store.state.workoutCalendar.is_workout_editable){
        store.commit('changeHeaderTitle','Add Library Workout');
        store.commit('changeComponent','SEARCHLIBRARYWORKOUT');
        next();
      // }
    }  
  }, 
/*   {
    path: '/search-library-cycle',
    name: 'SearchLibraryCycle',
    component: SearchLibraryCycle,
    meta: { requiredAuth: true },
    beforeEnter: function (to, from, next){
      // if(store.state.workoutCalendar.is_workout_editable){
      store.commit('changeHeaderTitle','Add Library Cycle');
      store.commit('changeComponent','SEARCHLIBRARYCYCLE');
      next();
      // }
    }  
  },  */
  {
    path: '/search-library-program',
    name: 'SearchLibraryProgram',
    component: SearchLibraryProgram,
    meta: { requiredAuth: true },
    beforeEnter: function (to, from, next){
      // if(store.state.workoutCalendar.is_workout_editable){
        store.commit('changeHeaderTitle','Add Library Program');
        store.commit('changeComponent','SEARCHLIBRARYPROGRAM');
        next();
      // }
    }  
  },  
  {
    path: '/search-exercise',
    name: 'SearchExercise',
    component: SearchExercise,
    meta: { requiredAuth: true },
    beforeEnter: function (to, from, next){
      if(store.state.bottomSearch.result_type=='SWAPEXERCISE'){
        store.commit('changeHeaderTitle','Swap Exercise');
      }
      if(store.state.bottomSearch.result_type=='EXERICSE'){
        store.commit('changeHeaderTitle','Add Exercises');
      }
      store.commit('changeComponent','SEARCHEXERCISE');
      next();
    }  
  }, 
  {
    path: '/tsearch',
    name: 'SearchTeamandTmembers',
    component: SearchTeamandTmembers,
    meta: { requiredAuth: true },
    beforeEnter: function (to, from, next){
      store.commit('changeComponent','SEARCHTEAMANDTMEMBERS');
      next();
    }  
  }, 
]

/* document.addEventListener("deviceready", onDeviceReady, false);

function onDeviceReady(){
    document.addEventListener("backbutton", function(e){
      let component_name=c
       if(component_name=='Home'){
           e.preventDefault();
           navigator.app.exitApp();
       }
       else {
           navigator.app.backHistory();
       }
    }, false);
} */


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    window.scrollTo(0,0);
  },
  routes
})


router.beforeEach((to, from, next) => {
    const auth = store.getters["user/isTokenActive"];

    if(to.fullPath == "/"){
        /* if(!auth && to.meta.requiredAuth){
          return next({path: '/login'});
        }else{
          return next({path: '/user-profile'});
        }     */
        return next();
    }
    else if(auth && !to.meta.requiredAuth){
        // return next({path:"/"});
    }
    else if(!auth && to.meta.requiredAuth){
        return next({path: '/login'});
    }
    store.commit('loading', true)
    return next();

});

router.afterEach(() => {
  let component_name=store.state.component_name
  if(component_name!='WORKOUTEXERCISESET' && component_name!='ENDWORKOUT'){
    store.commit('loading', false);
  }
})
    
export default router 
