<template>
  <div class="MyTeamTrainers">
      <v-card class="mt-8">
        <v-fab-transition>
          <v-btn v-if="upgrade_to_trainer==true || my_teams_ascoach.length > 0" color="avidocolor black--text" dark absolute top right fab small @click="createteam()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-toolbar color="black">
          <v-row align="center" justify="center">
            <v-col cols="12" md="3">
              <h2 class="avidocolor--text">My Teams</h2>
              <v-divider class="white"></v-divider>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider></v-divider>
        
        <v-tabs background-color="black avidocolor--text" grow>
          <v-tab v-if="my_teams_ascoach.length > 0">
            <v-icon left>mdi-account</v-icon>AS COACH
          </v-tab>
          <v-tab v-if="my_teams_asmember.length > 0">
            <v-icon left>mdi-account-multiple-outline</v-icon>AS MEMBER
          </v-tab>


          <v-tab-item v-if="my_teams_ascoach.length > 0">
            
          <v-list>
            <v-expansion-panels focusable>
              <v-expansion-panel
                v-for="(team, tindex) in my_teams_ascoach"
                :key="tindex"
                v-model="teammodel"
                
              >
                <v-expansion-panel-header class="py-0">
                  <v-list-item>
                    <v-list-item-avatar tile size=50>
                      <v-img class="rounded" :alt="team.name"
                        :src="(team.profile_pic != null) ? team.profile_pic : '/images/logo.jpg'"
                        @error="team.profile_pic = '/images/logo.jpg'"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content class="text-left">
                      <v-list-item-title  class="align-center" v-text="team.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content  class="text-left">
                  <v-btn text  @click.stop.prevent="getteamtrainingprefrences(team.id)" class="tbtn avidocolor--text">
                    Program Settings 
                  </v-btn> 
                  <v-btn text @click="getteamprofile(team,true)" class="tbtn avidocolor--text">
                    Manage Roster
                  </v-btn>  
                  <v-btn text @click.stop.prevent="teamhistory(team)" class="tbtn avidocolor--text">
                    Training Report 
                  </v-btn> 
                  <v-btn text @click.stop.prevent="editteam(team.id)" class="tbtn avidocolor--text">
                    Profile Settings 
                  </v-btn> 
                  <v-btn class="float-right" icon dark @click.stop="removeTeam(tindex, team.id)">
                    <v-icon color="red">mdi-minus-circle</v-icon>
                  </v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
          </v-expansion-panels>

        </v-list>
           <!--  <v-list two-line>
              <template v-for="(team, c) in my_teams_ascoach">
                <v-list-item :key="c" @click="getteamprofile(team,false)">

                  <v-list-item-avatar tile size=50>
                    <v-img class="rounded" :alt="team.name"
                      :src="(team.profile_pic != null) ? team.profile_pic : '/images/logo.jpg'"
                      @error="team.profile_pic = '/images/logo.jpg'"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="text-left align-self-start">
                    <v-list-item-title v-text="team.name"></v-list-item-title>
                    <v-list-item-subtitle>
                      <v-btn text  @click.stop.prevent="getteamtrainingprefrences(team.id)" class="tbtn avidocolor--text">
                        Manage Training
                      </v-btn> 
                      <v-btn text @click="getteamprofile(team,true)" class="tbtn avidocolor--text">
                        Manage Roster
                      </v-btn>  
                      <v-btn text @click.stop.prevent="teamhistory(team)" class="tbtn avidocolor--text">
                        Team Report
                      </v-btn> 
                      <v-btn text @click.stop.prevent="editteam(team.id)" class="tbtn avidocolor--text">
                        Team Settings
                      </v-btn> 
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-row>
                      <v-col class="pa-0">
                        <v-btn icon dark @click.stop="removeTeam(tindex, team.id)">
                          <v-icon color="red">mdi-minus-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="tindex < my_teams_ascoach.length - 1" :key="tindex + '_' + team.id"></v-divider>
              </template>
            </v-list> -->

            <v-dialog v-model="dialog" max-width="300">
              <v-card>
                <v-card-title>
                  Are you sure you would like to leave this team?
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="dialog = false">
                    No
                  </v-btn>

                  <v-btn color="avidocolor" text @click.stop.prevent="leaveTeam">
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>
          
          <v-tab-item v-if="my_teams_asmember.length > 0">
            <v-list>
              <template v-for="(mteam, tmindex) in my_teams_asmember">
                <v-list-item :key="tmindex" @click="getteamprofile(mteam,false)">

                  <v-list-item-avatar tile size=50>
                    <v-img class="rounded" :alt="mteam.name"
                      :src="(mteam.profile_pic != null) ? mteam.profile_pic : '/images/logo.jpg'"
                      @error="mteam.profile_pic = '/images/logo.jpg'"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="text-left">
                    <v-list-item-title class="align-center">{{ mteam.name }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-row>
                      <v-col class="pa-0">
                        <v-btn icon dark @click.stop="removeTeamMember(tmindex, mteam.id)">
                          <v-icon color="red">mdi-minus-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="tmindex < my_teams_asmember.length - 1" :key="tmindex + '_' + mteam.id"></v-divider>
              </template>
            </v-list>


            <v-dialog v-model="member_dialog" max-width="300">
              <v-card>
                <v-card-title>
                  Are you sure you would like to leave this team?
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="member_dialog = false">
                    No
                  </v-btn>

                  <v-btn color="avidocolor" text @click.stop.prevent="leaveTeamMember">
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>

        </v-tabs>
      </v-card>

      <v-card class="mt-8" v-if="my_trainers.length > 0">
        <v-toolbar color="black">
          <v-row align="center" justify="center">
            <v-col cols="12" md="3">
              <h2 class="avidocolor--text">My Trainers</h2>
              <v-divider class="white"></v-divider>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-list v-if="my_trainers.length > 0">
          <template v-for="(my_trainer, ctindex) in my_trainers">
            <v-list-item :key="ctindex" @click="getuserprofile(my_trainer.id, my_trainer.name)">

              <v-list-item-avatar tile size=50>
                <v-img class="rounded" alt="my_trainer.name"
                  :src="(my_trainer.profile_pic != null) ? my_trainer.profile_pic : '/images/logo.jpg'"
                  @error="my_trainer.profile_pic = '/images/logo.jpg'"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="text-left">
                <v-list-item-title class="align-center">{{ my_trainer.name }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon color="red" dark
                  @click.stop.prevent="leaveTrainerModel({ 'remove_index': ctindex, 'trainer_user_ids': my_trainer.id })">
                  <v-icon color="red">mdi-minus-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="ctindex < my_trainers.length - 1" :key="ctindex + '_' + my_trainer.id"></v-divider>
          </template>
        </v-list>
      </v-card>

      <v-card class="mt-8" v-if="my_clients.length > 0">
        <v-fab-transition>
          <v-btn color="avidocolor black--text" dark absolute top right fab small to="/client"
            v-if="upgrade_to_trainer">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-toolbar color="black">
          <v-row  justify="center">
            <v-col cols="12" md="3">
              <h2 class="avidocolor--text">My Clients</h2>
              <v-divider class="white"></v-divider>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-list v-if="my_clients.length > 0">
          <template v-for="(my_client, cindex) in my_clients">
            <v-list-item :key="cindex" @click="getuserprofile(my_client.id, my_client.name)">

              <v-list-item-avatar tile size=50>
                <v-img class="rounded" :alt="my_client.name"
                  :src="(my_client.profile_pic != null) ? my_client.profile_pic : '/images/logo.jpg'"
                  @error="my_client.profile_pic = '/images/logo.jpg'"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="text-left">
                <v-list-item-title class="align-center">{{ my_client.name }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon color="red" dark
                  @click.stop.prevent="leaveClientModel({ 'remove_index': cindex, 'client_user_ids': my_client.id })">
                  <v-icon color="red">mdi-minus-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="cindex < my_clients.length - 1" :key="cindex + '_' + my_client.id"></v-divider>
          </template>
        </v-list>
      </v-card>

      <v-card class="mt-8" v-if="my_organization_ascoach.length > 0">
        <v-fab-transition>
          <v-btn color="avidocolor black--text" dark absolute top right fab small @click="upgradeToTrainer()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-toolbar color="black">
          <v-row  justify="center">
            <v-col cols="12" md="4">
              <h2 class="avidocolor--text">My Organizations</h2>
              <v-divider class="white"></v-divider>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-list>
          <template v-for="(my_organization, oindex) in my_organization_ascoach">
            <v-list-item :key="oindex" @click="getorganizationprofile(my_organization)">

              <v-list-item-avatar tile size=50>
                <v-img class="rounded" :alt="my_organization.name"
                  :src="(my_organization.profile_pic != null) ? my_organization.profile_pic : '/images/logo.jpg'"
                  @error="my_organization.profile_pic = '/images/logo.jpg'"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="text-left">
                <v-list-item-title class="align-center" v-text="my_organization.name"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action v-if="my_organization.is_owner == 1">
                <v-btn icon color="red" dark @click.stop.prevent="editOrganization(my_organization.id)">
                  <v-icon color="avidocolor">mdi-pencil-box-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="oindex < my_organization_ascoach.length - 1" :key="oindex + '_' + my_organization.id">
            </v-divider>
          </template>
        </v-list>
      </v-card>

    <template  v-if="my_teams_asmember.length == 0 && my_clients.length == 0  && my_teams_ascoach.length == 0  && my_organization_ascoach.length == 0">
      <v-alert type="error" dense outlined>
        Sorry, You are not part of any organization.
      </v-alert>
    </template>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "MyTeamTrainers",
  data: () => ({
    dialog: false,
    member_dialog: false,
    deleted_tindex: 0,
    deleted_teamid: 0,
    deleted_tmindex: 0,
    teammodel:[]
  }),
  computed: {
    ...mapFields('myTeamTrainers', {
      get_api_call: 'get_api_call',
      upgrade_to_trainer: 'upgrade_to_trainer',
      my_teams_ascoach: 'my_teams_ascoach',
      my_teams_asmember: 'my_teams_asmember',
      my_clients: 'my_clients',
      my_trainers: 'my_trainers',
      my_organization_ascoach: 'my_organization_ascoach',
    }),
    ...mapFields('teamProfile', {
      team_id: 'team_id',
      team_name: 'team_name',
    }),
    ...mapFields('teamTrainingPreferences', {
      prefrence_team_id: 'team_id',
    }),
    ...mapFields('team', {
      edit_team_id: 'team_id',
      team_report: 'team_report',
    }),
    ...mapFields('organizationProfile', {
      organizationp_id: 'organization_id',
      organizationp_name: 'organization_name',
    }),
    ...mapFields('organization', {
      organization_id: 'organization_id',
      organization_name: 'organization_name',
    }),
    ...mapFields("workoutCalendar", {
      get_for_user_id: 'get_for_user_id',
      get_for_team_id: 'get_for_team_id',
    })
  },
  methods: {
    ...mapActions('myTeamTrainers', {
      getMyTeamTrainerModel: 'getMyTeamTrainerModel'
    }),
    ...mapActions('client', {
      leaveClientModel: 'leaveClientModel',
      leaveTrainerModel: 'leaveTrainerModel'
    }),
    ...mapActions('team', {
      leaveTeamAsCoachModel: 'leaveTeamAsCoachModel',
      leaveTeamAsTeamMember:'leaveTeamAsTeamMember'
    }),
    getuserprofile(client_user_id, client_user_name) {
      this.$router.push({ path: 'user-profile?uid=' + client_user_id + '&uname=' + client_user_name });
    },
    getteamprofile(team_model,roaster) {
      this.team_id = team_model.id
      this.team_name = team_model.name
      let params=(roaster) ? '?mr=1' : ''
      this.$router.push({ path: 'team-profile'+params });
    },
    getteamtrainingprefrences(team_id) {
      this.get_for_user_id = 0
      this.get_for_team_id = team_id
      this.prefrence_team_id = team_id
      this.$router.push({ path: 'teamtrainingpreferences?rc=1' });
    },
    editteam(team_id) {
      this.edit_team_id = team_id
      this.$router.push({ path: 'team' });
    },
    teamhistory(team_model) {
      this.team_report.team_id = team_model.id
      this.team_report.team_name = team_model.name
      this.$router.push({ path: 'team-report' });
    },
    createteam() {
      this.edit_team_id = 0
      this.$router.push({ path: 'team' });
    },
    editOrganization(organization_id) {
      this.organization_id = organization_id
      this.$router.push({ path: 'organization' });
    },
    getorganizationprofile(organization) {
      this.organizationp_id = organization.id
      this.organizationp_name = organization.name
      this.$router.push({ path: 'organization-profile' });
    },
    removeTeam(tindex, team_id) {
      this.deleted_tindex = tindex
      this.deleted_teamid = team_id
      this.dialog = true
    },
    leaveTeam() {
      this.dialog = false
      this.leaveTeamAsCoachModel({ 'remove_index': this.deleted_tindex, 'team_id': this.deleted_teamid, 'delete_coach_user_ids': localStorage.getItem('user_id'), 'call_from' : 'TEAMTEAINERS'  })
    },    
    removeTeamMember(tmindex,team_id) {
      this.deleted_tmindex = tmindex
      this.deleted_member_teamid = team_id
      this.member_dialog = true
    },
    leaveTeamMember() {
      this.member_dialog = false
      this.leaveTeamAsTeamMember({ 'remove_index': this.deleted_tmindex, 'team_id': this.deleted_member_teamid, 'delete_member_user_ids': localStorage.getItem('user_id'), 'call_from' : 'TEAMTEAINERS'  })
    },
    upgradeToTrainer() {
      this.organizationp_id = 0
      this.organizationp_name = ''
      this.$router.push({ path: '/join-organization' });
    }

  },
  created() {

    // if (this.get_api_call) {
      this.getMyTeamTrainerModel();
    // }

  },
};
</script>
