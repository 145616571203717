<template>
  <div class="athletesfaq">
   
    <v-expansion-panels focusable class="mt-3">
      <v-expansion-panel
        v-for="(model, index) in afaqitems"
        :key="index"
        v-model="afaqmodel"
      >
        <v-expansion-panel-header>
        {{ model.header}}
        </v-expansion-panel-header>
        <v-expansion-panel-content  class="text-left align-self-start">
            <div class="px-2 pt-4" v-if="model.value!=undefined">{{ model.value }}</div>
            <div class="px-2 pt-4" v-if="model.stepone!=undefined">{{ model.stepone }}</div>
            <div class="px-2 pt-4" v-if="model.steptwo!=undefined">{{ model.steptwo }}</div>
            <div class="px-2 pt-4" v-if="model.stepthree!=undefined">{{ model.stepthree }}
              <div class="px-2 pt-4" v-if="model.stepthreesub1!=undefined">{{ model.stepthreesub1 }}</div>
              <div class="px-2 pt-4" v-if="model.stepthreesub2!=undefined">{{ model.stepthreesub2 }}</div>
            </div>
            <div class="px-2 pt-4" v-if="model.stepfour!=undefined">{{ model.stepfour }}</div>
            <div class="px-2 pt-4" v-if="model.stepfive!=undefined">{{ model.stepfive }}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>



<script>
export default {
  name: "athletesfaq",
  data () {
    return {
      afaqmodel:[],
      afaqitems: [
        {
          header: 'How to create an account (iPhone)',
          stepone: 'Step 1: sign up by entering your enter name, email and date of birth',
          steptwo: 'Step 2: select a goal based program or sport specific program',
          stepthree: 'Step 3: select up to 2 muscles/areas to target in workouts. (target areas are optional and may be left blank)',
          stepfour: 'Step 4: choose how many time you want to workout each week and the equipment you want to use.',
          stepfive: 'Step 5: upload a profile picture, enter bio/goals, height, weight, and gender (this info is all optional and my be left blank. Your height, weight and gender will never be shown to anyone)',
        },
        {
          header: 'How to create an account (Android)',
          stepone: 'Step 1: sign up by entering your enter name, email and date of birth',
          steptwo: 'Step 2: select a goal based program or sport specific program',
          stepthree: 'Step 3: select up to 2 muscles/areas to target in workouts. (target areas are optional and may be left blank)',
          stepfour: 'Step 4: choose how many time you want to workout each week and the equipment you want to use.',
          stepfive: 'Step 5: upload a profile picture and enter bio/goals (this info is all optional and my be left blank)',
        },
        {
          header: 'How to join a team',
          stepone: 'Step 1: from your profile screen tap the search icon in the top left corner',
          steptwo: 'Step 2: search for your team by name, then tap on the one you wish to join to go to that team’s profile.',
          stepthree: 'Step 3: from the teams profile page:',
          stepthreesub1: 'for iPhone: tap “+” button on the team profile picture for Android: tap “Join Team” at bottom of the screen',
          stepthreesub2: 'for Android: tap “Join Team” at bottom of the screen',
          stepfour: 'Step 4: choose to join as an athlete, then enter the join code given to you by your coach.',
          stepfive: 'Step 5: after joining a team you can complete the team workouts by going to Training Preferences in your menu, setting the "Program Type" to Team, and selecting that team',
        },
        {
          header: 'How to set my team colors (iPhone)',
          stepone: 'Step 1: from the “My Training” screen go to the menu and tap “Settings”',
          steptwo: 'Step 2: under the "Profile Settings" tap Select Theme.',
          stepthree: 'Step 3: choose the display theme you want',
        },
        {
          header: 'How to set my team colors (android)',
          stepone: 'Step 1: from the “My Workouts” screen go to the menu and tap “Profile Settings”',
          steptwo: 'Step 2: use the "Select Display Theme" drop down menu to choose the theme you want',
        },
        {
          header: 'View my results',
          stepone: 'Step 1: go to your profile page then tap "Results"',
          steptwo: 'Step 2: you may choose to view a specific exercise or view results on all exercises you ahve completed',
          stepthree: 'Step 3: you may choose the time frame in which you want to view the results',
        },
        {
          header: 'View team results',
          stepone: 'Step 1: go to the teams profile page then tap "Results"',
          steptwo: 'Step 2: search for the exercise you want to see results on',
          stepthree: 'Step 3: choose the time frame in which you want to view the results',
        },
        {
          header: "Change equipment in a workout",
          value: "Your workouts are designed using the equipment you or your coach selected (for team workouts) but if you don't have that equipment available you may change this at the start of a workout to recalibrate the exercises in the workout using the equipment that you have availabe that day.",
        },
        {
          header: "How does ‘Swap Exercise' work?",
          value: "In those times when you can’t complete the assigned exercise, you may tap the ‘Swap Exercise' to select from a list of other exercises and variations that will accomplish the same goal. The original exercise in a workout should be done if possible, as it was assigned for a specific reason.  You may also choose any exercise from the Avido exercise library if you know exactly what you want to do.",
        },
      ],
    }
  },
};
</script>