import axios from "axios";
import { getField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    avido_exercise_list:[],
    user_WarmUp_list:[],
    user_CoolDown_list:[],
    user_Finisher_list:[],
    user_LCycles_list:[],
    user_LProgram_list:[],
  },

  getters: {
    getField,
  },

  mutations: {

    setExerciseState(state, response_data) {
      state.avido_exercise_list = response_data
    },
    reSetExerciseState(state) {
      state.avido_exercise_list = []
    },

    setWARMUPState(state, response_data) {
      state.user_WarmUp_list = response_data
    },
    resetWARMUPState(state) {
      state.user_WarmUp_list = []
    },

    setCoolDownState(state, response_data) {
      state.user_CoolDown_list = response_data
    },
    resetCoolDownState(state) {
      state.user_CoolDown_list = []
    },

    setFinisherState(state, response_data) {
      state.user_Finisher_list = response_data
    },
    resetFinisherState(state) {
      state.user_Finisher_list = []
    },



  },

  actions: {
    getExerciseList({ commit }) {
      return new Promise((resolve) => {
        axios({
          method: 'GET',
          url: 'get_search_exercise_list',
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setExerciseState", response_data)
              commit('bottomSearch/setonlyavidoExercise', response_data, { root: true })
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              commit("reSetExerciseState");
            }
          })
      })
    },

    getWarmUpList({ commit }) {
      return new Promise((resolve) => {
        axios({
          method: 'GET',
          url: 'get_user_wfc',
          params: {
            wfc_type: 'WARMUP'
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setWARMUPState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              commit("resetWARMUPState");
            }
          })
      })
    },
    
    getCoolDownList({ commit }) {
      return new Promise((resolve) => {
        axios({
          method: 'GET',
          url: 'get_user_wfc',
          params: {
            wfc_type: 'COOLDOWN'
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setCoolDownState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              commit("resetCoolDownState");
            }
          })
      })
    },
    
    getFinisherList({ commit }) {
      return new Promise((resolve) => {
        axios({
          method: 'GET',
          url: 'get_user_wfc',
          params: {
            wfc_type: 'FINISHER'
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setFinisherState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              commit("resetFinisherState");
            }
          })
      })
    },
    
  },

};