<template>
  <div class="viewwfc">

   <v-card color="black">
      <v-container>

        <v-row align-items="center" justify="center">
          <v-col cols="12" md="6">
            <h2 class="mb-3 d-inline">
              <span class="avidocolor--text">{{ wfcName }}</span>
            </h2>
          </v-col>
        </v-row>

        <v-row align-items="center">
          <v-col cols="5" md="2"  class="py-0">
            <v-avatar tile size="160">
              <v-img class="rounded" :alt="wfcName" 
                :src="(wfcImage!=null) ? wfcImage : '/images/logo.jpg'"
              @error="wfcImage='/images/logo.jpg'"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="7" md="10">
            <v-row>   
                <v-col cols="12" md="6">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Rounds</v-list-item-subtitle>
                      <v-list-item-title class="text-h5">{{wfcRoundOfTime}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>       
                <v-col cols="12" md="6">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Rest Time</v-list-item-subtitle>
                      <v-list-item-title class="text-h5">
                        <span>
                          <span id="minutes">{{ minutes }}</span>
                          <span id="middle">:</span>
                          <span id="seconds">{{ seconds }}</span>
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="wfcDescription!='null'">
          <v-col class="mx-2 text-justify">
            {{wfcDescription}}
          </v-col>
        </v-row>
      </v-container>  
    </v-card>

    <div class="text-left text-body-1 pt-2">Exercises</div> 
    <template v-if="wfcExercise != null && wfcExercise.length > 0">
      <template  v-for="(model, index) in wfcExercise">
        <v-card  class="rounded-lg mb-1" :key="index+'_'+model.wfc_exercise_id+'_'+model.exercise_id">  
          <v-list-item   @click="exerciseInfo(model)">
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" :alt="model.exercise_name"
                :src="(model.exercise_image_thumbnail != null) ? model.exercise_image_thumbnail : '/images/logo.jpg'"
                @error="model.exercise_image_thumbnail = '/images/logo.jpg'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="text-left">
              <v-list-item-title class="align-center" v-text="model.exercise_name"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="align-center ma-0">
              <v-list-item-action-text>
                <span>{{ model.reps_type }}</span>
                <div class="text-h5">{{model.reps}}</div>
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </template>
    </template>

    <div class="clear"></div>
    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>
        <v-btn  @click="$router.go(-1)" class="my-2 avidocolor--text" text>
          <v-icon>mdi-chevron-left</v-icon>Back
        </v-btn>
        <v-btn v-if="bw!=undefined" @click="nextPrevious()" class="my-2 avidocolor--text" text>
          Next<v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </v-footer>

  </div>
</template>


<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "ViewWFC",
  data () {
    return {
      wfcName:null,
      wfcRestTime:null,
      wfcRoundOfTime:null,
      wfcImage:null,
      wfcDescription:null,
      wfcExercise:null,
      bw: this.$route.query.bw,
    }
  },
  computed:{ 
    ...mapFields('libraryExercise',[
      'viewLibraryWFC', 
    ]),
    minutes: function() {
      const minutes = (this.wfcRestTime!=undefined) ? Math.floor(this.wfcRestTime / 60) : 0;
      return this.padTime(minutes);
    },
    seconds: function() {
      const seconds = (this.wfcRestTime!=undefined) ? this.wfcRestTime - (this.minutes * 60) : 0;
      return this.padTime(seconds);
    }
  },
  methods: {
    padTime: function(time) {
      return (time < 10 ? '0' : '') + time;
    },
  },
  created(){
    if(this.viewLibraryWFC!=null){
      this.wfcName=this.viewLibraryWFC.name;
      this.wfcRestTime=this.viewLibraryWFC.rest_time;
      this.wfcRoundOfTime=this.viewLibraryWFC.no_of_rounds;
      this.wfcImage=this.viewLibraryWFC.image;
      this.wfcDescription=this.viewLibraryWFC.description;
      this.wfcExercise=this.viewLibraryWFC.exercises;
    }
  },
};
</script>
