<template>
<div class="UserSearch">
 <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="black">

            <v-btn icon dark @click="closeDialog()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            
            <v-text-field
              v-model="search_text"
              :loading="isLoading"
              @input="searchWatch"
              clearable
              flat
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search By Name"
            ></v-text-field>

          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

          <v-tabs v-model="tab_type" grow background-color="black"  color="avidocolor">
            <v-tab @change="changeTab()">Athletes</v-tab>
            <v-tab @change="changeTab()">Teams</v-tab>
            <v-tab @change="changeTab()">Coaches</v-tab>
            <v-tabs-slider color="avidocolor"></v-tabs-slider>
          </v-tabs>
        
        <v-data-iterator
          :items="items"      
          disable-pagination
          hide-default-footer
        >
          
            <template v-slot:default="props">
                <v-list>
                  <v-list-item-group>
                    <template v-for="(model, index)  in props.items">
                        <v-list-item
                          :key="index"
                          :value="model"
                          active-class="avidocolor--text text--accent-4"
                          @click="getprofile(model)"
                        >                        
                        <template>
                          <v-list-item-avatar size=50>
                            <v-img
                              :src="
                                model.profile_pic != null
                                  ? model.profile_pic
                                  : '/images/logo.jpg'
                              "
                              @error="model.profile_pic = '/images/logo.jpg'"
                            ></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content class="pl-2 text-left">
                            <v-list-item-title v-text="model.name"></v-list-item-title>
                            <v-list-item-subtitle v-text="model.sub_text"></v-list-item-subtitle>
                          </v-list-item-content>

                        </template>
                      </v-list-item>
                      <v-divider v-if="index < items.length - 1" :key="'d'+index"></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
          </template>
        </v-data-iterator>

      </v-card>
    </v-dialog>
  </v-row>
</div>  
</template>


<script>

import { mapFields } from "vuex-map-fields";
import axios from "axios";

export default {
  name: "UserSearch",
  data: () => ({
    dialog: true,
    isLoading: false,
  }),
  computed:{
    ...mapFields("search", {
      tab_type: "user_tab_type",
      search_text: "user_search_text",
      items: "user_search_item",
    }),
   ...mapFields("library", {
      library_workout_list: "library_workout_list",
    }),
    ...mapFields('teamProfile',{
      team_id:'team_id',
      team_name:'team_name',
    }),
  },  
  methods:{
    closeDialog(){
      this.dialog=false
      this.$router.go(-1) ;
    },
    changeTab(){
      this.search_text=null
      this.items=[]
    },
    getprofile(model){
      if(this.tab_type==1){
        this.team_id=model.id
        this.team_name=model.name
        this.$router.push({ path: 'team-profile' });
      }else{
        this.$router.push({ path: 'user-profile?uid='+model.id+'&uname='+model.name });
      }
    },
    searchWatch(){
      let result_type=null
      if(this.tab_type==0) result_type='USER';
      if(this.tab_type==1) result_type='TEAM';
      if(this.tab_type==2) result_type='TRAINER';

      // Items have already been requested
//       if (this.isLoading) return
      let search_keyword=this.search_text
      if(search_keyword!=null){
          // Lazily load input items
          if(search_keyword.length>0){
            this.isLoading = true
              axios({
                method: 'GET',
                url:  `search_by_name`,
                params: {
                  search_keyword: search_keyword,
                  result_type: result_type,
                },
              })
              .then((resp) => {
                // console.log(resp.data.response)
                this.items=resp.data.response
              })
              .catch((error) => {
                this.$store.commit("SET_NOTIFICATION", {
                  display: true,
                  text: error.response.data.errors,
                  alertClass: "error",
                });
              })
              .finally(() => (this.isLoading = false));

          }

      } 
      
    },
  },
}
</script>

