<template>
  <div class="team-search">
    <v-card class="pa-2"> 
      <v-text-field
        v-model="search_text"
        :loading="isLoading"
        @input="searchWatch"
        clearable
        hide-details
        color="avidocolor"
        prepend-inner-icon="mdi-magnify"
        label="Search Team"
      ></v-text-field>

        <v-data-iterator
          :items="items"      
          disable-pagination
          hide-default-footer
          no-data-text=''
        >
            <template v-slot:default="props">
                <v-list>
                  <v-list-item-group>
                    <template v-for="(model, index)  in props.items">
                        <v-list-item
                          :key="index"
                          :value="model"
                          active-class="avidocolor--text text--accent-4"
                          @click="getteamprofile(model)"
                        >                        
                        <template>
                          <v-list-item-avatar size=50>
                            <v-img
                              :src="
                                model.profile_pic != null
                                  ? model.profile_pic
                                  : '/images/logo.jpg'
                              "
                              @error="model.profile_pic = '/images/logo.jpg'"
                            ></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content class="pl-2 text-left">
                            <v-list-item-title v-text="model.name"></v-list-item-title>
                            <v-list-item-subtitle v-text="model.sub_text"></v-list-item-subtitle>
                          </v-list-item-content>

                        </template>
                      </v-list-item>
                      <v-divider v-if="index < items.length - 1" :key="'d'+index"></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
          </template>
        </v-data-iterator>
      </v-card> 
</div>
</template>


<script>

import { mapFields } from "vuex-map-fields";
import axios from "axios";

export default {
  name: "TeamSearch",
  data: () => ({
    search_text: null,
    isLoading: false,
  }),
  computed:{
    ...mapFields('teamProfile',{
      team_id:'team_id',
      team_name:'team_name',
    }),
  },  
  methods:{
    getteamprofile(model){
      this.team_id=model.id
      this.team_name=model.name
      this.$router.push({ path: 'team-profile' });
    },
    searchWatch(){
      let result_type='TEAM';

      // Items have already been requested
//       if (this.isLoading) return
      let search_keyword=this.search_text
      if(search_keyword!=null){
          // Lazily load input items
          if(search_keyword.length>0){
            this.isLoading = true
              axios({
                method: 'GET',
                url:  `search_by_name`,
                params: {
                  search_keyword: search_keyword,
                  result_type: result_type,
                },
              })
              .then((resp) => {
                // console.log(resp.data.response)
                this.items=resp.data.response
              })
              .catch((error) => {
                this.$store.commit("SET_NOTIFICATION", {
                  display: true,
                  text: error.response.data.errors,
                  alertClass: "error",
                });
              })
              .finally(() => (this.isLoading = false));

          }

      } 
      
    },
  },
}
</script>

