<template>
  <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      :timeout="NOTIFICATION.timeout"
      :color="NOTIFICATION.class"
      dark
    >
      {{ NOTIFICATION.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>


<script>
import { mapGetters } from "vuex";


export default {
  name: "notification",
  data: () => ({
    multiLine: true,
    text: `I'm a multi-line snackbar.`,
  }),
  computed: {
    ...mapGetters(["NOTIFICATION"]),
    snackbar: {
        get () {
            return this.$store.getters.NOTIFICATION.display;
        },
        set () {
            this.$store.commit("SET_NOTIFICATION", {
                display: false
            })
        }
    }
  }
};
</script>
