<template>
  <div class="forgotpassword">

    <v-card class="pa-8">
      <v-form
        ref="forgotpasswordform"
        v-model="validate"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
        <v-text-field
          v-model="email"
          :rules="emailRules"
          required
          class="pa-md-4"
          prepend-icon="mdi-email"
          name="email"
          label="Email"
          color="avidocolor"
        ></v-text-field>
        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor avidosolidtextcolor--text"> Submit </v-btn>
        </v-card-actions>
      </v-form>

      <v-row align="center" justify="center">
        <v-col>
          <v-btn text color="avidocolor" to="/login"> Login </v-btn> |
          <v-btn text color="avidocolor" to="/signup"> Sign Up </v-btn> 
        </v-col>
      </v-row>
    </v-card>

  </div>
</template>


<script>

import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ForgotPassword",
  data() {
    return {
      show:false,
      validate: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  computed:{
    ...mapFields('forgotPassword',{
      email:'email',
    }),
  },
  methods: {
    ...mapActions('forgotPassword',{
      submitForgotPassword:'submitForgotPassword'      
    }), 
    onSubmit: function () {
      if (this.$refs.forgotpasswordform.validate()) {
        this.submitForgotPassword();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {
    this.$store.commit("forgotPassword/unsetForgotPassword");
  }
};
</script>