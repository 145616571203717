<template>
  <div class="LibraryForm">

      <v-form
        ref="libraryForm"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
        class="pb-12 mb-12"
      >
      <v-card class="pa-2">
        <v-text-field
          v-model="library_name"
          class="pa-md-4"
          prepend-icon="mdi-pencil"
          name="library_name"
          label="Enter Name"
          color="avidocolor"
          :rules="[(v) => !!v || 'Name is required']"
        ></v-text-field>

        <v-textarea
          counter='256'
          v-model="library_description"
          class="pa-md-4"
          prepend-icon="mdi-checkbox-marked-circle-outline"
          name="library_description"
          color="avidocolor"
          rows="2"
        >
          <template v-slot:label>
            <div>Description</div>
          </template>
        </v-textarea>

        <v-row>
          <v-col cols="5" md="2">
            <v-icon>mdi-camera</v-icon> Select Image
          </v-col> 
          <v-col cols="7" md="10">
          <v-file-input
              @change="onFileChange"
              v-model="library_image"
              id="image-upload"
              class="d-none"
            ></v-file-input>
            <v-avatar :color="(imagePreviewURL!=null) ? null : 'avidocolor'" class="rounded" tile size="160" @click="openUpload">
              <v-icon color="black"  x-large :class="(imagePreviewURL!=null) ? 'd-none' : null">
                mdi-camera
              </v-icon>
              <v-img class="rounded" :alt="library_name" 
                :class="(imagePreviewURL==null) ? 'd-none' : null"
                :src="(imagePreviewURL!=null) ? imagePreviewURL : '/images/logo.jpg'"
              @error="imagePreviewURL='/images/logo.jpg'"></v-img>
            </v-avatar>
          </v-col>
        </v-row> 


        <template v-if="library_tab == 'WARMUP' || library_tab == 'FINISHER' || library_tab == 'COOLDOWN'">
              
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="no_of_rounds"
                    :placeholder="placeholderString"
                    v-on:focus="noOfRoundsFocus"
                    v-on:change="noOfRoundsChange"
                    class="pa-md-4"
                    prepend-icon="mdi-pencil"
                    name="no_of_rounds"
                    label="Enter # Of Rounds"
                    color="avidocolor"
                    :rules="[(v) => !!v || 'No Of Rounds is required']"
                    type="number"
                    min=1
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" >
                  <v-row>
                    <v-col cols="12" md="3"  align="left"><v-label><v-icon>mdi-pencil</v-icon> Rest Time</v-label></v-col>
                    <v-col cols="12" md="9" >
                      <v-row  align-items="left">
                        <v-col cols="6" class="pa-0 px-2">
                          <v-select
                            :items="rest_time_minitems"
                            v-model="wfc_RestTime_minutes"
                            label="MM"
                            dense
                            outlined
                            color="avidocolor"
                            v-on:change="onchangeRestTimeMin"
                          ></v-select>
                        </v-col>
                        <v-col cols="6" class="pa-0 pr-2">
                          <v-select
                            :items="rest_time_secitems"
                            v-model="wfc_RestTime_seconds"
                            label="SS"
                            dense
                            outlined
                            color="avidocolor"
                            v-on:change="onchangeRestTimeSec"
                          ></v-select>
                        </v-col>
                      </v-row> 
    
                    </v-col>
                  </v-row> 
                  
                </v-col>
              </v-row>
          </template> 
              
      </v-card>

        <template v-if="library_tab == 'CYCLE'">
          <div class="text-left text-body-1 pt-2" v-if="library_workout_array.length>0">Workouts</div> 
            <draggable
              v-model="library_workout_array"
              class="list-group"
              handle=".handle"
              ghost-class="grey"
              @start="dragging = true"
              @end="dragging = false"
              v-if="(library_workout_array.length>0)" 
              color="grey darken-4"
            >
              <div
                class="list-group-item"
                v-for="(model,windex) in library_workout_array"
                :key="windex+model.name"
              >
              <v-card class="rounded-lg mb-1" :key="windex">
                <v-list-item>
                  <v-list-item-action class="mr-4">
                    <v-row>
                      <v-col class="pa-0">
                        <v-btn
                          icon
                          color="red"
                          dark
                          @click.stop.prevent="
                            removeLibraryWorkouts(windex)
                          "
                        >
                          <v-icon color="red">mdi-minus-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-action>
                  <v-list-item-avatar tile size=50>
                      <v-img class="rounded" 
                      :alt="model.name"
                      :src="
                        model.image != null ? model.image : '/images/logo.jpg'
                      "
                      @error="model.image = '/images/logo.jpg'"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="text-left">
                    <v-list-item-title v-html="model.name" class="pl-2"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-row>
                      <v-col class="pa-0">
                        <v-btn
                          icon
                          color="avidocolor"
                          dark
                          class="handle"
                        >
                          <v-icon color="avidocolor">mdi-drag</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
               </v-card>
              </div>
            </draggable>
 
        </template> 

        <template v-if="(library_tab == 'PROGRAM')">
          <!-- <v-text-field 
            v-model="program_price"
            class="pa-md-4"
            prepend-icon="mdi-pencil"
            name="program_price"
            label="Enter Price"
            color="avidocolor"
            :rules="[(v) => !!v || 'Price is required']"
          ></v-text-field> -->

          <template v-if="(library_cycle_array.length>0)" >
            <div class="text-left text-body-1 pt-2">Cycles</div> 
              <draggable
                v-model="library_cycle_array"
                class="list-group"
                handle=".handle"
                ghost-class="grey"
                @start="dragging = true"
                @end="dragging = false"
                color="grey darken-4"
              >
                <div
                  class="list-group-item"
                  v-for="(model,cindex) in library_cycle_array"
                  :key="cindex"
                  two-line
                >
                <v-card class="rounded-lg pa-2 mb-1" :key="cindex">

                  <div class="text-left">{{model.name}}</div> 
                  <v-list-item class="pa-0">
                      <v-list-item-action class="mr-4">
                        <v-row>
                          <v-col class="pa-0">
                            <v-btn
                              icon
                              color="red"
                              dark
                              @click.stop.prevent="
                                removeLibraryCycle(cindex)
                              "
                            >
                              <v-icon color="red">mdi-minus-circle</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-list-item-action>

                      <v-list-item-avatar tile size=50>
                        <v-img class="rounded" 
                          :alt="model.name"
                          :src="
                            model.image != null ? model.image : '/images/logo.jpg'
                          "
                          @error="model.image = '/images/logo.jpg'"
                        ></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content class="pa-0 text-left align-self-start">
                        <v-list-item-subtitle>
                          <v-row  align="center" justify="center">>
                            <v-col class="pt-0 pb-0">
                              <v-text-field
                                class="pa-md-4"
                                :placeholder="placeholderString"
                                v-model="library_cycle_array[cindex].num_of_weeks"
                                v-on:focus="numOfWeekFocus(cindex)"
                                v-on:change="numOfWeekChange(cindex)"
                                hint="# of weeks"
                                persistent-hint
                                color="avidocolor"
                                type="number" 
                                min=1
                                :rules="[rules.required, rules.numberRule]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-row>
                          <v-col class="pa-0">
                            <v-btn
                              icon
                              color="avidocolor"
                              dark
                              class="handle"
                            >
                              <v-icon color="avidocolor">mdi-drag</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-list-item-action>
                  </v-list-item>

                </v-card>

              </div>
            </draggable>
         </template>

        </template>


        <template v-if="library_tab == 'WARMUP' || library_tab == 'FINISHER' || library_tab == 'COOLDOWN'">
            <template v-if="(wfc_lexrecise_array.length>0)" >
            <div class="text-left text-body-1 pt-2">Exercises</div> 
           
              <draggable
                v-model="wfc_lexrecise_array"
                class="list-group"
                handle=".handle"
                ghost-class="grey"
                @start="dragging = true"
                @end="dragging = false"
                color="grey darken-4"
              >
                <div
                  class="list-group-item"
                  v-for="(addexerciseids,eindex) in wfc_lexrecise_array"
                  :key="addexerciseids.exercise_name"
                  two-line
                >
                <v-card class="rounded-lg pa-2 mb-1" :key="eindex">
                  <div class="text-left">{{addexerciseids.exercise_name}}</div> 
                  <v-list-item :key="addexerciseids.exercise_id+eindex" class="pa-0">
                      <v-list-item-action class="mr-4">
                        <v-row>
                          <v-col class="pa-0">
                            <v-btn
                              icon
                              color="red"
                              dark
                              @click.stop.prevent="
                                removeExercise(eindex,addexerciseids)
                              "
                            >
                              <v-icon color="red">mdi-minus-circle</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-list-item-action>

                      <v-list-item-avatar tile size=50>
                        <v-img class="rounded" 
                          :alt="addexerciseids.exercise_name"
                          :src="
                            addexerciseids.exercise_image_thumbnail != null ? addexerciseids.exercise_image_thumbnail : '/images/logo.jpg'
                          "
                          @error="addexerciseids.exercise_image_thumbnail = '/images/logo.jpg'"
                        ></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content class="pa-0 text-left align-self-start">
                        <v-list-item-subtitle>
                          <v-row  align="center" justify="center">>
                            <v-col class="pt-0 pb-0">
                              <v-text-field
                                v-model="addexerciseids.reps"
                                :placeholder="placeholderString"
                                v-on:focus="repsFocus(eindex)"
                                v-on:change="repsChange(eindex)"
                                name="exercise_reps"
                                persistent-hint
                                color="avidocolor"
                                type="number" 
                                min=0
                              ></v-text-field>
                            </v-col>
                            <v-col class="pt-0 pb-0">
                              <v-select
                                v-model="addexerciseids.reps_type"
                                :items="reps_type_options"
                                item-value="reps_type" 
                                item-text="display_reps_type" 
                                color="avidocolor"
                                item-color="avidocolor"
                                name="exercise_reps_type"
                              ></v-select>
                            </v-col> 
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-row>
                          <v-col class="pa-0">
                            <v-btn
                              icon
                              color="avidocolor"
                              dark
                              class="handle"
                            >
                              <v-icon color="avidocolor">mdi-drag</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-list-item-action>
                  </v-list-item>

                </v-card>

                </div>
            </draggable>

         </template>
        </template>
     
        <v-footer color="black" fixed>
          <v-row justify="center" no-gutters>
            <v-btn v-if="(library_tab=='CYCLE')" @click="searchLibraryWorkout()" class="my-2 avidocolor--text" text>
              <v-icon>mdi-plus-box</v-icon>Add Workouts
            </v-btn>
             <v-btn v-if="(library_tab=='PROGRAM')" @click="searchLibraryCycle()"  class="my-2 avidocolor--text" text>
              <v-icon>mdi-plus-box</v-icon>Add Cycles
            </v-btn>
            <v-btn v-if="(library_tab=='WARMUP' || library_tab=='FINISHER' || library_tab=='COOLDOWN')" @click="getFavoriteExercise('LWFCEXERICSE')"  class="my-2 avidocolor--text" text>
              <v-icon>mdi-plus-box</v-icon>Add Exercise
            </v-btn>

            <v-btn  type="submit" class="my-2 avidocolor--text" text>
              <v-icon>mdi-content-save</v-icon>save {{ library_tab }}
            </v-btn>
          </v-row>
        </v-footer>

      </v-form>
    
  </div>
</template>



<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import draggable from 'vuedraggable';

export default {
  name: "LibraryForm",
  components: {
    draggable
  },
  data() {
    return {
      valid: false,
      startingValue: null,
      placeholderString: '',
      exercise_search: null,
      lworkout_search: null,
      lcycle_search: null,
      rest_time_minitems: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59],
      rest_time_secitems: ['00','05','10','15','20','25','30','35','40','45','50','55'],
      rules: {
          required: value => !!value || 'Cycle length is required.',
          numberRule: v  => {
            if (!isNaN(parseFloat(v)) && v >= 1) return true;
            return 'Cycle length has to be > 0.';
          },
     },
    };
  },

  computed: {
    ...mapFields("library", {
      library_id: "library_id",
      library_name:'library_name',
      library_description:'library_description',
      library_tab: "library_tab",
      library_image:'library_image',
      imagePreviewURL: "imagePreviewURL",
      library_workout_list: "library_workout_list",
      library_cycle_list: "library_cycle_list",
    }),
    ...mapFields('libraryExercise',{
    //  program_price:'program_price',
      reps_type_options:'reps_type_options',
      wfc_rest_time:'wfc_rest_time',
      wfc_RestTime:'wfc_RestTime',
      wfc_RestTime_minutes:'wfc_RestTime_minutes',
      wfc_RestTime_seconds:'wfc_RestTime_seconds',
      no_of_rounds:'round_of_time',
      lexerciseList:'lexerciseList',
      wfc_lexrecise_array:'wfc_lexrecise_array',
      library_workout_array:'library_workout_array',
      library_cycle_array:'library_cycle_array',
    }), 
    ...mapFields('bottomSearch',{
      result_type:'result_type',
      selected_id:'selected_id',
    }),
  },
  methods: {

    ...mapActions("library", {
      getLibraryModel: "getLibraryModel",
    }),

    ...mapActions("bottomSearch", {
      getFavoriteExercise: "getFavoriteExercise",
    }),
    ...mapActions('libraryExercise',{
      saveWFC:'saveWFC',
      viewLibraryModel: "viewLibraryModel",
      saveLCycleLProgram: "saveLCycleLProgram",
    }),

    openUpload () {
      document.getElementById('image-upload').click()
    },
   
    onFileChange(payload) {
      //const file = payload.target.files[0]; // use it in case of normal HTML input
      const file = payload; // in case vuetify file input
      if (file) {
        this.imagePreviewURL = URL.createObjectURL(file);
        URL.revokeObjectURL(file); // free memory
        // console.log(payload)
      } else {
        this.imagePreviewURL =  null
      }
    },

    searchLibraryWorkout(){
      this.$store.commit("bottomSearch/unsetselectedExercise");
      if(this.library_workout_list.length>0){
        this.result_type='LADDWORKOUTS'
        this.selected_id=0
        this.$router.push({ path: 'search-exercise' });
      }else{
        this.getLibraryModel(['WORKOUT','LADDWORKOUTS',0]);
      } 
    },

    searchLibraryCycle(){
      this.$store.commit("bottomSearch/unsetselectedExercise");
      if(this.library_cycle_list.length>0){
        this.result_type='LADDCYCLES'
        this.selected_id=0
        this.$router.push({ path: 'search-exercise' });
      }else{
        this.getLibraryModel(['CYCLE','LADDCYCLES',0]);
      }
    }, 

   /*  updateSelectedExercise(value) {
       this.exercise_search=[]
       let library_tab=this.library_tab
       value.new_exercise = 1;
       if(library_tab=='WARMUP' || library_tab=='FINISHER' || library_tab=='COOLDOWN'){
        value.wfc_exercise_id = 0;
        value.reps_type = "REPS";
        value.reps = 1;
        this.wfc_lexrecise_array.push(value)
       }
    }, 

    updateLibraryWorkouts(value) {    
       this.lworkout_search=[]  
       this.lworkouts_array.push(value)
    },
    
    updateLibraryCycle(value) {
       this.lcycle_search=[]  
       value.num_of_weeks = 1;
       this.lcycle_array.push(value)
    },
    
    checkMove: function(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    */

    onSubmit: function () {
      if (this.$refs.libraryForm.validate()) {
        let library_tab=this.library_tab
        let display_error=false
        let error_mesg=null

        if(this.wfc_lexrecise_array.length==0 && (library_tab=='WARMUP' || library_tab=='FINISHER' || library_tab=='COOLDOWN')){
          display_error=true
          error_mesg='Please add exercise in LIBRARY '+library_tab
        }

        if(library_tab=='CYCLE' && this.library_workout_array.length==0){
          display_error=true
          error_mesg='Please add library workout in LIBRARY '+library_tab
        }

        if(library_tab=='PROGRAM' && this.library_cycle_array.length==0){
          display_error=true
          error_mesg='Please add library cycle in LIBRARY '+library_tab
        }

        if(display_error){
          this.$store.dispatch(
            "setNotification",
            {
              display: true,
              text: error_mesg,
              alertClass: "error",
            },
            { root: true }
          );
        }else{
          if(library_tab=='CYCLE' || library_tab=='PROGRAM'){
            this.saveLCycleLProgram();
          }
          if(library_tab=='WARMUP' || library_tab=='FINISHER' || library_tab=='COOLDOWN'){
            this.saveWFC();
          }  
        }

      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },

    removeLibraryWorkouts(index) {
      if (index >= 0) this.library_workout_array.splice(index, 1);
    },

    removeLibraryCycle(index) {
      if (index >= 0){
        this.library_cycle_array.splice(index, 1);
      }
    },

    removeExercise(index,library_model) { 
      if("lw_exercise_id" in library_model){
        this.delete_lw_exerciseids.push(library_model.lw_exercise_id)
      }
      if (index >= 0) this.wfc_lexrecise_array.splice(index, 1);
    },

    onchangeRestTimeMin(minutes){
      let new_min=minutes*60
      let new_sec=this.wfc_RestTime_seconds
      let new_rest_time=parseInt(new_min)+parseInt(new_sec)
      this.wfc_rest_time = new_rest_time
    },
    
    onchangeRestTimeSec(seconds){
      let new_min=this.wfc_RestTime_minutes*60
      let new_sec=seconds
      let new_rest_time=parseInt(new_min)+parseInt(new_sec)
      this.wfc_rest_time = new_rest_time
    },

    repsFocus(setIndex){
      this.placeholderString=(this.wfc_lexrecise_array[setIndex].reps!=null) ? this.wfc_lexrecise_array[setIndex].reps.toString() : ''
      this.startingValue=this.wfc_lexrecise_array[setIndex].reps
      this.wfc_lexrecise_array[setIndex].reps=null
    },    
    repsChange(setIndex){
      if(this.wfc_lexrecise_array[setIndex].reps==null && this.startingValue!=null){
        this.wfc_lexrecise_array[setIndex].reps=this.startingValue
      }
    },  

    noOfRoundsFocus(){
      this.placeholderString=(this.no_of_rounds!=null) ? this.no_of_rounds.toString() : ''
      this.startingValue=this.no_of_rounds
      this.no_of_rounds=null
    },    
    noOfRoundsChange(){
      if(this.no_of_rounds==null && this.startingValue!=null){
        this.no_of_rounds=this.startingValue
      }
    },   
    numOfWeekFocus(setIndex){
      this.placeholderString=(this.library_cycle_array[setIndex].num_of_weeks!=null) ? this.library_cycle_array[setIndex].num_of_weeks.toString() : ''
      this.startingValue=this.library_cycle_array[setIndex].num_of_weeks
      this.library_cycle_array[setIndex].num_of_weeks=null
    },    
    numOfWeekChange(setIndex){
      if(this.library_cycle_array[setIndex].num_of_weeks==null && this.startingValue!=null){
        this.library_cycle_array[setIndex].num_of_weeks=this.startingValue
      }
    },  
  },

};
</script>

<style scoped>
.handle {
  cursor: move; 
}
</style>