
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import store from '../store/index';

Vue.use(Vuetify)

const opts = {
    theme: { 
        dark: true,
        themes: {
            dark: {
              avidocolor: store.state.darkTextColor,
              avidosolidtextcolor: store.state.solidTextColor,
              defaultcolor: '#52F60C',
            },
          },
    },
}

export default new Vuetify(opts)