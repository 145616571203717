<template>
  <div class="WorkoutWFC">

   <v-card color="black">
      <v-container>

        <v-row align-items="center" justify="center">
          <v-col cols="12" md="6">
            <h2 class="mb-3 d-inline">
              <span class="avidocolor--text">{{ wfcName }}</span>
            </h2>
          </v-col>
        </v-row>

        <v-row align-items="center">
          <v-col cols="5" md="2"  class="py-0">
            <v-avatar tile size="160">
              <v-img class="rounded" :alt="wfcName" 
                :src="(wfcImage!=null) ? wfcImage : '/images/logo.jpg'"
              @error="wfcImage='/images/logo.jpg'"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="7" md="10">
            <v-row>   
                <v-col cols="12" md="6">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Rounds</v-list-item-subtitle>
                      <v-list-item-title class="text-h5">{{wfcRoundOfTime}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>       
                <v-col cols="12" md="6">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Rest Time</v-list-item-subtitle>
                      <v-list-item-title>
                        <div id="timer">
                          <!--  Restart Timer -->
                          <v-icon 
                            id="reset" 
                            class="mr-2"
                            v-if="bw!=undefined && resetButton"
                            color="avidocolor"
                            style="vertical-align: initial;"
                            @click="resetTimer()">
                              mdi-history
                          </v-icon>
                          <span class="text-h5">
                            <span id="minutes">{{ minutes }}</span>
                            <span id="middle">:</span>
                            <span id="seconds">{{ seconds }}</span>
                          </span>
                          <!-- Start TImer -->
                            <v-icon 
                              id="start" 
                              v-if="bw!=undefined && !timer"
                              class="ml-2"
                              color="avidocolor"
                              style="vertical-align: initial;"
                              @click="startTimer()">
                              mdi-play-circle
                            </v-icon>
                          <!--  Pause Timer -->
                            <v-icon 
                              id="stop" 
                              class="ml-2"
                              color="avidocolor"
                              style="vertical-align: initial;"
                              v-if="bw!=undefined && timer"
                              @click="stopTimer()">
                                mdi-pause-circle
                            </v-icon>
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="wfcDescription!='null'">
          <v-col class="mx-2 text-justify">
            {{wfcDescription}}
          </v-col>
        </v-row>
      </v-container>  
    </v-card>

    <div class="text-left text-body-1 pt-2">Exercises</div> 
    <template v-if="wfcExercise.length > 0">
      <template  v-for="(model, index) in wfcExercise">
        <v-card  class="rounded-lg mb-1" :key="index+'_'+model.wfc_exercise_id+'_'+model.exercise_id">  
          <v-list-item   @click="exerciseInfo(model)">
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" :alt="model.exercise_name"
                :src="(model.exercise_image_thumbnail != null) ? model.exercise_image_thumbnail : '/images/logo.jpg'"
                @error="model.exercise_image_thumbnail = '/images/logo.jpg'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="text-left">
              <v-list-item-title class="align-center" v-text="model.exercise_name"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="align-center ma-0">
              <v-list-item-action-text>
                  <span>{{ model.reps_type }}</span>
                  <div class="text-h5">{{ (model.reps>0) ? model.reps : "max"}}</div>
              </v-list-item-action-text>
            </v-list-item-action>

          </v-list-item>
        </v-card>
      </template>
    </template>

    <div class="clear"></div>
    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>
        <v-btn  @click="$router.go(-1)" class="my-2 avidocolor--text" text>
          <v-icon>mdi-chevron-left</v-icon>Back
        </v-btn>
        <v-btn v-if="bw!=undefined" @click="nextPrevious()" class="my-2 avidocolor--text" text>
          Next<v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </v-footer>

  </div>
</template>


<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "WorkoutWFC",
  data() {
    return {
      bw: this.$route.query.bw,
      qwfctype: this.$route.query.wfctype,
      wfcName: null,
      wfcRestTime: null,
      wfcRoundOfTime: null,
      wfcImage: null,
      wfcDescription: null,
      wfcExercise: null,
      timer: null,
      resetButton: true,
    }
  },
  computed: {
    ...mapFields('workoutExercise', [
      'exer_index',
      'super_index',
      'wfcType',
      'warmup',
      'cooldown',
      'finisher',
      'totalTime',
    ]),
    ...mapFields('exerciseInstruction', [
      'exercise_name',
      'exercise_instruction_id',
    ]),
    minutes: function() {
      const minutes = Math.floor(this.totalTime / 60);
      return this.padTime(minutes);
    },
    seconds: function() {
      const seconds = this.totalTime - (this.minutes * 60);
      return this.padTime(seconds);
    }
  },
  methods: {

    startTimer: function() {
      this.timer = setInterval(() => this.countdown(), 1000);
    },
    stopTimer: function() {
      clearInterval(this.timer);
      this.timer = null;
    },
    resetTimer: function() {
      this.totalTime = this.wfcRestTime //(25 * 60);
      clearInterval(this.timer);
      this.timer = null;
    },
    padTime: function(time) {
      return (time < 10 ? '0' : '') + time;
    },
    countdown: function() {
      if(this.totalTime >= 1){
        this.totalTime--;
      } else{
        this.totalTime = 0;
        this.resetTimer()
      }
    },

    nextPrevious(){
      let qbr=parseInt(this.$route.query.br)+1
      if (this.qwfctype == 'WARMUP') {
        this.$router.push({ path: 'workoutexerciseset?bw=1&ei=0&si=0&br='+qbr });
      } else if (this.qwfctype == 'FINISHER') {
        if (this.cooldown != null) {
          this.wfcType = 'COOLDOWN'
          this.wfcName = this.cooldown.name;
          this.wfcRestTime = this.cooldown.rest_time;
          this.totalTime = this.cooldown.rest_time;
          this.wfcRoundOfTime = this.cooldown.no_of_rounds;
          this.wfcImage = this.cooldown.image;
          this.wfcDescription = this.cooldown.description;
          this.wfcExercise = this.cooldown.exercises;
          this.$router.push({ path: 'workoutwfc?bw=1&wfctype=COOLDOWN&br='+qbr });
        } else {
          this.wfcType = 'finishworkout'
          this.$router.push({ path: 'endworkout?fw=1&br='+qbr });
        }
      } else if (this.qwfctype == 'COOLDOWN') {  
        this.wfcType = 'finishworkout'
        this.$router.push({ path: 'endworkout?fw=1&br='+qbr });
      } else if (this.qwfctype == 'finishworkout') {  
        this.wfcType = 'finishworkout'
        this.$router.push({ path: 'endworkout?fw=1&br='+qbr });
      }
    },
    exerciseInfo(cmodel) {
      this.exercise_instruction_id = cmodel.exercise_id
      this.exercise_name = cmodel.exercise_name
      this.$router.push({ path: 'exercise-instruction' });
    },
  },
  watch: {
    '$route.query.wfctype'(wfc_type){
      if (wfc_type == 'WARMUP' && this.warmup != null) {
        this.wfcName = this.warmup.name;
        this.wfcRestTime = this.warmup.rest_time;
        this.totalTime = this.warmup.rest_time;
        this.wfcRoundOfTime = this.warmup.no_of_rounds;
        this.wfcImage = this.warmup.image;
        this.wfcDescription = this.warmup.description;
        this.wfcExercise = this.warmup.exercises;
      } else if (wfc_type == 'FINISHER' && this.finisher != null) {
        this.wfcName = this.finisher.name;
        this.wfcRestTime = this.warmup.rest_time;
        this.totalTime = this.warmup.rest_time;
        this.wfcRoundOfTime = this.finisher.no_of_rounds;
        this.wfcImage = this.finisher.image;
        this.wfcDescription = this.finisher.description;
        this.wfcExercise = this.finisher.exercises;
      } else if (wfc_type == 'COOLDOWN' && this.cooldown != null) {
        this.wfcName = this.cooldown.name;
        this.wfcRestTime = this.cooldown.rest_time;
        this.totalTime = this.cooldown.rest_time;
        this.wfcRoundOfTime = this.cooldown.no_of_rounds;
        this.wfcImage = this.cooldown.image;
        this.wfcDescription = this.cooldown.description;
        this.wfcExercise = this.cooldown.exercises;
      }      
    },
  },
  created() {

    if(this.qwfctype!=undefined && this.qwfctype == 'WARMUP' && this.warmup != null) {
      this.wfcName = this.warmup.name;
      this.wfcRestTime = this.warmup.rest_time;
      this.totalTime = this.warmup.rest_time;
      this.wfcRoundOfTime = this.warmup.no_of_rounds;
      this.wfcImage = this.warmup.image;
      this.wfcDescription = this.warmup.description;
      this.wfcExercise = this.warmup.exercises;
    } else if (this.qwfctype!=undefined && this.qwfctype == 'FINISHER' && this.finisher != null) {
      this.wfcName = this.finisher.name;
      this.wfcRestTime = this.warmup.rest_time;
      this.totalTime = this.warmup.rest_time;
      this.wfcRoundOfTime = this.finisher.no_of_rounds;
      this.wfcImage = this.finisher.image;
      this.wfcDescription = this.finisher.description;
      this.wfcExercise = this.finisher.exercises;
    } else if (this.qwfctype!=undefined && this.qwfctype == 'COOLDOWN' && this.cooldown != null) {
      this.wfcName = this.cooldown.name;
      this.wfcRestTime = this.cooldown.rest_time;
      this.totalTime = this.cooldown.rest_time;
      this.wfcRoundOfTime = this.cooldown.no_of_rounds;
      this.wfcImage = this.cooldown.image;
      this.wfcDescription = this.cooldown.description;
      this.wfcExercise = this.cooldown.exercises;
    }
    if (this.qwfctype!=undefined && this.bw != undefined) {
      this.$store.commit('changeHeaderAfterbw',this.bw);
    }else{
      this.$store.commit('changeHeaderAfterbw',null);
    }
  },
};
</script>
