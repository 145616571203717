import axios from "axios";
import { getField,updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    user_id: 0,
    first_name: null,
    user_name: null,
    profile_url: null,
    bio: null,
    total_reps: 0,
    total_weight: 0,
    total_workouts: 0,
    my_teams: [],
    trainer_user_id: 0,
    trainer_join_code: null,
  //  is_my_trainer: false,
    upgrade_to_trainer: false,
    is_private_account: false,
  },

  getters: {
    getField,
  },

  mutations: {
    setModelState(state, response_data) {
      state.total_reps = response_data.total_reps
      state.total_weight = response_data.total_weight
      state.total_workouts = response_data.total_workouts
      state.my_teams = response_data.my_teams
      state.user_id = response_data.user.id
      state.first_name = response_data.user.first_name
      state.user_name = response_data.user.first_name+' '+response_data.user.last_name
      state.profile_url = response_data.user.profile_pic
      state.bio = response_data.user.bio
      state.upgrade_to_trainer = response_data.user.upgrade_to_trainer
    //  state.is_my_trainer = response_data.is_my_trainer
      state.is_private_account = response_data.is_private_account
    },
    resetTrainer(state){
      state.trainer_user_id = 0
      state.trainer_join_code = null
      state.upgrade_to_trainer = false
    //  state.is_my_trainer = false
      state.is_private_account = false
    },
    updateField
  },

  actions: {
    getuserProfileModel({ commit, dispatch }) {
      commit("resetTrainer");
      return new Promise((resolve,reject) => {
        axios({
          method: 'GET',
          url: 'get_user_profile',
          params: {
            get_for_user_id: this.state.userProfile.user_id
          }
        }).then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setModelState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

   /*  joinTrainer({ dispatch }) {
      return new Promise((resolve,reject) => {
        axios({
          method: 'POST',
          url: 'join_trainer',
          data: {
            trainer_user_id: this.state.userProfile.trainer_user_id,
            trainer_join_code: this.state.userProfile.trainer_join_code,
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const success_mesg = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: success_mesg,
                alertClass: "success"
              }, { root: true })
              router.go(-1);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },  */



  },

};