<template>
  <div class="libraryexerciseset">
    <v-card>
        <v-row>
          <v-col cols="12">
            <h2 class="mb-3 d-inline">
              <span class="ml-4 float-left">{{ superset_letter }}</span>
              <span class="avidocolor--text">{{ current_exercise_name }}</span>
            </h2>
            <v-icon small @click="exerciseInfo()" class="ml-2" style="vertical-align: top;" color="avidocolor" dark>
              mdi-information
            </v-icon>
          </v-col>
        </v-row>

        <!--  <v-container style="min-height: 250px">
        <v-fade-transition mode="out-in">
          <v-row>
            <v-col cols="12" class="pb-0"> -->

        <v-img :src="image_large" max-height="600" class="grey darken-4"
          @error="image_large = '/images/logo.jpg'"></v-img>

        <v-list color="black" class="pa-0">
          <v-divider></v-divider>
          <v-row>
            
            <v-col cols="6">
              <v-list-item-action class="ma-0 align-center">
                <div class="text-body-1 pt-2 grey--text">Tempo</div>
                <v-dialog v-model="tempoDialog" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on" min-width="0px">
                      <span
                        :class="(lexerciseList[exer_index][super_index]['tempo'] != null) ? 'text-h5 avidocolor--text' : 'text-h5'">
                        {{ tempoLDisplay }}
                      </span>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Edit Tempo</span>
                    </v-card-title>
                    <v-card-subtitle class="text-left pt-4 pb-0">
                      This 3 or 4 digit number is the length in seconds of each phase of a repetition:
                      <br/>1st: Eccentric (lowering / relaxation)
                      <br/>2nd: Isometric (pause after eccentric)
                      <br/>3rd: Concentric (lifting / exertion)
                      <br/>4th: Isometric (pause after concentric)
                    </v-card-subtitle>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="avidocolor" label="Tempo" clearable
                              v-model="lexerciseList[exer_index][super_index]['tempo']"
                              v-on:change="data_change == false ? data_change = !data_change : data_change" type="number"
                              :rules="[(v) => (v === null || v.length <= 4) || 'Max 4 characters allow.']"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions
                      v-if="lexerciseList[exer_index][super_index]['tempo'] == null || lexerciseList[exer_index][super_index]['tempo'].length <= 4">
                      <v-btn color="red" text @click="tempoDialog = false">
                        close
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="avidocolor" text @click="tempoDialog = false">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-list-item-action>
            </v-col>

            <v-col cols="6">
              <v-list-item-action class="ma-0 align-center">
                <div class="text-body-1 py-2 grey--text">Rest Time</div>

                <v-row align-items="center" justify="center">
                  <v-col cols="5" class="px-0">
                    <v-select :items="rest_time_minitems" v-model="current_lexer_rest_time_minutes" label="MM" dense
                      outlined color="avidocolor" v-on:change="onchangeRestTimeMin"></v-select>
                  </v-col>
                  <v-col cols="5" class="px-0">
                    <v-select :items="rest_time_secitems" v-model="current_lexer_rest_time_seconds" label="SS" dense
                      outlined color="avidocolor" v-on:change="onchangeRestTimeSec"></v-select>
                  </v-col>
                </v-row>

              </v-list-item-action>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-list>

        <!--  </v-col>
          </v-row>
        </v-fade-transition> -->

        <v-row v-if="exercise_sets.length > 0">
          <v-col cols="12" class="pt-0 pb-0">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="pa-0"></th>
                    <th class="pa-0 text-center">SETS</th>
                    <!-- <th class="text-center text-body-1">REPS</th> -->
                    <th class="pa-0">
                      <div class="text-center">
                        <v-btn id="reps_type_menu" color="avidocolor" class="font-weight-bold" text>
                          <div class="avidocolor--text">{{ selectedRepsType }}</div>
                        </v-btn>
                        <v-menu offset-y activator="#reps_type_menu" open-on-hover>
                          <v-list>
                            <v-list-item v-for="(item, index) in reps_type_options" :key="index" :value="item.reps_type"
                              @click="changeRepsType(item)">
                              <v-list-item-title>{{ item.display_reps_type }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      <!-- <v-select
                        v-model="lexerciseList[exer_index][super_index]['reps_type']"
                        :items="reps_type_options"
                        item-value="reps_type" 
                        item-text="display_reps_type" 
                        color="avidocolor"
                        item-color="avidocolor"
                        class="setinputheader setinput"
                        v-on:change="data_change==false ? data_change=!data_change : data_change"
                        autofocus
                      ></v-select> -->
                    </th>
                    <th class="pa-0">
                      <div class="text-center">
                        <v-btn id="weight_type_menu" color="avidocolor" class="font-weight-bold" text>
                          <div class="avidocolor--text">{{ selectedWeightType }} </div>
                        </v-btn>
                        <v-menu offset-y activator="#weight_type_menu" open-on-hover>
                          <v-list>
                            <v-list-item v-for="(item, index) in weight_type_options" :key="index"
                              :value="item.weight_type" @click="changeWeightType(item)">
                              <v-list-item-title>{{ item.display_weight_type }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      <!-- <v-select
                        v-model="lexerciseList[exer_index][super_index]['weight_type']"
                        :items="weight_type_options"
                        item-value="weight_type" 
                        item-text="display_weight_type" 
                        color="avidocolor"
                        item-color="avidocolor"
                        class="setinputheader setinput"
                        v-on:change="data_change==false ? data_change=!data_change : data_change"
                        autofocus
                      ></v-select> -->
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in exercise_sets" :key="item.id">

                    <td>
                      <v-btn icon color="red" dark @click.stop.prevent="
                deleteSet(index)
                ">
                        <v-icon color="red">mdi-minus-circle</v-icon>
                      </v-btn>
                    </td>

                    <td>{{ index + 1 }}</td>
                    <td>
                      <!-- <v-edit-dialog> {{ item.reps }}
                        <template v-slot:input>
                          <v-text-field
                            v-model="exercise_sets[index].reps"
                            v-on:change="data_change==false ? data_change=!data_change : data_change"
                            label="Edit Reps"
                            single-line
                            color="avidocolor"
                          ></v-text-field>
                        </template>
      </v-edit-dialog>
      <v-text-field v-model="exercise_sets[index].reps"
        v-on:change="data_change==false ? data_change=!data_change : data_change" placeholder="0" class="setinput"
        color="avidocolor" type="number" min=0></v-text-field> -->
                      <v-text-field :value="(exercise_sets[index].reps == 0) ? 'max' : exercise_sets[index].reps"
                        placeholder="" v-on:focus="repsFocus(index)" v-on:change="repsChange(index, $event)"
                        :id="'reps_' + index" :type="(exercise_sets[index].reps == 0) ? 'text' : 'number'" hide-spin-buttons
                        color="avidocolor" class="setinput" min=0>
                      </v-text-field>
                    </td>
                    <td>
                      <!-- <v-edit-dialog>
                         {{ item.weight }}
                        <template v-slot:input>
                          <v-text-field
                            v-model="exercise_sets[index].weight"
                            v-on:change="data_change==false ? data_change=!data_change : data_change"
                            label="Edit Weight"
                            single-line
                            color="avidocolor"
                          ></v-text-field>
                        </template>
                      </v-edit-dialog> 
                        <v-text-field
                          v-model="exercise_sets[index].weight"
                          v-on:change="data_change==false ? data_change=!data_change : data_change"
                          placeholder="0"
                          class="setinput"
                          color="avidocolor"
                          type="number" min=0
                        ></v-text-field> -->
                      <v-text-field
                        :value="(exercise_sets[index].weight == 0) ? lexerciseList[exer_index][super_index]['zero_weight_label'] : exercise_sets[index].weight"
                        placeholder="" v-on:focus="weightFocus(index)" v-on:change="weightChange(index, $event)"
                        :id="'weight_' + index" :type="(exercise_sets[index].weight == 0) ? 'text' : 'number'"
                        hide-spin-buttons color="avidocolor" class="setinput" min=0>
                      </v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <!-- </v-container> -->
    </v-card>

    <div class="clear"></div>
    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>

        <v-btn @click="exerciseSearch('LSWAPEXERCISE')" class="my-2 avidocolor--text" text>
          <v-icon>mdi-shuffle-variant</v-icon>Swap
        </v-btn>

        <v-btn @click="addSets()" class="my-2 avidocolor--text" text>
          <v-icon>mdi-plus-box</v-icon> Set
        </v-btn>

        <v-btn @click="$router.go(-1)" class="my-2 avidocolor--text" text>
          <v-icon>mdi-content-save</v-icon>save
        </v-btn>

      </v-row>
    </v-footer>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  data() {
    return {
      dialog: false,
      tempoDialog: false,
      startingValue: null,
      selectedRepsType: null,
      selectedWeightType: null,
      rest_time_minitems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59],
      rest_time_secitems: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
    }
  },
  computed: {
    ...mapFields('libraryExerciseSet', [
      'current_exercise_id',
      'superset_letter',
      'current_exercise_name',
      'image_large',
      'exercise_sets',
      'current_lexer_rest_time_minutes',
      'current_lexer_rest_time_seconds',
    ]),
    ...mapFields('libraryExercise', {
      exer_index: 'exer_index',
      super_index: 'super_index',
      data_change: 'data_change',
      lexerciseList: 'lexerciseList',
      reps_type_options: 'reps_type_options',
      weight_type_options: 'weight_type_options',
      total_reps: 'total_reps',
      total_sets: 'total_sets',
      total_weight: 'total_weight',
    }),
    ...mapFields('exerciseInstruction', {
      exercise_name: 'exercise_name',
      exercise_instruction_id: 'exercise_instruction_id',
    }),
    ...mapFields('bottomSearch', {
      result_type: 'result_type',
    }),
    tempoLDisplay() {
      return (this.lexerciseList[this.exer_index][this.super_index]['tempo'] != null) ? ((this.lexerciseList[this.exer_index][this.super_index]['tempo'].length <= 4) ? this.lexerciseList[this.exer_index][this.super_index]['tempo'] : '----') : '----'
    }
  },
  methods: {
    ...mapActions("bottomSearch", {
      getAvidoExercise: "getAvidoExercise",
      getFavoriteExercise: "getFavoriteExercise",
    }),
    exerciseInfo() {
      this.exercise_name = this.current_exercise_name
      this.exercise_instruction_id = this.current_exercise_id
      this.$router.push({ path: 'exercise-instruction' });
    },
    exerciseSearch(result_type) {
      this.result_type = result_type
      this.$store.commit("bottomSearch/unsetswapRecomExercise");
      this.getAvidoExercise(result_type)
      this.getFavoriteExercise(result_type)
    },
    addSets() {
      this.data_change = true
      let current_set_count = this.exercise_sets.length
      let last_resp = 0
      let last_weight = 0
      if (current_set_count > 0) {
        last_resp = this.exercise_sets[current_set_count - 1].reps
        last_weight = this.exercise_sets[current_set_count - 1].weight
      }
      let new_set = {
        reps: last_resp,
        set_id: current_set_count,
        weight: last_weight,
      }
      this.lexerciseList[this.exer_index][this.super_index]['exercise_set_count'] = current_set_count + 1;
      this.exercise_sets.push(new_set)
    },
    deleteSet(remove_index) {
      this.data_change = true
      this.exercise_sets.splice(remove_index, 1);
      let current_set_count = this.exercise_sets.length
      this.lexerciseList[this.exer_index][this.super_index]['exercise_set_count'] = current_set_count;
    },
    repsFocus(setIndex) {
      this.$el.querySelector("input#reps_" + setIndex).type = "number"
      this.startingValue = this.exercise_sets[setIndex].reps
      this.exercise_sets[setIndex].reps = null
      this.$el.querySelector("input#reps_" + setIndex).placeholder = (this.exercise_sets[setIndex].reps != null) ? this.exercise_sets[setIndex].reps.toString() : ''
      // console.log(setIndex+'=repsFocus_'+this.exercise_sets[setIndex].reps)
    },
    repsChange(setIndex, content) {
      this.data_change = true
      this.exercise_sets[setIndex].reps = content
      if (content == null && this.startingValue != null) {
        this.exercise_sets[setIndex].reps = this.startingValue
      }
      this.$el.querySelector("input#reps_" + setIndex).type = "text"
      // console.log(setIndex+'=repsChange'+this.exercise_sets[setIndex].reps)
    },
    weightFocus(setIndex) {
      this.$el.querySelector("input#weight_" + setIndex).type = 'number'
      this.startingValue = this.exercise_sets[setIndex].weight
      this.exercise_sets[setIndex].weight = null
      this.$el.querySelector("input#weight_" + setIndex).placeholder = (this.exercise_sets[setIndex].weight != null) ? this.exercise_sets[setIndex].weight.toString() : ''
    },
    weightChange(setIndex, content) {
      this.data_change = true
      this.exercise_sets[setIndex].weight = content
      if (content == null && this.startingValue != null) {
        this.exercise_sets[setIndex].weight = this.startingValue
      }
      this.$el.querySelector("input#weight_" + setIndex).type = "text"
    },
    onchangeRestTimeMin(minutes) {
      this.data_change = true
      let new_min = minutes * 60
      let new_sec = this.current_lexer_rest_time_seconds
      let new_rest_time = parseInt(new_min) + parseInt(new_sec)
      this.lexerciseList[this.exer_index][this.super_index]['rest_time'] = new_rest_time
    },
    onchangeRestTimeSec(seconds) {
      this.data_change = true
      let new_min = this.current_lexer_rest_time_minutes * 60
      let new_sec = seconds
      let new_rest_time = parseInt(new_min) + parseInt(new_sec)
      this.lexerciseList[this.exer_index][this.super_index]['rest_time'] = new_rest_time
    },
    changeRepsType(selected_repsType) {
      this.data_change = true
      this.lexerciseList[this.exer_index][this.super_index]['reps_type'] = selected_repsType.reps_type
      this.selectedRepsType = selected_repsType.display_reps_type
    },
    changeWeightType(selected_weightType) {
      this.data_change = true
      this.lexerciseList[this.exer_index][this.super_index]['weight_type'] = selected_weightType.weight_type
      this.selectedWeightType = selected_weightType.display_weight_type
    }
  },
  created() {
    // For Reps type and Weight type
    this.selectedRepsType = (this.reps_type_options.find(option => option.reps_type === this.lexerciseList[this.exer_index][this.super_index]['reps_type'])).display_reps_type
    this.selectedWeightType = (this.weight_type_options.find(option => option.weight_type === this.lexerciseList[this.exer_index][this.super_index]['weight_type'])).display_weight_type
  },
  beforeDestroy() {
    this.total_reps = this.total_weight = this.total_sets = 0
    this.completed_reps = this.completed_weight = this.completed_sets = 0
    for (let superexerInfo of Object.values(this.lexerciseList)) {
      for (let subexerInfo of Object.values(superexerInfo)) {
        for (let setInfo of Object.values(subexerInfo.sets)) {
          if (setInfo.checkmark_status) {
            if (subexerInfo.reps_type == "REPS") {
              this.completed_reps = parseFloat(this.completed_reps) + parseFloat(setInfo.reps)
            }
            if (subexerInfo.reps_type == "REPS" && subexerInfo.weight_type == "LBS") {
              this.completed_weight = parseFloat(this.completed_weight) + parseFloat(setInfo.reps * setInfo.weight)
            }

            this.completed_sets = parseFloat(this.completed_sets) + 1
          } else {
            this.total_reps = parseFloat(this.total_reps) + parseFloat(setInfo.reps)
            this.total_weight = parseFloat(this.total_weight) + parseFloat(setInfo.reps * setInfo.weight)
          }
        }
        this.total_sets += subexerInfo.exercise_set_count;
      }
    }
  }
};
</script>
