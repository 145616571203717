import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    current_calendar_option: [],
    program_type_option: [],
    no_workouts_per_week_option:  [],
    goal_option: [],
    sport_option: [],
    position_option: {},
    muscles_option: [],
    equipment_option: [],
    athlete_team: [],
    library_program: [],
    userTrainingState: {
      current_calendar: '',
      program_type: 'SPORT',
      program_start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      sport_inseason_start_date: '',
      sport_inseason_end_date: '',
      goal_end_date: '',
      no_workouts_per_week: 0,
      goal_sport_id: 0,
      position_id: 0,
      target1_muscle_id: 0,
      target2_muscle_id: 0,
      current_team_id: {},
      equipment_ids: [15],
    },
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    resetTrainingModelState(state) {
      state.position_option = {}
      state.goal_option = []
      state.sport_option = []
      state.muscles_option = []
      state.equipment_option = []
      state.program_type_option = []
      state.library_program = []
      state.athlete_team = []
      state.userTrainingState.current_calendar = ''
      state.userTrainingState.program_start_date =  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      state.userTrainingState.sport_inseason_start_date = ''
      state.userTrainingState.sport_inseason_end_date = ''
      state.userTrainingState.goal_end_date = ''
      state.userTrainingState.no_workouts_per_week = 0
      state.userTrainingState.program_type = 'SPORT'
      state.userTrainingState.target1_muscle_id = ''
      state.userTrainingState.target2_muscle_id = ''
      state.userTrainingState.current_team_id = {}
      state.userTrainingState.goal_sport_id = 0
      state.userTrainingState.position_id = 0
      state.userTrainingState.equipment_ids = [15]
    },

    setTrainingTeamState(state, team_data) {
      if(team_data!=null){
        for (let team_value of Object.values(team_data)){
            state.userTrainingState.current_team_id={
              id : team_value.id,
              name: team_value.name
            }
        }
      }
    },
    setTrainingModelState(state, user_data) {
      state.userTrainingState.current_calendar = user_data.current_calendar
      if(user_data.user_preference!=null){
        state.userTrainingState.program_start_date = (user_data.user_preference.program_start_date==null) ? (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) : user_data.user_preference.program_start_date
        state.userTrainingState.sport_inseason_start_date = user_data.user_preference.sport_inseason_start
        state.userTrainingState.sport_inseason_end_date = user_data.user_preference.sport_inseason_end
        state.userTrainingState.goal_end_date = user_data.user_preference.goal_end_date
        state.userTrainingState.no_workouts_per_week = user_data.user_preference.no_workouts_per_week
        state.userTrainingState.program_type = (user_data.user_preference.program_type==null) ? 'SPORT' : user_data.user_preference.program_type
        state.userTrainingState.target1_muscle_id = (user_data.user_preference.target1_muscle.id>0) ? user_data.user_preference.target1_muscle.id : ''
        state.userTrainingState.target2_muscle_id = (user_data.user_preference.target2_muscle.id>0) ? user_data.user_preference.target2_muscle.id : ''
        state.userTrainingState.goal_sport_id = (user_data.user_preference.goal_sport_id>0) ? user_data.user_preference.goal_sport_id : ''
        state.userTrainingState.position_id = (user_data.user_preference.position.id>0) ? user_data.user_preference.position.id : ''

        if(user_data.user_preference.equipments!=null){
          state.userTrainingState.equipment_ids=[];
          for (let value of Object.values(user_data.user_preference.equipments)) {
            if(typeof(value.name) != "undefined" && value.name !== null) {
              state.userTrainingState.equipment_ids.push(value.id)
            }
          }
        }
      }

    /*   if(user_data.user_preference.goal_sport_id>0){
        if(typeof(user_data.user_preference.goal_sport_name) != "undefined" && user_data.user_preference.goal_sport_name !== null) {
          state.userTrainingState.goal_sport_id={
              id : user_data.user_preference.goal_sport_id,
              name: user_data.user_preference.goal_sport_name
          }
        }
      } */


    },

    setAvidoOptionsState(state, option_data) {
      state.current_calendar_option=option_data.CALENDAR_TYPE
      state.athlete_team=option_data.ATHLETE_TEAM
      state.library_program=option_data.LIBRARY_PROGRAM
      state.program_type_option=option_data.PROGRAM_TYPE
      state.no_workouts_per_week_option=option_data.WORKOUT_PER_WEEK
      state.equipment_option=option_data.EQUIPMENT
      state.muscles_option=option_data.MUSCLES
      state.goal_option=option_data.GOAL

      // state.position_option=option_data.POSITION
      // state.sport_option=option_data.SPORT
      
      if(option_data.SPORT!=null){
        for (let value of Object.values(option_data.SPORT)) {
          if(typeof(value.sport_positions) != "undefined" && value.sport_positions !== null) {
            state.position_option[value.id]=value.sport_positions
          }
          state.sport_option.push({
            id : value.id,
            name: value.name
          })
        }
      }
    },

  },

  actions: {
    
    getTrainingModel({ dispatch,commit }) {
      commit("resetTrainingModelState");
      return new Promise((resolve,reject) => {

        let one = "get_training_preferences"
        let two = "get_avido_options"

        const requestOne = axios.get(one);
        const requestTwo = axios.get(two);

        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
          const responseOne = responses[0]
          const responseTwo = responses[1]

          const training_resp_code = responseOne.status
          if (training_resp_code == 200) {
            const user_data = responseOne.data.response.user_training_preferences
            const team_data = responseOne.data.response.team_training_preferences
            commit("setTrainingModelState", user_data);
            commit("setTrainingTeamState", team_data);

            if(user_data.current_calendar=='LIBRARY'){
              const user_library_data = responseOne.data.response.library_training_preferences
              if(typeof(user_library_data) != "undefined" && user_library_data !== null) {
                for (let library_value of Object.values(user_library_data)){
                    this.state.workoutCalendar.assign_library_program_id={
                      id : user_data.current_library_program_id,
                      name: library_value.name
                    }
                  this.state.workoutCalendar.assign_program_startdate=user_data.user_preference.program_start_date
                }
              }
            }
            
          } 

          const avido_options_resp_code = responseTwo.status
          if (avido_options_resp_code == 200) {
            const options_data = responseTwo.data.response
            commit("setAvidoOptionsState", options_data);
          } 
          resolve(responses)
        })).catch(error => {
          const resp_code = error.response.status
          if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })
      })
    },
    
    updateTrainingModel({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        let model=this.state.trainingPreferences.userTrainingState
        model.equipment_ids =  model.equipment_ids.join(',')
        model.target1_muscle_id = (model.target1_muscle_id==undefined) ? 0 : model.target1_muscle_id
        model.target2_muscle_id = (model.target2_muscle_id==undefined) ? 0 : model.target2_muscle_id
        if (typeof model.current_team_id === 'object') {
          model.current_team_id = model.current_team_id.id
        }

        axios({ url: 'set_training_preferences', data: model, method: 'POST' })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const resp_message = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });

              const user_data = resp.data.response
              commit("setTrainingModelState", user_data);

              let uri = window.location.search.substring(1); 
              let params = new URLSearchParams(uri);
              if(params.get("ar")==1){
                router.push({ path: '/profilesettings?ar=1' });
              }else{
                // router.push({ path: '/week-summary' });
                router.go(-1);
              }
                            
              resolve(resp)
            } else {
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass: "error"
              }, { root: true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
  },

};