<template>
  <div class="cycleDetails">
    <v-card color="black">
      <v-container>
          <v-row class="align-center">
            <v-col cols="5" md="2"  class="py-0">
              <v-avatar tile size="160">
                <v-img class="rounded" :alt="current_cycle_name" 
                  :src="(current_cycle_image!=null) ? current_cycle_image : '/images/logo.jpg'"
                @error="current_cycle_image='/images/logo.jpg'"></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="7" md="10">
              <v-row>  
                <v-col cols="12">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Workouts</v-list-item-subtitle>
                      <v-list-item-title class="text-h5">{{newcworkouts.length}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="current_cycle_description!='null'">
            <v-col class="mx-2 text-justify">
              {{current_cycle_description}}
            </v-col>
          </v-row>
        </v-container>  
      </v-card>

      <template v-if="newcworkouts.length>0">
        <div class="text-left text-body-1 pt-2">Workouts</div> 
        <template v-for="(model, index) in newcworkouts">
          <v-card class="rounded-lg mb-1" :key="index">
          <v-list-item :key="model.workout_name">
            <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="model.workout_name" :src="(model.workout_image!=null)?model.workout_image:'/images/logo.jpg'" @error="model.workout_image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="text-left">
              <v-list-item-title class="align-center" v-text="model.workout_name"></v-list-item-title>
            </v-list-item-content>


            <v-list-item-action class="ma-0">
              <v-list-item-action-text>
                DAY
                <div class="text-h5">{{index+1}}</div>
              </v-list-item-action-text>            
            </v-list-item-action>

          </v-list-item>
          </v-card>
        </template>
      </template>

  </div>
</template>


<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "CycleDetails",
  data () {
    return {
      newcworkouts: [],
    }
  },
  computed:{
    ...mapFields('workoutCalendar',[
      'current_cycle_id', 
      'current_cycle_name', 
      'current_cycle_image', 
      'current_cycle_description', 
      'calendar_workouts'
    ]), 
  },
  created(){

    for (let calendarworkouts of Object.values(this.calendar_workouts)) {
      if(this.current_cycle_id==calendarworkouts.cycle_id){
        this.newcworkouts.push(calendarworkouts)
      }else if(this.current_cycle_id==calendarworkouts.library_cycle_id){
        this.newcworkouts.push(calendarworkouts)
      }
    }
  }
};
</script>
