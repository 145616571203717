var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"results"},[_c('v-form',{ref:"resultForm",attrs:{"lazy-validation":"","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{staticClass:"pa-md-4 pb-md-0",attrs:{"name":"selected_exercise_id","color":"avidocolor","label":"Start typing to Search Exercise","multiple":"","items":(_vm.team_id>0) ? _vm.avido_exercise_list : _vm.result_exercise_array,"item-text":"exercise_name","item-value":"exercise_id","menu-props":{closeOnContentClick:true}},on:{"change":_vm.onSubmit},scopedSlots:_vm._u([((_vm.team_id==0))?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.result_exercise_array.length > 0 ? 'avidocolor' : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null,{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0 && _vm.selected_exercises.length==1)?_c('v-chip',{attrs:{"color":"avidocolor--text"}},[_c('span',[_vm._v(_vm._s(item.exercise_name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" All Exercises ")]):_vm._e()]}},{key:"item",fn:function(data){return [_c('v-list-item-avatar',{attrs:{"tile":"","size":"50"},on:{"click":_vm.selectexercise}},[_c('v-img',{staticClass:"rounded",attrs:{"src":data.item.exercise_image_thumbnail != null
                  ? data.item.exercise_image_thumbnail
                  : '/images/logo.jpg'},on:{"error":function($event){data.item.exercise_image_thumbnail = '/images/logo.jpg'}}})],1),_c('v-list-item-content',{staticClass:"text-left align-self-start",on:{"click":_vm.selectexercise}},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.exercise_name)}})],1)]}}],null,true),model:{value:(_vm.selected_exercises),callback:function ($$v) {_vm.selected_exercises=$$v},expression:"selected_exercises"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.result_type_items,"item-text":"title","item-value":"value","color":"avidocolor","label":"Result Type","required":""},on:{"change":_vm.onSubmit},model:{value:(_vm.result_type),callback:function ($$v) {_vm.result_type=$$v},expression:"result_type"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":(_vm.team_id > 0) ? _vm.team_time_frame_option : (_vm.selected_exercises > 0) ? _vm.oneex_time_frame_option : _vm.allex_time_frame_option,"item-text":"title","item-value":"value","color":"avidocolor","label":"Time Frame"},on:{"change":_vm.onSubmit},model:{value:(_vm.time_frame),callback:function ($$v) {_vm.time_frame=$$v},expression:"time_frame"}})],1),(_vm.time_frame == 'YYYY-MM')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.month_time,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.month_time=$event},"update:return-value":function($event){_vm.month_time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedDateFormattedDatefns,"label":"Select Month","prepend-icon":"mdi-calendar","color":"avidocolor","readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,3343947456),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"type":"month","color":"avidocolor black--text","scrollable":""},model:{value:(_vm.month_time),callback:function ($$v) {_vm.month_time=$$v},expression:"month_time"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"avidocolor"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.month_time); _vm.onSubmit()}}},[_vm._v(" OK ")])],1)],1)],1):_vm._e()],1)],1),(_vm.result_count > 0)?_c('v-card',[_c('div',{attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"bar","height":_vm.result_count,"options":_vm.chartOptions,"series":_vm.y_chart_array}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }