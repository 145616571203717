<template>
  <div class="register">

    <v-card class="pa-8">
      <v-form
        ref="registerform"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
        v-model="validate"
      >
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="firstname"
              :rules="firstnameRules"
              required
              class="pa-md-4"
              prepend-icon="mdi-account"
              name="firstname"
              label="First Name"
              color="avidocolor"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="lastname"
              :rules="lastnameRules"
              required
              class="pa-md-4"
              prepend-icon="mdi-account"
              name="lastname"
              label="Last Name"
              color="avidocolor"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          required
          class="pa-md-4"
          prepend-icon="mdi-email"
          name="email"
          label="Email"
          color="avidocolor"
        ></v-text-field>

        <v-text-field
          v-model="password"
          :rules="passwordRules"
          class="pa-md-4"
          prepend-icon="mdi-lock"
          name="password"
          label="Password"
          :type="show ?'text': 'password'"
          :append-icon="show ?'mdi-eye':'mdi-eye-off'" 
          @click:append="show=!show"
          color="avidocolor"
        ></v-text-field>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="birth_date"
              label="Date of Birth"
              readonly
              v-bind="attrs"
              v-on="on"
              class="pa-md-4"
              color="avidocolor"
              prepend-icon="mdi-calendar"
              :rules="ageRules"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="birth_date"
            :max="new Date().toISOString().substr(0, 10)"
            @change="save"
            color="avidocolor black--text"
          ></v-date-picker>
        </v-menu>

        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor avidosolidtextcolor--text">
            Register
          </v-btn>
        </v-card-actions>

        <v-row align="center" justify="center">
          <v-col>
            <v-btn text color="avidocolor" to="/login"> Login </v-btn> |
            <v-btn text color="avidocolor" to="/forgot-password"> Forgot Password ? </v-btn>
          </v-col>
        </v-row>

      </v-form>
    </v-card>

  </div>
</template>


<script>

import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Register",
  data: () => ({
    show:false,
    menu: false,
    validate: false,
    userExists: false,
    firstnameRules: [(v) => !!v || "First Name is required"],
    lastnameRules: [(v) => !!v || "Last Name is required"],
    ageRules: ['Date of Birth is required.'],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      // (v) => (v && v.length <= 20) || "Password must be less than 20 characters",
    ],
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed:{
    ...mapFields('user',{
      email:'email',
      firstname:'firstname',
      lastname:'lastname',
      password:'password',
      birth_date:'birth_date',
    }),
  },
  methods: {
    ...mapActions('user',{
      register:'register'      
    }), 
    save(date) {
      var today = new Date();
      var birthDate = new Date(date);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
      {
          age--;
      }
      if(age>12){
        this.ageRules=[]; 
        this.$refs.menu.save(date);
      }else{
          this.ageRules=['Must be at least 13 years old.'];
      }      
    },
    onSubmit: function () {
      if (this.$refs.registerform.validate()) {
        this.register();

        /* let email = this.email;
        let password = this.password;
        let first_name = this.firstname;
        let last_name = this.lastname;
        this.$store
          .dispatch("user/signup", { first_name, last_name, email, password })
          .then(() => this.$router.push("/trainingpreferences"))
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              this.$store.commit("SET_NOTIFICATION", {
                display: true,
                text: error_mesg,
                alertClass: "error",
              });
            } 
          }); */
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created(){
    this.$store.commit("user/resetRegistration");
  }
};
</script>