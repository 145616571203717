<template>
  <div class="editInstruction">
    <v-form ref="editnotesform" v-model="valid" lazy-validation @submit.prevent="onSubmit" method="post">

      <v-card class="pa-8">
      
        <v-row align-items="center" justify="center">
          <v-col cols="12" md="6">
            <h2 class="mb-3 d-inline">
              <span class="avidocolor--text">{{ currentNoteExercise.exercise_name }}</span>
            </h2>
          </v-col>
        </v-row>
        
        <!-- <v-card-text class="pa-0">
          <div class="text-left align-self-start subheading">
            Exercise
          </div>
          <v-chip-group>
            <v-chip outlined class="avidocolor--text">
              <v-avatar start class="mr-2">
                <v-img :alt="currentNoteExercise.exercise_name"
                  :src="(currentNoteExercise.exercise_image_thumbnail != null) ? currentNoteExercise.exercise_image_thumbnail : '/images/logo.jpg'"
                  @error="currentNoteExercise.exercise_image_thumbnail = '/images/logo.jpg'"></v-img>
              </v-avatar>
              {{ currentNoteExercise.exercise_name }}
            </v-chip>
          </v-chip-group>
        </v-card-text> -->

        <v-textarea v-model="updateNote.notes" required color="avidocolor" :rules="notesRules">
          <template v-slot:label>
            <div>Write Note</div>
          </template>
        </v-textarea>
      </v-card>


        <!-- Condition For Personal Note Edit -->
      <template v-if="personal_note_edit == undefined">

        <v-card class="mt-2">
          <div class="pa-8 pb-0 text-left align-self-start v-label v-label--active theme--dark">
            Assign Note
          </div>

            <v-switch class="pl-10" v-model="updateNote.is_personal_note" :label="`Personal Note`" color="avidocolor"></v-switch>

          <!-- <template v-if="!updateNote.is_personal_note && updateNote.assigned_userids.length > 0">
            <v-card-text>
              <div class="text-left align-self-start subheading">Assigned Users</div>
              <v-chip-group>
                <template v-for="(userModel, uindex) in updateNote.assigned_userids">
                  <v-chip :key="uindex" outlined class="avidocolor--text">
                    <v-avatar start class="mr-2">
                      <v-img :alt="userModel.name"
                        :src="(userModel.profile_pic != null) ? userModel.profile_pic : '/images/logo.jpg'"
                        @error="userModel.profile_pic = '/images/logo.jpg'"></v-img>
                    </v-avatar>
                    {{ userModel.name }}
                    <v-icon color="red" class="ml-2" @click="deleteTeamMember(uindex)">mdi-minus-circle</v-icon>
                  </v-chip>
                </template>
              </v-chip-group>
            </v-card-text>
          </template>

          <template v-if="!updateNote.is_personal_note && updateNote.assigned_teamids.length > 0">
            <v-card-text>
              <div class="text-left align-self-start subheading">Assigned Teams</div>
              <v-chip-group active-class="avidocolor--text">
                <template v-for="(teamModel, tindex) in updateNote.assigned_teamids">
                  <v-chip :key="tindex" outlined class="avidocolor--text">
                    <v-avatar start class="mr-2">
                      <v-img :alt="teamModel.name" :src="(teamModel.image != null) ? teamModel.image : '/images/logo.jpg'"
                        @error="teamModel.image = '/images/logo.jpg'"></v-img>
                    </v-avatar>
                    {{ teamModel.name }}
                    <v-icon color="red" class="ml-2" @click="deleteTeam(tindex)">mdi-minus-circle</v-icon>
                  </v-chip>
                </template>
              </v-chip-group>
            </v-card-text>
          </template> -->
          
          <SearchTeamandTmembers v-if="!updateNote.is_personal_note" />
        </v-card>
      </template>

      <div class="clear"></div>
      <v-footer color="black" fixed padless>
        <v-row justify="center" no-gutters>
          <v-btn type="submit" class="my-2 avidocolor--text" text>
            Save
          </v-btn>
        </v-row>
      </v-footer>

    </v-form>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import SearchTeamandTmembers from '@/views/SearchScreens/SearchTeamandTmembers.vue'

export default {
  data () {
    return {
      valid: true,
      notesRules: [(v) => !!v || "Notes is required"],
      personal_note_edit: this.$route.query.pn,
    }
  },
  name: "updateNotes",
  components: {
    SearchTeamandTmembers
  },
  computed: {
    ...mapFields('exerciseNotes', {
      currentNoteExercise: 'currentNoteExercise',
      updateNote: 'updateNote',
    }),
  },
  methods: {
    ...mapActions("exerciseNotes", {
      getTeamTmember: "getTeamTmember",
      updateNotes: "updateNotes",
    }),
    ...mapActions("bottomSearch", {
      getFavoriteExercise: "getFavoriteExercise",
    }),
    onSubmit: function () {

      let submitForm = false
      if (this.$refs.editnotesform.validate()) {
        submitForm = true
      }
      if (!this.updateNote.is_personal_note) {
        if (this.updateNote.assigned_userids.length == 0 && this.updateNote.assigned_teamids.length == 0) {
          this.app_error_mesg = 'Please assign this note to Team or TeamMember.'
          submitForm = false
        }
      }

      if (submitForm) {
        this.updateNotes();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
   /*  deleteTeam(remove_tindex) {
      this.updateNote.assigned_teamids.splice(remove_tindex, 1);
    },
    deleteTeamMember(remove_tmindex) {
      this.updateNote.assigned_userids.splice(remove_tmindex, 1);
    } */
  },
  created(){
    if(this.personal_note_edit==undefined){
      this.getTeamTmember()
    }
  }
}
</script>
