<template>
  <div class="results">

    <v-form ref="resultForm" v-model="valid" lazy-validation @submit.prevent="onSubmit" method="post">
      <v-row>
        <v-col cols="12" md="6" class="py-0">
         
          <v-autocomplete 
            v-model="selected_exercises" 
            name="selected_exercise_id" 
            class="pa-md-4 pb-md-0"
            color="avidocolor" 
            label="Start typing to Search Exercise" 
            multiple
            :items="(team_id>0) ? avido_exercise_list : result_exercise_array" 
            item-text="exercise_name"
            item-value="exercise_id"
            v-on:change="onSubmit"
            :menu-props="{closeOnContentClick:true}"
          >
            <template v-slot:prepend-item v-if="(team_id==0)">
              <v-list-item ripple @mousedown.prevent @click="toggle">
                <v-list-item-action>
                  <v-icon :color="result_exercise_array.length > 0 ? 'avidocolor' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Select All
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>

            <template v-slot:selection="{ item, index }">
              <v-chip color="avidocolor--text" v-if="index === 0 && selected_exercises.length==1">
                <span>{{ item.exercise_name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                <!-- (+{{ selected_exercises.length - 1 }} others) -->
                All Exercises
              </span>
            </template>


            <template v-slot:item="data">
              <v-list-item-avatar tile size=50 @click="selectexercise">
                <v-img class="rounded" :src="
                  data.item.exercise_image_thumbnail != null
                    ? data.item.exercise_image_thumbnail
                    : '/images/logo.jpg'
                " @error="data.item.exercise_image_thumbnail = '/images/logo.jpg'"></v-img>
              </v-list-item-avatar>
              <v-list-item-content  class="text-left align-self-start" @click="selectexercise">
                <v-list-item-title v-html="data.item.exercise_name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="3" class="py-0">
          <v-select v-model="result_type"
            :items="result_type_items"
            item-text="title"
            item-value="value"
            color="avidocolor"
            label="Result Type"
            v-on:change="onSubmit"
            required
            ></v-select>
        </v-col>

        <v-col cols="12" md="3" class="py-0">
          <v-select v-model="time_frame"
            :items="(team_id > 0) ? team_time_frame_option : (selected_exercises > 0) ? oneex_time_frame_option : allex_time_frame_option"
            item-text="title"
            item-value="value"
            color="avidocolor"
            label="Time Frame"
            v-on:change="onSubmit"
            ></v-select>
        </v-col>

        <v-col cols="12" md="3" class="py-0" v-if="time_frame == 'YYYY-MM'">
          <v-dialog ref="dialog" v-model="modal" :return-value.sync="month_time" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="computedDateFormattedDatefns"  label="Select Month" prepend-icon="mdi-calendar"
                color="avidocolor" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="month_time" type="month" color="avidocolor black--text" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="red" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="avidocolor" @click="$refs.dialog.save(month_time); onSubmit()">
                OK
              </v-btn>
            </v-date-picker>

          </v-dialog>
        </v-col>
      </v-row>
    </v-form>

    <v-card v-if="result_count > 0">
      <div id="chart"> 
        <apexchart type="bar" :height="result_count" :options="chartOptions" :series="y_chart_array"></apexchart>
      </div>
    </v-card>

  </div>
</template>


<script>
import { mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import VueApexCharts from 'vue-apexcharts'
import { format, parseISO } from 'date-fns'

export default {
  name: "Result",
  components: {
    apexchart: VueApexCharts,
  },
  data () {
    return {
      modal: false,
      valid: false,
    }
  },
  computed: {
    computedDateFormattedDatefns() {
      return this.month_time ? format(parseISO(this.month_time), 'MMMM yyyy') : ''
    },
    ...mapFields("getDataList", {
        avido_exercise_list: "avido_exercise_list"
    }),
    ...mapFields("results", {
      result_type: "result_type",
      time_frame: "time_frame",
      month_time: "month_time",
      team_id: "team_id",
      selected_exercises: "selected_exercises",
      result_type_items: "result_type_items",
      oneex_time_frame_option: "oneex_time_frame_option",
      allex_time_frame_option: "allex_time_frame_option",
      team_time_frame_option: "team_time_frame_option",
      chartOptions: "chartOptions",
      y_chart_array: 'y_chart_array',
      result_count: 'result_count',
      result_exercise_array: 'result_exercise_array',
    }),
    likesAllItem() {
      let total_sexercise=(this.selected_exercises.length==undefined) ? 0: this.selected_exercises.length
      return total_sexercise === this.result_exercise_array.length
    },
    likesSomeItem() {
      return this.selected_exercises.length > 0 && !this.likesAllItem
    },
    icon() {
      if (this.likesAllItem) return 'mdi-close-box'
      if (this.likesSomeItem) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllItem) {
          this.selected_exercises = [] 
        } else {
          this.selected_exercises = this.result_exercise_array.slice()
          this.time_frame='ALL'
          this.onSubmit()
        }   
      })
    },
    selectexercise(){
      this.selected_exercises=[]
      if(this.team_id==0){
        this.time_frame='DAILY'
      }
    },
    ...mapMutations("results", {
      reInitResultModel: "reInitResultModel",
    }),
    ...mapActions("results", {
      getModel: "getModel",
    }),
    onSubmit: function () {
      if (this.$refs.resultForm.validate()) {
        this.getModel();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },

  },
  watch: {
    '$route.query.my'(my_result) {
        if(my_result!=undefined && my_result==1){
          this.user_id = localStorage.getItem('user_id');
          this.team_id = 0;
        }
        this.$store.commit('changeHeaderTitle','My Results');
        this.reInitResultModel();
        this.getModel();
    },
  },
  created() {
    this.reInitResultModel();
    this.getModel();
  },
};
</script>

