import { getField,updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    exercise_sets: [],
    rest_time: '0:00',
    superset_letter: null,
    current_exercise_id: null,
    current_exercise_name: null,
    current_lexer_rest_time_minutes: 0,
    current_lexer_rest_time_seconds: '00',
    image_large: null,
  },
  mutations: {
    resetModel(state) {
      state.exercise_sets= [],
      state.rest_time= '0:00',
      state.current_exercise_id= null,
      state.superset_letter= null,
      state.current_exercise_name= null,
      state.current_lexer_rest_time_minutes=0,
      state.current_lexer_rest_time_seconds='00',
      state.image_large= null
    },
    setModel(state, resp_data) {
      
        // state.rest_time = resp_data.rest_time
        // let result = new Date(null, null, null, null, null, resp_data.rest_time);
        // state.rest_time = result.toTimeString().split(' ')[0].substring(3); //rest_minutes+':'+rest_seconds   

        if(resp_data.exercise_set_details!=null){
          for (let exercisesdata of Object.values(resp_data.exercise_set_details)) {
            state.current_exercise_id = exercisesdata.id
            state.current_exercise_name = exercisesdata.exercise_name
            state.image_large = exercisesdata.image_large
            if(exercisesdata.sets.length>0){
              state.exercise_sets = exercisesdata.sets
            }
          }
        }
        state.rest_time = resp_data.rest_time
    }, 
    updateField
  },
  getters: {
    getField,
  },
  actions: {
   /*  getModel({ commit, dispatch }) {
      commit("resetModel");
      return new Promise((resolve,reject) => {
        axios({
          method: 'GET',
          url: 'get_lw_exercise_sets',
          params: {
            lw_exercises_id: this.state.libraryExercise.current_lexercise_id
          }
        })
        .then(resp => {
          const resp_code = resp.status
          if (resp_code == 200) {
            const response_data = resp.data.response
            commit("setModel", response_data);
            resolve(resp)
          }
        })
        .catch(error => {
          const resp_code = error.response.status
          if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })
      })
    }, */
/* 
  updateModel({ dispatch }) {
      return new Promise((resolve,reject) => {
          let lw_exercise_sets={}
          let lexercise_sets=this.state.libraryExerciseSet.exercise_sets
          if(lexercise_sets!=null){
            for (let value of Object.values(lexercise_sets)) {
              lw_exercise_sets[value.set_id]=value.reps+','+value.weight
            }
          }
          axios({
            method: 'POST',
            url: 'add_edit_lw_exercise_set',
            data: {
              rest_time: this.state.libraryExerciseSet.rest_time,
              weight_type: 'LBS',
              reps_type: 'REPS',
              lw_exercises_id: this.state.libraryExercise.current_lexercise_id,
              exercise_id: this.state.libraryExerciseSet.current_exercise_id,
              lw_exercise_sets: lw_exercise_sets,
            }
          })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              router.push({ path: '/library-exercise' });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 ||resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    }, */

  },
};