<template>
  <div class="CopyWorkout">

      <v-container>
        <v-text-field
          v-model="copy_name"
          :label="copy_label"
          persistent-hint
          required
        ></v-text-field>
      </v-container>

      <v-footer color="black" fixed padless>
        <v-row justify="center" no-gutters>

          <v-btn @click="saveCopyCalendar" class="my-2 avidocolor--text" text>
            <v-icon>mdi-content-save</v-icon>save 
          </v-btn>

        </v-row>
      </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "CopyWorkout", 
  computed: {
    ...mapFields("workoutCalendar", {
      copy_id: "copy_id",
      copy_label: "copy_label",
      copy_name: "copy_name",
    }),
  },
  methods: {
    ...mapActions("workoutCalendar", {
      saveCopyCalendar: "saveCopyCalendar",
    }),

  },

};
</script>
