<template>
  <div class="AllNotes">

    <template  v-if="allNotes.length>0">
       <template v-for="(model, noteindex) in allNotes">
         <v-card  class="rounded-lg mb-1" :key="noteindex">
            <v-list-item :key="'nlist'+noteindex" @click="setcurrentNoteExercise(model)">

              <v-list-item-avatar tile size=50>
                <v-img class="rounded" :alt="model.exercise_name" :src="(model.exercise_image_thumbnail!=null)?model.exercise_image_thumbnail:'/images/logo.jpg'" @error="model.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="pl-2 text-left">
                <v-list-item-title v-html="model.exercise_name" class="wrap-text"></v-list-item-title>
                <v-list-item-subtitle v-html="(model.note_details.length>0) ? model.note_details.length+' Notes' : null" class="wrap-text"></v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-card>
        </template>
      </template>

      <div class="clear"></div>
      <v-footer color="black" fixed padless>
        <v-row justify="center" no-gutters>
          <v-btn  @click="getFavoriteExercise('NEXERICSE')"  class="my-2 avidocolor--text" text>
            <v-icon>mdi-plus-box</v-icon>Add Note
          </v-btn>
        </v-row>
      </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "AllNotes",
  data() {
    return {
      is_coach: this.$store.state.header_upgrade_to_coach,
    }
  },

  computed: {
    ...mapFields('exerciseNotes', [
      'allNotes',
      'currentNoteExercise',
    ]),    
    ...mapFields('bottomSearch', [
      'avidoExerciseList'
    ])
  },
  methods: {
    ...mapActions("exerciseNotes", {
      getAllNotes: "getAllNotes",
    }),
    ...mapActions("bottomSearch", {
      getFavoriteExercise: "getFavoriteExercise",
    }),
    setcurrentNoteExercise(model){
      this.currentNoteExercise=model
      this.$router.push({ path: 'notedetails' });
    },
    ...mapActions("bottomSearch", {
      getAvidoExercise: "getAvidoExercise",
    })
  },
  
  created() {
    this.getAllNotes();    
    if(this.avidoExerciseList.length==0){
      this.getAvidoExercise();
    } 
  },
};
</script>
