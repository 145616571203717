<template>
  <div class="workoutexerciseset" :key="componentKey">

<!--     <v-container style="min-height: 250px">
      <v-fade-transition mode="out-in">
        <v-row>
          <v-col cols="12" class="py-0"> -->
            <v-card class="py-2">
              <v-row>
                <v-col cols="12">
                  <h2 class="mb-3 d-inline">
                    <span class="ml-4 float-left" v-if="exerciseList[exer_index].length>1">{{ alphabet[super_index] }}</span>
                    <span class="avidocolor--text">{{ exerciseList[exer_index][super_index]['exercise_name'] }}</span>
                  </h2>
                    <v-icon small @click="exerciseInfo()" class="ml-2" style="vertical-align: top;" color="avidocolor" dark>
                      mdi-information
                    </v-icon>
                </v-col>
              </v-row>

             <!--  <Media 
                :kind="'video'"
                :controls="true"
                preload="none"
                controlsList="noplaybackrate nodownload"
                :src="current_exer_videourl"
                :poster="current_exer_largeimage"
                :style="{'width': '100%', 'min-width': '100%','max-width':' 100%'}"
              ></Media> --> 
              <!-- <v-img
                :src="(current_exer_largeimage!=null)? current_exer_largeimage: 'images/logo.jpg'" 
                @error="current_exer_largeimage='images/logo.jpg'"
                @load="onImageLoad"
              ></v-img> -->
              <v-img
                :src="(current_exer_largeimage!=null) ? current_exer_largeimage: 'images/logo.jpg'" 
                @error="current_exer_largeimage='images/logo.jpg'"
                v-if="playVideo==0"
                @load="onImageLoad"
                contain
                :height="$vuetify.breakpoint.xs ? '100%' : ($vuetify.breakpoint.sm ? '100%' : '300px')"
              >

                <!-- <v-btn v-if="superset_letter!=null" small class="mt-2 d-flex rounded-0" color="avidocolor black--text">
                    SuperSet
                </v-btn> -->
                
                <v-btn absolute bottom left
                  icon color="avidocolor"
                  v-if="(current_exer_largeimage!='images/logo.jpg')"
                  @click="playVideo=1">
                    <v-icon size="40">mdi-play-circle-outline</v-icon>
                </v-btn>
              </v-img>
              <video v-else width="100%" :height="$vuetify.breakpoint.xs ? '100%' : ($vuetify.breakpoint.sm ? '100%' : '300px')" autoplay controls="false" poster="noposter" :src="current_exer_videourl" preload="none" disablePictureInPicture controlsList="noplaybackrate nodownload">
              </video>

              <v-list color="black" class="pa-0">
                <v-divider></v-divider>
                <v-row>
                
                  <span v-if="afterbw == undefined && is_workout_editable==1"></span>
                  <v-col cols="4"  v-else>
                    <v-list-item-action class="ma-0 align-center">                                                          
                        <template v-if="afterbw != undefined">
                          <div class="text-body-1 pt-2 grey--text">{{ (exerciseList[exer_index][super_index]['zero_weight_label']!="max" && is_workout_editable==1) ? 'Max Reps' : (exerciseList[exer_index][super_index]['user_one_rep_max']>0 ? 'Entered 1RM' : 'Est. 1RM') }} </div>
                          <v-dialog
                            v-model="dialog"
                            persistent
                            max-width="600px"
                          >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              text
                              v-bind="attrs"
                              v-on="on"
                              min-width="0px"
                              :disabled="exerciseList[exer_index][super_index]['zero_weight_label']!='max' ? true : false"
                              :class="exerciseList[exer_index][super_index]['zero_weight_label']!='max' ? 'onerepbtn' : null"
                            >
                            <span :class="(exerciseList[exer_index][super_index]['user_one_rep_max']>0) ? 'text-h5 avidocolor--text' : 'text-h5'">{{ (exerciseList[exer_index][super_index]['zero_weight_label']=="body") ? exerciseList[exer_index][super_index]['result_one_rep_max'] : (exerciseList[exer_index][super_index]['user_one_rep_max']>0 ? exerciseList[exer_index][super_index]['user_one_rep_max'] : exerciseList[exer_index][super_index]['result_one_rep_max']) }}</span>
                            <!-- <span class="text-h5 text-lowercase font-weight-light"> &nbsp; lbs.</span>  -->
                            </v-btn>
                          </template>    
                          
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">Edit One Rep Max</span>
                              </v-card-title>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-text-field
                                        color="avidocolor"
                                        label="One Rep Max"
                                        clearable
                                        v-model="exerciseList[exer_index][super_index]['user_one_rep_max']"
                                        v-on:change="data_change==false ? data_change=!data_change : data_change"
                                        type="number"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="red darken-1"
                                  text
                                  @click="dialog = false"
                                >
                                  Close
                                </v-btn>
                                <v-btn
                                  color="avidocolor"
                                  text
                                  @click="saveuseronemax"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </template>
                        <template v-if="afterbw == undefined">
                          <div class="text-body-1 pt-2 grey--text">{{ (exerciseList[exer_index][super_index]['user_one_rep_max']>0 && is_workout_editable==1) ? 'Working' : 'Current' }} 1RM</div>
                          <span class="text-h5">{{ (exerciseList[exer_index][super_index]['zero_weight_label']=="max") ? exerciseList[exer_index][super_index]['user_one_rep_max'] : exerciseList[exer_index][super_index]['result_one_rep_max'] }}
                          <span class="text-lowercase font-weight-light"> &nbsp; lbs.</span> </span>
                        </template>
                    </v-list-item-action>  
                  </v-col> 

                  <v-col :cols="(is_workout_editable==1 && afterbw == undefined) ? 6 : 4" >
                    <v-list-item-action class="ma-0 align-center">                     
                      <div class="text-body-1 pt-2 grey--text">Rest Time</div>
                      <div v-if="is_workout_editable==0 && afterbw == undefined">
                       {{exerciseList[exer_index][super_index]['rest_time']}}
                      </div>
                      <v-row v-if="is_workout_editable==1 && afterbw == undefined" align-items="center" justify="center">
                        <v-col cols="5" class="px-0">
                          <v-select
                            :items="rest_time_minitems"
                            v-model="current_exer_rest_time_minutes"
                            label="MM"
                            dense
                            outlined
                            color="avidocolor"
                            v-on:change="onchangeRestTimeMin"
                          ></v-select>
                        </v-col>
                        <v-col cols="5" class="px-0"> 
                          <v-select
                            :items="rest_time_secitems"
                            v-model="current_exer_rest_time_seconds"
                            label="SS"
                            dense
                            outlined
                            color="avidocolor"
                            v-on:change="onchangeRestTimeSec"
                          ></v-select>
                        </v-col>
                      </v-row>   
                      <div id="timerval" v-if="is_workout_editable==1 && afterbw != undefined">
                        <!--  Restart Timer -->
                        <v-icon id="reset"  class="mr-2"  style="vertical-align: baseline;" v-if="resetButton" color="avidocolor"
                          @click="resetTimer()">
                          mdi-history
                        </v-icon>
                        <span class="text-h5 pt-2">
                          <span id="minutes">{{ minutes }}</span>
                          <span id="middle">:</span>
                          <span id="seconds">{{ seconds }}</span>
                        </span>
                        <!-- Start TImer -->
                        <v-icon id="start" v-if="!timerval"  class="ml-2"  style="vertical-align: baseline;" color="avidocolor" @click="startTimer()">
                          mdi-play-circle
                        </v-icon>
                        <!--  Pause Timer -->
                        <v-icon id="stop"  class="ml-2"  style="vertical-align: baseline;" v-if="timerval" color="avidocolor" @click="stopTimer()">
                          mdi-pause-circle
                        </v-icon>
                      </div>
                    </v-list-item-action>
                  </v-col>
                  
                  <v-col :cols="(is_workout_editable==1 && afterbw == undefined) ? 6 : 4" >
                    <v-list-item-action class="ma-0 align-center">                                                          
                          <div class="text-body-1 pt-2 grey--text">Tempo</div>
                          <v-dialog
                            v-model="tempoDialog"
                            persistent
                            max-width="600px"
                          >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              text
                              v-bind="attrs"
                              v-on="on"
                              min-width="0px"
                            >
                              <span :class="(exerciseList[exer_index][super_index]['tempo'] != null) ? 'text-h5 avidocolor--text' : 'text-h5'">
                                {{ tempoDisplay }}
                              </span>
                            </v-btn>
                          </template>    
                          
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">Edit Tempo</span>
                              </v-card-title>
                              <v-card-subtitle class="text-left pt-4 pb-0">
                                This 3 or 4 digit number is the length in seconds of each phase of a repetition:
                                <br/>1st: Eccentric (lowering / relaxation)
                                <br/>2nd: Isometric (pause after eccentric)
                                <br/>3rd: Concentric (lifting / exertion)
                                <br/>4th: Isometric (pause after concentric)
                              </v-card-subtitle>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-text-field
                                        color="avidocolor"
                                        label="Tempo"
                                        clearable
                                        v-model="exerciseList[exer_index][super_index]['tempo']"
                                        v-on:change="data_change==false ? data_change=!data_change : data_change"
                                        type="number"
                                        :rules="[(v) => (v === null || v.length <= 4) || 'Max 4 characters allow.']"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions v-if="exerciseList[exer_index][super_index]['tempo']==null || exerciseList[exer_index][super_index]['tempo'].length<=4">
                                <v-btn color="red" text @click="tempoDialog = false">
                                  close
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="avidocolor"
                                  text
                                  @click="tempoDialog = false"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                    </v-list-item-action>  
                  </v-col> 

                </v-row>
              </v-list>

         <!--  </v-col>
        </v-row>
      </v-fade-transition> -->

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-if="is_workout_editable==1 && afterbw == undefined && current_user_workout_completed == false"></th>
                  <th class="text-center font-weight-bold">SETS</th>
                  <!-- 
                  <th class="text-center">{{exerciseList[exer_index][super_index]['reps_type'] }}</th>
                  <th class="text-center">{{exerciseList[exer_index][super_index]['weight_type'] }}</th>
                  -->
                  <th class="pa-0">                                    
                    <div class="text-center">
                      <v-btn id="reps_type_menu"  color="avidocolor"  class="font-weight-bold" text :disabled="(is_workout_editable) ? false : true" ><div :class="(is_workout_editable) ?  'avidocolor--text' : 'rwbtn'">{{selectedRepsType}}</div></v-btn>
                      <v-menu offset-y activator="#reps_type_menu"  open-on-hover>
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in reps_type_options"
                            :key="index"
                            :value="item.reps_type"
                            @click="changeRepsType(item)"
                          >
                            <v-list-item-title>{{ item.display_reps_type }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>

                    <!--   <v-select
                        v-model="exerciseList[exer_index][super_index]['reps_type']"
                        :items="reps_type_options"
                        item-value="reps_type" 
                        item-text="display_reps_type" 
                        color="avidocolor"
                        item-color="avidocolor"
                        v-on:change="data_change==false ? data_change=!data_change : data_change"
                        autofocus
                      ></v-select> -->
                    </th>      
                    <th class="pa-0">
                      <div class="text-center">
                        <v-btn id="weight_type_menu" color="avidocolor" class="font-weight-bold"  text :disabled="(is_workout_editable) ? false : true" > <div :class="(is_workout_editable) ?  'avidocolor--text' : 'rwbtn'">{{selectedWeightType}} </div></v-btn>
                        <v-menu  offset-y activator="#weight_type_menu"  open-on-hover>
                          <v-list>
                            <v-list-item
                              v-for="(item, index) in weight_type_options"
                              :key="index"
                              :value="item.weight_type"
                              @click="changeWeightType(item)"
                            >
                              <v-list-item-title>{{ item.display_weight_type }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>

                      <!-- <v-select
                        v-model="exerciseList[exer_index][super_index]['weight_type']"
                        :items="weight_type_options"
                        item-value="weight_type" 
                        item-text="display_weight_type" 
                        color="avidocolor"
                        item-color="avidocolor"
                        class="setinputheader setinput"
                        v-on:change="data_change==false ? data_change=!data_change : data_change"
                        autofocus
                      ></v-select> -->
                    </th>
                  <th class="pa-0 text-center"
                    v-if="((afterbw == undefined && current_user_workout_completed == true) || afterbw != undefined)">
                      <v-checkbox class="text-center allSetCheckBox" v-model="allSetSelect" color="avidocolor" @change="checkAllSet"></v-checkbox>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in exerciseList[exer_index][super_index]['sets']" :key="item.id">

                  <td v-if="is_workout_editable==1 && afterbw == undefined && current_user_workout_completed == false && is_workout_editable == 1">

                    <v-btn icon color="red" dark @click.stop.prevent="
                      deleteSet(index)
                    ">
                      <v-icon color="red">mdi-minus-circle</v-icon>
                    </v-btn>
                  </td>

                  <td>{{ index + 1 }}{{superset_letter}}</td>

                  <td>

                    <!--  <v-text-field 
                      v-model="exercise_sets[index].weight"
                      :disabled="(is_workout_editable) ? false : true"
                      v-on:focus="weightFocus(index)"
                      v-on:change="weightChange(index)"
                      :type="(exercise_sets[index].weight>0) ? 'number' : 'text'"
                      color="avidocolor" class="setinput" type="number" min=0>
                    </v-text-field> -->
                     <v-text-field 
                        :disabled="(is_workout_editable) ? false : true"
                        :value="(item.reps==0) ? exerciseList[exer_index][super_index]['zero_weight_label']  : item.reps"
                        placeholder=""
                        v-on:focus="repsFocus(index)"
                        v-on:input="repsInput(index,$event)"
                        v-on:change="repsChange(index,$event)"
                        :type="(item.reps==0) ? 'text': 'number'"
                        :id="'reps_'+exer_index+'_'+super_index+'_'+index"
                        hide-spin-buttons
                        color="avidocolor" class="setinput" min=0>
                      </v-text-field> 
                  </td>

                  <td>
                   <!--  <v-text-field 
                      v-model="exercise_sets[index].weight"
                      :disabled="(is_workout_editable) ? false : true"
                      v-on:focus="weightFocus(index)"
                      v-on:change="weightChange(index)"
                      :type="(exercise_sets[index].weight>0) ? 'number' : 'text'"
                      color="avidocolor" class="setinput" type="number" min=0>
                    </v-text-field> -->
                    <v-text-field 
                        :disabled="(is_workout_editable) ? false : true"
                        :value="(item.weight==0) ? exerciseList[exer_index][super_index]['zero_weight_label']  : item.weight"
                        placeholder=""
                        v-on:focus="weightFocus(index)"
                        v-on:input="weightInput(index,$event)"
                        v-on:change="weightChange(index,$event)"
                        :type="(item.weight==0) ? 'text': 'number'"
                        :id="'weight_'+exer_index+'_'+super_index+'_'+index"
                        hide-spin-buttons
                        color="avidocolor" class="setinput" min=0>
                      </v-text-field>
                  </td>

                  <td
                    v-if="((afterbw == undefined && current_user_workout_completed == true) || afterbw != undefined)">
                    <v-icon :color="
                      (item.checkmark_status == true || item.checkmark_status == 'true') ? 'avidocolor' : 'grey'" 
                      v-model="exercise_sets[index].checkmark_status"
                      @click="(is_workout_editable == 1) ? exercise_sets[index].checkmark_status = !exercise_sets[index].checkmark_status : null"
                      v-on:click="data_change == false ? data_change = !data_change : data_change">
                      mdi-check
                    </v-icon>
                  </td>

                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>



    <div class="clear"></div>
    <v-footer v-if="is_workout_editable" color="black" fixed padless>
      <v-row justify="center" no-gutters>

        <v-btn v-if="afterbw != undefined && (exer_index > 0 || (exer_index == 0 && super_index > 0))"
          @click="prevButton" class="my-2 avidocolor--text" text>
          <v-icon>mdi-chevron-left</v-icon><span class="d-none d-lg-flex d-xl-flex">Back</span>
        </v-btn>

        <v-btn v-if="afterbw != undefined && (exer_index == 0 && super_index == 0)" @click="$router.go(-1)"
          class="my-2 avidocolor--text" text>
          <v-icon>mdi-chevron-left</v-icon><span class="d-none d-lg-flex d-xl-flex">Back</span>
        </v-btn>

        <v-btn v-if="is_workout_editable == 1" @click="exerciseSearch('SWAPEXERCISE')" class="my-2 avidocolor--text" text>
          <v-icon>mdi-shuffle-variant</v-icon>Swap
        </v-btn>

        <v-btn v-if="is_workout_editable == 1" @click="addSets()" class="my-2 avidocolor--text" text>
          <v-icon>mdi-plus</v-icon> Set
        </v-btn>

        <v-btn
          v-if="afterbw != undefined && is_workout_editable == 1 && (exerciseList_length == exer_index && (exerciseList[exer_index].length-1) == (super_index))"
          @click="finishButton" class="my-2 avidocolor--text" text>
            <span class="d-none d-lg-flex d-xl-flex">Next</span>
            <v-icon>mdi-chevron-right</v-icon>
        </v-btn>

        <template v-else>
          <v-btn v-if="afterbw != undefined" @click="nextButton('next')" class="my-2 avidocolor--text" text>
            <span class="d-none d-lg-flex d-xl-flex">Next</span>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <!-- <v-btn v-if="afterbw == undefined" @click="$router.go(-1)"
            class="my-2 avidocolor--text" text>
            <v-icon>mdi-content-save</v-icon>save
          </v-btn> -->
        </template>

      </v-row>
    </v-footer>

  </div>
</template>


<style>
.v-application p {
    margin-bottom: 0px !important;
}
.pv-controls{
  padding: 5px;
}
</style>

<script>

import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields"
// import Media from '@dongido/vue-viaudio'

export default {
  name: "WorkoutExerciseSet",
  /* components: {
    'Media': Media,
  }, */ 
  data() {
    return {
      tempoDialog: false,
      dialog: false,
      allSetSelect: false,
      playVideo: 0,
      afterbw: this.$route.query.bw,
      timerval: undefined,
      startingValue: null,
      selectedRepsType: null,
      selectedWeightType: null,
      componentKey: new Date().toISOString(),
      //  totalTime: (25 * 60),
      resetButton: true,
      alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      rest_time_minitems: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59],
      rest_time_secitems: ['00','05','10','15','20','25','30','35','40','45','50','55'],
    }
  },
  computed: {
    ...mapFields('workoutCalendar', {
      calendar_type: 'calendar_type',
      get_exercise:'get_exercise',
      is_workout_editable: 'is_workout_editable',
      current_user_workout_id: "current_user_workout_id",
      current_user_workout_completed: 'current_user_workout_completed',
    }),
    ...mapFields('workoutExercise', {
      data_change: 'data_change',
      wfcType: 'wfcType',
      exer_index: 'exer_index',
      super_index: 'super_index',
      exerciseList: 'exerciseList',
      cooldown: 'cooldown',
      finisher: 'finisher',
      exerciseList_length: 'exerciseList_length',
      super_exercise_length: 'super_exercise_length',
      superset_letter: 'superset_letter',
      current_exer_name: 'current_exer_name',
      current_uw_exercise_id: 'current_uw_exercise_id',
      current_exercise_id: 'current_exercise_id',
      current_exer_largeimage: 'current_exer_largeimage',
      current_exer_videourl: 'current_exer_videourl',
      current_exer_rest_time_minutes: 'current_exer_rest_time_minutes',
      current_exer_rest_time_seconds: 'current_exer_rest_time_seconds',
      exercise_sets: 'current_exer_sets',
      current_exer_resttime: 'current_exer_resttime',
      totalTime: 'totalTime',
    }),
    ...mapFields('libraryExercise',{
      reps_type_options:'reps_type_options',
      weight_type_options:'weight_type_options',
    }),
    ...mapFields('bottomSearch', {
      result_type: 'result_type',
      swapRecomExerciseList: 'swapRecomExerciseList',
    }),
    ...mapFields('exerciseInstruction', {
      exercise_name: 'exercise_name',
      exercise_instruction_id: 'exercise_instruction_id',
    }),
    minutes: function () {
      const minutes = Math.floor(this.totalTime / 60);
      return this.padTime(minutes);
    },
    seconds: function () {
      const seconds = this.totalTime - (this.minutes * 60);
      return this.padTime(seconds);
    },
    tempoDisplay() {
      return (this.exerciseList[this.exer_index][this.super_index]['tempo']!=null) ? ((this.exerciseList[this.exer_index][this.super_index]['tempo'].length <= 4) ? this.exerciseList[this.exer_index][this.super_index]['tempo'] : '----')  : '----'
    }
  },
  methods: {
    ...mapActions("bottomSearch", {
      getSwapExercise: "getSwapExercise",
      getFavoriteExercise: "getFavoriteExercise",
    }),
    startTimer: function () {
      this.timerval = setInterval(() => this.countdown(), 1000);
    },
    stopTimer: function () {
      window.clearTimeout(this.timerval);
      this.timerval = null;
    },
    resetTimer: function () {
      this.totalTime = this.current_exer_resttime //(25 * 60);
      window.clearTimeout(this.timerval);
      this.timerval = null;
    },
    padTime: function (time) {
      return (time < 10 ? '0' : '') + time;
    },
    countdown: function () {
      if (this.totalTime >= 1) {
        this.totalTime--;
      } else {
        this.totalTime = 0;
        this.resetTimer()
      }
    },
    exerciseInfo() {
      this.exercise_instruction_id = this.exerciseList[this.exer_index][this.super_index]['exercise_id']
      this.exercise_name=this.exerciseList[this.exer_index][this.super_index]['exercise_name']
      this.$router.push({ path: 'exercise-instruction' });
    },
    returnRestTime(curt_rest_time) {
      if (typeof curt_rest_time == 'string') {
        let restvalue = curt_rest_time.split(":");
        let restTime = parseInt(restvalue[0] * 60) + parseInt(restvalue[1]);
        this.exerciseList[this.exer_index][this.super_index]['rest_time'] = restTime
        this.current_exer_resttime = restTime
        this.totalTime = restTime
        const cminutes = Math.floor(restTime / 60)
        const cseconds = restTime - (cminutes * 60)
        this.current_exer_rest_time_minutes = cminutes
        this.current_exer_rest_time_seconds = (cseconds==0) ? '00' : ((cseconds==5) ? '05' : cseconds.toString())
      }
      //Set Rest Time  
      if(curt_rest_time>0){
        this.totalTime=curt_rest_time
        this.current_exer_resttime=curt_rest_time
        const cminutes = Math.floor(curt_rest_time / 60)
        const cseconds = curt_rest_time - (cminutes * 60)
        this.current_exer_rest_time_minutes = cminutes
        this.current_exer_rest_time_seconds = (cseconds==0) ? '00' : ((cseconds==5) ? '05' : cseconds.toString())
      } 
    },
    setCurrentExercise(){
      
      // console.log(this.super_index)
      let curt_rest_time = this.exerciseList[this.exer_index][this.super_index]['rest_time']
      this.returnRestTime(curt_rest_time)

      this.current_exercise_id = this.exerciseList[this.exer_index][this.super_index]['exercise_id']
      this.current_exer_name = this.exerciseList[this.exer_index][this.super_index]['exercise_name']
      this.current_uw_exercise_id=this.exerciseList[this.exer_index][this.super_index]['user_workout_exercise_id']
      this.current_exer_largeimage = this.exerciseList[this.exer_index][this.super_index]['exercise_large_image']
      this.current_exer_videourl = this.exerciseList[this.exer_index][this.super_index]['exercise_video_url']
      this.exercise_sets = this.exerciseList[this.exer_index][this.super_index]['sets']
      this.super_exercise_length = this.exerciseList[this.exer_index].length
      this.superset_letter = (this.super_exercise_length > 1) ? this.alphabet[this.super_index] : null
      
      /* let eindex=this.exer_index
      let sindex=this.super_index
      if (data == 'previous') {
        this.$router.go(-1);
      }else{ 
        this.$router.push({ path: 'workoutexerciseset?bw=1&ei='+eindex+'&si='+sindex+qbrcounter });
      } */
    },
    
    nextButton() {
      let currentSubExerciseIndex=this.super_index
      let currentExerciseIndex=this.exer_index
      let qbr=parseInt(this.$route.query.br)+1
      let qbrcounter='&br='+qbr
      if (currentSubExerciseIndex < this.exerciseList[currentExerciseIndex].length - 1) {
        currentSubExerciseIndex++;
        this.super_index=currentSubExerciseIndex
        this.setCurrentExercise()
      } else if (currentExerciseIndex < this.exerciseList.length - 1) {
          currentExerciseIndex++;
          currentSubExerciseIndex = 0;
          this.exer_index=currentExerciseIndex
          this.super_index=currentSubExerciseIndex
          this.setCurrentExercise()
      } else {
        //  console.log("You are at the last exercise.");
      }
      this.$router.push({ path: 'workoutexerciseset?bw=1&ei='+currentExerciseIndex+'&si='+currentSubExerciseIndex+qbrcounter });
    },

    prevButton() {
      let currentSubExerciseIndex=this.super_index
      let currentExerciseIndex=this.exer_index
      if (currentSubExerciseIndex > 0) {
        currentSubExerciseIndex--;
        this.super_index=currentSubExerciseIndex
        this.setCurrentExercise()
      } else if (currentExerciseIndex > 0) {
          currentExerciseIndex--;
          currentSubExerciseIndex = this.exerciseList[currentExerciseIndex].length - 1;
          this.exer_index=currentExerciseIndex
          this.super_index=currentSubExerciseIndex
          this.setCurrentExercise()
      } else {
        // console.log("You are at the first exercise.");
      }
      this.$router.go(-1);
    },

    finishButton() {
      let routeName = 'workoutwfc'
      let qbr=parseInt(this.$route.query.br)+1
      if (this.finisher != null) {
        this.wfcType = 'FINISHER'
        this.$router.push({ path: routeName + '?bw=1&wfctype=FINISHER&br='+qbr });
      } else if (this.cooldown != null) {
        this.wfcType = 'COOLDOWN'
        this.$router.push({ path: routeName + '?bw=1&wfctype=COOLDOWN&br='+qbr });
      } else if (this.afterbw) {
        if (this.finisher == null && this.cooldown == null) {
          this.wfcType = 'finishworkout'
          this.$router.push({ path: 'endworkout?fw=1&br='+qbr });
        } else {
          this.$router.push({ path: routeName + '?bw=1&br='+qbr });
        }
      } else {
        this.$router.push({ path: routeName+'br='+qbr });
      }
    },

   /*  nextPrevious(data) {

      if (data == 'finish') {
        
        let routeName = 'workoutwfc'
        let qbr=parseInt(this.$route.query.br)+1
        if (this.finisher != null) {
          this.wfcType = 'FINISHER'
          this.$router.push({ path: routeName + '?bw=1&wfctype=FINISHER&br='+qbr });
        } else if (this.cooldown != null) {
          this.wfcType = 'COOLDOWN'
          this.$router.push({ path: routeName + '?bw=1&wfctype=COOLDOWN&br='+qbr });
        } else if (this.afterbw) {
          if (this.finisher == null && this.cooldown == null) {
            this.wfcType = 'finishworkout'
            this.$router.push({ path: 'endworkout?fw=1&br='+qbr });
          } else {
            this.$router.push({ path: routeName + '?bw=1&br='+qbr });
          }
        } else {
          this.$router.push({ path: routeName+'br='+qbr });
        }
      } else {

        let qbrcounter=''
        if (data == 'next') {
          this.super_index = this.super_index + 1;
          if (this.super_index >= this.super_exercise_length) {
            this.super_index = 0;
            this.exer_index = this.exer_index + 1;
          } else if (this.exer_index < this.exerciseList_length && this.super_index == this.super_exercise_length) {
            this.exer_index = this.exer_index + 1;
          }

          let qbr=parseInt(this.$route.query.br)+1
          qbrcounter='&br='+qbr
        }

        if (data == 'previous') {
          if (this.super_index >= this.super_exercise_length) {
            this.exer_index = this.exer_index - 1;
            this.super_index == this.exerciseList[this.exer_index].length
          } else if (this.super_index < this.super_exercise_length && this.super_index > 0) {
            this.super_index = this.super_index - 1;
          } else if (this.super_index == 0) {
            this.exer_index = this.exer_index - 1;
          }
        }

        if (this.afterbw != undefined) {
          let crestTime = this.exerciseList[this.exer_index][this.super_index]['rest_time']
          if (typeof crestTime == 'string') {
            let restvalue = crestTime.split(":");
            let restTime = parseInt(restvalue[0] * 60) + parseInt(restvalue[1]);
            this.exerciseList[this.exer_index][this.super_index]['rest_time'] = restTime
          }
        }


        this.current_exer_resttime = this.exerciseList[this.exer_index][this.super_index]['rest_time']
        this.totalTime = this.exerciseList[this.exer_index][this.super_index]['rest_time']
        this.current_exercise_id = this.exerciseList[this.exer_index][this.super_index]['exercise_id']
        this.current_exer_name = this.exerciseList[this.exer_index][this.super_index]['exercise_name']
        this.current_uw_exercise_id=this.exerciseList[this.exer_index][this.super_index]['user_workout_exercise_id']
        this.current_exer_largeimage = this.exerciseList[this.exer_index][this.super_index]['exercise_large_image']
        this.current_exer_videourl = this.exerciseList[this.exer_index][this.super_index]['exercise_video_url']
        this.exercise_sets = this.exerciseList[this.exer_index][this.super_index]['sets']
        this.super_exercise_length = this.exerciseList[this.exer_index].length
        this.superset_letter = (this.super_exercise_length > 1) ? this.alphabet[this.super_index] : null
        
        let eindex=this.exer_index
        let sindex=this.super_index
        if (data == 'previous') {
          this.$router.go(-1);
        }else{
          this.$router.push({ path: 'workoutexerciseset?bw=1&ei='+eindex+'&si='+sindex+qbrcounter });
        }
        
      }
      this.stopTimer()
    }, */
    exerciseSearch(result_type) {
      this.stopTimer()
      this.result_type = result_type
      if(this.calendar_type=='AVIDO' && this.current_uw_exercise_id>0){
        this.getSwapExercise(result_type)
      }else{
        this.swapRecomExerciseList=[]
        // this.$router.push({ path: 'search-exercise' });
      }
      this.getFavoriteExercise(result_type)
      this.$store.commit('loading', false);
    },
    addSets() {
      this.data_change=true
      let current_set_count=this.exercise_sets.length
      let last_resp=0
      let last_weight=0
      let last_checkmark_status=false
      let last_percentage=0
      let last_rpe_level=0
      if(current_set_count>0){
         last_resp=this.exercise_sets[current_set_count-1].reps
         last_weight=this.exercise_sets[current_set_count-1].weight
         last_checkmark_status=this.exercise_sets[current_set_count-1].checkmark_status
         last_percentage=this.exercise_sets[current_set_count-1].percentage
         last_rpe_level=this.exercise_sets[current_set_count-1].rpe_level
      }

      let new_set = {
        checkmark_status: last_checkmark_status,
        reps: last_resp,
        set_id: this.current_exercise_id + '_' + current_set_count,
        weight: last_weight,
        percentage: last_percentage,
        rpe_level: last_rpe_level,
      }
      
      this.exerciseList[this.exer_index][this.super_index]['exercise_set_count'] = current_set_count + 1;
      this.exercise_sets.push(new_set)
    },
    deleteSet(remove_index) {
      this.data_change = true
      this.exercise_sets.splice(remove_index, 1);
      let current_set_count = this.exercise_sets.length
      this.exerciseList[this.exer_index][this.super_index]['exercise_set_count'] = current_set_count;
    },
    onchangeRestTimeMin(minutes){
      this.data_change = true
      let new_min=minutes*60
      let new_sec=this.current_exer_rest_time_seconds
      let new_rest_time=parseInt(new_min)+parseInt(new_sec)
      this.exerciseList[this.exer_index][this.super_index]['rest_time'] = new_rest_time
    },
    onchangeRestTimeSec(seconds){
      this.data_change = true
      let new_min=this.current_exer_rest_time_minutes*60
      let new_sec=seconds
      let new_rest_time=parseInt(new_min)+parseInt(new_sec)
      this.exerciseList[this.exer_index][this.super_index]['rest_time'] = new_rest_time
    },   
    repsFocus(setIndex){
      this.$el.querySelector("input#reps_"+this.exer_index+"_"+this.super_index+"_"+setIndex).type = 'number'
      this.startingValue=this.exerciseList[this.exer_index][this.super_index]['sets'][setIndex].reps
      this.exerciseList[this.exer_index][this.super_index]['sets'][setIndex].reps=null
      this.$el.querySelector("input#reps_"+this.exer_index+"_"+this.super_index+"_"+setIndex).placeholder = (this.exerciseList[this.exer_index][this.super_index]['sets'][setIndex].reps!=null) ? this.exerciseList[this.exer_index][this.super_index]['sets'][setIndex].reps.toString() : ''
    },    
    repsInput(setIndex,content){
      this.data_change = true
      this.exerciseList[this.exer_index][this.super_index]['sets'][setIndex].reps=content
    },
    repsChange(setIndex,content){
      if(content==null && this.startingValue!=null){
        this.exerciseList[this.exer_index][this.super_index]['sets'][setIndex].reps=this.startingValue
      }
      this.$el.querySelector("input#reps_"+this.exer_index+"_"+this.super_index+"_"+setIndex).type = "text" 
    },
    weightFocus(setIndex){
      this.$el.querySelector("input#weight_"+this.exer_index+"_"+this.super_index+"_"+setIndex).type = 'number'
      this.startingValue=this.exerciseList[this.exer_index][this.super_index]['sets'][setIndex].weight
      this.exerciseList[this.exer_index][this.super_index]['sets'][setIndex].weight=null
      this.$el.querySelector("input#weight_"+this.exer_index+"_"+this.super_index+"_"+setIndex).placeholder = (this.exerciseList[this.exer_index][this.super_index]['sets'][setIndex].weight!=null) ? this.exerciseList[this.exer_index][this.super_index]['sets'][setIndex].weight.toString() : ''
    },    
    weightInput(setIndex,content){
      this.data_change = true
      this.exerciseList[this.exer_index][this.super_index]['sets'][setIndex].weight=content
    },
    weightChange(setIndex,content){
      if(content==null && this.startingValue!=null){
        this.exerciseList[this.exer_index][this.super_index]['sets'][setIndex].weight=this.startingValue
      }
      this.$el.querySelector("input#weight_"+this.exer_index+"_"+this.super_index+"_"+setIndex).type = "text" 
    },
    saveuseronemax(){
      // let current_weight_type=this.exerciseList[this.exer_index][this.super_index]['weight_type']
      // if(current_weight_type=='PERCENT'){
        let step=5
        let current_exercise_sets = this.exerciseList[this.exer_index][this.super_index]['sets']
        for (let setinfo of Object.values(current_exercise_sets)){
          let setweight=null
          if(this.exerciseList[this.exer_index][this.super_index]['user_one_rep_max']>0){
            setweight=(setinfo.percentage/100)*this.exerciseList[this.exer_index][this.super_index]['user_one_rep_max']
          }else{
            setweight=(setinfo.percentage/100)*this.exerciseList[this.exer_index][this.super_index]['result_one_rep_max']
          }
          setinfo.weight=(setweight % 5 != 0) ?  Math.ceil(setweight / step) * step : setweight;       
        }
        this.exercise_sets = current_exercise_sets
      // }
       this.dialog = false
    },
    onImageLoad(){
      this.$store.commit('loading', false);
    },  
    checkAllSet(){
        for (let setinfo of Object.values(this.exercise_sets)){
          setinfo.checkmark_status=this.allSetSelect
        }
    },
    changeRepsType(selected_repsType){
      this.exerciseList[this.exer_index][this.super_index]['reps_type']=selected_repsType.reps_type
      this.selectedRepsType=selected_repsType.display_reps_type
    },
    changeWeightType(selected_weightType){
      this.exerciseList[this.exer_index][this.super_index]['weight_type']=selected_weightType.weight_type
      this.selectedWeightType=selected_weightType.display_weight_type
    }
  },
  watch: {
    $route() {
      let eindex=this.$route.query.ei
      let sindex=this.$route.query.si
      let defaultSetAll=false
      if(eindex!=undefined){
       /*  this.current_exer_resttime = this.exerciseList[eindex][sindex]['rest_time']
        this.totalTime = this.exerciseList[eindex][sindex]['rest_time']
        this.current_exercise_id = this.exerciseList[eindex][sindex]['exercise_id']
        this.current_exer_name = this.exerciseList[eindex][sindex]['exercise_name']
        this.current_exer_largeimage = this.exerciseList[eindex][sindex]['exercise_large_image']
        this.current_exer_videourl = this.exerciseList[eindex][sindex]['exercise_video_url']
        this.exercise_sets = this.exerciseList[eindex][sindex]['sets']
        this.super_exercise_length = this.exerciseList[eindex].length
        this.superset_letter = (this.super_exercise_length > 1) ? this.alphabet[sindex] : null
         */
        // For SelectAll CheckBox
        const allTrue = Object.values(this.exercise_sets).every(setInfo => setInfo.checkmark_status === true);
        if (allTrue) { defaultSetAll=true }
        this.allSetSelect=defaultSetAll

        // For Reps type and Weight type
        this.selectedRepsType=(this.reps_type_options.find(option => option.reps_type === this.exerciseList[eindex][sindex]['reps_type'])).display_reps_type 
        this.selectedWeightType=(this.weight_type_options.find(option => option.weight_type === this.exerciseList[eindex][sindex]['weight_type'])).display_weight_type 
        
        this.exer_index = eindex
        this.super_index = sindex

      // console.log(this.super_index)
      let curt_rest_time = this.exerciseList[eindex][sindex]['rest_time']
      this.returnRestTime(curt_rest_time)
      
      }
      this.playVideo=0

      this.$store.commit('loading', false);
    },
  },
  created() {

    // this.get_exercise=false
    // console.log(this.current_user_workout_id)
    this.exerciseList_length = this.exerciseList.length - 1;
    if (this.afterbw != undefined) {
      this.$store.commit('changeHeaderAfterbw',this.afterbw);
      let curt_rest_time = this.exerciseList[this.exer_index][this.super_index]['rest_time']
      this.returnRestTime(curt_rest_time)
    }else{
      this.$store.commit('changeHeaderAfterbw',null);
    }
     


    // For CheckMark Status 
    let defaultSetAll=false
    const allTrue = Object.values(this.exercise_sets).every(setInfo => setInfo.checkmark_status === true);
    if (allTrue) { defaultSetAll=true }
    this.allSetSelect=defaultSetAll

    // For Reps type and Weight type
    this.selectedRepsType=(this.reps_type_options.find(option => option.reps_type === this.exerciseList[this.exer_index][this.super_index]['reps_type'])).display_reps_type 
    this.selectedWeightType=(this.weight_type_options.find(option => option.weight_type === this.exerciseList[this.exer_index][this.super_index]['weight_type'])).display_weight_type 
      
    this.$store.commit('loading', false);
  },

  beforeDestroy(){
    this.stopTimer()
  }

};
</script>

