<template>
  <div class="search-team">
    <template>
      <v-tabs  v-model="search_tab" background-color="black avidocolor--text" grow>
        <v-tab href="#TEAM">
          TEAM
        </v-tab>
        <v-tab href="#TEAMMEMBER" >
          Individuals
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="search_tab">
        <v-tab-item value="TEAM" v-if="search_tab=='TEAM'">
          <v-text-field v-model="team_skeyword" placeholder="Search Team..." clearable @click:clear="team_skeyword=null" name="teamSearchInput"
            color="avidocolor" prepend-icon="mdi-magnify" class="px-2" />
            <v-list>
              <v-list-item-group  v-model="updateNote.assigned_teamids" :multiple="true" color="avidocolor">
                <template v-for="(tmodel, tindex) in searchTeam">

                  <v-list-item :key="(tmodel.id +'tm_'+ tindex)" :value="tmodel" :ripple="false"
                    active-class="avidocolor--text text--accent-4">

                    <v-list-item-avatar tile size=50>
                      <v-img class="rounded" :alt="tmodel.name"
                        :src="(tmodel.profile_pic != null) ? tmodel.profile_pic : '/images/logo.jpg'"
                        @error="tmodel.profile_pic = '/images/logo.jpg'"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content class="text-left">
                      <v-list-item-title class="align-center" v-text="tmodel.name"></v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                  <v-divider v-if="tindex < searchTeam.length - 1" :key="tindex"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
        </v-tab-item>

        <v-tab-item value="TEAMMEMBER"  v-if="search_tab=='TEAMMEMBER'">
          <v-text-field v-model="teamMember_skeyword" placeholder="Search..." required clearable  @click:clear="teamMember_skeyword=null"  name="teamMemberSearchInput"
            color="avidocolor" prepend-icon="mdi-magnify" class="px-2" />
          <v-list>
            <v-list-item-group v-model="updateNote.assigned_userids" :multiple="true" color="avidocolor">
              <template v-for="(tmmodel, tmindex) in searchTeamMember">

                <v-list-item :key="(tmmodel.id +'tm_'+ tmindex)" :value="tmmodel" :ripple="false"
                  active-class="avidocolor--text text--accent-4">

                  <v-list-item-avatar tile size=50>
                    <v-img class="rounded" :alt="tmmodel.name"
                      :src="(tmmodel.profile_pic != null) ? tmmodel.profile_pic : '/images/logo.jpg'"
                      @error="tmmodel.profile_pic = '/images/logo.jpg'"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="text-left">
                    <v-list-item-title class="align-center" v-text="tmmodel.name"></v-list-item-title>
                  </v-list-item-content>

                </v-list-item>

                <v-divider v-if="tmindex < searchTeamMember.length - 1" :key="tmindex"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </template>
    

    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>

        <v-btn @click="$router.go(-1)" class="avidocolor--text" text>
          <v-icon>mdi-chevron-left</v-icon>Back
        </v-btn>

        <v-btn @click="$router.go(-1)" class="avidocolor--text" text>
          <v-icon>mdi-content-save</v-icon>save
        </v-btn>

      </v-row>
    </v-footer>
  </div>
</template>


<script>
// import axios from "axios";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "SearchExercise",
  data: () => ({
    search_tab: 'TEAM',
    team_skeyword: null,
    teamMember_skeyword: null,
  }),
  computed: {
    ...mapFields('exerciseNotes', {
      updateNote: 'updateNote',
      searchTeamList: 'searchTeamList',
      searchTeamMemberList: 'searchTeamMemberList',
    }),
    searchTeam() { 
      if (this.team_skeyword != null) {
        return this.searchTeamList.filter(item => {
          return item.name.toUpperCase().includes(this.team_skeyword.toUpperCase())
        })
      } else {
        return this.searchTeamList
      }
    },    
    searchTeamMember() {
      if (this.teamMember_skeyword != null) {
        return this.searchTeamMemberList.filter(item => {
          return item.name.toUpperCase().includes(this.teamMember_skeyword.toUpperCase())
        })
      } else {
        return this.searchTeamMemberList
      }
    },
  },
  methods: {
    ...mapActions("exerciseNotes", {
      addExerciseToNotes: "addExerciseToNotes",
    }),
  }
};


</script>
