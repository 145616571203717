<template>
  <div class="editlibraryworkoutexercise">

    <v-form
      ref="editLibraryWorkoutForm"
      v-model="valid"
      lazy-validation
      @submit.prevent="onSubmit"
      method="post"
      class="pb-12 mb-12"
    >
    <v-card class="pa-2">
      <v-text-field
        counter='50'
        v-model="library_name"
        prepend-icon="mdi-pencil"
        name="name"
        label="Enter Name"
        color="avidocolor"
        :rules="[(v) => !!v || 'Name is required',(v) => !!v && v.length <= 50 || 'Max 50 characters allow.']"
      ></v-text-field>
  
      <v-textarea
        counter='500'
        v-model="library_description"
        prepend-icon="mdi-pencil"
        name="description"
        color="avidocolor"
        :rules="[v => (v || '' ).length <= 500 || 'Description must be 500 characters or less']"
      >
        <template v-slot:label>
          <div>Description</div>
        </template>
      </v-textarea>
      

      <v-row>
        <v-col cols="5" md="2" class="pl-0">
          <v-icon>mdi-camera</v-icon> Select Image
        </v-col> 
        <v-col cols="7" md="10">
        <v-file-input
            @change="onFileChange"
            v-model="library_image"
            id="image-upload"
            class="d-none"
          ></v-file-input>
          <v-avatar :color="(imagePreviewURL!=null) ? null : 'avidocolor'" class="rounded"  tile size="160"  @click="openUpload">
            <v-icon color="black" x-large :class="(imagePreviewURL!=null) ? 'd-none' : null">
              mdi-camera
            </v-icon>
            <v-img class="rounded" :alt="library_name" 
              :class="(imagePreviewURL==null) ? 'd-none' : null"
              :src="(imagePreviewURL!=null) ? imagePreviewURL : '/images/logo.jpg'"
             @error="imagePreviewURL='/images/logo.jpg'"></v-img>
          </v-avatar>
        </v-col>
      </v-row> 
    </v-card>

      <div class="text-left text-body-1 pt-2">WarmUp</div> 
      <v-card class="rounded-lg mb-1">
        <v-list-item v-if="warmup!=null">
          <v-btn
            icon
            color="red"
            dark
            @click.stop.prevent="
              removeWFC('WARMUP')
            "
          >
            <v-icon color="red">mdi-minus-circle</v-icon>
          </v-btn>
          <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="warmup.name" :src="(warmup.image!=null)?warmup.image:'/images/logo.jpg'" @error="warmup.image='/images/logo.jpg'"></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="pl-2  text-left">
            <v-list-item-title v-if="warmup!=null" >{{warmup.name}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="align-center ma-0">
            <v-list-item-avatar  @click.stop.prevent="getLibraryModel(['WARMUP','LADDWARMUP',warmup.id])" >
              <v-icon large class="avidocolor--text">mdi-shuffle-variant</v-icon>
            </v-list-item-avatar>
          </v-list-item-action>
        </v-list-item>   

        <v-list-item v-if="warmup==null" @click.stop.prevent="(library_name!=null) ? getLibraryModel(['WARMUP','LADDWARMUP',0]) : null">
            <v-list-item-avatar>
              <v-icon class="avidocolor--text">mdi-plus-box</v-icon> 
            </v-list-item-avatar>
            <v-list-item-content  class="text-left">
              <v-list-item-title>Add WarmUp</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-card>  

      <div class="text-left text-body-1 pt-2 pb-2">
        Exercise
        <v-btn rounded small class="avidocolor black--text" @click="setallset" style="float: right;">
          Set All
        </v-btn>
      </div>
      
        <draggable v-model="exerciseList"
          handle=".handle"
          ghost-class="grey"
          @start="dragging = true"
          @end="dragging = false"
          v-if="
            (exerciseList.length>0)
          " 
          color="grey darken-4">
            <transition-group>
                  <div
                    class="list-group-item"
                    v-for="(exerciseListmodel,eindex) in exerciseList"
                    :key="'lgindex-'+eindex"
                  >
                  <v-card class="rounded-lg mb-1" :key="eindex">
                  <template  v-if = "exerciseListmodel.length==1">
                    <template v-for="(model,mindex) in exerciseListmodel">
                      <v-list-item :key="mindex+model.lw_exercise_id+model.exercise_id" >
                        <v-btn
                          icon
                          color="red"
                          dark
                          @click.stop.prevent="
                            removeExercise(eindex,mindex)
                          "
                        >
                          <v-icon color="red">mdi-minus-circle</v-icon>
                        </v-btn>

                        <v-list-item-avatar tile size=50>
                          <v-img class="rounded" :alt="model.exercise_name" :src="(model.exercise_image_thumbnail!=null)?model.exercise_image_thumbnail:'/images/logo.jpg'" @error="model.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content class="pl-2 text-left">
                          <v-list-item-title
                            class="align-center"
                            v-text="model.exercise_name"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action class="align-center ma-0">
                            <v-btn
                              icon
                              color="red"
                              dark
                              class="handle"
                            >
                              <v-icon color="avidocolor">mdi-drag</v-icon>
                            </v-btn>

                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </template>

                  <template  v-if = "exerciseListmodel.length>1">
                    <template v-for="(supermodel,superindex) in exerciseListmodel">
                        
                        <v-list-item  :key="superindex+supermodel.lw_exercise_id+supermodel.exercise_id">
                          <v-btn
                            icon
                            color="red"
                            dark
                            @click.stop.prevent="
                              removeExercise(eindex,superindex)
                            "
                          >
                            <v-icon color="red">mdi-minus-circle</v-icon>
                          </v-btn>
                          
                          <!-- <div class="pl-9" v-if="superindex>0" ></div> -->
                          <v-list-item-avatar tile size=50>
                           <v-img class="rounded" alt="avtar_image" :src="(supermodel.exercise_image_thumbnail!=null)?supermodel.exercise_image_thumbnail:'/images/logo.jpg'" @error="supermodel.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content class="pl-2 text-left">
                            <v-list-item-title class="align-center"
                              >{{supermodel.exercise_name}}</v-list-item-title
                            >
                          </v-list-item-content>

                          <v-list-item-action class="align-center ma-0"  v-if="superindex==0" >
                            <v-btn
                              icon
                              dark
                              class="handle"
                            >
                              <v-icon color="avidocolor">mdi-drag</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>

                        <v-list-item  v-if="superindex < exerciseListmodel.length - 1" :key="'icon'+superindex+supermodel.lw_exercise_id+supermodel.exercise_id" style="min-height: 0px !important;">
                          <v-list-item-avatar height="0px"></v-list-item-avatar>
                          <v-list-item-content class="d-block ma-n5 pa-0 text-left">
                            <v-list-item-avatar class="ml-12 mt-0 mb-0 align-center">
                              <v-img @click="removeSuperSet(eindex,superindex)"  alt="superst" :src="imageSource"></v-img>
                            </v-list-item-avatar>
                          </v-list-item-content>
                        </v-list-item>

                    </template>
                  </template>
                </v-card>  
                  <v-list-item  v-if="eindex < exerciseList.length - 1" style="min-height: 0px !important;">
                    <v-list-item-avatar height="0px"></v-list-item-avatar>
                    <v-list-item-content class="d-block ma-n5 py-4 px-0  text-left">
                      <v-list-item-avatar class="ml-12 mt-0 mb-0 align-center">
                        <v-img @click="addSuperSet(eindex)"  alt="superst" :src="grayimageSource"></v-img>
                      </v-list-item-avatar>
                    </v-list-item-content>
                  </v-list-item>
                
                </div>
            </transition-group>
        </draggable>


      <div class="text-left text-body-1 pt-2">Finisher</div> 
      <v-card class="rounded-lg mb-1">
        <v-list-item v-if="finisher!=null" @click="(finisher!=null) ? viewLWorkoutWFC('FINISHER') : null">
          <v-btn
            icon
            color="red"
            dark
            @click.stop.prevent="
              removeWFC('FINISHER')
            "
          >
            <v-icon color="red">mdi-minus-circle</v-icon>
          </v-btn>
          <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="finisher.name" :src="(finisher.image!=null)?finisher.image:'/images/logo.jpg'" @error="finisher.image='/images/logo.jpg'"></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="text-left">
            <v-list-item-title v-if="finisher!=null" >{{finisher.name}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="align-center ma-0">
            <v-list-item-avatar  @click.stop.prevent="getLibraryModel(['FINISHER','LADDFINISHER',finisher.id])" >
              <v-icon large class="avidocolor--text">mdi-shuffle-variant</v-icon>
            </v-list-item-avatar>
          </v-list-item-action>
        </v-list-item>   

        <v-list-item v-if="finisher==null"  @click.stop.prevent="(library_name!=null) ? getLibraryModel(['FINISHER','LADDFINISHER',0]) : null">
            <v-list-item-avatar>
              <v-icon class="avidocolor--text">mdi-plus-box</v-icon> 
            </v-list-item-avatar>
            <v-list-item-content  class="text-left">
              <v-list-item-title>Add Finisher</v-list-item-title>
            </v-list-item-content>
        </v-list-item>   
      </v-card>  

    <div class="text-left text-body-1 pt-2">Cooldown</div> 
    <v-card class="rounded-lg mb-1">
        <v-list-item v-if="cooldown!=null" @click="(cooldown!=null) ? viewLWorkoutWFC('COOLDOWN') : null">
          <v-btn
            icon
            color="red"
            dark
            @click.stop.prevent="
              removeWFC('COOLDOWN')
            "
          >
            <v-icon color="red">mdi-minus-circle</v-icon>
          </v-btn>
          <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="cooldown.name" :src="(cooldown.image!=null)?cooldown.image:'/images/logo.jpg'" @error="cooldown.image='/images/logo.jpg'"></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="text-left">
            <v-list-item-title v-if="cooldown!=null" >{{cooldown.name}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="align-center ma-0">
            <v-list-item-avatar  @click.stop.prevent="getLibraryModel(['COOLDOWN','LADDCOOLDOWN',cooldown.id])">
              <v-icon large  class="avidocolor--text">mdi-shuffle-variant</v-icon>
            </v-list-item-avatar>
          </v-list-item-action>
        </v-list-item>   

        <v-list-item v-if="cooldown==null"  @click.stop.prevent="(library_name!=null) ? getLibraryModel(['COOLDOWN','LADDCOOLDOWN',0]) : null">
            <v-list-item-avatar>
              <v-icon class="avidocolor--text">mdi-plus-box</v-icon> 
            </v-list-item-avatar>
            <v-list-item-content  class="text-left">
              <v-list-item-title>Add Cooldown</v-list-item-title>
            </v-list-item-content>
        </v-list-item>   
      </v-card>  


      <v-footer color="black" fixed>
        <v-row justify="center" no-gutters>
          <v-btn  @click="getFavoriteExercise('LEXERICSE')"  class="my-2 avidocolor--text" text>
            <v-icon>mdi-plus-box</v-icon>Add Exercise
          </v-btn>

          <v-btn type="submit"  class="my-2 avidocolor--text" text>
            <v-icon>mdi-content-save</v-icon>save workout
          </v-btn>
        </v-row>
      </v-footer>
  </v-form>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import draggable from 'vuedraggable';

export default {
  name: "EditLibraryWorkoutExercise",
  data: () => ({
    valid: false,
    imageSource: "/images/superset_icon.png",
    grayimageSource: "/images/gray_superset_icon.png"
  }),
  components: {
    draggable
  },
  computed:{
    ...mapFields('library',[
      'library_id', 
      'library_name', 
      'library_tab', 
      'library_description',
      'library_image',
      'imagePreviewURL',
    ]),  
    
    ...mapFields('setAllExerciseSet',[
      'setall_workout_type', 
    ]),

    ...mapFields('libraryExercise',{
      total_reps:'total_reps',
      total_sets:'total_sets',
      total_weight:'total_weight',
      exerciseList:'lexerciseList',
      warmup:'warmup',
      cooldown:'cooldown',
      finisher:'finisher',
      viewLibraryWFC:'viewLibraryWFC',
    }),
    
  },
  methods: {

    ...mapActions("library", {
      getLibraryModel: "getLibraryModel",
    }),

    ...mapActions('libraryExercise',{
      saveLibraryWorkout:'saveLibraryWorkout',
    }),  
    ...mapActions("bottomSearch", {
      getFavoriteExercise: "getFavoriteExercise",
    }),


    openUpload () {
      document.getElementById('image-upload').click()
    },
   
    onFileChange(payload) {
      //const file = payload.target.files[0]; // use it in case of normal HTML input
      const file = payload; // in case vuetify file input
      if (file) {
        this.imagePreviewURL = URL.createObjectURL(file);
        URL.revokeObjectURL(file); // free memory
        // console.log(payload)
      } else {
        this.imagePreviewURL =  null
      }
    },

    onSubmit: function () {
      if (this.$refs.editLibraryWorkoutForm.validate()) {
        let display_error=false
        let error_mesg=null

        if(this.library_name==null){
          display_error=true
          error_mesg='Workout name is required.'
        }

        if(this.exerciseList.length==0){
          display_error=true
          error_mesg='Please add exercise in library workout.'
        }

        if(display_error){
          this.$store.dispatch(
            "setNotification",
            {
              display: true,
              text: error_mesg,
              alertClass: "error",
            },
            { root: true }
          );
        }else{
          this.saveLibraryWorkout(1);
        }
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },

    removeExercise(eindex,supindex) { 
      this.data_change=true
      let newexerciseList=[]
      if(this.exerciseList!=null){
        for (const [key, nexerciseList]  of Object.entries(this.exerciseList)) {
          if(nexerciseList.length>1){
            if(key==eindex){
              let firsthalfexercise=[]
              for (const [superkey, superexeriseList]  of Object.entries(this.exerciseList[eindex])) {
                if(superkey!=supindex){
                  firsthalfexercise.push(superexeriseList);
                }
              }
              newexerciseList.push(firsthalfexercise);
            }else{
              newexerciseList.push(nexerciseList);
            } 
          }else if(key!=eindex){
            newexerciseList.push(nexerciseList);
          }
        }
        this.exerciseList=newexerciseList
      }
    },  

    removeSuperSet(eindex,superindex) { 
      let newexerciseList=[]
      if(this.exerciseList!=null){
        for (const [key, nexerciseList]  of Object.entries(this.exerciseList)) {
          if(key==eindex){
            let firsthalfexercise=[]
            let secondhalfexercise=[]
            for (const [superkey, superexeriseList]  of Object.entries(this.exerciseList[eindex])) {
              if(superkey<=superindex){
                firsthalfexercise.push(superexeriseList);
              }else{
                secondhalfexercise.push(superexeriseList);
              }
            }
            newexerciseList.push(firsthalfexercise);
            newexerciseList.push(secondhalfexercise);
          }else{
            newexerciseList.push(nexerciseList);
          }
        }
        this.exerciseList=newexerciseList
      }
    }, 
    
    addSuperSet(eindex) { 
      if(this.exerciseList[eindex]!=null){
        let superexercise=[];
        let secondindex=eindex+1;
        for (let firstsuperexercises  of Object.values(this.exerciseList[eindex])) {           
          superexercise.push(firstsuperexercises);
        }   
        for (let secondsuperexercises  of Object.values(this.exerciseList[secondindex])) {           
          superexercise.push(secondsuperexercises);
        }
        if (eindex >= 0) this.exerciseList.splice(eindex, 1);
        this.exerciseList[eindex]=superexercise;        
      }
    },

    viewLWorkoutWFC(wfc_type){
      if(wfc_type=='WARMUP'){
        this.viewLibraryWFC=this.warmup
      }else if(wfc_type=='FINISHER'){
        this.viewLibraryWFC=this.finisher
      }else if(wfc_type=='COOLDOWN'){
        this.viewLibraryWFC=this.cooldown
      }
      this.$router.push({ path: 'view-wfc' });
    }, 
    removeWFC(wfc_type){
      if(wfc_type=='WARMUP'){
        this.warmup=null
      }else if(wfc_type=='FINISHER'){
        this.finisher=null
      }else if(wfc_type=='COOLDOWN'){
        this.cooldown=null
      }
    },
    setallset(){
      this.setall_workout_type='LIBRARY'
      this.$router.push({ path: 'setallexerciseset' });
    }
  },
};
</script>

<style scoped>
.handle {
  cursor: move; 
}
</style>