import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import store from "../index.js"

export default {
  namespaced: true,
  state: {
    exercise_instruction_id: 0,
    exercise_name: null,
    image_thumb: null,
    image_large: null,
    exer_videourl: null,
    user_exercise_notes: [],
    primary_muscles: null,
    secondary_muscles: null,
    position_set_up: null,
    movement: null,
    cue_points: null,
    user_1RM: null,
    result_1RM: null,
    zero_weight_label: null,
    reps_records: {},
    user_exercise_history: [],
    is_favorite_exercise:false,
    add_fav_exercise_id:0,
    remove_fav_exercise_id:0,
  },
  mutations: {
    setModel(state, resp_data) {
      state.exercise_name = resp_data.name
      state.image_thumb = resp_data.image_thumb
      state.image_large = resp_data.image_large
      state.exer_videourl = resp_data.video_url
      state.primary_muscles = resp_data.primary_muscles.join(',')
      state.secondary_muscles = resp_data.secondary_muscles.join(',')
      state.position_set_up = resp_data.position_set_up
      state.movement = resp_data.movement
      state.cue_points = resp_data.cue_points
      state.user_1RM = resp_data.user_one_rep_max
      state.result_1RM = resp_data.result_one_rep_max
      state.is_favorite_exercise = resp_data.is_favorite_exercise
      state.user_exercise_history = resp_data.user_exercise_history
      state.zero_weight_label = resp_data.zero_weight_label
      state.user_exercise_notes = resp_data.exercise_notes
      state.reps_records = (resp_data.reps_records==0) ? {} : resp_data.reps_records
    },
    unsetModel(state) {
      state.exercise_name = null
      state.image_large = null
      state.exer_videourl = null
      state.primary_muscles = null
      state.secondary_muscles = null
      state.position_set_up = null
      state.movement = null
      state.cue_points = null
      state.user_1RM = null
      state.result_1RM = null
      state.zero_weight_label = null
      state.user_exercise_history = []
      state.is_favorite_exercise = false
      state.add_fav_exercise_id = 0
      state.remove_fav_exercise_id = 0
      state.reps_records = {}
    },
    updateField
  },
  getters: {
    getField,
  },
  actions: {
    getModel({ commit, dispatch }) {
      commit("unsetModel")
      return new Promise((resolve,reject) => {
        axios({
          method: 'GET',
          url: 'get_exercise_instructions',
          params: {
            user_id: this.state.workoutCalendar.get_for_user_id,
            exercise_id: this.state.exerciseInstruction.exercise_instruction_id,
          }
        }).then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setModel", response_data)
              resolve(resp)
            }
        }).catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    favUnfavExercise({ dispatch }) {
      return new Promise((reject) => {
        axios({
          method: 'POST',
          url: 'add_to_favorite_exercise',
          data: {
            add_fav_exercise_id: this.state.exerciseInstruction.add_fav_exercise_id,
            remove_fav_exercise_id: this.state.exerciseInstruction.remove_fav_exercise_id,          
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              store.commit("bottomSearch/setFavExercise",response_data);
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    }, 


    
  },
};