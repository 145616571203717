import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'
import store from "../index.js"

export default {
  namespaced: true,
  state: {
    userModel: {
      first_name: null,
      last_name: null,
      email: null,
      birth_date: null,
      user_age: 1,
      height_feet: null,
      height_inch: null,
      weight: null,
      gender: null,
    //  client_join_code: null,
    //  privacy_setting: null,
      bio: null,
      imagePreviewURL: null,
      profile_pic: null,
      upgrade_to_trainer: false,
      display_skin_options: [],
      current_displayskin_id: 0,
    },
  },
  getters: {
    getField,
  },

  mutations: {
    setDisplaySkinOptions(state, display_data) {
      state.userModel.display_skin_options = display_data
    },
    setModelState(state, user_data) {
      state.userModel.first_name = user_data.first_name
      state.userModel.last_name = user_data.last_name
      state.userModel.email = user_data.email
      state.userModel.birth_date = user_data.birth_date
      state.userModel.height_feet = user_data.height_feet
      state.userModel.height_inch = user_data.height_inch
      state.userModel.weight = user_data.weight
      state.userModel.gender = user_data.gender
    //  state.userModel.client_join_code = (user_data.trainer_join_code!=null && user_data.trainer_join_code!='null') ? user_data.trainer_join_code : ''
    //  state.userModel.privacy_setting = user_data.privacy_setting
      state.userModel.bio = (user_data.bio!=null && user_data.bio!='null') ? user_data.bio : ''
      state.userModel.imagePreviewURL = user_data.profile_pic
      state.userModel.upgrade_to_trainer = user_data.upgrade_to_trainer
      state.userModel.current_displayskin_id = user_data.current_displayskin_id
      
      // User Age 
      var today = new Date();
      var birthDate = new Date(user_data.birth_date);
      state.userModel.user_age = today.getFullYear() - birthDate.getFullYear()

      if(user_data.profile_pic!=null){
        const user_profilepic = user_data.profile_pic
        this.state.user.user_profile_pic = user_profilepic
        localStorage.setItem("user_profile_pic", user_profilepic);
      }
      const username = user_data.first_name+' '+user_data.last_name
      localStorage.setItem("user_name", username);

    },
    updateField,
  },

  actions: {

    getModel({ commit, dispatch }) {
      return new Promise((resolve,reject) => {
          axios({
            method: 'GET',
            url: 'get_account_settings',
          })
          .then(resp => {

            const resp_code = resp.status
            if (resp_code == 200) {
              const user_data = resp.data.response.user_account_settings
              commit("setModelState", user_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    getProfileModel({ commit, dispatch }) {
      return new Promise((resolve,reject) => {

        let one = "get_account_settings"
        let two = "get_user_display_skin"

        const requestOne = axios.get(one);
        const requestTwo = axios.get(two);

        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
          const responseOne = responses[0]
          const responseTwo = responses[1]

          const accounting_resp_code = responseOne.status
          if (accounting_resp_code == 200) {
            const user_data = responseOne.data.response.user_account_settings
            commit("setModelState", user_data);
          } 

          const displayskin_resp_code = responseTwo.status

          if (displayskin_resp_code == 200) {
            const display_data = responseTwo.data.response
            commit("setDisplaySkinOptions", display_data);
          } 

          resolve(responses)
        })).catch(error => {
          const resp_code = error.response.status
          if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })

      })
    },

    profileSettingSave({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
          let formData = new FormData()
          formData.append('first_name', this.state.accountSettings.userModel.first_name)
          formData.append('last_name', this.state.accountSettings.userModel.last_name)
          formData.append('email', this.state.accountSettings.userModel.email)
          formData.append('profile_pic', this.state.accountSettings.userModel.profile_pic)
        //  formData.append('privacy_setting', this.state.accountSettings.userModel.privacy_setting)
          formData.append('bio', this.state.accountSettings.userModel.bio)
          formData.append('current_displayskin_id', this.state.accountSettings.userModel.current_displayskin_id)
          axios.post('set_account_settings',
            formData,
            {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  'Access-Control-Allow-Origin': '*'
              }
            }
          )
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const success_mesg = resp.data.message
              const user_data = resp.data.response.user_account_settings
              commit("setModelState", user_data);

              this.state.userProfile.user_id=resp.data.response.user_account_settings.id
              if(resp.data.response.current_displayskin!=null){
                store.commit('changeThemeColor',resp.data.response.current_displayskin);
              }
              
              dispatch("setNotification", {
                display: true,
                text: success_mesg,
                alertClass: "success"
              }, { root: true });
              
              let uri = window.location.search.substring(1); 
              let params = new URLSearchParams(uri);
              if(params.get("ar")==1){
                router.push({ path: '/user-profile' });
              }else{
                router.go(-1);
              }
             
              resolve(resp)
            } else {
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass: "error"
              }, { root: true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    updateModel({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
          let formData = new FormData()
          formData.append('first_name', this.state.accountSettings.userModel.first_name)
          formData.append('last_name', this.state.accountSettings.userModel.last_name)
          formData.append('email', this.state.accountSettings.userModel.email)
          formData.append('birth_date', this.state.accountSettings.userModel.birth_date)
          formData.append('gender', this.state.accountSettings.userModel.gender)
          formData.append('height_feet', this.state.accountSettings.userModel.height_feet)
          formData.append('height_inch', this.state.accountSettings.userModel.height_inch)
          formData.append('weight', this.state.accountSettings.userModel.weight)
/*           if(this.state.accountSettings.userModel.upgrade_to_trainer==true){
            formData.append('trainer_join_code', this.state.accountSettings.userModel.client_join_code)
          } */

          axios.post('set_account_settings',
            formData,
            {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  'Access-Control-Allow-Origin': '*'
              }
            }
          )
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const success_mesg = resp.data.message
              const user_data = resp.data.response.user_account_settings
              commit("setModelState", user_data);
              this.state.userProfile.user_id=user_data.id
              store.commit('changeHeaderUserName',user_data.first_name+' '+user_data.last_name)

              dispatch("setNotification", {
                display: true,
                text: success_mesg,
                alertClass: "success"
              }, { root: true });

              router.push({ path: '/user-profile' });
              location.reload();
              
              resolve(resp)
            } else {
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass: "error"
              }, { root: true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },


    deleteUserAccount({ dispatch }) {
      return new Promise((resolve,reject) => {
        axios({
          method: 'POST',
          url: 'delete_user_account',
        })
        .then(resp => {
          const resp_code = resp.status
          if (resp_code == 200) {
            router.push({ path: '/logout' });
            resolve(resp)
          }
        })
        .catch(error => {
          const resp_code = error.response.status
          if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })
      })
    }, 

  },

};