import axios from "axios";
import { getField,updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    workout_id: 0,
    user_cycle_id: 0,
    workout_date: null,
    equipmentList: [],
  },
  mutations: {
    setModel(state, parameters) {
      state.workout_id =  parameters.wid,
      state.user_cycle_id = parameters.ucid,
      state.workout_date = parameters.w_date
    },
    setModelEquipment(state, resp_data) {
      state.equipmentList = resp_data.EQUIPMENT
    },
    updateField
  },
  getters: {
    getField,
  },
  actions: {
    getModel({ commit, dispatch },parameters) {
      return new Promise((resolve,reject) => {
        commit("setModel", parameters);
        axios({
          method: 'GET',
          url: 'get_muscle_equipment_category',
          params: {
            type: 'EQUIPMENT',
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setModelEquipment", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
  },
};