import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'
import store from "../index.js"

export default {
  namespaced: true,
  state: {
    team_id: 0,
    team_name: null,
    joindialog: null,
    change_training_dialog: false,
    profile_url: null,
    team_description: null,
    team_organization_name: null,
    total_reps: 0,
    total_weight: 0,
    total_workouts: 0,
    fromseacrh: 0,
    team_coaches: [],
    team_members: [],
    coach_join_code: null,
    athlete_join_code: null,
    is_teammember: false,
    is_teamcoach: false,
    leaderBoard_team_id: 0,
    leaderBoard_team_name: null,
    viewleaderBoard: {},
    leaderBoards: [],
    leaderBoardResults: [],
    leaderBoarder_resultitems: [
      {
        title: 'Max Reps in 1 Set',
        value: 'MAXREPS'
      },
      {
        title: 'Max Weight Lifted',
        value: 'MAXWEIGHT'
      },
    ],
  },

  getters: {
    getField,
  },

  mutations: {
    setModelState(state, response_data) {
      state.team_name = response_data.team_name
      state.team_description = response_data.description
      state.profile_url = response_data.team_profile_pic
      state.team_coaches = response_data.team_coaches
      state.team_members = response_data.team_members
      state.team_organization_name = response_data.organization_name
      state.is_teammember = response_data.is_teammember
      state.is_teamcoach = response_data.is_teamcoach
      state.leaderBoards = response_data.leader_boards
      state.leaderBoardResults = response_data.leader_board_result
    },
    resetModelState(state){
      state.athlete_join_code=null
      state.coach_join_code=null
    },

    resetTeamLeaderBoard(state) {
      state.leaderBoard_team_id = 0
      state.viewleaderBoard = {}
      state.leaderBoards = []
      state.leaderBoardResults = []
    },

    updateField
  },

  actions: {
    getTeamProfileModel({ commit, dispatch }) {
      commit("resetTeamLeaderBoard");
      if(this.state.teamProfile.team_id>0){
        return new Promise((resolve, reject) => {
          axios({
            method: 'GET',
            url: 'view_team_detail',
            params: {
              team_id: this.state.teamProfile.team_id
            }
          }).then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setModelState", response_data);
              resolve(resp)
            }
          })
            .catch(error => {
              const resp_code = error.response.status
              if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
                const error_mesg = error.response.data.errors
                dispatch("setNotification", {
                  display: true,
                  text: error_mesg,
                  alertClass: "error"
                }, { root: true });
                reject(error)
              }
            })
        })
      }
    },


    joinTeam({ dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: 'join_team',
          data: {
            team_id: this.state.teamProfile.team_id,
            coach_join_code: this.state.teamProfile.coach_join_code,
            athlete_join_code: this.state.teamProfile.athlete_join_code,
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {

              const success_mesg = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: success_mesg,
                alertClass: "success"
              }, { root: true })

              const response_data = resp.data.response
              if(response_data.current_displayskin!=null){
                store.commit('changeThemeColor',response_data.current_displayskin);
              }

              this.state.teamProfile.joindialog=false
              this.state.teamProfile.change_training_dialog=true
              router.push({ path: '/user-profile' });  
              
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    viewTeamLeaderBoard({ commit, dispatch },lbrmodel) {
      commit("resetTeamLeaderBoard");
      if(this.state.teamProfile.team_id>0){
        return new Promise((resolve, reject) => {
          axios({
            method: 'GET',
            url: 'view_leader_board',
            params: {
              team_id: this.state.teamProfile.team_id,
              exercise_id: lbrmodel.exercise_id,
              result_type: lbrmodel.result_type
            }
          }).then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              this.state.teamProfile.viewleaderBoard={
                'team_id':this.state.teamProfile.team_id,
                'team_name':this.state.teamProfile.team_name,
                'exercise_id':lbrmodel.exercise_id,
                'exercise_name':lbrmodel.exercise_name,
                'result_type':lbrmodel.result_type,
                'lb_result':response_data
              }
              router.push({ path: '/view-leaderboard' }); 
              resolve(resp)
            }
          })
            .catch(error => {
              const resp_code = error.response.status
              if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
                const error_mesg = error.response.data.errors
                dispatch("setNotification", {
                  display: true,
                  text: error_mesg,
                  alertClass: "error"
                }, { root: true });
                reject(error)
              }
            })
        })
      }
    },

    saveTeamLeaderBoard({ dispatch }) {

      return new Promise((resolve, reject) => {
        let param_leaderBoards={}  
        let input_leaderBoards=this.state.teamProfile.leaderBoards

        param_leaderBoards = input_leaderBoards.map(({ exercise_id, result_type }) => ({ exercise_id, result_type }));

          axios({
            method: 'POST',
            url: 'save_leader_boards',
            data: {
              team_id: this.state.teamProfile.leaderBoard_team_id,
              leaderBoards: param_leaderBoards
            }
          })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const resp_message = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });
              router.go(-1)
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
    

    swichtrainingCalendertoteam({ dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: 'set_calendar_to_team',
          data: {            
            calendar_team_id: this.state.teamProfile.team_id
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {

              const success_mesg = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: success_mesg,
                alertClass: "success"
              }, { root: true })

              this.state.teamProfile.change_training_dialog=false
              router.push({ path: '/week-summary' });  
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

  },

};