<template>
  <div class="NoteDetails">
    <v-card class="pa-2">
        <v-row>
          <v-col cols="12">
            <h2 class="mb-3 d-inline">
              <span class="avidocolor--text">{{ currentNoteExercise.exercise_name}}</span>
            </h2>
          </v-col>
        </v-row>

        <v-img
          :src="(currentNoteExercise.exercise_image_large!=null) ? currentNoteExercise.exercise_image_large: 'images/logo.jpg'" 
          @error="currentNoteExercise.exercise_image_large='images/logo.jpg'"
          v-if="playVideo==0"
          @load="onImageLoad"
          contain
          :height="$vuetify.breakpoint.xs ? '100%' : ($vuetify.breakpoint.sm ? '100%' : '300px')"
        >

          <v-btn absolute bottom left
            icon color="avidocolor"
            v-if="(currentNoteExercise.exercise_image_large!='images/logo.jpg')"
            @click="playVideo=1">
              <v-icon size="40">mdi-play-circle-outline</v-icon>
          </v-btn>
        </v-img>
        <video v-else width="100%" :height="$vuetify.breakpoint.xs ? '100%' : ($vuetify.breakpoint.sm ? '100%' : '300px')" autoplay controls="false" poster="noposter" :src="currentNoteExercise.exercise_video_url" preload="none" disablePictureInPicture controlsList="noplaybackrate nodownload">
        </video>
    </v-card>

    <template  v-if="currentNoteExercise.note_details.length>0">
       <template v-for="(notemodel, noteindex) in currentNoteExercise.note_details">
          <v-card class="mt-2" outlined :key="noteindex">
            <v-card-text>

            <div class="text-left align-self-start text-body-1">{{ notemodel.assignedBy+' - '+notemodel.updated_at }}
              <template v-if="notemodel.is_editable">
                <v-icon color="avidocolor" class="float-right" @click="editNotes(notemodel)" >mdi-pencil-box-outline</v-icon>              
                <v-icon color="red" class="float-right mr-2" @click="deleteNote(noteindex,notemodel.note_id)" >mdi-minus-circle</v-icon>
              </template>
            </div>

            <div class="text-left align-self-start subtitle-1" v-for="(ptext, pindex) in notemodel.notes.split('\n')"
              :key="pindex">
              {{ ptext }}              
            </div>

            <template v-if="!notemodel.is_personal_note && notemodel.assigned_userids.length>0">
              <v-card-text>
                <div class="text-left align-self-start subheading">Individuals</div>
                <v-chip-group>
                  <template v-for="(userModel, uindex) in notemodel.assigned_userids">
                      <v-chip :key="uindex" outlined>
                        <v-avatar start class="mr-2">
                          <v-img :alt="userModel.name" :src="(userModel.profile_pic!=null)?  userModel.profile_pic :'/images/logo.jpg'" @error="userModel.profile_pic='/images/logo.jpg'"></v-img>
                        </v-avatar>
                        {{userModel.name}}
                      </v-chip>
                  </template>
                </v-chip-group>
              </v-card-text> 
            </template>
                            
            <template  v-if="!notemodel.is_personal_note && notemodel.assigned_teamids.length>0">
              <v-card-text>
              <div class="text-left align-self-start subheading">Teams</div>
                <v-chip-group active-class="avidocolor--text">
                  <template v-for="(teamModel, tindex) in notemodel.assigned_teamids">
                      <v-chip :key="tindex" outlined>
                        <v-avatar start class="mr-2">
                          <v-img :alt="teamModel.name" :src="(teamModel.image!=null)?  teamModel.image :'/images/logo.jpg'" @error="teamModel.image='/images/logo.jpg'"></v-img>
                        </v-avatar>
                        {{teamModel.name}}
                      </v-chip>
                  </template>
                </v-chip-group> 
              </v-card-text> 
            </template>
          
          </v-card-text>
        </v-card>
     </template>
    </template>


    <v-dialog v-model="delete_note_dialog" max-width="300">
      <v-card>
        <v-card-text class="red--text">
          Do you want to permenantly delete this note?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="delete_note_dialog = false">
            No
          </v-btn>

          <v-btn color="avidocolor" text @click.stop.prevent="conformdeleteNote">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <div class="clear"></div>
    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>
        <v-btn  @click="addNoteExercise"  class="my-2 avidocolor--text" text>
          <v-icon>mdi-plus-box</v-icon>Add Note
        </v-btn>
      </v-row>
    </v-footer>

  </div>
</template>


<script>

import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "NoteDetails",
  data() {
    return {
      playVideo: 0,
      delete_index: 0,
      delete_note_id: 0,
      delete_note_dialog: false,
      is_coach: this.$store.state.header_upgrade_to_coach,
    }
  },
  computed: {
    ...mapFields('exerciseNotes', [
      'currentNoteExercise',
      'updateNote',
    ]),
  },
  methods: {
    onImageLoad(){
      this.$store.commit('loading', false);
    },
    ...mapActions("exerciseNotes", {
      addExerciseToNotes: "addExerciseToNotes",
      deleteUserExerciseNote: "deleteUserExerciseNote",
    }),
    editNotes(notemodel){
      this.updateNote=notemodel
      this.$router.push({ path: 'edit-note' });
    },    
    addNoteExercise(){
      let searchExer={
            exercise_id : this.currentNoteExercise.exercise_id,
            exercise_name : this.currentNoteExercise.exercise_name,
            exercise_image_thumbnail : this.currentNoteExercise.exercise_image_thumbnail,
            exercise_large_image : this.currentNoteExercise.exercise_image_large,
            exercise_video_url : this.currentNoteExercise.exercise_video_url,        
          }
      this.addExerciseToNotes(searchExer)
    },
    deleteNote(dindex,note_id){
        this.delete_note_id=note_id
        this.delete_index = dindex
        this.delete_note_dialog=true
    },
    conformdeleteNote() {
      this.deleteUserExerciseNote({ remove_index: this.delete_index, delete_note_id: this.delete_note_id })
      this.delete_note_dialog = false
    }
  }
};
</script>
