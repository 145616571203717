import Vue from "vue";
import Vuex from "vuex";
import notification from "./modules/notification";
import accountSettings from "./modules/accountSettings";
import forgotPassword from "./modules/forgotPassword";
import trainingPreferences from "./modules/trainingPreferences";
import user from "./modules/user";
import exerciseInstruction from "./modules/exerciseInstruction";
import workoutCalendar from "./modules/workoutCalendar";
import workoutEquipment from "./modules/workoutEquipment";
import workoutExercise from "./modules/workoutExercise";
import workoutExerciseSet from "./modules/workoutExerciseSet";
import team from "./modules/team";
import myTeamTrainers from "./modules/myTeamTrainers";
import client from "./modules/client";
import userProfile from "./modules/userProfile";
import teamProfile from "./modules/teamProfile";
import organization from "./modules/organization";
import organizationProfile from "./modules/organizationProfile";
import teamTrainingPreferences from "./modules/teamTrainingPreferences";
import library from "./modules/library";
import libraryExercise from "./modules/libraryExercise";
import libraryExerciseSet from "./modules/libraryExerciseSet";
import getDataList from "./modules/getDataList";
import search from "./modules/search";
import setAllExerciseSet from "./modules/setAllExerciseSet";
import bottomSearch from "./modules/bottomSearch";
import results from "./modules/results";
import exerciseNotes from "./modules/exerciseNotes";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
      storage: window.sessionStorage,
  })],
  state: {
    refCount: 0,
    isLoading: false,
    header_title: 'AVIDO',
    header_upgrade_to_coach: false,
    component_name: null,
    header_user_name: null,
    header_afterbw: null,
    darkTextColor: (typeof(localStorage.getItem("darkTextColor")) != "undefined" && localStorage.getItem("darkTextColor") !== null) ? localStorage.getItem("darkTextColor") : "#52F60C",
    solidTextColor: (typeof(localStorage.getItem("solidTextColor")) != "undefined" && localStorage.getItem("solidTextColor") !== null) ? localStorage.getItem("solidTextColor") : "#000000",
    darkBackgroundColor: localStorage.getItem("darkBackgroundColor"),
    lightBackgroundColor: localStorage.getItem("lightBackgroundColor"),
  },
  mutations: {
    changeHeaderTitle(state, title) {
      state.header_title = title;
    }, 
    changeHeaderUserName(state, headerusername) {
      state.header_user_name = headerusername;
    },    
    changeHeaderUpgradeToCoach(state, headerupgradetocoach) {
      state.header_upgrade_to_coach = headerupgradetocoach;
    },     
    changeHeaderAfterbw(state, afterbw) {
      state.header_afterbw = afterbw;
    }, 
    changeComponent(state, componentName) {
      state.component_name = componentName;
    }, 
    changeDarkBackgroundColor(state, dark_background_color) {
      state.darkBackgroundColor = dark_background_color;
    }, 
    changeLightBackgroundColor(state, light_background_color) {
      state.lightBackgroundColor = light_background_color;
    }, 
    changeThemeColor(state,model) {
      /* state.darkTextColor = model.dark_text_color;
      state.solidTextColor = model.solid_text_color;  */
      state.darkBackgroundColor = model.dark_background_color;
      state.lightBackgroundColor = model.light_background_color; 
      localStorage.setItem("darkTextColor", model.dark_text_color);
      localStorage.setItem("solidTextColor", model.solid_text_color);
      localStorage.setItem("darkBackgroundColor", model.dark_background_color);
      localStorage.setItem("lightBackgroundColor", model.light_background_color); 
    }, 
    loading(state, isLoading) {
      if (isLoading) {
        // state.refCount++;
        state.refCount=1
        state.isLoading = true;
      }else if (state.refCount == 1) {
        state.refCount--;
        state.isLoading = (state.refCount > 0);
        
      }else if(state.refCount>=1){
        state.refCount=0
      } 
     
    /*  console.log(isLoading) 
      console.log(state.refCount)  */
      /* if (isLoading) {
        state.refCount++;
        state.isLoading = true;
      } else{
        state.refCount=0;
        state.isLoading =false;
      } */
    }
  },
  modules: {
    notification,
    getDataList,
    user,
    forgotPassword,
    accountSettings,
    trainingPreferences,
    exerciseInstruction,
    workoutCalendar,
    workoutEquipment,
    workoutExercise,
    workoutExerciseSet,
    team,
    myTeamTrainers,
    client,
    userProfile,
    teamProfile,
    organization,
    organizationProfile,
    teamTrainingPreferences,
    library,
    libraryExercise,
    libraryExerciseSet,
    exerciseNotes,
    search,
    setAllExerciseSet,
    bottomSearch,
    results,
  },
})
