import axios from 'axios'
import { isAfter,addSeconds,subMinutes,differenceInMilliseconds } from 'date-fns'
import {refreshToken} from "../../session-manager"
import { getField,updateField } from 'vuex-map-fields'
import router from '../../router'
import store from "../index.js"

export default {
  namespaced: true,
  name: 'user',
  state: {
    status: null,
    firstname: null,
    lastname:null,
    email: null,
    password: null,
    birth_date: null,
    Minutestosubstract: 2,
    user_id: localStorage.getItem('user_id'),
    username: (typeof(localStorage.getItem("user_name")) != "undefined" && localStorage.getItem("user_name") !== null) ? localStorage.getItem("user_name") : null,
    user_profile_pic: (typeof(localStorage.getItem("user_profile_pic")) != "undefined" && localStorage.getItem("user_profile_pic") !== null) ? localStorage.getItem("user_profile_pic") : null,
    access_token: localStorage.getItem("access_token") || null,
    refresh_token: localStorage.getItem("refresh_token") || null,
    tokensExpiry: localStorage.getItem("tokensExpiry") || null,
    favoriteExerciseArray: [],
    favoriteExerciseList: [],
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = null
      state.username = null
      state.access_token = null
      state.refresh_token = null
      state.tokensExpiry = null
      state.user_profile_pic = null
      state.user_id = 0
    },
    resetRegistration(state){
      state.firstname = null
      state.lastname = null
      state.email = null
      state.password = null
      state.birth_date = null
    },
    set_user(state,resp_data){
      const first_name = resp_data.first_name
      const last_name = resp_data.last_name                            
      const username = first_name+' '+last_name
      state.username = username
      state.user_id = resp_data.user_id
      state.favoriteExerciseArray = resp_data.favoriteExerciseArray
      state.favoriteExerciseList = resp_data.favoriteExerciseList

      localStorage.setItem("user_id", resp_data.user_id);
      localStorage.setItem("user_name", username);
 
      if(resp_data.user_id==localStorage.getItem("user_id")){
        if(resp_data.current_displayskin!=null){
          store.commit('changeThemeColor',resp_data.current_displayskin);
        }
      }


      // store._vm.$session.set('username',username);
      if(resp_data.profile_pic!=null){
        const user_profilepic = resp_data.profile_pic
        state.user_profile_pic = user_profilepic
        localStorage.setItem("user_profile_pic", user_profilepic);
      }

    },
    update_auth_tokens(state,tokenData) {
        localStorage.setItem("access_token", tokenData.access_token); //sometimes tokens are in snake case and other times they are in camelcase :/
        state.access_token = localStorage.getItem("access_token");

      //  console.log('update refresh_token-'+tokenData.refresh_token)
        localStorage.setItem("refresh_token", tokenData.refresh_token);
        state.refresh_token = localStorage.getItem("refresh_token");

        const tokensExpiry = addSeconds(new Date(), tokenData.expires_in);
      //  const tokensExpiry = tokenData.expires_in;
        state.tokensExpiry = tokensExpiry;
        localStorage.setItem("tokensExpiry", tokensExpiry);

        axios.defaults.headers.common['Authorization'] = 'Bearer ' +tokenData.access_token
    },
    updateField
  },
  actions: {

    login({ commit, dispatch }){ 

      commit('auth_request')
        return new Promise((resolve,reject) => {
          let api_name='login' 
          let api_data={
            email: this.state.user.email,        
            password: this.state.user.password   
          };
          axios({
            method: 'POST',
            url: api_name,
            params:api_data
          }).then(resp => {
              const resp_code = resp.status
              if (resp_code == 200) {
                let tokenData=resp.data.response.token
                commit("update_auth_tokens", tokenData);
                const resp_data = resp.data.response

                commit('set_user', resp_data)
                store.commit('changeHeaderUserName',resp_data.first_name+' '+resp_data.last_name)
                store.commit('changeHeaderUpgradeToCoach',resp_data.upgrade_to_trainer);
      
                const tokenExpiryDate=  new Date(this.getters["user/tokensExpiry"]);
                const MinutesBeforeExpiry = subMinutes(tokenExpiryDate, this.getters["user/Minutestosubstract"]);
                const now = new Date();
                const next_timeout=differenceInMilliseconds(MinutesBeforeExpiry, now)
  
                setTimeout(refreshToken,next_timeout);
                router.push({ path: '/user-profile' }); 
                resolve(resp)
              }
            })
            .catch(error => {
              const resp_code = error.response.status
              if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
                const error_mesg = error.response.data.errors
                dispatch("setNotification", {
                  display: true,
                  text: error_mesg,
                  alertClass: "error"
                }, { root: true });
                reject(error)
              }
            })
        })

    },

    register({ commit, dispatch }){ 

      commit('auth_request')
        return new Promise((resolve,reject) => {
          let api_name='register' 
          let api_data={
            email: this.state.user.email,
            first_name: this.state.user.firstname,
            last_name: this.state.user.lastname,           
            password: this.state.user.password,
            birth_date: this.state.user.birth_date   
          };
          axios({
            method: 'POST',
            url: api_name,
            params:api_data
          }).then(resp => {
              const resp_code = resp.status
              if (resp_code == 200) {
                const resp_data = resp.data.response

                let tokenData=resp_data.token
                commit("update_auth_tokens", tokenData);            
                commit('set_user', resp_data); 
                store.commit('changeHeaderUserName',resp_data.first_name+' '+resp_data.last_name)
                store.commit('changeHeaderUpgradeToCoach',false);
    
                const tokenExpiryDate=  new Date(this.getters["user/tokensExpiry"]);
                const MinutesBeforeExpiry = subMinutes(tokenExpiryDate, this.getters["user/Minutestosubstract"]);
                const now = new Date();
                const next_timeout=differenceInMilliseconds(MinutesBeforeExpiry, now)
                setTimeout(refreshToken,next_timeout);
                router.push({ path: '/trainingpreferences?ar=1' }); 
                resolve(resp) 
              }
            })
            .catch(error => {
              const resp_code = error.response.status
              if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
                const error_mesg = error.response.data.errors
                dispatch("setNotification", {
                  display: true,
                  text: error_mesg,
                  alertClass: "error"
                }, { root: true });
                reject(error)
              }
            })
        })

    },
  },
  getters: {
      isLoggedIn: state => !!state.access_token,
      user_name: state => (state.username!=='')? state.username : localStorage.getItem("user_name"),
      tokensExpiry: state => state.tokensExpiry,
      Minutestosubstract: state => state.Minutestosubstract,
      isTokenActive(state) {
        const now = new Date();
        const tokenExpiryDate=  new Date(state.tokensExpiry);
        let MinutesBeforeExpiry = subMinutes(tokenExpiryDate, state.Minutestosubstract);
        if (!state.tokensExpiry) {
          return false;
        }else if (state.tokensExpiry=='Invalid Date' || MinutesBeforeExpiry=='Invalid Date'){
          return false;
        }else if (isAfter(now, MinutesBeforeExpiry)) {
          return false;
        }else{
          return true;
        }
      },
      getField,
  }
};
