export default {
  name: 'notification',
  state: {
    notification: {
      display: false,
      text: "Welcome to Avido",
      timeout: 3000,
      class: "success"
    },
  },
  getters: {
    NOTIFICATION: state => {
      return state.notification;
    },
  },
  mutations: {
    SET_NOTIFICATION: (state, { display, text, alertClass }) => {
      state.notification.display = display;
      state.notification.text = text;
      state.notification.class = alertClass;
    },
  },
  actions: {
    setNotification: async ({ commit },{ display, text, alertClass }) => {
      commit("SET_NOTIFICATION", { display, text, alertClass });
    },
  }
};
