<template>
  <div class="organizationProfile">

    <v-row align="center" justify="center">
      <v-col>
        <v-avatar tile size="160">
          <v-img class="rounded" :alt="organization_name" 
            src="/images/logo.jpg"></v-img>
        </v-avatar>
      </v-col>
    </v-row>

    <v-card color="black" class="my-2 rounded-lg">
      <v-row>
        <v-col cols="6" class="pa-2">TEAMS: {{total_teams}}</v-col>
        <v-col cols="6" class="pa-2">COACHES: {{total_coaches}}</v-col>
      </v-row>
    </v-card>

      <template v-if="organization_teams.length > 0">
        <div class="text-left text-body-1 pt-2">Teams</div> 
        <template v-for="(team, i) in organization_teams">
          <v-card  class="rounded-lg mb-1" :key="i">
            <v-list-item
              @click="getTeamProfile(team)"
            >     
              <v-list-item-avatar tile size=50>
              <v-img class="rounded" :alt="team.name" :src="(team.image!=null)?team.image:'/images/logo.jpg'" @error="team.image='/images/logo.jpg'"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="text-left">
                <v-list-item-title v-text="team.name"  class="avidocolor--text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </template>    
      </template>

      <template v-if="organization_coaches.length > 0">
        <div class="text-left text-body-1 pt-2">Coaches</div> 
          <template v-for="(user, oindex) in organization_coaches">
            <v-card  class="rounded-lg mb-1" :key="oindex">
              <v-list-item
                @click=get_profile(user.id,user.name)
              >
              <v-list-item-avatar tile size=50>
                <v-img class="rounded" :alt="user.name" :src="(user.profile_pic!=null)?user.profile_pic:'/images/logo.jpg'" @error="user.profile_pic='/images/logo.jpg'"></v-img>
              </v-list-item-avatar>

                <v-list-item-content class="text-left">
                  <v-list-item-title v-text="user.name"  class="avidocolor--text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </template>  
       </template>  

      <div class="clear"></div>
      <v-footer color="black" fixed padless>
        <v-row justify="center" no-gutters>
            <v-btn @click="getTeamWorkout(team_id)"  class="my-2 avidocolor--text" text >
              <v-icon>mdi-dumbbell</v-icon>Workouts
            </v-btn>

            <v-btn @click="getTeamResult(team_id)"  class="my-2 avidocolor--text" text >
              <v-icon>mdi-chart-bar</v-icon>Results
            </v-btn>
 
            <v-btn to="/join-organization"  class="my-2 avidocolor--text" text >
              <v-icon>mdi-account-plus</v-icon>Join Organization
            </v-btn>
        </v-row>
      </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "OrganizationProfile",
  computed:{
    ...mapFields('organizationProfile',{      
      organization_name:'organization_name',
      total_teams:'total_teams',
      total_coaches:'total_coaches',
      organization_teams:'organization_teams',
      organization_coaches:'organization_coaches',
      fromseacrh:'fromseacrh',
    }),
    ...mapFields('teamProfile',{
      team_id:'team_id',
      team_name:'team_name',
    }),
  },
  methods: {
    ...mapActions('organizationProfile',{
      getOrganizationProfileModel:'getOrganizationProfileModel',
    }),
    get_profile(user_id,username){
      this.$router.push({ path: 'user-profile?uid='+user_id+'&uname='+username });
    },
    getTeamProfile(team) {
      this.team_id = team.id
      this.team_name=team.name
      this.$router.push({ path: 'team-profile' });
    }, 
  },
  created() {
    this.getOrganizationProfileModel();
  },
};
</script>