<template>
  <div class="contactus">
    <v-card class="pa-8">
      <v-form
        ref="contactform"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
        <v-text-field
          v-model="name"
          :rules="nameRules"
          required
          class="pa-md-4"
          prepend-icon="mdi-account"
          name="name"
          label="Name"
          color="avidocolor"
        ></v-text-field>
        <v-text-field
          v-model="email"
          :rules="emailRules"
          required
          class="pa-md-4"
          prepend-icon="mdi-email"
          name="email"
          label="Email"
          color="avidocolor"
        ></v-text-field>
        
        <v-textarea
          v-model="comments"
          required
          class="pa-md-4"
          prepend-icon="mdi-comment-account"
          name="comments"
          color="avidocolor"
          :rules="commentsRules"
        >
          <template v-slot:label>
            <div>Comments</div>
          </template>
        </v-textarea>

        <v-card-actions>
          <v-btn type="submit"  class="font-weight-bold" large block color="avidocolor avidosolidtextcolor--text">
            Contact Us
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data: () => ({
    valid: true,
    name: "",
    nameRules: [(v) => !!v || "Name is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    comments: "",
    commentsRules: [(v) => !!v || "Comments is required"],
  }),

  methods: {
    onSubmit: function () {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      if (this.$refs.contactform.validate()) {
        axios
          .post(
            `contact_us`,
            {
              name: this.name,
              email: this.email,
              comments: this.comments,
            },
            axiosConfig
          )
          .then((resp) => {
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: resp.data.message,
              alertClass: "success",
            });
            this.$refs.contactform.reset();
          })
          .catch((error) => {
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: error.response.data.errors,
              alertClass: "error",
            });
          });
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
};
</script>