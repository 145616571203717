<template>
    <v-form
      ref="searchlibraryprogram"
      v-model="valid"
      lazy-validation
      @submit.prevent="onSubmit"
      method="post"
    >

  <v-card dark>

      <v-autocomplete
        v-model="assign_library_program_id"
        name="assign_library_program_id"
        :items="user_LProgram_list"
        class="pa-md-4"
        style="padding-bottom: 0!important;"
        background-color="transparent"
        label="Search Library Program..."
        prepend-icon="mdi-magnify"
        color="avidocolor"
        item-text="name"
        item-value="id"
        filled
        return-object
      >
        <template v-slot:item="data">
          <v-list-item-avatar tile size=50>
            <v-img class="rounded"
              :src="
                data.item.image != null
                  ? data.item.image
                  : '/images/logo.jpg'
              "
              @error="data.item.image = '/images/logo.jpg'"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-html="data.item.name"
            ></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>

     <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            color="avidocolor"
            class="pa-md-4"
            v-model="assign_program_startdate"
            label="Select Program Start Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="assign_program_startdate"
          color="avidocolor black--text"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="avidocolor"
            @click="modal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="avidocolor"
            @click="$refs.dialog.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-card> 

    
      <div class="clear"></div>
      <v-footer color="black" fixed padless>
        <v-row justify="center" no-gutters>
          <v-btn type="submit" class="my-2 avidocolor--text" text>
            <v-icon>mdi-content-save</v-icon>save
          </v-btn>
        </v-row>
      </v-footer>
    </v-form>

</template>



<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "searchlibraryprogram",
  data: () => ({  
      date: new Date().toISOString().substr(0, 10),
      today: new Date().toISOString().substr(0, 10),
      modal: false,
      valid: false,
   }),
  computed: {
    ...mapFields("workoutCalendar", {
      user_LProgram_list: "user_LProgram_list",
      assign_library_program_id: "assign_library_program_id",
      assign_program_startdate: "assign_program_startdate",
      calendar_date:'calendar_date'
    }),
  },
  methods: {
    ...mapActions("workoutCalendar", {
      getUserLProgramList: "getUserLProgramList",
      addLibraryProgramToCalendar: "addLibraryProgramToCalendar",
    }),
    onSubmit: function () {
      if (this.$refs.searchlibraryprogram.validate()) {
        this.addLibraryProgramToCalendar();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {
      this.getUserLProgramList();
      this.assign_program_startdate=this.calendar_date
  },
};
</script>
