<template>
  <div class="PersonalWorkout">

    <v-form
        ref="personalWorkoutForm"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
        class="pb-12 mb-12"
      >
        <v-container>
          <v-text-field
            v-model="personal_workout_name"
            label="Workout Name"
            persistent-hint
            required
            color="avidocolor"
            :rules="[(v) => !!v || 'Workout Name is required',(v) => !!v && v.length <= 50 || 'Max 50 characters allow.']"
          ></v-text-field>
        </v-container>

        <v-footer color="black" fixed padless>
          <v-row justify="center" no-gutters>

            <v-btn type="submit" class="my-2 avidocolor--text" text>
              <v-icon>mdi-content-save</v-icon>save 
            </v-btn>

          </v-row>
        </v-footer>

      </v-form>
    
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PersonalWorkout", 
  data() {
    return {
      valid: false,
    };
  },
  computed: {
    ...mapFields("workoutCalendar", {
      personal_workout_name: "personal_workout_name",
    }),
  },
  
  methods: {
    ...mapActions("workoutCalendar", {
      savePersonalWorkout: "savePersonalWorkout",
    }),
    onSubmit: function () {
      if (this.$refs.personalWorkoutForm.validate()) {
        this.savePersonalWorkout()
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },

};
</script>
