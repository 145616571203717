import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router'
import store from "./store/index.js";
import axios from "axios";
import VCalendar from 'v-calendar';
import {refreshToken} from "./session-manager";
import VueMeta from 'vue-meta'
import infiniteScroll from "vue-infinite-scroll";
import VueAnalytics from 'vue-analytics';


Vue.config.productionTip = false
Vue.prototype.$http = axios;
const access_token = localStorage.getItem('access_token')
if (access_token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
}

axios.defaults.baseURL = "https://ianc42.sg-host.com/api";
// axios.defaults.baseURL = "http://localhost/projects2020/Laravel/AvidoBackend/public/api/";
axios.defaults.headers.common['Authorization'] =  'Bearer ' + access_token // for all requests

Vue.mixin({
  data: function () {
    return {
      app_error_mesg: 'Invalid Input.',
    }
  }
})


axios.interceptors.request.use(function (config) {
  // spinning start to show
  /* vuetify.framework.theme.themes.dark.avidocolor = (typeof(localStorage.getItem("darkTextColor")) != "undefined" && localStorage.getItem("darkTextColor") !== null) ? localStorage.getItem("darkTextColor") : "#52F60C"
  vuetify.framework.theme.themes.dark.avidosolidtextcolor = (typeof(localStorage.getItem("solidTextColor")) != "undefined" && localStorage.getItem("solidTextColor") !== null) ? localStorage.getItem("solidTextColor") : "#000000" 
  store.state.darkBackgroundColor = (typeof(localStorage.getItem("darkBackgroundColor")) != "undefined" && localStorage.getItem("darkBackgroundColor") !== null) ? localStorage.getItem("darkBackgroundColor") : null 
  store.state.lightBackgroundColor = (typeof(localStorage.getItem("lightBackgroundColor")) != "undefined" && localStorage.getItem("lightBackgroundColor") !== null) ? localStorage.getItem("lightBackgroundColor") : null
   */
  store.commit('loading', true);
  return config
}, function (error) {
  // store.commit('loading', true);
  return Promise.reject(error);
});

axios.interceptors.response.use(
  function(response) {
    store.commit('loading', false);
    // If the request succeeds, we don't have to do anything and just return the response
    return response
  },
  function(error) {
    // console.log('error')
    store.commit('loading', false);
    const errorResponse = error.response
    
    // Is token unauthorized
    if(errorResponse.status == 400){
      //logout user 
      // console.log('Token Invalid')
      // router.push({ path: '/logout' }); 
    } 

  /*   if (isTokenExpiredError(errorResponse)) {
      if(errorResponse.status == 401){
        //logout user 
        console.log('Toekn Expired')
        router.push({ path: '/logout' }); 
      } 
    } */

    if (isTokenExpiredError(errorResponse)) {
      return resetTokenAndReattemptRequest(error)
    }
    // If the error is due to other reasons, we just throw it back to axios
    return Promise.reject(error)
  }
)

function isTokenExpiredError(errorResponse) {
  // Your own logic to determine if the error is due to JWT token expired returns a boolean value
  console.log('Toekn Expired')
  return (errorResponse.status == 412 || errorResponse.status == 401) ? true : false;
}

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;
   
  //  const resetToken = await TokenUtils.getResetToken(); // Your own mechanism to get the refresh token to refresh the JWT token
    let refresh_token = localStorage.getItem('refresh_token'); // Your own mechanism to get the refresh token to refresh the JWT token
    if (!refresh_token) {
      // We can't refresh, throw the error anyway
      return Promise.reject(error);
    } 
    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from the failed
    request in the error object. */
    const retryOriginalRequest = new Promise(resolve => {
    /* We need to add the request retry to the queue
    since there another request that already attempt to
    refresh the token */
      addSubscriber(access_token => {
        errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      refreshToken()
      let newToken=localStorage.getItem("access_token");
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(newToken);
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

function onAccessTokenFetched() {
	// When the refresh is successful, we start retrying the requests one by one and empty the queue
  // subscribers.forEach(callback => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(infiniteScroll);

Vue.use(VueMeta, {
  keyName: 'head'
})

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
	id: 'UA-234335756-1',
  router
});

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify
}).$mount('#app')