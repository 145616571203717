<template>
  <div class="changepassword">
  
    <v-card class="pa-8">
      <v-form
        ref="chnagepasswordform"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
        <v-text-field
          v-model="newpassword"
          :rules="passwordRules"
          class="pa-md-4"
          prepend-icon="mdi-lock"
          name="newpassword"
          label="New Password"
          :type="show ?'text': 'password'"
          :append-icon="show ?'mdi-eye':'mdi-eye-off'" 
          @click:append="show=!show"
          color="avidocolor"
        ></v-text-field>
        <v-text-field
          v-model="confirmpassword"
          :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
          class="pa-md-4"
          prepend-icon="mdi-lock"
          name="confirmpassword"
          label="Confirm Password"
          :type="confirmshow ?'text': 'password'"
          :append-icon="confirmshow ?'mdi-eye':'mdi-eye-off'" 
          @click:append="confirmshow=!confirmshow"
          color="avidocolor"
        ></v-text-field>

        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor avidosolidtextcolor--text">
            Change Password
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data: () => ({
    valid: true,
    show:false,
    confirmshow:false,
    newpassword: "",
    confirmpassword: "",
    passwordRules: [
      (value) => !!value || "Password is required",
      (value) => (value && value.length >= 6) || "minimum 6 characters.",
    ],
    confirmPasswordRules: [
      (value) => !!value || "Confirm Password is required",
      (value) => (value && value.length >= 6) || "minimum 6 characters.",
    ],
  }),
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newpassword === this.confirmpassword ||
        "Confirm Password must match with New Password.";
    },
  },
  methods: {
    onSubmit: function () {
      if (this.$refs.chnagepasswordform.validate()) {
        axios
          .post(`change_password`, {
            new_password: this.newpassword,
          })
          .then((resp) => {
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: resp.data.message,
              alertClass: "success",
            });
            this.$refs.chnagepasswordform.reset();
          })
          .catch((error) => {
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: error.response.data.errors,
              alertClass: "error",
            });
          });
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
};
</script>