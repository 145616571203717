import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    exer_index: 0,
    super_index: 0,
    data_change: false,
    workoutExer_edit_mode: false,
    total_reps: 0,
    total_sets: 0,
    total_weight: 0,    
    completed_reps: 0,
    completed_sets: 0,
    completed_weight: 0,
    exerciseList: [],
    exerciseList_length: 0,
    super_exercise_length: 0,
    totalTime: 0,
    current_exer_resttime: 0,
    current_uw_exercise_id: 0,
    current_exercise_id: 0,
    current_exer_name: null,
    current_exer_largeimage: null,
    current_exer_videourl: null,
    current_exer_rest_time_minutes: 0,
    current_exer_rest_time_seconds: '00',
    current_exer_sets: [],
    wfcType: null,
    warmup: null,
    cooldown: null,
    finisher: null,
    is_team_wfc: 0,
    wfc_type: null,
    selectedWFC: null,
    wfc_search_array: null,
    superset_letter: null,
  },
  mutations: {
    unsetWFC(state) {
      state.wfc_search_array=null
    }, 
    setWFC(state, resp_data) {
      state.wfc_search_array=resp_data
    },
    unsetModel(state) {
      state.exer_index = 0
      state.super_index = 0
      state.total_reps = 0
      state.total_sets = 0
      state.total_weight = 0      
      state.completed_reps = 0
      state.completed_sets = 0
      state.completed_weight = 0
      state.exerciseList = []
      state.exerciseList_length = 0
      state.super_exercise_length = 0
      state.totalTime = 0
      state.current_exer_resttime = 0
      state.current_uw_exercise_id = 0
      state.current_exercise_id = 0
      state.current_exer_name = null
      state.current_exer_largeimage = null
      state.current_exer_videourl = null
      state.current_exer_rest_time_minutes=0
      state.current_exer_rest_time_seconds='00'
      state.current_exer_sets = []
      state.wfcType = null
      state.warmup = null
      state.cooldown = null
      state.finisher = null
      state.is_team_wfc = 0
      state.wfc_type = null
      state.selectedWFC = null
      state.wfc_search_array = null
      state.workoutExer_edit_mode = false
    },

    setModel(state, resp_data) {

      this.state.workoutCalendar.current_user_workout_id = resp_data.user_workout_id
      // this.state.workoutCalendar.current_workout_name = resp_data.workout_name
      this.state.workoutCalendar.current_workout_image = (resp_data.workout_image!=null) ? resp_data.workout_image : this.state.workoutCalendar.current_workout_image
      this.state.workoutCalendar.current_workout_description = resp_data.workout_description
      this.state.workoutCalendar.get_exercise = false
      
      // console.log('get_exercise'+this.state.workoutCalendar.get_exercise)

      state.total_reps = resp_data.total_reps
      state.total_sets = resp_data.total_sets
      state.total_weight = resp_data.total_weight
      state.completed_reps = resp_data.completed_reps
      state.completed_sets = resp_data.completed_sets
      state.completed_weight = resp_data.completed_weight
      state.warmup = resp_data.warmup
      state.cooldown = resp_data.cooldown
      state.finisher = resp_data.finisher
      state.exerciseList = resp_data.exercise
      state.exerciseList_length = resp_data.exercise.length  
      
      var now = new Date;
    //  var oldtarget = new Date(this.state.workoutCalendar.calendar_date);
      var target = (new Date(this.state.workoutCalendar.calendar_date)).toLocaleString('en-US', { day: '2-digit', timeZone: 'UTC' })
      
      /* console.log('now-'+now.getDate())
      console.log('oldtarget-'+oldtarget.getDate())
      console.log('target-'+target) */
      
      
      if(target > now.getDate()){
        // console.log("in")
        this.state.workoutCalendar.show_begin_workout = false
      }
        
    },
    updateField
  },
  getters: {
    getField,
  },
  actions: {
    getModel({ commit, dispatch }) {
      commit("unsetModel")

      let equipment_string=null
      if(this.state.workoutCalendar.change_equipment){
        let current_equipments=this.state.workoutCalendar.current_equipments
        if(current_equipments.length > 0){
          // generate_user_workout=1;
          equipment_string=current_equipments.join(',')
        }  
      }

      return new Promise((resolve,reject) => {

        let api_name='get_user_workout';
        let api_data=null;
        let api_method='POST';
        api_data={
          workout_type: this.state.workoutCalendar.calendar_type,
          workout_id: this.state.workoutCalendar.current_workout_id,
          user_cycle_id: this.state.workoutCalendar.current_user_cycle_id,
          equipment_array: equipment_string,
          workout_date: this.state.workoutCalendar.calendar_date,
          generate_user_workout: this.state.workoutCalendar.generate_user_workout,
          generate_for_user_id: this.state.workoutCalendar.get_for_user_id,
          generate_for_team_id: this.state.workoutCalendar.get_for_team_id,
          workout_status: this.state.workoutCalendar.current_user_workout_completed,
          deload_workout: this.state.workoutCalendar.current_deload_workout,
          ul_workout_id: (this.state.workoutCalendar.current_personal_uw_id>0) ? 0: this.state.workoutCalendar.current_user_workout_id,
          personal_uw_id: this.state.workoutCalendar.current_personal_uw_id,
        };

        axios({
          method: api_method,
          url: api_name,
          params: api_data
        }).then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setModel", response_data);
              resolve(resp)
            }
        }).catch(error => {
            commit("unsetModel")
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    getWFC({ commit, dispatch }) {
      commit("unsetWFC")
      return new Promise((resolve,reject) => {
        axios({
          method: 'GET',
          url: 'get_user_wfc',
          params: {
            wfc_type: this.state.workoutExercise.wfc_type,
          }
        }).then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setWFC", response_data);
              resolve(resp)
            }
        }).catch(error => {
            commit("unsetWFC")
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
    
  
    addExerciseToWorkout(context,search_exercise_ids) {
      if(search_exercise_ids.length>0){
        if(this.state.workoutExercise.exerciseList_length==undefined){
          this.state.workoutExercise.exerciseList=[]
        }
        for (let exerInfo of Object.values(search_exercise_ids)){
          let new_Exercise=[];
          let newExercise={
            lw_exercise_id : 0,
            user_workout_exercise_id : 'A',
            exercise_id : exerInfo.exercise_id,
            exercise_name : exerInfo.exercise_name,
            exercise_image_thumbnail : exerInfo.exercise_image_thumbnail,
            exercise_large_image : exerInfo.exercise_image_large,
            exercise_video_url : exerInfo.exercise_video_url,
            checkmark_status : false,
            rest_time : 0,
            user_one_rep_max : 0,
            exercise_set_count : 0,
            reps_type : 'REPS',
            weight_type : 'LBS',
            zero_weight_label : exerInfo.zero_weight_label,
            tempo : null,
            sets : [],
          }
          new_Exercise.push(newExercise)
          this.state.workoutExercise.exerciseList.push(new_Exercise)
        }
        this.state.workoutExercise.data_change=true
        this.state.workoutCalendar.get_exercise = false
        this.state.workoutExercise.exerciseList_length=this.state.workoutExercise.exerciseList.length
        // dispatch("workoutExerciseSet/saveUserWorkout", {savedata: 0}, { root: true });
        router.go(-1);
      }

      
    }, 


   /*  superExerciseToWorkout(context,search_exercise_ids) {
      if(search_exercise_ids.length>0){
        for (let exerInfo of Object.values(search_exercise_ids)){
          let newExercise={
            lw_exercise_id : 0,
            user_workout_exercise_id : 'A',
            exercise_id : exerInfo.exercise_id,
            exercise_name : exerInfo.exercise_name,
            exercise_image_thumbnail : exerInfo.exercise_image_thumbnail,
            exercise_large_image : exerInfo.exercise_image_large,
            exercise_video_url : exerInfo.exercise_video_url,
            checkmark_status : false,
            rest_time : 0,
            exercise_set_count : 0,
            reps_type : 'REPS',
            weight_type : 'LBS',
            zero_weight_label : exerInfo.zero_weight_label,
            sets : [],
          }
          this.state.workoutExercise.exerciseList[this.state.workoutExercise.exer_index][this.state.workoutExercise.super_index+1]=newExercise
          this.state.workoutExercise.data_change=true
        }
        router.go(-2);
      }
    },  */

    swapExerciseToWorkout({dispatch},search_exercise_ids) {
      if(search_exercise_ids!=null){
        // for (let exerInfo of Object.values(search_exercise_ids)){
          let exerInfo = search_exercise_ids

          let oldExerInfo=this.state.workoutExercise.exerciseList[this.state.workoutExercise.exer_index][this.state.workoutExercise.super_index]
          let current_exercise_sets = oldExerInfo.sets
          let current_weight_type = oldExerInfo.weight_type
          for (let setinfo of Object.values(current_exercise_sets)){
            if(exerInfo.zero_weight_label!='max' && setinfo.percentage>0){
              current_weight_type='RPE'
              setinfo.weight=Math.round(setinfo.percentage / 10) 
            }      
          }

          let newExercise={
            lw_exercise_id : oldExerInfo.lw_exercise_id,
            user_workout_exercise_id : oldExerInfo.user_workout_exercise_id,
            exercise_id : exerInfo.exercise_id,
            exercise_name : exerInfo.exercise_name,
            exercise_image_thumbnail : exerInfo.exercise_image_thumbnail,
            exercise_large_image : exerInfo.exercise_image_large,
            exercise_video_url : exerInfo.exercise_video_url,
            checkmark_status : false,
            rest_time : oldExerInfo.rest_time,
            exercise_set_count : oldExerInfo.exercise_set_count,
            reps_type : oldExerInfo.reps_type,
            weight_type : current_weight_type,
            zero_weight_label : exerInfo.zero_weight_label,
            tempo : null,
            sets : current_exercise_sets,
          }
          this.state.workoutExercise.exerciseList[this.state.workoutExercise.exer_index][this.state.workoutExercise.super_index]=newExercise
          this.state.workoutExercise.data_change=true
          this.state.workoutExercise.current_exer_name=exerInfo.exercise_name
          this.state.workoutExercise.current_exercise_id=exerInfo.exercise_id
          this.state.workoutExercise.current_exer_largeimage=exerInfo.exercise_image_large
          this.state.workoutExercise.current_exer_videourl=exerInfo.exercise_video_url
        // }
        dispatch("workoutExerciseSet/saveUserWorkout", {savedata: 0}, { root: true });
        router.go(-1);
      }
    }, 


     /* DO NOT DELETE THIS FUNCTION */
    swapWFCToWorkout({ dispatch },search_exercise_ids) {

      let newWFC=null
      if(search_exercise_ids!=null){
        
        let wfcInfo=search_exercise_ids
        let wfcid=wfcInfo.id

        let api_name='get_wfc_details'
        let api_data={
          wfc_id: wfcid
        };

        return new Promise((resolve,reject) => {
          axios({
            method: 'GET',
            url: api_name,
            params: api_data
          })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const responseData = resp.data.response

              newWFC={
                id : responseData.id,
                name : responseData.name,
                image : responseData.image,
                description : responseData.description,
                no_of_rounds : responseData.no_of_rounds,
                rest_time : responseData.rest_time,
                exercises : responseData.exercises,
              }

              if(this.state.bottomSearch.wfc_type=='WARMUP'){
                this.state.workoutExercise.warmup=newWFC
              }
              if(this.state.bottomSearch.wfc_type=='COOLDOWN'){
                this.state.workoutExercise.cooldown=newWFC
              }
              if(this.state.bottomSearch.wfc_type=='FINISHER'){
                this.state.workoutExercise.finisher=newWFC
              } 
              
              this.state.workoutExercise.data_change=true
              /* if(this.state.workoutExercise.is_team_wfc){
                router.push({ path: '/teamworkoutexercise' });
              }else{
                router.go(-1) ;
              }  */
              router.go(-1) ;

              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
        })
      }
    }

  },
};