import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    selected_search_ids: [],
    avidoExerciseList: [],
    sliceavidoExerciseList: [],
    swapRecomExerciseList: [],
    favoriteExerciseList: [],
    result_type: 'SWAPEXERCISE',
    wfc_type: null,
    selected_id: 0,
    search_tab: 'FAVEXERCISE',
    search_keyword: null,
  //  search_items: null,
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    unsetavidoExercise(state) {
      state.avidoExerciseList=[]
      state.selected_search_ids=[]
      state.swapRecomExerciseList=[]
      state.favoriteExerciseList=[]
      state.result_type='SWAPEXERCISE'
      state.wfc_type=null
    },  
    unsetselectedExercise(state) {
      state.selected_search_ids=[]
    },     
    unsetSearchInput(state) {
      state.search_keyword=null
    //  state.search_items=null
    }, 
    setavidoExercise(state,{response_one_data,resulttype}) {
      state.avidoExerciseList=response_one_data
      state.result_type=resulttype
    }, 
    unsetswapRecomExercise(state) {
      state.swapRecomExerciseList=[]
    },
    setswapRecomExercise(state,response_data) {
      state.swapRecomExerciseList=response_data
    },
    unsetFavExercise(state) {
      state.favoriteExerciseList=[]
    },
    setFavExercise(state,response_data) {
      state.favoriteExerciseList=response_data
    },

    setonlyavidoExercise(state,response_one_data) {
      const chunkSize=15
      const res = [];
      for (let i = 0; i < response_one_data.length; i += chunkSize) {
          const chunk = response_one_data.slice(i, i + chunkSize);
          res.push(chunk);
      }
      state.avidoExerciseList=response_one_data
      state.sliceavidoExerciseList=res
    }, 

  },

  actions: {
    /* getMixSwapAvidoExercise({ commit },resulttype) {
        commit("unsetavidoExercise");
        return new Promise((resolve) => {
          let request_apis=[]
          if(this.state.bottomSearch.avidoExerciseList.length==0){
            let one = "get_search_exercise_list"
            const requestOne = axios.get(one);
            request_apis.push(requestOne);
          }

          if(resulttype=='SWAPEXERCISE'){
            let two = "get_swap_exercise_list?uw_exercise_id="+this.state.workoutExercise.current_uw_exercise_id+"&exercise_id="+this.state.workoutExercise.current_exercise_id
            const requestTwo = axios.get(two);  
            request_apis.push(requestTwo);
          }

          if(request_apis.length==2){
            axios.all(request_apis).then(axios.spread((...responses) => {
              
              if(this.state.bottomSearch.avidoExerciseList.length==0){
                const responseOne = responses[0]
                const resp_one_code = responseOne.status
                if(resp_one_code == 200) {
                  const response_one_data = responseOne.data.response
                  commit("setavidoExercise",{response_one_data,resulttype})
                } 
              }
              
              if(resulttype=='SWAPEXERCISE'){
                const responseTwo = responses[1]
                const resp_two_code = responseTwo.status
                if(resp_two_code == 200){
                  const response_two_data = responseTwo.data.response
                  if(response_two_data.length>0){
                    commit("setswapRecomExercise",response_two_data)
                  }
                }
              }
            }))
            this.state.bottomSearch.result_type=resulttype
            router.push({ path: '/search-exercise' });
          }else{
            axios.all(request_apis).then(axios.spread((...responses) => {
              
              if(this.state.bottomSearch.avidoExerciseList.length==0){
                const responseOne = responses[0]
                const resp_one_code = responseOne.status
                if(resp_one_code == 200) {
                  const response_one_data = responseOne.data.response
                  commit("setavidoExercise",{response_one_data,resulttype})
                  this.state.bottomSearch.result_type=resulttype
                  router.push({ path: '/search-exercise' });
                } 
              }

              if(resulttype=='SWAPEXERCISE'){
                const responseTwo = responses[0]
                const resp_two_code = responseTwo.status
                if(resp_two_code == 200){
                  const response_two_data = responseTwo.data.response
                  commit("setswapRecomExercise",response_two_data)
                  router.push({ path: '/search-exercise' });
                }
              }
              resolve(responses)
            }))
          }
         
        })
    }, */

    getFavoriteExercise({ commit,dispatch },resulttype) {
      commit("unsetSearchInput");
      commit("unsetselectedExercise");
      this.state.bottomSearch.result_type=resulttype
      if(this.state.bottomSearch.favoriteExerciseList.length==0){
        commit("unsetFavExercise");
        return new Promise((resolve,reject) => {
          axios({
            method: 'GET',
            url: 'get_user_favorite_exercise',
          })
            .then(resp => {
              const resp_code = resp.status
              if (resp_code == 200) {
                const response_data = resp.data.response
                commit("setFavExercise",response_data);
                if(response_data.length>0){
                  this.state.bottomSearch.search_tab = 'FAVEXERCISE'
                }else{
                  this.state.bottomSearch.search_tab = 'ALLEXERCISE'
                }
                router.push({ path: '/search-exercise' });
                resolve(resp)
              }
            })
            .catch(error => {
              const resp_code = error.response.status
              if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
                const error_mesg = error.response.data.errors
                dispatch("setNotification", {
                  display: true,
                  text: error_mesg,
                  alertClass: "error"
                }, { root: true });
                reject(error)
              }
            })
        })
      }else{
        this.state.bottomSearch.search_tab = 'FAVEXERCISE'
        if(resulttype=='EXERICSE' || resulttype=='LEXERICSE' || resulttype=='LWFCEXERICSE' || resulttype=='SWAPEXERCISE' || resulttype=='NEXERICSE'){
         router.push({ path: '/search-exercise' });
        }        
      }
     
    }, 
   
    getSwapExercise({ commit },resulttype) {
      commit("unsetselectedExercise");
      commit("unsetswapRecomExercise");
      return new Promise((resolve) => {
        axios({
          method: 'GET',
          url: 'get_swap_exercise_list',
          params: {
            uw_exercise_id: this.state.workoutExercise.current_uw_exercise_id,
            exercise_id: this.state.workoutExercise.current_exercise_id
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              // console.log(response_data)
              commit("setswapRecomExercise",response_data);
              this.state.bottomSearch.result_type=resulttype
              // router.push({ path: '/search-exercise' });
              resolve(resp)
            }
          })
          /* .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          }) */
      })
    }, 
   
    getAvidoExercise({ commit,dispatch },resulttype) {
      commit("unsetSearchInput");
      commit("unsetselectedExercise");
      if(this.state.bottomSearch.avidoExerciseList.length==0){
        commit("unsetFavExercise");
        return new Promise((resolve,reject) => {
          axios({
            method: 'GET',
            url: 'get_search_exercise_list'
          })
            .then(resp => {
              const resp_code = resp.status
              if (resp_code == 200) {
                const response_data = resp.data.response
                commit("setonlyavidoExercise",response_data);
                commit('getDataList/setExerciseState', response_data, { root: true })
                this.state.getDataList.avido_exercise_list=response_data
                resolve(resp)
              }
            })
            .catch(error => {
              const resp_code = error.response.status
              if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
                const error_mesg = error.response.data.errors
                dispatch("setNotification", {
                  display: true,
                  text: error_mesg,
                  alertClass: "error"
                }, { root: true });
                reject(error)
              }
            })
        })
      }else{
        // must be route from here
        this.state.bottomSearch.result_type=resulttype
        router.push({ path: '/search-exercise' });
      }
    }, 

    addFavoriteExercise({dispatch}) {
      return new Promise((resolve,reject) => {
        axios({
          method: 'POST',
          url: 'add_to_favorite_exercise',
          data: {
            fav_exercise_ids: this.state.user.favoriteExerciseArray.join(','),
          }
        })
        .then(resp => {
          const resp_code = resp.status
          if (resp_code == 200) {
            const response_data = resp.data.response
            this.state.user.favoriteExerciseArray=response_data.favoriteExerciseArray
            this.state.user.favoriteExerciseList=response_data.favoriteExerciseList
            resolve(resp)
          }
        })
        .catch(error => {
          const resp_code = error.response.status
          if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })
      })
    },

   
  }
};