<template>
  <div class="search-exercise">
    <template
      v-if="(result_type == 'EXERICSE' || result_type == 'SWAPEXERCISE' || result_type == 'WFCEXERICSE' || result_type == 'LEXERICSE' || result_type == 'LSWAPEXERCISE' || result_type == 'LWFCEXERICSE' || result_type == 'NEXERICSE')">
      <v-tabs  v-model="search_tab" background-color="black avidocolor--text" grow>
        <v-tab href="#RECOMEXERCISE" v-if="result_type == 'SWAPEXERCISE' && swapRecomExerciseList.length>0">
          Recommended
        </v-tab>
        <v-tab href="#FAVEXERCISE" >
          FAVORITES
        </v-tab>
        <v-tab href="#ALLEXERCISE">
          All Exercises
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="search_tab">
        <v-tab-item value="FAVEXERCISE" v-if="search_tab=='FAVEXERCISE'">
          <v-text-field v-model="search_keyword" placeholder="Search..." required clearable @click:clear="clearSearch" name="searchInput"
            color="avidocolor" prepend-icon="mdi-magnify" class="px-2" />
          <v-list>
            <v-list-item-group :key="itemGroup_filter" v-model="selected_search_ids"
              :multiple="(result_type == 'SWAPEXERCISE' || result_type == 'LSWAPEXERCISE' || result_type == 'NEXERICSE') ? false : true" color="avidocolor">
              <template v-for="(model, mindex) in SearchfavoriteExerciseList">

                <v-list-item :key="(model.exercise_id + '_' + mindex)" :value="model" :ripple="false"
                  active-class="avidocolor--text text--accent-4">

                  <v-list-item-avatar tile size=50>
                    <v-img class="rounded" :alt="model.exercise_name"
                      :src="(model.exercise_image_thumbnail != null) ? model.exercise_image_thumbnail : '/images/logo.jpg'"
                      @error="model.exercise_image_thumbnail = '/images/logo.jpg'"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="text-left">
                    <v-list-item-title class="align-center" v-text="model.exercise_name"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon color="avidocolor" dark @click="exerciseInfo(model.exercise_id, model.exercise_name)">
                      mdi-information
                    </v-icon>
                    <!-- <v-icon>
                      mdi-check
                    </v-icon> -->
                  </v-list-item-icon>

                </v-list-item>
                <v-divider v-if="mindex < SearchfavoriteExerciseList.length - 1" :key="mindex"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-tab-item>
        <v-tab-item value="RECOMEXERCISE"  v-if="search_tab=='RECOMEXERCISE'  && swapRecomExerciseList.length>0">
          <v-text-field v-model="search_keyword" placeholder="Search..." required clearable  @click:clear="clearSearch"  name="searchInput"
            color="avidocolor" prepend-icon="mdi-magnify" class="px-2" />
          <v-list>
            <v-list-item-group :key="itemGroup_filter" v-model="selected_search_ids"
              :multiple="(result_type == 'SWAPEXERCISE' || result_type == 'LSWAPEXERCISE') ? false : true" color="avidocolor">
              <template v-for="(model, mindex) in filteredList">

                <v-list-item :key="(model.exercise_id + '_' + mindex)" :value="model" :ripple="false"
                  active-class="avidocolor--text text--accent-4">

                  <v-list-item-avatar tile size=50>
                    <v-img class="rounded" :alt="model.exercise_name"
                      :src="(model.exercise_image_thumbnail != null) ? model.exercise_image_thumbnail : '/images/logo.jpg'"
                      @error="model.exercise_image_thumbnail = '/images/logo.jpg'"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="text-left">
                    <v-list-item-title class="align-center" v-text="model.exercise_name"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon color="avidocolor" dark @click="exerciseInfo(model.exercise_id, model.exercise_name)">
                      mdi-information
                    </v-icon>
                    <!-- <v-icon>
                      mdi-check
                    </v-icon> -->
                  </v-list-item-icon>

                </v-list-item>
                <v-divider v-if="mindex < filteredList.length - 1" :key="mindex"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-tab-item>
        <v-tab-item value="ALLEXERCISE"  v-if="search_tab=='ALLEXERCISE'">
          <v-text-field v-model="search_keyword" placeholder="Search..." required clearable  @click:clear="clearSearch"  name="searchInput"
            color="avidocolor" prepend-icon="mdi-magnify" class="px-2" />
          <v-card v-scroll.self="getNextList" class="overflow-y-auto" max-height="100vh">
            <v-list v-if="scrollList.length > 0">
              <v-list-item-group :key="itemGroup_scroll" v-model="selected_search_ids"
                :multiple="(result_type == 'SWAPEXERCISE' || result_type == 'LSWAPEXERCISE' || result_type == 'NEXERICSE') ? false : true"
                color="avidocolor">
                <template v-for="(model, exindex) in scrollList">

                  <v-list-item :key="(model.exercise_id + '_' + exindex)"  :value="model" :ripple="false"
                    active-class="avidocolor--text text--accent-4">

                    <v-list-item-avatar tile size=50>
                      <v-img class="rounded" :alt="model.exercise_name"
                        :src="(model.exercise_image_thumbnail != null) ? model.exercise_image_thumbnail : '/images/logo.jpg'"
                        @error="model.exercise_image_thumbnail = '/images/logo.jpg'"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content class="pl-4 text-left">
                      <v-list-item-title class="align-center" v-text="model.exercise_name"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon color="avidocolor" dark @click="exerciseInfo(model.exercise_id, model.exercise_name)">
                        mdi-information
                      </v-icon>
                      <!-- <v-icon>
                      mdi-check
                    </v-icon> -->
                    </v-list-item-icon>
                    <!-- <v-list-item-icon>
                    <v-icon>
                      mdi-check
                    </v-icon>
                  </v-list-item-icon> -->
                  </v-list-item>
                  <v-divider v-if="exindex < scrollList.length - 1" :key="exindex"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-else>
      <v-text-field v-model="search_keyword" placeholder="Search..." required clearable  @click:clear="clearSearch"  name="searchInput"
        color="avidocolor" prepend-icon="mdi-magnify" class="px-2" />

      <v-list v-if="filteredList.length > 0">
        <v-list-item-group :key="itemGroup_filter" v-model="selected_search_ids"
          :multiple="(result_type == 'ADDWARMUP' || result_type == 'ADDFINISHER' || result_type == 'ADDCOOLDOWN' ||result_type == 'LADDWARMUP' || result_type == 'LADDFINISHER' || result_type == 'LADDCOOLDOWN' || result_type == 'SWAPEXERCISE' || result_type == 'LSWAPEXERCISE') ? false : true"
          color="avidocolor">
          <template v-for="(model, mindex) in filteredList">
            <v-list-item :key="(model.id + '_' + mindex)" :value="model" :ripple="false"
              active-class="avidocolor--text text--accent-4">

              <v-list-item-avatar tile size=50>
                <v-img class="rounded" :alt="model.name" :src="(model.image != null) ? model.image : '/images/logo.jpg'"
                  @error="model.image = '/images/logo.jpg'"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="text-left">
                <v-list-item-title class="align-center" v-text="model.name"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon color="avidocolor" dark @click="wfcSearchView({'wfcid' : model.id, 'wfc_type' : result_type})">
                  mdi-information
                </v-icon>
                <!-- <v-icon>
                  mdi-check
                </v-icon> -->
              </v-list-item-icon>
              <!--               <v-list-item-icon>
                <v-icon>
                  mdi-check
                </v-icon>
              </v-list-item-icon> -->
            </v-list-item>
            <v-divider v-if="mindex < filteredList.length - 1" :key="mindex"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </template>


    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>

        <v-btn @click="$router.go(-1)" class="avidocolor--text" text>
          <v-icon>mdi-chevron-left</v-icon>Back
        </v-btn>

        <v-btn text @click="addExerciseToWorkout(selected_search_ids)" v-if="result_type == 'EXERICSE'"
          color="avidocolor  avidosolidtextcolor--text">
          <v-icon>mdi-content-save</v-icon>save
        </v-btn>

        <!--  <v-btn text @click="superExerciseToWorkout(selected_search_ids)" v-if="result_type == 'SUPEREXERCISE'"
          color="avidocolor avidosolidtextcolor--text">
          <v-icon>mdi-content-save</v-icon>save
        </v-btn> -->

        <v-btn text @click="swapExerciseToWorkout(selected_search_ids)" v-if="result_type == 'SWAPEXERCISE'"
          color="avidocolor avidosolidtextcolor--text">
          <v-icon>mdi-content-save</v-icon>save
        </v-btn>

        <v-btn text @click="swapWFCToWorkout(selected_search_ids)" v-if="(result_type=='ADDWARMUP' || result_type=='ADDFINISHER' || result_type=='ADDCOOLDOWN')" color="avidocolor">
          <v-icon>mdi-content-save</v-icon>save 
        </v-btn>  

        <v-btn text @click="addExerciseToLworkout(selected_search_ids)" v-if="result_type == 'LEXERICSE'"
          color="avidocolor avidosolidtextcolor--text">
          <v-icon>mdi-content-save</v-icon>save
        </v-btn>

        <!-- <v-btn text @click="superExerciseToLWorkout(selected_search_ids)" v-if="result_type == 'LSUPEREXERCISE'"
          color="avidocolor avidosolidtextcolor--text">
          <v-icon>mdi-content-save</v-icon>save
        </v-btn>
 -->
        <v-btn text @click="swapExerciseToLWorkout(selected_search_ids)" v-if="result_type == 'LSWAPEXERCISE'"
          color="avidocolor avidosolidtextcolor--text">
          <v-icon>mdi-content-save</v-icon>save
        </v-btn>

        <v-btn text @click="swapWFCToLWorkout(selected_search_ids)"
          v-if="(result_type == 'LADDWARMUP' || result_type == 'LADDFINISHER' || result_type == 'LADDCOOLDOWN')"
          color="avidocolor avidosolidtextcolor--text">
          <v-icon>mdi-content-save</v-icon>save
        </v-btn>

        <v-btn text @click="addWorkoutToLCycle(selected_search_ids)" v-if="result_type == 'LADDWORKOUTS'"
          color="avidocolor avidosolidtextcolor--text">
          <v-icon>mdi-content-save</v-icon>save
        </v-btn>

        <v-btn text @click="addCycleToLProgram(selected_search_ids)" v-if="result_type == 'LADDCYCLES'"
          color="avidocolor avidosolidtextcolor--text">
          <v-icon>mdi-content-save</v-icon>save
        </v-btn>

        <v-btn text @click="addExerciseToLwfc(selected_search_ids)" v-if="result_type == 'LWFCEXERICSE'"
          color="avidocolor avidosolidtextcolor--text">
          <v-icon>mdi-content-save</v-icon>save
        </v-btn>

        <v-btn text @click="addExerciseToNotes(selected_search_ids)" v-if="result_type == 'NEXERICSE'"
          color="avidocolor avidosolidtextcolor--text">
          <v-icon>mdi-content-save</v-icon>save
        </v-btn>

      </v-row>
    </v-footer>
  </div>
</template>


<script>
// import axios from "axios";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "SearchExercise",
  data: () => ({
    scrollList: [],
    page_number: 0,
    itemGroup_filter: 0,
    itemGroup_scroll: 0,
    items: [],
  }),
  computed: {
    ...mapFields('workoutExercise', [
      'data_change',
    ]),
    ...mapFields('bottomSearch', {
      search_tab: "search_tab",
      search_keyword: 'search_keyword',
    //  search_items: 'search_items',
      avidoExerciseList: 'avidoExerciseList',  // make name will be items for search functionality
      swapRecomExerciseList: 'swapRecomExerciseList',
      favoriteExerciseList: 'favoriteExerciseList',
      sliceavidoExerciseList: 'sliceavidoExerciseList',
      selected_search_ids: 'selected_search_ids',
      result_type: 'result_type',
      selected_id: 'selected_id',
    }),
    ...mapFields("library", {
      library_workout_list: "library_workout_list",
      library_cycle_list: "library_cycle_list",
      library_program_list: "library_program_list",
      library_warmup_list: "library_warmup_list",
      library_finisher_list: "library_finisher_list",
      library_cooldown_list: "library_cooldown_list",
    }),
    ...mapFields('exerciseInstruction', {
      exercise_name: 'exercise_name',
      exercise_instruction_id: 'exercise_instruction_id',
    }),
    ...mapFields('workoutExercise', [
      'warmup',
      'cooldown',
      'finisher',
    ]),
    filteredList() { // for all items except avidoexercise
      // console.log('filteredList')
      if (this.search_keyword != null) {
        if (this.result_type == 'SWAPEXERCISE') {
          return this.items.filter(item => {
            return item.exercise_name.toUpperCase().includes(this.search_keyword.toUpperCase())
          })
        } else {
          return this.items.filter(item => {
            return item.name.toUpperCase().includes(this.search_keyword.toUpperCase())
          })
        }
      } else {
        return this.items
      }
    },
    SearchfavoriteExerciseList() { // for all items except avidoexercise

      // console.log('SearchfavoriteExerciseList')
      if (this.search_keyword != null) {
        return this.favoriteExerciseList.filter(item => {
          return item.exercise_name.toUpperCase().includes(this.search_keyword.toUpperCase())
        })
      } else {
        return this.favoriteExerciseList
      }

      /* SearchfavoriteExerciseList
      filteredList
      scrollList */

    },
  },
  watch: {
    search_tab(searchtab) {

      /* if(searchtab=='ALLEXERCISE' && this.search_items != null){
        this.search_keyword = this.search_items
      }else if(this.search_keyword != null){
        this.search_items = this.search_keyword
      } */

      if (searchtab == 'SWAPEXERCISE' || searchtab == 'RECOMEXERCISE') {
        this.items = this.swapRecomExerciseList
      }

    },   
    /* search_items() {
      this.itemGroup_filter += 1
    }, */
    search_keyword(searchkeyword){
      this.itemGroup_scroll += 1
      if (searchkeyword != null){
        if (searchkeyword.length == 0) {
          this.scrollList = this.sliceavidoExerciseList[this.page_number]
        }
        if (searchkeyword.length > 1) {
          this.page_number = 0
          return this.scrollList = this.avidoExerciseList.filter(exer => {
            return exer.exercise_name.toUpperCase().includes(searchkeyword.toUpperCase())
          })
        }
      } else if (searchkeyword == null) {
        this.scrollList = this.sliceavidoExerciseList[this.page_number]
      }

    },
  },
  methods: {
    ...mapActions("bottomSearch", {
      addFavoriteExercise: "addFavoriteExercise",
    }),
    ...mapActions("workoutExercise", {
      addExerciseToWorkout: "addExerciseToWorkout",
      swapExerciseToWorkout: "swapExerciseToWorkout",
      swapWFCToWorkout: "swapWFCToWorkout",
    }),
    ...mapActions("libraryExercise", {
      addExerciseToLworkout: "addExerciseToLworkout",
      swapExerciseToLWorkout: "swapExerciseToLWorkout",
      swapWFCToLWorkout: "swapWFCToLWorkout",
      addWorkoutToLCycle: "addWorkoutToLCycle",
      addCycleToLProgram: "addCycleToLProgram",
      addExerciseToLwfc: "addExerciseToLwfc",
      wfcSearchView: "wfcSearchView",
    }),
    ...mapActions("exerciseNotes", {
      addExerciseToNotes: "addExerciseToNotes",
    }),
    
    getInitialList() {
      /*       axios.get(`https://randomuser.me/api/?results=10`).then((response) => {
              this.users = response.data.results;
            }); */
      this.scrollList = this.sliceavidoExerciseList[this.page_number]
    },
    getNextList() {
      /* window.onscroll = () => {

      } */
      /* let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
      if (bottomOfWindow) { */
      
      if(this.search_keyword==null){
        this.page_number = this.page_number + 1
        this.scrollList = this.scrollList.concat(this.sliceavidoExerciseList[this.page_number]);
      }
      /* axios.get(`https://randomuser.me/api/?results=5`).then(response => {
        this.users = this.users.concat(response.data.results);
      }); */
      // }
    },
    exerciseInfo(current_exercise_id, current_exer_name) {

      this.exercise_instruction_id = current_exercise_id
      this.exercise_name = current_exer_name
      this.$router.push({ path: 'exercise-instruction' });
    },
    clearSearch(){
      this.search_keyword = null
    }
  },
  beforeMount() {
    if(this.search_keyword!=null){
      let searchkeyword=this.search_keyword
      if (searchkeyword.length > 1) {
        this.scrollList = this.avidoExerciseList.filter(exer => {
          return exer.exercise_name.toUpperCase().includes(searchkeyword.toUpperCase())
        })
      }
    }else{
      this.getInitialList();
    }
    
  },
  mounted() {
    // this.getNextList();
  },
  created() {

    let result_type = this.result_type

    if (result_type == 'SWAPEXERCISE') {
      this.items = this.swapRecomExerciseList
      /* if(this.swapRecomExerciseList.length>0) {
        this.search_tab='RECOMEXERCISE'
      } */
    } else if (result_type == 'ADDWARMUP' || result_type == 'LADDWARMUP') {
      this.items = this.library_warmup_list
    } else if (result_type == 'ADDFINISHER' || result_type == 'LADDFINISHER') {
      this.items = this.library_finisher_list
    } else if (result_type == 'ADDCOOLDOWN' || result_type == 'LADDCOOLDOWN') {
      this.items = this.library_cooldown_list
    } else if (result_type == 'ADDWORKOUTS' || result_type == 'LADDWORKOUTS') {
      this.items = this.library_workout_list
    } else if (result_type == 'ADDCYCLES' || result_type == 'LADDCYCLES') {
      this.items = this.library_cycle_list
    } else if (result_type == 'ADDPROGRAM') {
      this.items = this.library_program_list
    }
    // this.selected_search_ids = []
    // window.addEventListener("scroll", this.getNextList);

    /* if (this.search_tab == 'ALLEXERCISE') {
      this.search_items = null;
    }else{
      this.search_keyword = null;
    } */
    // this.search_items = null;
    // this.search_keyword = null;
  },
};


</script>
