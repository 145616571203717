<template>
  <div class="Athlete">
    <v-divider class="defaultcolor"></v-divider>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-card>
          <v-img
            src="/images/athletes_title_section.jpg"
            class="defaultcolor--text align-center"
          >
            <v-card-title class="d-inline headingclass">
              <p class="text-h4 text-md-h2">
                THE TRAINING ATHLETES DESERVE
              </p>
            </v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

      <v-row justify="center">
        <v-col cols="md-6" class="pa-0">
          <v-card flat color="#121212" class="mx-auto">
            <v-img
              contain    
              src="/images/athlete_1st_subsection.png"
               class="mx-auto"
            ></v-img>
          </v-card>
        </v-col>

        <v-col cols="md-6">
          <v-card-title class="pt-16 pb-0 px-0 text-h5 text-md-h4">
            <div class="pt-2">AVIDO Delivers Intelligent Science Based Strength & Conditioning To Your Phone</div>
          </v-card-title>

          <v-divider width="90%" class="defaultcolor mx-auto"></v-divider>

          <v-card-subtitle class="text-body-1">
            <div class="pa-8">
            Take the guesswork out of the gym and focus on what matters with a
            sport specific program built to meet the demands of your sport
            and customized to the equipment and time you have available. 
            </div>
          </v-card-subtitle>
        </v-col>
      </v-row>
    
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-card>
            <v-img
              contain
              :src="$vuetify.breakpoint.xs ? '/images/bg_athletes_2th_subsection_mb.png'  : '/images/bg_athletes_2th_subsection.png'"
              class="align-center"
            >
                <v-card-title :class="$vuetify.breakpoint.xs ? 'pb-0 text-md-h4 d-block'  : 'pb-0 text-h5 text-md-h4 d-block'">
                  <div :class="$vuetify.breakpoint.xs ? null  : 'py-2'" >The Training You Need Today To Be A Champion Tomorrow</div>
                </v-card-title>
                
                <v-divider width = "90%" class="defaultcolor mx-auto"></v-divider>

                <v-card-subtitle class="white--text  text-body-1">
                  <div :class="$vuetify.breakpoint.xs ? null  : 'pa-3'" >
                  AVIDO programs are built based on when your season starts. This
                  maximizes the effectiveness of Off-Season and Pre-Season training
                  to ensure you are in peak condition when it matters the most.
                  </div>

                  <div :class="$vuetify.breakpoint.xs ? null  : 'pa-3'" >
                  AVIDO's In-Season training provides the right amount of work to stay
                  strong but still recover so you countinue to perform at your best.
                  </div>
                </v-card-subtitle>
            </v-img>
          </v-card>
        </v-col>
      </v-row>


   
      <v-row>
        <v-col cols="md-6" class="pa-0">
          <v-card flat color="#121212" class="mx-auto">
            <v-img
              contain    
              src="/images/athlete_3rd_subsection.png"
              class="mx-auto"
            ></v-img>
          </v-card>
        </v-col>

        <v-col cols="md-6">
          <div>
              <v-card-title class="pt-16 pb-0 px-0 text-h5 text-md-h4">
                <div class="py-2">The Knowledge To Train With Confidence & The Motivation To Stay On Track</div>
              </v-card-title>

              <v-divider width="90%" class="defaultcolor mx-auto"></v-divider>

              <v-card-subtitle class="text-body-1">
                <div class="pa-8">
                1000+ exercises with simple instructions, coaching cues and detailed videos provide the variety to keep your training fresh.   
                </div>

                <div class="pa-6">
                Weekly training summaries showing total sets, reps and weight, along with progress charts for each exercise keep you focused. 
                </div>
              </v-card-subtitle>
          </div>
        </v-col>
      </v-row>
   

      <v-row>
        <v-col cols="12" class="pa-0">
          <v-card>
            <v-img
              contain
              src="/images/bg_athletes_4th_subsection.png"
              class="align-center"
            >
                <v-card-title class="pb-0 text-h5 text-md-h4 d-block">
                  <div :class="$vuetify.breakpoint.xs ? null  : 'py-2'" >Join Teams & Get Training From Your Coaches</div>
                </v-card-title>

                <v-divider width="90%" class="defaultcolor mx-auto"></v-divider>

                <v-card-subtitle class="white--text text-body-1">
                  <div :class="$vuetify.breakpoint.xs ? null  : 'pa-3'" >
                  Join teams through AVIDO and train on the exact
                  programs your coaches set up.
                  </div>

                  <div :class="$vuetify.breakpoint.xs ? null  : 'pa-3'" >
                  Complete the same workouts as your teammates 
                  to see how your progress compares.
                  </div>
                </v-card-subtitle>
            </v-img>
          </v-card>
        </v-col>
      </v-row>


      <!-- <v-parallax
          src="/images/bg_athletes_4th_subsection.png"
        >
        <v-row align="center">
              <v-col cols="md-6">
              <div>
                    <v-card-title class="text-h5 text-md-h4 d-inline">
                      <div class="py-2">Join Teams And Get</div>
                      <div class="py-2">Coaching From Trainers</div>
                    </v-card-title>

                    <v-divider class="defaultcolor"></v-divider>

                    <v-card-subtitle class="text-body-1">
                      <div class="pa-3">
                      Join teams through AVIDO and train on the exact
                      programs your coaches set up.
                      </div>

                      <div class="pa-3">
                      Complete the same workouts as your teammates 
                      to see how your progress compares.
                      </div>
                    </v-card-subtitle>
                </div>
              </v-col>
            </v-row>
        </v-parallax> -->

    <FooterComponent />
    
  </div>
</template>



<script>
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: "Athlete",
  components: {
    FooterComponent
  },
  data: () => ({    
  //  imgHeight: 500,
  }),
};
</script>