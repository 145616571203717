import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    client_first_name: null,
    client_last_name: null,
    email: null,
    organization_id: 0,
  },

  getters: {
    getField,
  },

  mutations: {
    unsetclient(state) {
      state.client_first_name=null
      state.client_last_name=null
      state.email=null
      state.organization_id=0
    },    
    updateField,
  },

  actions: {
    createClientModel({ dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          url: 'register_client',
          method: 'POST',
          data: this.state.client
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              this.state.myTeamTrainers.get_api_call=true
              const resp_message = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });              
              router.go(-1) 
              resolve(resp)
            } else {
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass: "error"
              }, { root: true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    leaveClientModel({ dispatch },model) {
      return new Promise((resolve, reject) => {
        axios({
          url: 'leave_client',
          method: 'POST',
          data: {client_user_ids:model.client_user_ids}
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const resp_message = resp.data.message
              
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });        
              
              this.state.myTeamTrainers.my_clients.splice(model.remove_index, 1);      

              resolve(resp)
            } else {
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass: "error"
              }, { root: true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    leaveTrainerModel({ dispatch },model) {
      return new Promise((resolve, reject) => {
        axios({
          url: 'leave_trainer',
          method: 'POST',
          data: {trainer_user_ids:model.trainer_user_ids}
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const resp_message = resp.data.message
              
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });        
              
              this.state.myTeamTrainers.my_trainers.splice(model.remove_index, 1);      

              resolve(resp)
            } else {
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass: "error"
              }, { root: true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },


  },

};