import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    organization_id:0,
    package_id:1,
    name:'',
    description:null,
    organization_number:null,
    organization_join_code:null,
    organization_teams:[],
    organization_coaches:[],
    organization_team_ids:[],
    coach_user_ids:[],
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,
    setModelState(state, response_data) {
      state.organization_id = response_data.id
      state.name = response_data.name      
      state.description = response_data.description
      state.organization_number = response_data.organization_number
      state.organization_join_code = response_data.organization_join_code
      state.organization_teams = response_data.teams
      state.organization_coaches = response_data.coaches

      if(response_data.coaches!=null){
        state.coach_user_ids=[];
        for (let value of Object.values(response_data.coaches)) {
            state.coach_user_ids.push(value.id)
        }
      }

      if(response_data.teams!=null){
        state.organization_team_ids=[];
        for (let value of Object.values(response_data.teams)) {
            state.organization_team_ids.push(value.id)
        }
      }
      
    },

  },

  actions: {
    getOrganizationModel({ commit, dispatch }) {
      return new Promise((resolve,reject) => {
        axios({
          method: 'GET',
          url: 'view_organization_details',
          params: {
            organization_id: this.state.organization.organization_id
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setModelState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },
    updateOrganizationModel({ dispatch }) {
      return new Promise((resolve, reject) => {
        let model=this.state.organization
        model.coach_user_ids =  model.coach_user_ids.join(',')
        axios({
          url: 'add_edit_organization',
          method: 'POST',
          data: model
       })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const resp_message = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });

              router.go(-1) 
              resolve(resp)
            } else {
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass: "error"
              }, { root: true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    leaveCoachModel({ dispatch },model) {
      return new Promise((resolve, reject) => {
        axios({
          url: 'add_edit_organization',
          method: 'POST',
          data: {
            team_id:model.team_id,
            delete_coach_user_ids:model.delete_coach_user_ids
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const resp_message = resp.data.response
              
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });        
              
              this.state.myTeamTrainers.my_teams_ascoach.splice(model.remove_index, 1);      

              resolve(resp)
            } else {
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass: "error"
              }, { root: true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

  },

};