<template>
<div class="TeamReport">

    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <h2 class="mb-3 d-inline">
          <span class="avidocolor--text">{{team_report.team_name}}</span></h2>
        <v-divider class="white"></v-divider>
      </v-col>
    </v-row>

    <v-simple-table v-if="team_report.result_data.length>0">
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              <v-select
                :items="items"
                v-model="report_type"
                item-text="label"
                item-value="name"
                label="Report Type"
                color="avidocolor"
              ></v-select>
            </th>
            <th class="text-center">Past week</th>
            <th class="text-center">Past Month</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item) in team_report.result_data"  :key="item.user_id">
            <td class="py-2 text-left avidocolor--text">

              <v-list-item class="pa-0">
                <v-list-item-avatar tile size=50>
                  <v-img class="rounded" :alt="item.user_name" :src="(item.profile_pic!=null) ? item.profile_pic : '/images/logo.jpg'"  @error="item.profile_pic='/images/logo.jpg'"></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="text-left avidocolor--text">
                  {{item.user_name}}
                </v-list-item-content>
              </v-list-item> 
                
            </td>
            <td>
              {{
              (report_type=='total_workouts') ? item.week_array.workouts : 
              (report_type=='total_sets') ? item.week_array.sets : 
              (report_type=='total_weight') ? item.week_array.weight : 
              (report_type=='total_reps') ? item.week_array.reps : 0
            }}</td>
            <td>
              {{ (report_type=='total_workouts') ? item.month_array.workouts : 
              (report_type=='total_sets') ? item.month_array.sets : 
              (report_type=='total_weight') ? item.month_array.weight : 
              (report_type=='total_reps') ? item.month_array.reps : 0 }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  
   <!--  <v-alert v-else color="red">
      Sorry, No report data found.
    </v-alert> -->

</div>
</template>



<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";


export default {
  name: "TeamReport",
  data: () => ({
     report_type: 'total_workouts',
     items: [{ label: "Total Workouts", name: "total_workouts" },
              { label: "Total Sets", name: "total_sets" },
              { label: "Total Weight", name: "total_weight" },
              { label: "Total Reps", name: "total_reps" }],
  }),
  computed:{
     ...mapFields('team',{
      team_report:'team_report',
    })
  },
  methods: {
    ...mapActions('team',{
      getTeamReport:'getTeamReport',
    }),

  },
  created() {
    this.getTeamReport();
  },
};
</script>