<template>
<div class="Team">

    <v-card flat class="pa-8">
      <v-form
        ref="teamform"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
        <v-text-field
          class="pa-md-4"
          name="name"
          v-model="name"
          label="Team Name"
          color="avidocolor"
          :rules="[(v) => !!v || 'Team Name is required']"
        ></v-text-field>

        <v-row>
          <v-col cols="5" md="2" class="pl-0">
            <v-icon>mdi-camera</v-icon> Select Image
          </v-col> 
          <v-col cols="7" md="10">
            <v-file-input
              @change="onFileChange"
              v-model="team_image"
              id="image-upload"
              class="d-none"
            ></v-file-input>
            <v-avatar            
              size="200"
              rounded="xl"
              @click="openUpload"
              :color="(team_profileImage!=null) ? null : 'avidocolor'"
            >
              <v-icon color="black" x-large :class="(team_profileImage!=null) ? 'd-none' : null">
                mdi-camera
              </v-icon>
              <v-img :alt="name"  :class="(team_profileImage==null) ? 'd-none' : null" :src="(team_profileImage!=null) ? imagePreviewURL : '/images/logo.jpg'" @error="imagePreviewURL='/images/logo.jpg'">
                <v-btn
                  @click.stop="imagePreviewURL = null"
                  fab
                  x-small
                  class="avidocolor"
                  style="position: absolute; top: 10px; right: 10px; z-index: 1;"
                >
                  <v-icon class="black--text">mdi-trash-can</v-icon>
                </v-btn>            
              </v-img>
            </v-avatar>
          </v-col>
        </v-row> 

        <v-select
          v-model="organization_id"
          :items="organization_option"
          item-text="name"
          item-value="id"
          item-color="avidocolor"
          label="Team's Organization"
          color="avidocolor"
          class="pa-md-4"
          :rules="[(v) => !!v || 'Team\'s Organization is required']"
        ></v-select>


        <v-textarea
          v-model="description"
          class="pa-md-4"
          name="description"
          color="avidocolor"
        >
          <template v-slot:label>
            <div>Set Team Goal</div>
          </template>
        </v-textarea>

        <v-text-field
          v-model="athlete_join_code"
          class="pa-md-4"
          name="athlete_join_code"
          label="Athlete Join Code"
          color="avidocolor"
          clearable
        ></v-text-field>

        <v-text-field
          v-model="coach_join_code"
          class="pa-md-4"
          name="coach_join_code"
          label="Coach Join Code"
          color="avidocolor"
          clearable
        ></v-text-field>

     <!--  <v-row class="pl-md-4">
        <v-col align="left">
            Team Privacy Setting :
            <v-btn-toggle
              v-model="team_privacy"
              tile
              color="avidocolor"
              group
              mandatory
            >
              <v-btn value="PUBLIC">
                PUBLIC
              </v-btn>

              <v-btn value="PRIVATE">
                PRIVATE
              </v-btn>
            </v-btn-toggle>
        </v-col>
      </v-row>   -->

        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor avidosolidtextcolor--text"> <v-icon>mdi-content-save</v-icon>save  </v-btn>
        </v-card-actions>

      </v-form> 
    </v-card>  

  </div>
</template>




<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Team",
  data: () => ({
    valid: false,
  }),
  computed:{
    ...mapFields('team',{
      team_id:'team_id',
      name:'team_name',
      organization_id:'organization_id',
      description:'description',
      athlete_join_code:'athlete_join_code',
      coach_join_code:'coach_join_code',
      organization_option:'organization_option',
      team_image:'team_image',
      team_profileImage:'team_profileImage',
      imagePreviewURL:'imagePreviewURL',
    //  team_privacy:'team_privacy',
    })
  },
  
  methods: {
    ...mapActions('team',{
      updateTeamModel:'updateTeamModel',
      getTeamModel:'getTeamModel',
    }),


    openUpload () {
      document.getElementById('image-upload').click()
    },
   
    onFileChange(payload) {
      //const file = payload.target.files[0]; // use it in case of normal HTML input
      const file = payload; // in case vuetify file input
      if (file) {
        this.imagePreviewURL = URL.createObjectURL(file);
        URL.revokeObjectURL(file); // free memory
        // console.log(payload)
      } else {
        this.imagePreviewURL =  null
      }
    },

    onSubmit: function () {
      if (this.$refs.teamform.validate()) {
        this.updateTeamModel();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {
    this.getTeamModel();
  },
};
</script>