<template>
  <div class="libraryexercise">

    <v-card color="black">
      <v-container>
          <v-row class="align-center">
            <v-col cols="5" md="2"  class="pb-0">
              <v-avatar tile size="160">
                <v-img class="rounded" :alt="library_name" 
                  :src="(imagePreviewURL!=null) ? imagePreviewURL : '/images/logo.jpg'"
                @error="imagePreviewURL='/images/logo.jpg'"></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="7" md="10">
              <v-row>   
                <template v-if="(library_tab=='WORKOUT')">
                  <v-col cols="12" md="4" class="pb-0">
                    <v-list-item>
                      <v-list-item-content class="py-0">
                        <v-list-item-subtitle class="text-uppercase">Sets</v-list-item-subtitle>
                        <v-list-item-title class="text-h5">{{total_sets}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>       
                  <v-col cols="12" md="4" class="pb-0">
                    <v-list-item>
                      <v-list-item-content class="py-0">
                        <v-list-item-subtitle class="text-uppercase">Reps</v-list-item-subtitle>
                        <v-list-item-title class="text-h5">{{total_reps}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="4" class="pb-0">
                    <v-list-item>
                      <v-list-item-content class="py-0">
                        <v-list-item-subtitle class="text-uppercase">Weight</v-list-item-subtitle>
                        <v-list-item-title class="text-h5">{{total_weight}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </template>
                <template v-if="(library_tab=='CYCLE')">
                  <v-col cols="12" class="py-0">
                    <v-list-item>
                      <v-list-item-content class="py-0">
                        <v-list-item-subtitle class="text-uppercase">Workouts</v-list-item-subtitle>
                        <v-list-item-title class="text-h5">{{library_workout_array.length}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>  
                </template>  
                <template v-if="(library_tab=='PROGRAM')">
                  <v-col cols="12" md="6">
                    <v-list-item>
                      <v-list-item-content class="py-0">
                        <v-list-item-subtitle class="text-uppercase">Total Cycles</v-list-item-subtitle>
                        <v-list-item-title class="text-h5">{{library_cycle_array.length}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>       
                  <v-col cols="12" md="6">
                    <v-list-item>
                      <v-list-item-content class="py-0">
                        <v-list-item-subtitle class="text-uppercase">Total Weeks</v-list-item-subtitle>
                        <v-list-item-title class="text-h5">{{total_weeks}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </template>
                <template v-if="(library_tab=='WARMUP' || library_tab=='FINISHER' || library_tab=='COOLDOWN')">
                  <v-col cols="12" md="6">
                    <v-list-item>
                      <v-list-item-content class="py-0">
                        <v-list-item-subtitle class="text-uppercase">Rounds</v-list-item-subtitle>
                        <v-list-item-title class="text-h5">{{round_of_time}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>       
                  <v-col cols="12" md="6">
                    <v-list-item>
                      <v-list-item-content class="py-0">
                        <v-list-item-subtitle class="text-uppercase">Rest Time</v-list-item-subtitle>
                        <v-list-item-title>
                          <span class="text-h5 pt-2">
                            <span id="minutes">{{ minutes }}</span>
                            <span id="middle">:</span>
                            <span id="seconds">{{ seconds }}</span>
                          </span>
                          <!-- <div id="timer">
                            </!--  Restart Timer --/>
                            <v-icon 
                              id="reset" 
                              class="mr-2"
                              style="vertical-align: initial;"
                              v-if="resetButton"
                              color="avidocolor"
                              @click="resetTimer()">
                                mdi-history
                            </v-icon>
                            <span class="text-h5 pt-2">
                              <span id="minutes">{{ minutes }}</span>
                              <span id="middle">:</span>
                              <span id="seconds">{{ seconds }}</span>
                            </span>
                            </!-- Start TImer --/>
                              <v-icon 
                                id="start" 
                                v-if="!timer"
                                class="ml-2"
                                style="vertical-align: initial;"
                                color="avidocolor"
                                @click="startTimer()">
                                mdi-play-circle
                              </v-icon>
                            </!--  Pause Timer --/>
                              <v-icon 
                                id="stop" 
                                class="ml-2"
                                style="vertical-align: initial;"
                                color="avidocolor"
                                v-if="timer"
                                @click="stopTimer()">
                                  mdi-pause-circle
                              </v-icon>
                          </div> -->

                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mx-2 text-justify">
              <div v-if="library_description!='null' && library_description!=null">
                <div v-for="(text, index) in library_description.split('\n')" :key="index">
                  {{ text }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>  
    </v-card>

    <template v-if="lexerciseList!=null && lexerciseList.length>0">  
      <template v-if="warmup!=null">
        <div class="text-left text-body-1 pt-2">WarmUp</div> 
        <v-card class="rounded-lg">
          <v-list-item  @click="(warmup!=null) ? viewLWorkoutWFC('WARMUP') : null">
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" v-if="warmup!=null" :alt="warmup.name" :src="(warmup.image!=null)?warmup.image:'/images/logo.jpg'" @error="warmup.image='/images/logo.jpg'"></v-img>
              <v-icon v-if="warmup==null" class="avidocolor--text">mdi-plus-box</v-icon> 
            </v-list-item-avatar>
            <v-list-item-content class="text-left">
              <v-list-item-title v-html="(warmup!=null) ? warmup.name : 'Add Warmup'" ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>   
        </v-card>  
      </template>

      <div class="text-left text-body-1 pt-2">Exercise</div> 
      
        <template v-for="(exerciseListmodel,index) in lexerciseList">
          <v-card class="rounded-lg mb-1" :key="index">
          <template  v-if = "exerciseListmodel.length==1">
            <template v-for="(model,mindex) in exerciseListmodel">
              <v-list-item :key="index+model.lw_exercise_id+model.exercise_id" @click="addlExerciseSet(index,mindex,model)"  >
                <v-list-item-avatar tile size=50>
                <v-img class="rounded" :alt="mindex+model.exercise_name" :src="(model.exercise_image_thumbnail!=null)?model.exercise_image_thumbnail:'/images/logo.jpg'" @error="model.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                </v-list-item-avatar>

                <v-list-item-content class="text-left">
                  <v-list-item-title v-html="model.exercise_name"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action class="ma-0">
                  <v-list-item-action-text>
                    SETS
                    <div class="text-h5">{{ model.exercise_set_count }}</div>
                  </v-list-item-action-text>   
                </v-list-item-action>
              </v-list-item>
            </template>
          </template>

          <template  v-if = "exerciseListmodel.length>1">
            <template v-for="(supermodel,superindex) in exerciseListmodel">
                  
                <v-list-item  :key="index+supermodel.lw_exercise_id+supermodel.exercise_id"
                  @click="addlExerciseSet(index,superindex,supermodel)" 
                  >

                  <v-list-item-avatar tile size=50>
                    <v-img class="rounded"  alt="avtar_image" :src="supermodel.exercise_image_thumbnail" @error="supermodel.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="text-left">
                    <v-list-item-title v-html="supermodel.exercise_name"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action class="ma-0">
                    <v-list-item-action-text>
                      SETS
                      <div class="text-h5">{{ supermodel.exercise_set_count }}</div>
                    </v-list-item-action-text>   
                  </v-list-item-action>
                </v-list-item>

                <v-list-item  v-if="superindex < exerciseListmodel.length - 1" :key="'icon'+superindex+supermodel.lw_exercise_id+supermodel.exercise_id" style="min-height: 0px !important;">
                  <v-list-item-avatar height="0px"></v-list-item-avatar>
                  <v-list-item-content class="d-block ma-n5 pa-0 text-left">
                    <v-list-item-avatar class="ml-5 mt-0 mb-0 align-center">
                      <v-img alt="superst" src="/images/superset_icon.png"></v-img>
                    </v-list-item-avatar>
                  </v-list-item-content>
                </v-list-item>

            </template>
          </template>

          <!-- <v-divider v-if="index < lexerciseList.length - 1"
              :key="index" ></v-divider> -->
          </v-card>    
        </template>
      

      <template v-if="finisher!=null">
        <div class="text-left text-body-1 pt-2">Finisher</div> 
        <v-card class="rounded-lg">
          <v-list-item  @click="(finisher!=null) ? viewLWorkoutWFC('FINISHER') : null">
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" v-if="finisher!=null" :alt="finisher.name" :src="(finisher.image!=null) ? finisher.image:'/images/logo.jpg'" @error="finisher.image='/images/logo.jpg'"></v-img>
              <v-icon v-if="finisher==null" class="avidocolor--text">mdi-plus-box</v-icon> 
            </v-list-item-avatar>
            <v-list-item-content class="text-left">
              <v-list-item-title v-html="(finisher!=null) ? finisher.name : 'Add Finisher'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>   
        </v-card>  
      </template>  

      <template v-if="cooldown!=null">
        <div class="text-left text-body-1 pt-2">Cooldown</div> 
        <v-card class="rounded-lg">
          <v-list-item  @click="(cooldown!=null) ? viewLWorkoutWFC('COOLDOWN') : null">
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" v-if="cooldown!=null" :alt="cooldown.name" :src="(cooldown.image!=null) ? cooldown.image:'/images/logo.jpg'" @error="cooldown.image='/images/logo.jpg'"></v-img>
              <v-icon v-if="cooldown==null" class="avidocolor--text">mdi-plus-box</v-icon> 
            </v-list-item-avatar>
            <v-list-item-content class="text-left">
              <v-list-item-title v-html="(cooldown!=null) ? cooldown.name : 'Add CoolDown'" ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>   
        </v-card>  
      </template>  

    </template>  

    <template v-if="library_tab=='CYCLE' && library_workout_array.length>0">   
      <div class="text-left text-body-1 pt-2">Workouts</div> 
      
        <template v-for="(model, index) in library_workout_array">
          <v-card  class="rounded-lg mb-1" :key="index">
          <v-list-item :key="index+model.id+model.name">
            <v-list-item-avatar tile size=50>
             <v-img class="rounded" :alt="model.id+model.name" :src="(model.image!=null)?model.image:'/images/logo.jpg'" @error="model.image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="text-left">
              <v-list-item-title v-html="model.name"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="ma-0">
              <v-list-item-action-text>
                DAY
                <div class="text-h5">{{index+1}}</div>
              </v-list-item-action-text>            
            </v-list-item-action>

          </v-list-item>
          </v-card>
        </template>
      
    </template>     
    
    <template v-if="library_tab=='PROGRAM' && library_cycle_array.length>0">   
      <div class="text-left text-body-1 pt-2">Cycles</div> 

        <template v-for="(model, index) in library_cycle_array">
          <v-card  class="rounded-lg mb-1" :key="index">
          <v-list-item :key="index+model.name">
            <v-list-item-avatar tile size=50>
             <v-img class="rounded" :alt="model.id+model.name" :src="(model.image!=null)?model.image:'/images/logo.jpg'" @error="model.image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="text-left">
              <v-list-item-title v-html="model.name"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="ma-0">
              <v-list-item-action-text>
                WEEKS
                <div class="text-h5">{{model.num_of_weeks}}</div>
              </v-list-item-action-text>            
            </v-list-item-action>

          </v-list-item>
          </v-card>
        </template>

    </template>     
    
    <template v-if="wfc_lexrecise_array.length>0"> 
      <div class="text-left text-body-1 pt-2">Exercises</div> 
      
        <template v-for="(model, index) in wfc_lexrecise_array">
          <v-card  class="rounded-lg mb-1" :key="index">
            <v-list-item :key="index+model.exercise_name">
              <v-list-item-avatar tile size=50>
                <v-img class="rounded" :alt="index+model.exercise_name" :src="(model.exercise_image_thumbnail!=null)?model.exercise_image_thumbnail:'/images/logo.jpg'" @error="model.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="text-left">
                <v-list-item-title v-html="model.exercise_name"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action class="ma-0">
                <v-list-item-action-text>
                  {{model.reps_type}}
                  <div class="text-h5">{{model.reps}}</div>
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </template>
      
    </template> 

    <div class="clear"></div>
    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>
        <v-btn class="my-2 avidocolor--text" text
          :to="{
                path: (library_tab=='WORKOUT') ? 'edit-lwe' : 'library-form',
            }" >
          <v-icon>mdi-pencil-box-outline</v-icon>
          Edit {{library_tab}}
        </v-btn>
      </v-row>
    </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "LibraryExercise",
  data () {
    return {
    //  timer: null,
    //  totalTime: (25 * 60),
    //  resetButton: true,
      alphabet : ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
    }
  },
  computed:{
    ...mapFields('library',{
      library_id:'library_id',
      library_name:'library_name',
      library_description:'library_description',
      imagePreviewURL:'imagePreviewURL',
      library_tab:'library_tab',
    }), 
    ...mapFields('libraryExercise',{
      exer_index:'exer_index',
      super_index:'super_index',
      data_change:'data_change',
      totalTime:'totalTime',
      wfc_rest_time:'wfc_rest_time',
      round_of_time:'round_of_time',
      lexerciseList:'lexerciseList',
      wfc_lexrecise_array:'wfc_lexrecise_array',
      library_workout_array:'library_workout_array',
      library_cycle_array:'library_cycle_array',
      current_lexercise_id:'current_lexercise_id',
      warmup:'warmup',
      cooldown:'cooldown',
      finisher:'finisher',
      total_reps:'total_reps',
      total_sets:'total_sets',
      total_weight:'total_weight',
      total_weeks:'total_weeks',
      viewLibraryWFC:'viewLibraryWFC',
    }),
    ...mapFields('libraryExerciseSet',[
      'rest_time', 
      'current_exercise_id', 
      'superset_letter', 
      'current_exercise_name', 
      'image_large', 
      'exercise_sets',
      'current_lexer_rest_time_minutes',
      'current_lexer_rest_time_seconds',
    ]), 
    ...mapFields('search',{
      result_type:'result_type',
    }),
    minutes: function() {
      const minutes = Math.floor(this.totalTime / 60);
      return this.padTime(minutes);
    },
    seconds: function() {
      const seconds = this.totalTime - (this.minutes * 60);
      return this.padTime(seconds);
    }
  },
  methods: {
    ...mapActions('libraryExercise',{
      getLibraryExerciseModel:'getLibraryExerciseModel',
      saveLibraryWorkout:'saveLibraryWorkout',
      saveWFC:'saveWFC',
    }), 

    /* startTimer: function() {
      this.timer = setInterval(() => this.countdown(), 1000);
    },
    stopTimer: function() {
      clearInterval(this.timer);
      this.timer = null;
    },
    resetTimer: function() {
      this.totalTime = this.wfc_rest_time //(25 * 60);
      clearInterval(this.timer);
      this.timer = null;
    },
    countdown: function() {
      if(this.totalTime >= 1){
        this.totalTime--;
      } else{
        this.totalTime = 0;
        this.resetTimer()
      }
    }, */
    padTime: function(time) {
      return (time < 10 ? '0' : '') + time;
    },
    viewLWorkoutWFC(wfc_type){
      if(wfc_type=='WARMUP'){
        this.viewLibraryWFC=this.warmup
      }else if(wfc_type=='FINISHER'){
        this.viewLibraryWFC=this.finisher
      }else if(wfc_type=='COOLDOWN'){
        this.viewLibraryWFC=this.cooldown
      }
      this.$router.push({ path: 'view-wfc' });
    }, 
    addlExerciseSet(exerIndex,superIndex,model){
      // let result = new Date(null, null, null, null, null, model.rest_time);
      // let rest_time = result.toTimeString().split(' ')[0].substring(3); //rest_minutes+':'+rest_seconds         
      
      this.current_exercise_id=model.exercise_id
      this.current_exercise_name=model.exercise_name
      this.image_large=model.exercise_large_image
      this.exercise_sets=model.sets  
      this.exer_index=exerIndex
      this.super_index=superIndex    

      // Set Alphabet for super exerice
      this.super_exercise_length=this.lexerciseList[exerIndex].length
      this.superset_letter=(this.super_exercise_length>1) ? this.alphabet[this.super_index] : null

/*       if(model.rest_time==0){
        this.rest_time = '0:00'
        this.lexerciseList[this.exer_index][this.super_index]['rest_time']='0:00'
      }else{
        this.lexerciseList[this.exer_index][this.super_index]['rest_time']=rest_time
      }
 */
      let curt_rest_time=model.rest_time
      this.rest_time = model.rest_time
      if(curt_rest_time>0){
        const cminutes = Math.floor(curt_rest_time / 60)
        const cseconds = curt_rest_time - (cminutes * 60)
        this.current_lexer_rest_time_minutes = cminutes
        this.current_lexer_rest_time_seconds = (cseconds==0) ? '00' : ((cseconds==5) ? '05' : cseconds.toString())
      }else{
        this.current_lexer_rest_time_minutes = 0
        this.current_lexer_rest_time_seconds = '00'
      }

      this.$router.push({ path: 'library-exercise-set' });
    },
  },
  created() {
    this.imagePreviewURL=null
    // console.log(this.data_change)
    if(!this.data_change){
      this.getLibraryExerciseModel();
    }
  },
  beforeDestroy(){
    let component_name=this.$options.name
    let redirect_location=document.location.pathname
    /* console.log(component_name)
    console.log(redirect_location)
    console.log(this.data_change)  */
    if(component_name=='LibraryExercise' && (redirect_location!='/library-exercise-set' || redirect_location!='/edit-lwe')){
      let tab_type = this.library_tab
      if(this.data_change){
        if(tab_type=='WORKOUT'){
          this.saveLibraryWorkout()
        }
        if(tab_type=='WARMUP' || tab_type=='FINISHER' || tab_type=='COOLDOWN'){
          this.saveWFC()
        }
      }
    }
  },

};
</script>
