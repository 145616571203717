import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    team_id: 0,
    team_name: null,
    team_image: null,
    team_profileImage: null,
    imagePreviewURL: null,
    team_description: null,
    team_coaches: null,
    organization_id: 0,
    description: null,
    athlete_join_code: null,
    coach_join_code: null,
    organization_option: [],
    //  team_privacy: 'PRIVATE',
    team_report: {
      team_id: 0,
      team_name: null,
      result_data: [],
    },
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,

    setTeamOption(state, response_data) {
      state.team_name = response_data.team_name
      state.organization_id = response_data.organization_id
      state.description = response_data.description
      state.athlete_join_code = response_data.athlete_join_code
      state.coach_join_code = response_data.coach_join_code
      state.team_profileImage = response_data.team_profileImage
      state.team_description = response_data.description
      state.team_coaches = response_data.team_coaches
      //  state.team_privacy = response_data.team_privacy
      state.imagePreviewURL = response_data.team_profile_pic
    },

    restsetTeamForm(state) {
      state.team_id = 0
      state.team_name = null
      state.organization_id = 0
      state.description = null
      state.athlete_join_code = null
      state.coach_join_code = null
      state.team_image = null
      state.team_profileImage = null
      state.imagePreviewURL = null
      //  state.team_privacy = 'PRIVATE'
    },

    setOrganizationOption(state, response_data) {
      state.organization_option = response_data
    },

    resetTeamReport(state) {
      state.team_report.result_data = []
    },

    setTeamReport(state, response_data) {
      state.team_report.result_data = response_data
    },

  },

  actions: {

    getTeamModel({ dispatch, commit }) {
      return new Promise((resolve, reject) => {

        if (this.state.team.team_id == 0) {
          commit('restsetTeamForm')
        }

        let one = "get_coach_organization"
        const requestOne = axios.get(one);
        const requestArray = [requestOne]
        if (this.state.team.team_id > 0) {
          let two = "view_team_detail?team_id=" + this.state.team.team_id
          const requestTwo = axios.get(two);
          requestArray.push(requestTwo);
        }

        axios.all(requestArray).then(axios.spread((...responses) => {

          const responseOne = responses[0]
          const training_resp_code = responseOne.status
          if (training_resp_code == 200) {
            const response_data = responseOne.data.response
            commit("setOrganizationOption", response_data);
          }

          if (this.state.team.team_id > 0) {
            const responseTwo = responses[1]
            const team_resp_code = responseTwo.status
            if (team_resp_code == 200) {
              const options_data = responseTwo.data.response
              commit("setTeamOption", options_data);
            }
          }

          resolve(responses)
        })).catch(error => {
          const resp_code = error.response.status
          if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })
      })
    },

    getCoachOrganization({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          url: 'get_coach_organization',
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setOrganizationOption", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    getTeamReport({ commit, dispatch }) {
      commit('resetTeamReport')
      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          url: 'team_history_forcoach',
          params: {
            team_id: this.state.team.team_report.team_id
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setTeamReport", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    updateTeamModel({ dispatch }) {
      return new Promise((resolve, reject) => {

        // console.log(this.state.team.athlete_join_code)

        let api_data = {
          team_id: this.state.team.team_id,
          name: this.state.team.team_name,
          image: this.state.team.team_image,
          description: this.state.team.description,
          organization_id: this.state.team.organization_id,
          athlete_join_code: (this.state.team.athlete_join_code == null || this.state.team.athlete_join_code == '') ? null : this.state.team.athlete_join_code,
          coach_join_code: (this.state.team.coach_join_code == null || this.state.team.coach_join_code == '') ? null : this.state.team.coach_join_code,
          //  team_privacy: this.state.team.team_privacy,
        }

        var formData = new FormData();
        for (var key in api_data) {
          formData.append(key, api_data[key]);
        }

        axios.post('add_edit_team',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const resp_message = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });

              this.state.myTeamTrainers.get_api_call = true
              this.state.teamTrainingPreferences.team_id = resp.data.response.team_id

              if (this.state.team.team_id == 0) {
                router.push({ path: '/teamtrainingpreferences?rc=2' });
              } else {
                router.go(-1)
              }

              resolve(resp)
            } else {
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass: "error"
              }, { root: true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    leaveTeamAsCoachModel({ dispatch }, model) {

      return new Promise((resolve, reject) => {
        axios({
          url: 'add_edit_coach',
          method: 'POST',
          data: {
            team_id: model.team_id,
            delete_coach_user_ids: model.delete_coach_user_ids
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const resp_message = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });

              if (model.call_from == 'MANAGEROASTER') {
                this.state.teamProfile.team_coaches.splice(model.remove_index, 1);
              } else if (model.call_from == 'TEAMTEAINERS') {
                this.state.myTeamTrainers.my_teams_ascoach.splice(model.remove_index, 1);
              }

              resolve(resp)
            } else {
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass: "error"
              }, { root: true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    leaveTeamAsTeamMember({ dispatch }, model) {
      return new Promise((resolve, reject) => {
        axios({
          url: 'add_edit_teammember',
          method: 'POST',
          data: {
            team_id: model.team_id,
            delete_team_member_userids: model.delete_member_user_ids,
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {

              const resp_message = resp.data.message
              dispatch("setNotification", {
                display: true,
                text: resp_message,
                alertClass: "success"
              }, { root: true });

              if (model.call_from == 'MANAGEROASTER') {
                this.state.teamProfile.team_members.splice(model.remove_index, 1);
              } else if (model.call_from == 'TEAMTEAINERS') {
                this.state.myTeamTrainers.my_teams_asmember.splice(model.remove_index, 1);
              }

              resolve(resp)
            } else {
              dispatch("setNotification", {
                display: true,
                text: this.app_error_mesg,
                alertClass: "error"
              }, { root: true });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

  },

};