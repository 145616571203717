<template>
  <div class="CopyLibrary">

      <v-container>
        <v-text-field
          v-model="copy_library_name"
          :label="copy_label"
          persistent-hint
          required
          color="avidocolor"
        ></v-text-field>
      </v-container>

      <v-footer color="black" fixed padless>
        <v-row justify="center" no-gutters>

          <v-btn @click="saveCopyLibrary" class="my-2 avidocolor--text" text>
            <v-icon>mdi-content-save</v-icon>save 
          </v-btn>

        </v-row>
      </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "CopyLibrary", 
  computed: {
    ...mapFields("library", {
      copy_label: "copy_label",
      copy_library_id: "copy_library_id",
      copy_library_name: "copy_library_name",
    }),
  },
  methods: {
    ...mapActions("library", {
      saveCopyLibrary: "saveCopyLibrary",
    }),

  },

};
</script>
