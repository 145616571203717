<template>
  <div class="accountsettings">

    <v-card class="pa-8">
      <v-form
        ref="accountsettings"
        v-model="valid"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="userModel.first_name"
              required
              class="pa-md-4"
              name="firstname"
              label="First Name"
              color="avidocolor"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="userModel.last_name"
              required
              class="pa-md-4"
              name="lastname"
              label="Last Name"
              color="avidocolor"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="userModel.email"
          :rules="emailRules"
          required
          class="pa-md-4"
          name="email"
          label="Email"
          color="avidocolor"
        ></v-text-field>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="userModel.birth_date"
              label="Date of Birth"
              readonly
              v-bind="attrs"
              v-on="on"
              class="pa-md-4"
              color="avidocolor"
              :rules="(userModel.birth_date==null) ? ageRules : ageRuleErrors"
              :error="(userModel.birth_date==null) ? true : ageError"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="userModel.birth_date"
            :max="new Date().toISOString().substr(0, 10)"
            color="avidocolor black--text"
            @change="save"
          ></v-date-picker>
        </v-menu>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="userModel.height_feet"
              required
              class="pa-md-4"
              name="height_feet"
              label="Height Feet"
              color="avidocolor"
              suffix="FEET"
              type="number" 
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="userModel.height_inch"
              required
              class="py-4"
              name="height_inch"
              label="Height Inch"
              color="avidocolor"
              suffix="INCH"
              type="number" 
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="userModel.weight"
          required
          class="pa-md-4"
          name="weight"
          label="Weight"
          color="avidocolor"
          suffix="LBS"
          type="number" 
        ></v-text-field>

        <v-row class="pl-md-4">
          <v-col align="left">
              Gender :
              <v-btn-toggle
                v-model="userModel.gender"
                tile
                color="avidocolor"
                group
                mandatory
              >
                <v-btn value="F">
                  F
                </v-btn>

                <v-btn value="M">
                  M
                </v-btn>

                <v-btn value="X">
                  X
                </v-btn>
              </v-btn-toggle>
          </v-col>
        </v-row>

       <!--  <v-text-field
          v-if="userModel.upgrade_to_trainer==true"
          v-model="userModel.client_join_code"
          required
          class="pa-md-4"
          name="client_join_code"
          label="Client JoinCode"
          color="avidocolor"
        ></v-text-field> -->

        <template v-if="after_register==undefined">
          <v-row class="pl-md-4" >
            <v-col align="left">
              <a href="#" class="avidocolor--text" @click="upgradeToTrainer()"> {{ (userModel.upgrade_to_trainer) ? "Join Organization" : "Upgrade To Coach" }} </a>
            </v-col>
          </v-row>  

          <v-row class="pl-md-4">
            <v-col align="left">
              <router-link class="avidocolor--text" to="/changepassword"> Change Password</router-link>
            </v-col>
          </v-row>  

          <v-row class="pl-md-4">
            <v-col align="left">
              <router-link class="avidocolor--text" to="#"><span @click.stop="dialog = true"> Delete Account </span></router-link>

              <v-dialog v-model="dialog" max-width="300">
                  <v-card>
                    <v-card-text class="red--text">
                      Do you want to permenantly delete this account?
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red" text @click="dialog = false">
                        No
                      </v-btn>

                      <v-btn color="avidocolor" text @click.stop.prevent="deleteUserAccount">
                        Yes
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
            </v-col>
          </v-row>  

          <v-row class="pl-md-4">
            <v-col align="left">
              <router-link class="avidocolor--text" to="/logout"> Logout</router-link>
            </v-col>
          </v-row>  

        </template>

        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor avidosolidtextcolor--text"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Accountsettings",
  data () {
    return {
      dialog: false,
      after_register:this.$route.query.ar,
      valid: true,
      menu: false,
      ageError: false,
      ageRuleErrors: [],
      ageRules: ['Date of Birth is required.'],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    }
  },
  computed:{
    ...mapFields('accountSettings',{
      userModel:'userModel',
    }),
    ...mapFields('organizationProfile',{
      organization_id:'organization_id',
      organization_name:'organization_name',
    }),
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    ...mapActions('accountSettings',{
      getModel:'getModel',
      updateModel:'updateModel',
      deleteUserAccount:'deleteUserAccount',
    }),
    save(date) {
      var today = new Date();
      var birthDate = new Date(date);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
      {
          age--;
      }
      if(age>12){
        this.ageError=false;
        this.ageRuleErrors=[];
        this.$refs.menu.save(date);
      }else{
          this.ageError=true;
          this.ageRuleErrors=['Must be at least 13 years old.'];
      }      
    },
    upgradeToTrainer: function () {
      this.organization_id=0
      this.organization_name=''
      this.$router.push({ path: '/join-organization' });
    },
    onSubmit: function () {
      if (this.$refs.accountsettings.validate()) {
        let userModel = this.userModel;
        this.updateModel(userModel);
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {
    this.getModel();
  },
};
</script>