<template>
  <div class="workoutexercise">

      <v-card color="black" class="rounded-lg">
        <v-container>
          <v-row align="center">
            <v-col cols="5" md="2">
              <v-avatar tile size="160">
                <v-img class="rounded" :alt="current_workout_name" 
                  :src="(current_workout_image!=null) ? current_workout_image : '/images/logo.jpg'"
                @error="current_workout_image='/images/logo.jpg'"></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="7" md="10">
              <v-row>   
                <v-col cols="12" :md="(!is_team_workout) ? 4 : 6" class="pb-0">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Sets</v-list-item-subtitle>
                      <v-list-item-title class="text-h5">{{ (is_completed!=undefined || end_workout!=undefined) ? completed_sets : total_sets }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>       
                <v-col cols="12" :md="(!is_team_workout) ? 4 : 6" class="pb-0">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Reps</v-list-item-subtitle>
                      <v-list-item-title class="text-h5">{{(is_completed!=undefined || end_workout!=undefined) ? completed_reps : total_reps }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="4" class="pb-0" v-if="!is_team_workout">
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle class="text-uppercase">Weight</v-list-item-subtitle>
                      <v-list-item-title class="text-h5">{{(is_completed!=undefined || end_workout!=undefined) ? completed_weight : total_weight }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="current_workout_description!='null'">
            <v-col class="mx-2 text-justify">
              <div v-if="current_workout_description!='null' && current_workout_description!=null">
                <div v-for="(text, index) in current_workout_description.split('\n')" :key="index">
                  {{ text }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>  
      </v-card>

      <template v-if="warmup!=null">
        <div class="text-left text-body-1 pt-2">Warm Up</div> 
        <v-card class="rounded-lg">
          <v-list-item  @click="setWorkoutWFC('WARMUP')">
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" :alt="warmup.name" :src="(warmup.image!=null)?warmup.image:'/images/logo.jpg'" @error="warmup.image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="text-left">
              <v-list-item-title>{{warmup.name}}</v-list-item-title>
            </v-list-item-content>
            <!-- <v-list-item-action class="align-center ma-0">
              <v-list-item-avatar  @click.stop.prevent="(is_workout_editable==1) ? getLibraryModel(['WARMUP','ADDWARMUP',warmup.id]) : null" >
                <v-img alt="swap" src="/images/swap_icon.png"></v-img>
              </v-list-item-avatar>
            </v-list-item-action> -->
          </v-list-item>   
        </v-card>  
      </template>

      <div class="text-left text-body-1 pt-2">Exercise</div> 
          <template v-for="(exerciseListmodel,index) in exerciseList">
            <v-card class="rounded-lg mb-1" :key="index">
              <template  v-if = "exerciseListmodel.length==1">
                <template v-for="(model,mindex) in exerciseListmodel">
                    <v-list-item :key="mindex+'_'+model.user_workout_exercise_id+model.exercise_id" @click="setCurrentExercise(index,mindex,0)" >
                      <v-list-item-avatar tile size=50>
                        <v-img class="rounded" :alt="model.exercise_name" :src="(model.exercise_image_thumbnail!=null)?model.exercise_image_thumbnail:'/images/logo.jpg'" @error="model.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content class="text-left">
                        <v-list-item-title
                          class="align-center"
                          v-text="model.exercise_name"
                        ></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action class="align-center ma-0">
                        <v-list-item-action-text>
                          SETS
                          <div class="text-h5">{{model.exercise_set_count}}</div>
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                </template>
              </template>

              <template  v-if = "exerciseListmodel.length>1">
                <template v-for="(supermodel,superindex) in exerciseListmodel">
                      
                    <v-list-item  :key="superindex+'_'+supermodel.user_workout_exercise_id+supermodel.exercise_id"
                      @click="setCurrentExercise(index,superindex,0)"
                      >
                      <v-list-item-avatar tile size=50>
                        <v-img class="rounded" alt="avtar_image" :src="supermodel.exercise_image_thumbnail" @error="supermodel.exercise_image_thumbnail='/images/logo.jpg'"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content class="text-left">
                        <v-list-item-title class="align-center"
                          >{{supermodel.exercise_name}}</v-list-item-title
                        >
                      </v-list-item-content>

                      <v-list-item-action class="align-center ma-0">
                        <v-list-item-action-text>
                          SETS
                          <div class="text-h5">{{supermodel.exercise_set_count}}</div>
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item  v-if="superindex < exerciseListmodel.length - 1" :key="'icon_'+superindex+supermodel.user_workout_exercise_id+supermodel.exercise_id" style="min-height: 0px !important;">
                      <v-list-item-avatar height="0px"></v-list-item-avatar>
                      <v-list-item-content class="d-block ma-n5 pa-0 text-left">
                        <v-list-item-avatar class="ml-5 mt-0 mb-0 align-center">
                          <v-img alt="superst" src="/images/superset_icon.png"></v-img>
                        </v-list-item-avatar>
                      </v-list-item-content>
                    </v-list-item>
                </template>
              </template>
            </v-card>
           <!--  <v-divider v-if="index < exerciseList.length - 1"
                :key="index" ></v-divider> -->
          </template>

      <template v-if="finisher!=null">
        <div class="text-left text-body-1 pt-2">Finisher</div> 
        <v-card class="rounded-lg">
          <v-list-item  @click="setWorkoutWFC('FINISHER')">
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" :alt="finisher.name" :src="(finisher.image!=null)?finisher.image:'/images/logo.jpg'" @error="finisher.image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="text-left">
              <v-list-item-title>{{finisher.name}}</v-list-item-title>
            </v-list-item-content>
            <!-- <v-list-item-action class="align-center ma-0">
              <v-list-item-avatar  @click.stop.prevent="(is_workout_editable==1) ? getLibraryModel(['FINISHER','ADDFINISHER',finisher.id]) : null" >
                <v-img alt="swap" src="/images/swap_icon.png"></v-img>
              </v-list-item-avatar>
            </v-list-item-action> -->
          </v-list-item>   
        </v-card>  
      </template>

     <template v-if="cooldown!=null">
        <div class="text-left text-body-1 pt-2">Cooldown</div> 
        <v-card class="rounded-lg">
          <v-list-item @click="setWorkoutWFC('COOLDOWN')">
            <v-list-item-avatar tile size=50>
              <v-img class="rounded" :alt="cooldown.name" :src="(cooldown.image!=null)?cooldown.image:'/images/logo.jpg'" @error="cooldown.image='/images/logo.jpg'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="text-left">
              <v-list-item-title>{{cooldown.name}}</v-list-item-title>
            </v-list-item-content>
           <!--  <v-list-item-action class="align-center ma-0">
              <v-list-item-avatar  @click.stop.prevent="(is_workout_editable==1) ? getLibraryModel(['COOLDOWN','ADDCOOLDOWN',cooldown.id]) : null" >
                <v-img alt="swap" src="/images/swap_icon.png"></v-img>
              </v-list-item-avatar>
            </v-list-item-action> -->
          </v-list-item>   
        </v-card>  
     </template>
 
    <div class="clear"></div>
    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>

        <template v-if="is_workout_editable==1">
            <!----- For AVIDO Workout------->
            <template v-if="calendar_type=='AVIDO'">
              <!----- Workout Exercise Screen ------->
              <template v-if="end_workout==undefined">
                <!----- If Workout is NOT COMPLETED ------->
                <template v-if="is_completed==undefined">
                    <v-btn v-if="show_begin_workout" @click="setCurrentExercise(0,0,1)"  class="my-2 avidocolor--text" text>  
                      <v-icon>mdi-weight-lifter</v-icon>Begin
                    </v-btn>
                    <!-- <v-btn :to="'/editworkoutexercise?cw='+is_completed+'&fw='+end_workout" class="my-2 avidocolor--text" text>
                      <v-icon>mdi-pencil-box-outline</v-icon>Edit Exercises
                    </v-btn> -->
                    <v-btn to="/workoutequipment" class="my-2 avidocolor--text" text>
                      <v-icon>mdi-swap-horizontal</v-icon>Equipment
                    </v-btn>
                </template>  
                <!----- If Workout is COMPLETED ------->
                <template v-else>
                  <v-btn @click="setCurrentExercise(0,0,1)"  class="my-2 avidocolor--text" text>
                    <v-icon>mdi-weight-lifter</v-icon>Update Workout
                  </v-btn>
                  <!-- <v-btn :to="'/editworkoutexercise?cw='+is_completed+'&fw='+end_workout" class="my-2 avidocolor--text" text>
                    <v-icon>mdi-pencil-box-outline</v-icon>Edit Exercises
                  </v-btn> -->
                </template>  
              </template> 
              <!----- End Workout Screen ------->
              <template v-else>
                <!-- <v-btn :to="'/editworkoutexercise?cw='+is_completed+'&fw='+end_workout" class="my-2 avidocolor--text" text>
                  <v-icon>mdi-pencil-box-outline</v-icon>Edit Exercises
                </v-btn> -->
                <v-btn @click="complete_workout()"  class="my-2 avidocolor--text" text>
                  <v-icon>mdi-flag-checkered</v-icon>End Workout
                </v-btn>
              </template> 
            </template>  

            <!----- For LIBRARY Workout------->
            <template v-if="calendar_type=='LIBRARY'">
              <!----- Workout Exercise Screen ------->
              <template v-if="end_workout==undefined">
                <v-btn v-if="show_begin_workout" @click="setCurrentExercise(0,0,1)"  class="my-2 avidocolor--text" text>
                  <template v-if="is_completed==undefined">
                    <v-icon>mdi-weight-lifter</v-icon>Begin
                  </template>
                  <template v-else>
                    <v-icon>mdi-weight-lifter</v-icon>Update Workout
                  </template>
                </v-btn>
                <!-- <v-btn :to="'/editworkoutexercise?cw='+is_completed" class="my-2 avidocolor--text" text>
                  <v-icon>mdi-pencil-box-outline</v-icon>Edit Exercises
                </v-btn> -->
              </template>  
              <!----- End Workout Screen ------->
              <template v-else>
                <v-btn @click="complete_workout()"  class="my-2 avidocolor--text" text>
                  <v-icon>mdi-flag-checkered</v-icon>End Workout
                </v-btn>
              </template> 
            </template>  
            
            <!----- For PERSONAL Workout------->
            <template v-if="calendar_type=='PERSONAL'">
              <!----- Workout Exercise Screen ------->
              <template v-if="end_workout==undefined">
                <v-btn v-if="show_begin_workout" @click="setCurrentExercise(0,0,1)"  class="my-2 avidocolor--text" text> 
                  <template v-if="is_completed==undefined">
                    <v-icon>mdi-weight-lifter</v-icon>Begin
                  </template>
                  <template v-else>
                    <v-icon>mdi-weight-lifter</v-icon>Update Workout
                  </template>
                </v-btn>
                <v-dialog v-model="pdialog" max-width="300">
                    <v-card>
                      <v-card-title>
                        Before beginning this workout you must add at least one exercise.
                      </v-card-title>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="avidocolor" text @click="pdialog = false">
                          Ok
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
              </template>  
              <!----- End Workout Screen ------->
              <template v-else>
                <v-btn @click="complete_workout()"  class="my-2 avidocolor--text" text>
                  <v-icon>mdi-flag-checkered</v-icon>End Workout
                </v-btn>
              </template> 
            </template>  

        </template>  
      </v-row>
    </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "WorkoutExercise",
  data () {
    return {
      pdialog:false,
      is_team_workout:false,
      afterbw:this.$route.query.bw,
      is_completed:this.$route.query.cw,
      end_workout:this.$route.query.fw,
      alphabet : ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
    }
  },
  computed:{
    ...mapFields('workoutExercise',[
      'exer_index', 
      'super_index', 
      'data_change', 
      'total_reps', 
      'total_sets', 
      'total_weight',       
      'completed_reps', 
      'completed_sets', 
      'completed_weight', 
      'exerciseList',
      'super_exercise_length',
      'current_exer_resttime',
      'totalTime',
      'superset_letter',
      'current_uw_exercise_id',
      'current_exercise_id',
      'current_exer_name',
      'current_exer_largeimage',
      'current_exer_videourl',
      'current_exer_rest_time_minutes',
      'current_exer_rest_time_seconds',
      'current_exer_sets',
      'wfcType', 
      'warmup', 
      'cooldown', 
      'finisher', 
      'selectedWFC',
      'wfc_type'
    ]),
    ...mapFields('bottomSearch',{
      avidoExerciseList:'avidoExerciseList',
    }), 
    ...mapFields('workoutCalendar',{
      get_for_user_id:'get_for_user_id',
      get_for_team_id:'get_for_team_id',
      get_exercise:'get_exercise',
      show_begin_workout:'show_begin_workout',
      calendar_type:'calendar_type',
      current_workout_name: "current_workout_name",
      current_workout_image: "current_workout_image",
      current_workout_description: "current_workout_description",
      is_workout_editable: "is_workout_editable",
      current_user_workout_completed: "current_user_workout_completed",
      current_user_workout_id: "current_user_workout_id",
    }),
  },
  methods: {

    ...mapActions("workoutExercise", {
      getModel: "getModel",
    }),
    ...mapActions("workoutExerciseSet", {
      saveUserWorkout: "saveUserWorkout"      
    }),
    ...mapActions("library", {
      getLibraryModel: "getLibraryModel",
    }),   
    ...mapActions("bottomSearch", {
      getAvidoExercise: "getAvidoExercise",
    }),

    setWorkoutWFC(wfc_type){
      this.wfcType=wfc_type
      let qbrcounter=''
      if(this.end_workout){
        let qbr=parseInt(this.$route.query.br)+1
        qbrcounter='&bw=1&br='+qbr
      }
      this.$router.push({ path: 'workoutwfc?wfctype='+wfc_type+qbrcounter });
    },
    setCurrentExercise(exerIndex,superIndex,bw){

      if(this.exerciseList.length>0){

        let curt_rest_time=this.exerciseList[exerIndex][superIndex]['rest_time']
        if(curt_rest_time>0){
          this.totalTime=curt_rest_time
          this.current_exer_resttime=curt_rest_time
          const cminutes = Math.floor(curt_rest_time / 60)
          const cseconds = curt_rest_time - (cminutes * 60)
          this.current_exer_rest_time_minutes = cminutes
          this.current_exer_rest_time_seconds = (cseconds==0) ? '00' : ((cseconds==5) ? '05' : cseconds.toString())
        }

        this.current_exer_name=this.exerciseList[exerIndex][superIndex]['exercise_name']
        this.current_uw_exercise_id=this.exerciseList[exerIndex][superIndex]['user_workout_exercise_id']
        this.current_exercise_id=this.exerciseList[exerIndex][superIndex]['exercise_id']
        this.current_exer_largeimage=this.exerciseList[exerIndex][superIndex]['exercise_large_image']
        this.current_exer_videourl=this.exerciseList[exerIndex][superIndex]['exercise_video_url']
        this.current_exer_sets=this.exerciseList[exerIndex][superIndex]['sets']
        this.super_exercise_length=this.exerciseList[exerIndex].length
        this.exer_index=exerIndex
        this.super_index=superIndex

        // Set Alphabet for super exerice
        this.superset_letter=(this.super_exercise_length>1) ? this.alphabet[this.super_index] : null

        let routeName=null;
        let qbr=parseInt(this.$route.query.br)+1
        if(bw==1 && exerIndex==0 && superIndex ==0 && this.warmup!=null){
          routeName='workoutwfc?bw=1&wfctype=WARMUP&br='+qbr
          this.wfcType='WARMUP'
          this.$router.push({ path: routeName });
        }else if(this.end_workout){
            this.$router.push({ path: 'workoutexerciseset?bw=1&ei=0&si=0&br='+qbr });
        }else{
          routeName='workoutexerciseset'
          if(bw==1 && exerIndex==0 && superIndex ==0){
            routeName='workoutexerciseset?bw=1&ei=0&si=0&br='+qbr
          }
          this.wfcType='exerciseSet'
          let crestTime=this.exerciseList[exerIndex][superIndex]['rest_time']
          if(crestTime>0){
            let result = new Date(null, null, null, null, null, crestTime);
            this.exerciseList[exerIndex][superIndex]['rest_time'] = result.toTimeString().split(' ')[0].substring(3);
          }else{
            this.exerciseList[exerIndex][superIndex]['rest_time'] = '0:00';
          }
          this.$router.push({ path: routeName });
        }

      /*  if(bw){
          this.$router.push({ path: routeName });
        }else{
          let crestTime=this.exerciseList[exerIndex][superIndex]['rest_time']
          if(crestTime>0){
            let result = new Date(null, null, null, null, null, crestTime);
            this.exerciseList[exerIndex][superIndex]['rest_time'] = result.toTimeString().split(' ')[0].substring(3);
          }else{
            this.exerciseList[exerIndex][superIndex]['rest_time'] = '0:00';
          }
          this.$router.push({ path: routeName });
        }    */

      }else{
        this.pdialog=true
      }      
    }, 
    editUserWorkout(){
      this.$router.push({ path: 'editworkoutexercise' });
    },

    complete_workout(){
      this.data_change=true
      this.current_user_workout_completed=true
      this.saveUserWorkout(this.$route.query.br)
    }
  },
  created(){

    if(this.avidoExerciseList.length==0){
      this.getAvidoExercise();
    }

/*     console.log('current_user_workout_id-'+this.current_user_workout_id)
    console.log('1get_exercise-'+this.get_exercise)
 */
  //  console.log('1get_exercise-'+this.get_exercise)
    if(this.get_exercise){ 
      this.getModel();
    }

    if(this.data_change || this.end_workout!=undefined){
      this.total_reps=this.total_weight=this.total_sets=0
      this.completed_reps=this.completed_weight=this.completed_sets=0
      for (let superexerInfo of Object.values(this.exerciseList)){
        for (let subexerInfo of Object.values(superexerInfo)){
          for(let setInfo of Object.values(subexerInfo.sets)){
            if(setInfo.checkmark_status){
              if(subexerInfo.reps_type=="REPS"){
                this.completed_reps = parseFloat(this.completed_reps) + parseFloat(setInfo.reps)  
              }
              if(subexerInfo.reps_type=="REPS"  && subexerInfo.weight_type=="LBS"){
                this.completed_weight = parseFloat(this.completed_weight) + parseFloat(setInfo.reps*setInfo.weight)
              }
              
              this.completed_sets = parseFloat(this.completed_sets) + 1
            }else{
              this.total_reps = parseFloat(this.total_reps) + parseFloat(setInfo.reps)
              this.total_weight = parseFloat(this.total_weight) + parseFloat(setInfo.reps*setInfo.weight)
            }
          }
          this.total_sets += subexerInfo.exercise_set_count;
        }
      }
    }

    if(this.get_for_team_id>0 && this.get_for_user_id==0){
      this.is_team_workout=true
    }
    // console.log(this.componentData)
  },
  beforeDestroy(){
    let component_name=this.$options.name
    let redirect_location=document.location.pathname
/*     console.log(component_name)
    console.log(redirect_location)
    console.log(this.get_exercise)
   */  
   // console.log(this.data_change)

    if(component_name=='WorkoutExercise' && redirect_location!='/workoutexerciseset' && redirect_location!='/search-exercise' && redirect_location=='/week-summary'){
      if(this.data_change){
        this.saveUserWorkout(0)
      }
    }

    if(component_name=='WorkoutExercise' && redirect_location=='/week-summary'){
      this.get_exercise=true
    }  

    // console.log('2get_exercise-'+this.get_exercise)
  },
};
</script>