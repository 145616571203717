<template>
  <div class="resetpassword">

    <v-card class="pa-8">
      <v-form
        ref="resetpasswordform"
        v-model="validate"
        lazy-validation
        @submit.prevent="onSubmit"
        method="post"
      >
        <v-text-field
          v-model="email"
          :rules="emailRules"
          required
          class="pa-md-4"
          prepend-icon="mdi-email"
          name="email"
          label="Email"
          color="avidocolor"
        ></v-text-field>
        
        <v-text-field
          v-model="password"
          :rules="passwordRules"
          class="pa-md-4"
          prepend-icon="mdi-lock"
          name="password"
          label="Password"
          :type="show ?'text': 'password'"
          :append-icon="show ?'mdi-eye':'mdi-eye-off'" 
          @click:append="show=!show"
          color="avidocolor"
        ></v-text-field>

        <v-text-field
          v-model="password_confirmation"
          :rules="confirmPasswordRules"
          class="pa-md-4"
          prepend-icon="mdi-lock"
          name="password_confirmation"
          label="ReEnter Password"
          :type="coshow ?'text': 'password'"
          :append-icon="coshow ?'mdi-eye':'mdi-eye-off'" 
          @click:append="coshow=!coshow"
          color="avidocolor"
        ></v-text-field>


        <v-card-actions>
          <v-btn type="submit" large block color="avidocolor avidosolidtextcolor--text"> Submit </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

  </div>
</template>


<script>

import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ResetPassword",
  data() {
    return {
      show:false,
      coshow:false,
      validate: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        // (v) => (v && v.length <= 20) || "Password must be less than 20 characters",
        // (v) => (v && v.length >= 6) || "Password must be greater than 6 characters",
      ],
      confirmPasswordRules: [
        (v) => !!v || 'type confirm password',
        (v) => v === this.password || 'The password confirmation does not match.',
      ],
      forgot_token:this.$route.query.fot,
    };
  },
  computed:{
    ...mapFields('forgotPassword',{
      email:'email',
      token:'token',
      password:'password',
      password_confirmation:'password_confirmation',
    }),
  },
  methods: {
    ...mapActions('forgotPassword',{
      submitResetPassword:'submitResetPassword'      
    }), 
    onSubmit: function () {
      if (this.$refs.resetpasswordform.validate()) {
        this.token=this.forgot_token
        this.submitResetPassword();
      } else {
        this.$store.dispatch(
          "setNotification",
          {
            display: true,
            text: this.app_error_mesg,
            alertClass: "error",
          },
          { root: true }
        );
      }
    },
  },
  created() {
    this.$store.commit("forgotPassword/unsetResetPassword");
  }
};
</script>