import axios from "axios";
import { getField, updateField } from 'vuex-map-fields';
import router from '../../router'

export default {
  namespaced: true,
  state: {
    exer_index: 0,
    super_index: 0,
    data_change: false,
    current_lexercise_id:0,
    totalTime: 0,
    wfc_rest_time: 0,
    wfc_RestTime: '0:00',
    wfc_RestTime_minutes: 0,
    wfc_RestTime_seconds: '00',
    round_of_time: 1,
    total_reps: 0,
    total_sets: 0,
    total_weight: 0, 
    lexerciseList:[],
    warmup: null,
    cooldown: null,
    finisher: null,
    viewLibraryWFC: null,
    wfc_lexrecise_array:[],
    library_workout_array:[],
    library_cycle_array:[],
    api_name:null,
    api_data:{},
    program_price:0,
    total_weeks:0,
    reps_type_options: [{ reps_type: 'REPS', display_reps_type: 'REPS' },  { reps_type: 'SEC', display_reps_type: 'SEC' }, { reps_type: 'MIN', display_reps_type: 'MIN' }, { reps_type: 'MILE', display_reps_type: 'MILE' }, { reps_type: 'YARDS', display_reps_type: 'YARDS'}, { reps_type: 'METER', display_reps_type: 'METER' }],
    weight_type_options: [ { weight_type: 'PERCENT', display_weight_type: '%1RM' }, { weight_type: 'RPE', display_weight_type: 'RPE' }, { weight_type: 'LBS', display_weight_type: 'LBS' }, { weight_type: 'KGS', display_weight_type: 'KGS' }]
    // weight_type_options: [{ weight_type: 'PERCENT', display_weight_type: '%1RM' }, { weight_type: 'RPE', display_weight_type: 'RPE' }]
  },

  getters: {
    getField,
  },

  mutations: {
    updateField,

    reSetModelState(state) {
      state.exer_index = 0
      state.super_index = 0
      state.data_change = false
      state.round_of_time=1
      state.totalTime=0
      state.wfc_rest_time=0
      state.wfc_RestTime='0:00'
      state.wfc_RestTime_minutes=0
      state.wfc_RestTime_seconds='00'
      state.total_reps = 0
      state.total_sets = 0
      state.total_weight = 0
      state.lexerciseList=[]
      state.warmup = null
      state.cooldown = null
      state.finisher = null
      state.total_weeks = 0
      state.program_price = 0
      state.viewLibraryWFC = null
      state.wfc_lexrecise_array=[]
      state.library_workout_array=[]
      state.library_cycle_array=[]
    },

    setModelState(state, response_data) {
      
      let tab_type=this.state.library.library_tab
      let nlibrary_name=null
      let nlibrary_description=null
      let nlibrary_image=null
    
      if(tab_type=='WORKOUT'){
        state.lexerciseList = response_data.exercise
        state.total_reps = response_data.total_reps
        state.total_sets = response_data.total_sets
        state.total_weight = response_data.total_weight
        state.warmup = response_data.warmup
        state.cooldown = response_data.cooldown
        state.finisher = response_data.finisher

        nlibrary_name = response_data.workout_name
        nlibrary_description = response_data.workout_description  
        nlibrary_image = response_data.workout_image
      }

      if(tab_type=='CYCLE'){
        state.library_workout_array = response_data.library_cycle_workouts
        nlibrary_description = response_data.description  
        nlibrary_name = response_data.library_cycle_name
        nlibrary_image = response_data.library_cycle_image
      }

      if(tab_type=='PROGRAM'){
        state.library_cycle_array = response_data.library_program_cycles
        nlibrary_name = response_data.library_program_name
        nlibrary_description = response_data.description  
        nlibrary_image = response_data.library_program_image
        state.total_weeks = response_data.total_weeks
        state.program_price = response_data.library_program_price
      }

      if(tab_type=='WARMUP' || tab_type=='FINISHER' || tab_type=='COOLDOWN'){
        state.wfc_lexrecise_array = response_data.exercises
        state.round_of_time = response_data.no_of_rounds

        let curt_rest_time = response_data.rest_time
        if(curt_rest_time>0){
          state.totalTime=curt_rest_time
          state.wfc_rest_time=curt_rest_time
          const cminutes = Math.floor(curt_rest_time / 60)
          const cseconds = curt_rest_time - (cminutes * 60)
          state.wfc_RestTime_minutes = cminutes
          state.wfc_RestTime_seconds = (cseconds==0) ? '00' : cseconds.toString()
        }else{
          state.wfc_RestTime_minutes = 0
          state.wfc_RestTime_seconds = '00'
        }

        let result = new Date(null, null, null, null, null, response_data.rest_time);
        state.wfc_RestTime = result.toTimeString().split(' ')[0].substring(3); //rest_minutes+':'+rest_seconds   
        nlibrary_name = response_data.name
        nlibrary_description = response_data.description  
        nlibrary_image = response_data.image
      }

      this.state.library.imagePreviewURL=nlibrary_image
      this.state.library.library_name=nlibrary_name
      this.state.library.library_description=(nlibrary_description=='null') ? '' : nlibrary_description
    },

    setWFCModelState(state,response_data){
      state.viewLibraryWFC = response_data
    },

  },

  actions: {

    getLibraryExerciseModel({ commit,dispatch }){
      commit("reSetModelState");
      return new Promise((resolve,reject) => {

        let tab_type=this.state.library.library_tab
        let lowertab_type=tab_type.toLowerCase()
        
        if(tab_type=='WORKOUT' || tab_type=='CYCLE' || tab_type=='PROGRAM'){
          this.api_name='view_library_'+lowertab_type;

          if(tab_type=='WORKOUT'){
            this.api_data={ library_workout_id: this.state.library.library_id };
          }
          if(tab_type=='CYCLE'){
            this.api_data={ library_cycle_id: this.state.library.library_id };
          }
          if(tab_type=='PROGRAM'){
            this.api_data={ library_program_id: this.state.library.library_id };
          }
        }

        if(tab_type=='WARMUP' || tab_type=='FINISHER' || tab_type=='COOLDOWN'){
          this.api_name='get_wfc_details';
          this.api_data={
            wfc_id: this.state.library.library_id
          };
        }

        axios({
          method: 'GET',
          url: this.api_name,
          params: this.api_data
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              commit("setModelState", response_data);
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    viewLibraryModel({ commit, dispatch }) {

        let api_name=null
        let api_data=null
        let library_id=this.state.library.library_id
        let library_tab=this.state.library.library_tab
        
        commit("resetCrudLibrary");

        if(library_tab=='WORKOUT'){
          api_name='view_library_workout'
          api_data={
            library_workout_id: library_id
          };
        }    
        if(library_tab=='CYCLE'){
          api_name='view_library_cycle'
          api_data={
            library_cycle_id: library_id
          };
        }     
        if(library_tab=='PROGRAM'){
          api_name='view_library_program'
          api_data={
            library_program_id: library_id
          };
        }    
        if(library_tab=='WARMUP' || library_tab=='FINISHER' || library_tab=='COOLDOWN'){
          api_name='get_wfc_details'
          api_data={
            wfc_id: library_id
          };
        }

        return new Promise((resolve,reject) => {
          axios({
            method: 'GET',
            url: api_name,
            params: api_data
          })
            .then(resp => {
              const resp_code = resp.status
              if (resp_code == 200) {
                const response_data = resp.data.response
                commit("setModelState", response_data);
                resolve(resp)
              }
            })
            .catch(error => {
              const resp_code = error.response.status
              if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
                const error_mesg = error.response.data.errors
                dispatch("setNotification", {
                  display: true,
                  text: error_mesg,
                  alertClass: "error"
                }, { root: true });
                reject(error)
              }
            })
        })
    },


    wfcSearchView({ commit, dispatch },{wfcid,wfc_type}) {

      let api_name=null
      let api_data=null
      let wfctype=null
      if(wfc_type=='LADDWARMUP' || wfc_type=='LADDFINISHER' || wfc_type=='LADDCOOLDOWN'){
         api_name='get_wfc_details'
         api_data={
          wfc_id: wfcid
         };
         wfctype=(wfc_type=='LADDWARMUP') ? 'WARMUP' : ((wfc_type=='LADDFINISHER') ? 'FINISHER' : 'COOLDOWN')
      }else if(wfc_type=='LADDCYCLES'){
         api_name='view_library_cycle'
         api_data={
          library_cycle_id: wfcid
        };
      }else if(wfc_type=='LADDWORKOUTS'){
         api_name='view_library_workout'
         api_data={
          library_workout_id: wfcid
        };
      }
      
      
      return new Promise((resolve,reject) => {
        axios({
          method: 'GET',
          url: api_name,
          params: api_data
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              const response_data = resp.data.response
              console.log(wfctype)
              commit("setWFCModelState",response_data)
              if(wfc_type=='LADDWARMUP' || wfc_type=='LADDFINISHER' || wfc_type=='LADDCOOLDOWN'){
                router.push({ path: 'view-wfc' });
              }else{
                router.push({ path: 'library-exercise' });
              }
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
  },

    
  /*   addExerciseToLworkout({ dispatch },search_exercise_ids) {
      return new Promise((reject) => {
        axios({
          method: 'POST',
          url: 'add_lw_exercise',
          data: {
            library_workout_id: this.state.library.library_id,
            add_exercise_ids: search_exercise_ids.join(','),
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              dispatch("setNotification", {
                display: true,
                text: resp.data.message,
                alertClass: "success"
              }, { root: true })
              router.push({ path: '/library-exercise' });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },  */

    /* editLibraryWorkoutExercise({ dispatch }) {
      let order_uw_exercise_ids=[]
      if(this.state.libraryExercise.lexerciseList!=null){
        for (let superexercises of Object.values(this.state.libraryExercise.lexerciseList)) {
          for (let subexercises of Object.values(superexercises)) {
              order_uw_exercise_ids.push(subexercises.lw_exercise_id)
          }
        }
      }

      return new Promise((reject) => {
        
        let update_uw_exercise_ids=[];
        for (const allexercises of Object.values(this.state.libraryExercise.lexerciseList)) {
          if(allexercises.length>1){
            let super_exercise_ids=[];
            for (const superexercises of Object.values(allexercises)){
              super_exercise_ids.push(superexercises.lw_exercise_id+'-'+superexercises.exercise_id);
            }
            let super_exer=super_exercise_ids.join(',')
            update_uw_exercise_ids.push(super_exer);
          }else{
            for (const exercises of Object.values(allexercises)){
              update_uw_exercise_ids.push(exercises.lw_exercise_id+'-'+exercises.exercise_id);
            }
          }
        }

        let api_name='update_lw_exercises' 
        let api_data={
          library_workout_id: this.state.library.library_id,
          update_lw_exercise_ids: update_uw_exercise_ids.join('|'),
        };

        axios({
          method: 'POST',
          url: api_name,
          data: api_data
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              dispatch("setNotification", {
                display: true,
                text: resp.data.message,
                alertClass: "success"
              }, { root: true })
              router.push({ path: '/library-exercise' });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },  */

   /*  superExerciseToLWorkout({ dispatch },search_exercise_ids) {

      let old_uexercise_id= this.state.libraryExercise.current_lexercise_id
      let make_super_set={};
      make_super_set[old_uexercise_id] = { avido_exercise_ids: search_exercise_ids }; 

      return new Promise((reject) => {
          let api_data={
            library_workout_id: this.state.library.library_id,
            make_super_set:  make_super_set,
          };
          axios({
            method: 'POST',
            url: 'super_set_lw_exercise',
            data: api_data
          })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              dispatch("setNotification", {
                display: true,
                text: resp.data.message,
                alertClass: "success"
              }, { root: true })
              router.push({ path: '/library-exercise' });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    }, 

    swapExerciseToLWorkout({ dispatch },search_exercise_ids) {
      return new Promise((reject) => {
        axios({
          method: 'POST',
          url: 'swap_lw_exercise',
          data: {
            current_lw_exercise_id: this.state.libraryExercise.current_lexercise_id,
            old_exercise_id: this.state.libraryExerciseSet.current_exercise_id,
            new_exercise_id:  search_exercise_ids,
          }
        })
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              dispatch("setNotification", {
                display: true,
                text: resp.data.message,
                alertClass: "success"
              }, { root: true })
              router.push({ path: '/library-exercise' });
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },  */

    addExerciseToLworkout(context,search_exercise_ids) {
      if(search_exercise_ids.length>0){
        for (let exerInfo of Object.values(search_exercise_ids)){
          let new_Exercise=[];
          let newExercise={
            lw_exercise_id : 'A',
            user_workout_exercise_id : 0,
            exercise_id : exerInfo.exercise_id,
            exercise_name : exerInfo.exercise_name,
            exercise_image_thumbnail : exerInfo.exercise_image_thumbnail,
            exercise_large_image : exerInfo.exercise_image_large,
            exercise_video_url : exerInfo.exercise_video_url,
            checkmark_status : false,
            rest_time : 0,
            exercise_set_count : 0,
            reps_type : 'REPS',
            weight_type : 'LBS',
            zero_weight_label : exerInfo.zero_weight_label,
            tempo : null,
            sets : [],
          }
          new_Exercise.push(newExercise)
          this.state.libraryExercise.lexerciseList.push(new_Exercise)
        }
        // this.state.libraryExercise.lexerciseList_length=this.state.libraryExercise.lexerciseList.length
        router.go(-1);
      }
    }, 

   /*  superExerciseToLWorkout(context,search_exercise_ids) {
     
      if(search_exercise_ids.length>0){
        for (let exerInfo of Object.values(search_exercise_ids)){
          let newExercise={
            lw_exercise_id : 'A',
            user_workout_exercise_id : 0,
            exercise_id : exerInfo.exercise_id,
            exercise_name : exerInfo.exercise_name,
            exercise_image_thumbnail : exerInfo.exercise_image_thumbnail,
            exercise_large_image : exerInfo.exercise_image_large,
            exercise_video_url : exerInfo.exercise_video_url,
            checkmark_status : false,
            rest_time : 0,
            exercise_set_count : 0,
            reps_type : 'REPS',
            weight_type : 'LBS',
            zero_weight_label : exerInfo.zero_weight_label,
            sets : [],
          }
          this.state.libraryExercise.data_change=true
          this.state.libraryExercise.lexerciseList[this.state.libraryExercise.exer_index][this.state.libraryExercise.super_index+1]=newExercise
        }
        router.go(-2);
      }
    }, */

    swapExerciseToLWorkout(context,search_exercise_ids) {
      if(search_exercise_ids!=null){
        // for (let exerInfo of Object.values(search_exercise_ids)){
          let exerInfo=search_exercise_ids
          let oldExerInfo=this.state.libraryExercise.lexerciseList[this.state.libraryExercise.exer_index][this.state.libraryExercise.super_index]
          
          let current_exercise_sets = oldExerInfo.sets
          let current_weight_type = oldExerInfo.weight_type
          for (let setinfo of Object.values(current_exercise_sets)){
            if(exerInfo.zero_weight_label!='max' && setinfo.percentage>0){
              current_weight_type='RPE'
              setinfo.weight=Math.round(setinfo.percentage / 10) 
            }      
          }

          let newExercise={
            lw_exercise_id : oldExerInfo.lw_exercise_id,
            user_workout_exercise_id : oldExerInfo.user_workout_exercise_id,
            exercise_id : exerInfo.exercise_id,
            exercise_name : exerInfo.exercise_name,
            exercise_image_thumbnail : exerInfo.exercise_image_thumbnail,
            exercise_large_image : exerInfo.exercise_image_large,
            exercise_video_url : exerInfo.exercise_video_url,
            checkmark_status : false,
            rest_time : oldExerInfo.rest_time,
            exercise_set_count : oldExerInfo.exercise_set_count,
            reps_type : oldExerInfo.reps_type,
            weight_type : current_weight_type,
            zero_weight_label : exerInfo.zero_weight_label,
            tempo : null,
            sets : current_exercise_sets,
          }
          this.state.libraryExercise.data_change=true
          this.state.libraryExercise.lexerciseList[this.state.libraryExercise.exer_index][this.state.libraryExercise.super_index]=newExercise
        // }  
        router.go(-2);
      }
    },

    swapWFCToLWorkout(context,search_exercise_ids) {

      let newWFC=null
      if(search_exercise_ids!=null){
        let wfcInfo=search_exercise_ids
        // for (let wfcInfo of Object.values(search_exercise_ids)){
          newWFC={
            id : wfcInfo.id,
            name : wfcInfo.name,
            image : wfcInfo.image,
          }
        // }
      }

      if(this.state.bottomSearch.wfc_type=='WARMUP'){
        this.state.libraryExercise.warmup=newWFC
      }
      if(this.state.bottomSearch.wfc_type=='COOLDOWN'){
        this.state.libraryExercise.cooldown=newWFC
      }
      if(this.state.bottomSearch.wfc_type=='FINISHER'){
        this.state.libraryExercise.finisher=newWFC
      } 
      router.go(-1) ;  
    }, 

    saveLibraryWorkout({ dispatch },savedata=0) {
      return new Promise((resolve,reject) => {

          let lw_exercise={}
          let workoutexerciselist=this.state.libraryExercise.lexerciseList;
          if(workoutexerciselist!=null){
            let order_count=0;
            for (let workoutexercise of Object.values(workoutexerciselist)) {
              let super_count=0;
              let order_exercise={}  
              for (let wexercise of Object.values(workoutexercise)) {
                let wexercise_sets='';
                let super_exercise={}
                if(wexercise.sets.length>0){
                  let wesets_array=[];
                  for(let wesets of Object.values(wexercise.sets)) {
                    wesets_array.push(wesets.reps+'-'+wesets.weight+'-'+wesets.checkmark_status)
                  }
                  wexercise_sets=wesets_array.join(',')
                }
                
                let restTime=wexercise.rest_time
/*                 if(wexercise.rest_time!=undefined && wexercise.rest_time!=0){
                  if (typeof wexercise.rest_time != 'number') {  
                    let restvalue=wexercise.rest_time.split(":");
                    restTime=parseInt(restvalue[0]*60)+parseInt(restvalue[1]);
                  }
                } */

                super_exercise[wexercise.lw_exercise_id]=wexercise.exercise_id+'|'+restTime+'|'+wexercise.tempo+'|'+wexercise.reps_type+'|'+wexercise.weight_type+'|'+wexercise_sets;
                order_exercise[super_count]=super_exercise;
                super_count++;
              } 
              lw_exercise[order_count]=order_exercise;
              order_count++;
            }
          }

          let api_name='save_library_workout';
          let api_data={
            library_workout_id: this.state.library.library_id,
            name: this.state.library.library_name,
            description: this.state.library.library_description,
            warmup_id: (this.state.libraryExercise.warmup!=null) ? this.state.libraryExercise.warmup.id : 0,
            cooldown_id: (this.state.libraryExercise.cooldown!=null) ? this.state.libraryExercise.cooldown.id : 0,
            finisher_id: (this.state.libraryExercise.finisher!=null) ? this.state.libraryExercise.finisher.id : 0,
            image: this.state.library.library_image, 
            lw_exercise: JSON.stringify(lw_exercise),
          };

          var formData = new FormData();
          for ( var key in api_data ) {
            formData.append(key, api_data[key]);
          }
          
          axios.post(api_name,
            formData,
            {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  'Access-Control-Allow-Origin': '*'
              }
            }
          )
          .then(resp => {
            const resp_code = resp.status
            if (resp_code == 200) {
              this.state.libraryExercise.data_change=false
              if(savedata==1){
                this.state.library.library_workout_list=[]
                router.go(-1);
              }
              resolve(resp)
            }
          })
          .catch(error => {
            const resp_code = error.response.status
            if (resp_code == 400 ||resp_code == 401 || resp_code == 404) {
              const error_mesg = error.response.data.errors
              dispatch("setNotification", {
                display: true,
                text: error_mesg,
                alertClass: "error"
              }, { root: true });
              reject(error)
            }
          })
      })
    },

    addExerciseToLwfc(context,search_exercise_ids) {
      if(search_exercise_ids.length>0){
        for (let exerInfo of Object.values(search_exercise_ids)){
          let newExercise={
            wfc_exercise_id : 0,
            exercise_id : exerInfo.exercise_id,
            exercise_name : exerInfo.exercise_name,
            exercise_image_thumbnail : exerInfo.exercise_image_thumbnail,
            reps : 0,
            reps_type : 'REPS',
          }
          this.state.libraryExercise.wfc_lexrecise_array.push(newExercise)
        }
        router.go(-1);
      }
    }, 

    saveWFC({ dispatch }){
      return new Promise((resolve,reject) => {
        let wfc_exercises=null
        let wfcexercises=[]

        if(this.state.libraryExercise.wfc_lexrecise_array!=null){
          for (let wfc_exer of Object.values(this.state.libraryExercise.wfc_lexrecise_array)) {
            wfcexercises.push(wfc_exer.wfc_exercise_id+'-'+wfc_exer.exercise_id+'-'+wfc_exer.reps_type+'-'+wfc_exer.reps)
          }
        }
        var tab_type=this.state.library.library_tab
        if(tab_type=='WARMUP') this.state.library.library_warmup_list = []
        else if(tab_type=='FINISHER') this.state.library.library_finisher_list = []
        else if(tab_type=='COOLDOWN') this.state.library.library_cooldown_list = []

        wfc_exercises=wfcexercises.join('|')

        let api_name='save_wfc';
        let api_data={
          type: this.state.library.library_tab,
          wfc_id: this.state.library.library_id,
          name: this.state.library.library_name,
          no_of_rounds: this.state.libraryExercise.round_of_time,
          rest_time: this.state.libraryExercise.wfc_rest_time,
          image: this.state.library.library_image,
          description: this.state.library.library_description,
          wfc_exercises: wfc_exercises,
        };

        var formData = new FormData();
        for ( var key in api_data ) {
          formData.append(key, api_data[key]);
        }
        
        axios.post(api_name,
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*'
            }
          }
        )
        .then(resp => {
          const resp_code = resp.status
          if (resp_code == 200) {
            router.go(-1);
            resolve(resp)
          }
        })
        .catch(error => {
          const resp_code = error.response.status
          if (resp_code == 400 ||resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })
      })
    },

    addWorkoutToLCycle(context,search_exercise_ids) {
      if(search_exercise_ids.length>0){
        for (let workoutInfo of Object.values(search_exercise_ids)){
          let newWorkout={
            id : workoutInfo.id,
            name : workoutInfo.name,
            image : workoutInfo.image,
          }
          this.state.libraryExercise.library_workout_array.push(newWorkout)
        }
        router.go(-1);
      }
    }, 

    addCycleToLProgram(context,search_exercise_ids) {
      if(search_exercise_ids.length>0){
        for (let cycleInfo of Object.values(search_exercise_ids)){
          let newWorkout={
            cycle_id : cycleInfo.id,
            name : cycleInfo.name,
            image : cycleInfo.image,
            num_of_weeks : 1,
          }
          this.state.libraryExercise.library_cycle_array.push(newWorkout)
        }
        router.go(-1);
      }
    }, 

    saveLCycleLProgram({ dispatch }) {
      return new Promise((resolve, reject) => {

        let api_name=null
        let api_data=null
        let library_tab=this.state.library.library_tab

        if(library_tab=='CYCLE'){
          api_name='add_edit_library_cycle'

          let libraryworkouts=null
          let libraryworkouts_array=[]
          let lworkouts=this.state.libraryExercise.library_workout_array
          if(lworkouts!=null){
            for (let lworkoutid  of Object.values(lworkouts)) {
              libraryworkouts_array.push(lworkoutid.id)
            }
            libraryworkouts = libraryworkouts_array.join(',')
          }

          api_data={
            library_cycle_id: this.state.library.library_id,
            name: this.state.library.library_name,            
            description: this.state.library.library_description,
            image: this.state.library.library_image,
            library_workouts: libraryworkouts,
          };
          this.state.library.library_cycle_list=[];
        }     

        if(library_tab=='PROGRAM'){
          api_name='add_edit_library_program'

          let librarycycles=null
          let lcycles=this.state.libraryExercise.library_cycle_array
          if(lcycles!=null){
            let temp_library_cycles=[]
            for (const value  of Object.values(lcycles)) {
              let lcycleid=value.cycle_id
              let lcyclelength=value.num_of_weeks
              temp_library_cycles.push(lcycleid+'-'+lcyclelength)
            }
            librarycycles = temp_library_cycles.join('|');
          }

          api_data={
            library_program_id: this.state.library.library_id,
            name: this.state.library.library_name,            
            description: this.state.library.library_description,
            image: this.state.library.library_image,
            price: this.state.libraryExercise.program_price,
            library_cycles: librarycycles,
          };
          this.state.library.library_program_list=[];
        }    
       
        var formData = new FormData();
        for ( var key in api_data ) {
          formData.append(key, api_data[key]);
        }
        
        axios.post(api_name,
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*'
            }
          }
        ).then(resp => {
          const resp_code = resp.status
          if (resp_code == 200) {
            const resp_message = resp.data.message
            dispatch("setNotification", {
              display: true,
              text: resp_message,
              alertClass: "success"
            }, { root: true });

            router.go(-1) 
            resolve(resp)
          } else {
            dispatch("setNotification", {
              display: true,
              text: this.app_error_mesg,
              alertClass: "error"
            }, { root: true });
          }
        })
        .catch(error => {
          const resp_code = error.response.status
          if (resp_code == 400 || resp_code == 401 || resp_code == 404) {
            const error_mesg = error.response.data.errors
            dispatch("setNotification", {
              display: true,
              text: error_mesg,
              alertClass: "error"
            }, { root: true });
            reject(error)
          }
        })
      })
    },


  },

};