<template>
  <div class="instructionfaq">
   
    <v-row v-for="(model, index) in infaqitems" :key="index">
      <v-col>
        <v-toolbar class="text-h6 avidocolor--text">
              {{model.header}}
            </v-toolbar>
            <Media 
              :kind="'video'"
              :controls="true"
              controlsList="noplaybackrate nodownload"
              :src="videourl+model.videoname"
              :style="{'width': '100%', 'min-width': '100%','max-width':' 100%'}"
            ></Media>
       </v-col>
    </v-row>
   
  </div>
</template>



<script>
import Media from '@dongido/vue-viaudio'

export default {
  name: "coachfaq",
  components: {
    'Media': Media,
  },
  data () {
    return {
      videourl:'https://ianc42.sg-host.com/uploads/instruction_videos/',
      infaqitems: [
        {
          header: 'Create Account',
          videoname: '200520234CA.mov',
        },           
        {
          header: 'How to Create a Team',
          videoname: '200520231CT.mov',
        },   
        {
          header: 'Joins Team',
          videoname: '200520236JT.mov',
        },           
   
        {
          header: 'How to Log a Workout',
          videoname: '200520232LW.mov',
        },         
        {
          header: 'View My Results',
          videoname: '200520235VR.mov',
        },           
        {
          header: 'How to Create Library Workouts',
          videoname: '200520233CLW.mov',
        },
      ],
    }
  },
};
</script>