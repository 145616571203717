<template>
  <div class="library">

    <div fixed color="white" hide-on-scroll horizontal scroll-target="#scroll-threshold-example" scroll-threshold="500">

      <v-tabs v-model="tab" background-color="black" color="avidocolor" centered icons-and-text fixed-tabs show-arrows>
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#WORKOUT"
          @click="library_workout_list.length == 0 ? getLibrary('WORKOUT') : changeLibraryArray('WORKOUT')">
          Workouts
        </v-tab>

        <v-tab href="#CYCLE" @click="library_cycle_list.length == 0 ? getLibrary('CYCLE') : changeLibraryArray('CYCLE')">
          Cycles
        </v-tab>

        <v-tab href="#PROGRAM"
          @click="library_program_list.length == 0 ? getLibrary('PROGRAM') : changeLibraryArray('PROGRAM')">
          Programs
        </v-tab>
        <v-tab href="#WARMUP"
          @click="library_warmup_list.length == 0 ? getLibrary('WARMUP') : changeLibraryArray('WARMUP')">
          WarmUp
        </v-tab>
        <v-tab href="#FINISHER"
          @click="library_finisher_list.length == 0 ? getLibrary('FINISHER') : changeLibraryArray('FINISHER')">
          Finisher
        </v-tab>
        <v-tab href="#COOLDOWN"
          @click="library_cooldown_list.length == 0 ? getLibrary('COOLDOWN') : changeLibraryArray('COOLDOWN')">
          CoolDown
        </v-tab>
      </v-tabs>
    </div>

    <v-row>
      <v-col cols="12">

        <div id="scroll-threshold-example">

          <v-alert v-if="library_array.length == 0" type="error" dense outlined>
            Record Not Found.
          </v-alert>

          <template v-if="library_array.length > 0">
            <template v-for="(model, index) in library_array">

              <v-card  class="rounded-lg mb-1" :key="index">
              
              <v-list-item  :key="model.name + index" @click="getLibraryExercise(model)">

                <v-btn  v-if="library_edit_mode" icon color="red" dark @click.stop="deleteLibrary(index,model.id,model.name)">
                  <v-icon color="red">mdi-minus-circle</v-icon>
                </v-btn>


                <!-- <v-col v-if="tab!='WORKOUT'" class="pa-0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="red"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click.stop.prevent="
                        editLibraryModel(model.id)
                      "
                    >
                      <v-icon color="avidocolor"
                        >mdi-pencil-box-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Edit This {{ tab }} Library</span>
                </v-tooltip>
              </v-col> -->

                <v-list-item-avatar tile size=50>
                  <v-img class="rounded" :alt="model.name" :src="
                    model.image != null ? model.image : '/images/logo.jpg'
                  " @error="model.image = '/images/logo.jpg'"></v-img>
                </v-list-item-avatar>

                <v-list-item-content class="pl-2 text-left">
                  <v-list-item-title v-html="model.name" class="wrap-text"></v-list-item-title>
                </v-list-item-content>


                  <v-list-item-action v-if="library_edit_mode">
                    <v-row>
                      <v-col class="pa-0">
                        <v-btn icon dark @click.stop="copyLibrary(model)">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-action>

              </v-list-item>
            </v-card>
              
            </template>
          </template>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="300">
      <v-card>
        <v-card-text class="red--text">
          Do you want to permenantly delete
        </v-card-text>

        <v-card-title>
          {{ delete_libraryname }} ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialog = false">
            No
          </v-btn>

          <v-btn color="avidocolor" text @click.stop.prevent="conformdeleteLibrary()">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="clear"></div>
    <v-footer color="black" fixed padless>
      <v-row justify="center" no-gutters>
        <v-btn @click="newLibrary()"  class="my-2 avidocolor--text" text>
          <v-icon>mdi-plus-box</v-icon>Create {{ tab }}
        </v-btn>

          <v-dialog
            v-model="pcdialog"
            width="500"
          >
          <v-card>
            <v-card-title v-if="tab=='CYCLE' && workout_count == 0">
              You must create a Workout before you can create a Cycle.
            </v-card-title>
            <v-card-title v-if="tab=='PROGRAM' && cycle_count == 0">
              You must create a Cycle before you can create a Program.
            </v-card-title>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="avidocolor"
                text
                @click="pcdialog = false"
              >
              ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-row>
    </v-footer>

  </div>
</template>


<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Library",
  data: () => ({
    dialog: false,
    pcdialog: false,
    delete_index: 0,
    delete_libraryid: 0,
    delete_libraryname: null,
  }),
  computed: {
    ...mapFields("library", {
      library_id: "library_id",
      library_name: "library_name",
      library_edit_mode: "library_edit_mode",
      copy_label: "copy_label",
      copy_library_id: "copy_library_id",
      copy_library_name: "copy_library_name",
      tab: "library_tab",
      workout_count: "workout_count",
      cycle_count: "cycle_count",
      library_array: "library_array",
      library_workout_list: "library_workout_list",
      library_cycle_list: "library_cycle_list",
      library_program_list: "library_program_list",
      library_warmup_list: "library_warmup_list",
      library_finisher_list: "library_finisher_list",
      library_cooldown_list: "library_cooldown_list",
    }),
    ...mapFields('bottomSearch',{
      avidoExerciseList:'avidoExerciseList',
    }), 
  },
  methods: {
    ...mapActions("library", {
      getLibraryModel: "getLibraryModel",
      deleteLibraryModel: "deleteLibraryModel",
      saveCopyLibrary: "saveCopyLibrary",
    }),
    ...mapActions("bottomSearch", {
      getAvidoExercise: "getAvidoExercise",
    }),
    newLibrary() {
      let workoutcount=this.workout_count
      let cyclecount=this.cycle_count
      this.$store.commit("library/reSetModelState");
      this.$store.commit("libraryExercise/reSetModelState");
      this.$store.commit('changeHeaderTitle', 'CREATE ' + this.tab);
      if (this.tab == 'WORKOUT') {
        this.$router.push({ path: 'edit-lwe' });
      } else {
        this.workout_count=workoutcount
        this.cycle_count=cyclecount
        if(this.tab == 'CYCLE' && this.workout_count==0){
          this.pcdialog=true
        }else if(this.tab == 'PROGRAM' && this.cycle_count==0){
          this.pcdialog=true
        }else{
          this.$router.push({ path: 'library-form' });
        }
      }
    },

    copyLibrary(library) {
      this.copy_library_id = library.id;
      this.copy_library_name = library.name+' Copy';
      this.copy_label = 'New '+this.tab+' Name';
      this.$router.push({ path: 'copy-library' });
    },

    getLibraryExercise(library) {
      this.library_id = library.id;
      this.library_name = library.name;
      this.$router.push({ path: "library-exercise" });
    },

    editLibraryModel(library_id) {
      this.library_id = library_id
      this.$router.push({ path: 'library-form' });
    },

    getLibrary(tab_type) {
      this.$store.commit('changeHeaderTitle', tab_type + ' LIBRARY');
      this.library_edit_mode=false
      this.getLibraryModel([tab_type, null, 0]);
    },

    changeLibraryArray(tab_type) {
      this.$store.commit('changeHeaderTitle', tab_type + ' LIBRARY');
      this.library_edit_mode=false
      if (tab_type == 'WORKOUT' && this.library_workout_list.length > 0) this.library_array = this.library_workout_list
      else if (tab_type == 'CYCLE' && this.library_cycle_list.length > 0) this.library_array = this.library_cycle_list
      else if (tab_type == 'PROGRAM' && this.library_program_list.length > 0) this.library_array = this.library_program_list
      else if (tab_type == 'WARMUP' && this.library_warmup_list.length > 0) this.library_array = this.library_warmup_list
      else if (tab_type == 'FINISHER' && this.library_finisher_list.length > 0) this.library_array = this.library_finisher_list
      else if (tab_type == 'COOLDOWN' && this.library_cooldown_list.length > 0) this.library_array = this.library_cooldown_list
    },

    deleteLibrary(dindex, deleteLibraryid,deleteLibraryname) {
      this.delete_index = dindex
      this.delete_libraryid = deleteLibraryid
      this.delete_libraryname = deleteLibraryname
      this.dialog = true
    },

    conformdeleteLibrary() {
      this.deleteLibraryModel({ remove_index: this.delete_index, delete_library_id: this.delete_libraryid })
      this.dialog = false
    }

  },
  created() {
    var get_list = 0
    var tab_type = this.tab
    if (tab_type == 'WORKOUT' && this.library_workout_list.length == 0) {
      get_list = 1
    }
    else if (tab_type == 'CYCLE' && this.library_cycle_list.length == 0) {
      get_list = 1
    }
    else if (tab_type == 'PROGRAM' && this.library_program_list.length == 0) {
      get_list = 1
    }
    else if (tab_type == 'WARMUP' && this.library_warmup_list.length == 0) {
      get_list = 1
    }
    else if (tab_type == 'FINISHER' && this.library_finisher_list.length == 0) {
      get_list = 1
    }
    else if (tab_type == 'COOLDOWN' && this.library_cooldown_list.length == 0) {
      get_list = 1
    }
    
    if (get_list) {
      this.getLibraryModel([this.tab, null, 0]);
    } else {
      if (tab_type == 'WORKOUT') this.library_array = this.library_workout_list
      else if (tab_type == 'CYCLE') this.library_array = this.library_cycle_list
      else if (tab_type == 'PROGRAM') this.library_array = this.library_program_list
      else if (tab_type == 'WARMUP') this.library_array = this.library_warmup_list
      else if (tab_type == 'FINISHER') this.library_array = this.library_finisher_list
      else if (tab_type == 'COOLDOWN') this.library_array = this.library_cooldown_list
    }

    if(this.avidoExerciseList.length==0){
      this.getAvidoExercise();
    }
  },
};
</script>
