<template>
  <div class="WeekSummary">
    <v-card class="mx-auto" width="100%">
      <v-toolbar color="black">
        <!-- <v-toolbar-title class="avidocolor--text">TRAINING</v-toolbar-title> -->
        <v-spacer></v-spacer>
        
        <v-toolbar-items>
        <v-dialog
          ref="calendardate_dialog"
          v-model="menu2"
          :return-value.sync="calendar_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="calendar_date_text"
              prepend-icon="mdi-calendar"
              @click:prepend="on.click"
              readonly
              v-bind="attrs"
              v-on="on"
              color="avidocolor"
              class="pa-4 pl-0 centered-input"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="calendar_date"
            scrollable
            color="avidocolor black--text"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="menu2 = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="avidocolor"
              @click="change_week_summary(calendar_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        </v-toolbar-items>  
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <div class="text-left text-body-1 pt-2">Week Totals</div> 
    <v-card class="pa-2 rounded-lg"> 
      <template v-if="get_team_id==undefined || get_team_id==0">   
        <v-row>
            <v-col cols='4' class="pa-2">
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-subtitle class="text-uppercase">REPS</v-list-item-subtitle>
                  <v-list-item-title>
                    <v-progress-circular
                      :rotate="360"
                      :size="80"
                      :width="8"
                      :value="week_totals.percentage_reps"
                      color="avidocolor"
                    >
                      {{week_totals.percentage_reps}}%
                    </v-progress-circular> 
                    <div class="text-h5">{{week_totals.completed_reps}}</div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col  cols='4' class="pa-2">
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-subtitle class="text-uppercase">SETS</v-list-item-subtitle>
                  <v-list-item-title>
                    <v-progress-circular
                      :rotate="360"
                      :size="80"
                      :width="8"
                      :value="week_totals.percentage_sets"
                      color="avidocolor"
                    >
                      {{week_totals.percentage_sets}}%
                    </v-progress-circular> 
                    <div class="text-h5">{{week_totals.completed_sets}}</div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col  cols='4' class="pa-2">
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-subtitle class="text-uppercase">WORKOUTS</v-list-item-subtitle>
                  <v-list-item-title>
                    <v-progress-circular
                      :rotate="360"
                      :size="80"
                      :width="8"
                      :value="week_totals.percentage_workouts"
                      color="avidocolor"
                    >
                      {{week_totals.percentage_workouts}}%
                    </v-progress-circular> 
                    <div class="text-h5">{{week_totals.completed_workouts}}</div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="avidocolor--text text-uppercase text-h5">Total Weight = {{week_totals.completed_weight}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </template>
      <v-row v-else>
        <v-col>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-subtitle class="text-uppercase">REPS</v-list-item-subtitle>
              <v-list-item-title class="text-h5">{{week_totals.assigned_reps}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-subtitle class="text-uppercase">SETS</v-list-item-subtitle>
              <v-list-item-title class="text-h5">{{week_totals.assigned_sets}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-subtitle class="text-uppercase">WORKOUTS</v-list-item-subtitle>
              <v-list-item-title class="text-h5">{{week_totals.assigned_workouts}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card> 

    <div v-if="current_cycle_id>0" class="text-left text-body-1 pt-2">Cycle</div> 
    <v-card class="rounded-lg" v-if="current_cycle_id>0">
      <v-list-item to='/cycle-details'>
        <v-list-item-avatar tile size=50>
            <v-img class="rounded" :alt="current_cycle_name" :src="(current_cycle_image!=null)?current_cycle_image:'/images/logo.jpg'" @error="current_cycle_image='/images/logo.jpg'"></v-img>
        </v-list-item-avatar>
        <v-list-item-content class="text-left">
          <v-list-item-title>{{current_cycle_name}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>   
    </v-card>   
    
    <div v-if="calendar_workouts.length==0" class="pt-8 stext-body-1">
      <template  v-if="local_user_id==get_for_user_id">
        <div>
          To follow an Avido or Team program go to Training Preferences in the Menu.
        </div>  
        <div class="pt-4">
          To schedule training from your library use the buttons below.
        </div>
      </template>
      <div v-else>
        No workouts currently scheduled.
      </div>  
    </div>  

    <div v-if="calendar_workouts.length>0"  class="text-left text-body-1 pt-4 pb-2">Workouts</div>
      <template  v-if="calendar_workouts.length>0">
       <template v-for="(model, workoutindex) in calendar_workouts">
        <v-card  class="rounded-lg mb-1" :key="workoutindex">
            <v-list-item
              @click.stop.prevent="(calendar_edit_mode) ? false : askdeloadingworkout(model)"
              :key="'list'+workoutindex" >

              <v-btn v-if="calendar_edit_mode" icon color="red" :disabled="!model.is_deletable" dark @click.stop.prevent="deleteCalanderWorkout(workoutindex,model)">
                <v-icon color="red">mdi-minus-circle</v-icon>
              </v-btn>

              <v-list-item-avatar tile size=50>
                <v-img class="rounded" :alt="model.workout_name" :src="(model.workout_image!=null) ? model.workout_image : current_workout_image" @error="model.workout_image='/images/logo.jpg'"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="pl-2 text-left">
                <v-list-item-title v-html="model.workout_name" class="wrap-text"></v-list-item-title>
              </v-list-item-content>

              
              <v-list-item-action class="ma-0">
                <v-icon color="avidocolor" v-if="model.workout_completed && !calendar_edit_mode">
                  mdi-check
                </v-icon>
                <v-list-item-action-text v-if="!model.workout_completed && !calendar_edit_mode">
                  DAY
                  <div class="text-h5">{{workoutindex+1}}</div>
                </v-list-item-action-text>   

                <!-- Copy Workout-->
                <v-list-item-action-text v-if="calendar_edit_mode">
                  <v-btn icon dark @click.stop="copyCalendar(model)" :disabled="(model.user_workout_id>0 || model.ul_workout_id>0) ? false : true">
                      <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </v-list-item-action-text>        

              </v-list-item-action>
              
            </v-list-item>
          </v-card>
        </template>
      </template>

    <v-dialog v-model="deload_dialog" max-width="300">
      <v-card>
        <v-card-text class="red--text">
          Do you want to De-load this workout?
        </v-card-text>

        <v-card-title>
          {{ current_workout_name }} ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="setCurrentCalendar()">
            No
          </v-btn>

          <v-btn color="avidocolor" text @click.stop.prevent="conformdeloadingworkout()">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="delete_cw_dialog" max-width="300">
      <v-card>
        <v-card-text class="red--text">
          Do you want to permenantly delete
        </v-card-text>

        <v-card-title>
          {{ delete_Calendarname }} ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="delete_cw_dialog = false">
            No
          </v-btn>

          <v-btn color="avidocolor" text @click.stop.prevent="conformDeleteCalendar()">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



        <div class="clear"></div>
        <v-footer color="black" fixed padless v-if="show_footer">
          <v-row
            justify="center"
            no-gutters
          >
            <v-btn  class="my-2 avidocolor--text" text to="/search-library-program">
              <v-icon>mdi-plus-box</v-icon>Program
            </v-btn>

            <!-- <v-btn  class="my-2 avidocolor--text" text to="/search-library-cycle">
              <v-icon>mdi-plus-box</v-icon>Cycle
            </v-btn> -->

            <v-btn  class="my-2 avidocolor--text" text to="/search-library-workout">
              <v-icon>mdi-plus-box</v-icon>Workout
            </v-btn>
          </v-row>
        </v-footer>

  </div>
</template>



<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  data () {
    return {
      delete_cw_dialog: false,
      delete_index: 0,
      delete_Calendarid: null,
      delete_Calendarname: null,
      deload_dialog: false,
      menu2: false,
      current_model: {},
      get_user_id:this.$route.query.uid,
      get_team_id:this.$route.query.tid,
      local_user_id:localStorage.getItem('user_id'),
    }
  },
  computed: {
    ...mapFields('workoutExercise', [
      'data_change',
    ]),
    ...mapFields("workoutCalendar", {
      show_footer:'show_footer',
      current_workout_image:'current_workout_image',
      get_for_user_id:'get_for_user_id',
      get_for_team_id:'get_for_team_id',
      calendar_workouts: "calendar_workouts",
      calendar_date_text: "calendar_date_text",
      calendar_date: "calendar_date",
      calendar_type: "calendar_type",
      current_workout_id: "current_workout_id",
      current_user_workout_completed: "current_user_workout_completed",
      current_user_cycle_id: "current_user_cycle_id",
      current_user_workout_id: "current_user_workout_id",
      current_personal_uw_id: "current_personal_uw_id",
      current_workout_name: "current_workout_name",
      current_cycle_id: "current_cycle_id",
      generate_user_workout: "generate_user_workout",
      current_cycle_image: "current_cycle_image",
      current_cycle_name: "current_cycle_name",
      current_cycle_week: "current_cycle_week",
      current_deload_workout: "current_deload_workout",
      week_totals: "week_totals",
      is_workout_editable: "is_workout_editable",
      calendar_edit_mode: "calendar_edit_mode",
      copy_label: "copy_label",
      copy_id: "copy_id",
      copy_name: "copy_name",
    }),
  },
  methods: {
    ...mapActions("workoutCalendar", {
      getWeekSumaryModel: "getWeekSumaryModel",
      deleteCalendarModel: "deleteCalendarModel",
    }),

    deleteCalanderWorkout(dindex, dworkout) {
      this.delete_index = dindex
      this.delete_Calendarid = (dworkout.user_workout_id>0) ? dworkout.user_workout_id : (dworkout.ul_workout_id>0 ? dworkout.ul_workout_id : dworkout.personal_uw_id) 
      this.delete_Calendarname = dworkout.workout_name
      this.delete_cw_dialog = true
    },

    conformDeleteCalendar() {
      this.deleteCalendarModel({ remove_index: this.delete_index, delete_calendar_id: this.delete_Calendarid })
      this.delete_cw_dialog = false
    },

    copyCalendar(cworkout) {
      this.copy_id = (cworkout.user_workout_id>0) ? cworkout.user_workout_id : (cworkout.ul_workout_id>0 ? cworkout.ul_workout_id : cworkout.personal_uw_id)
      this.copy_name = cworkout.workout_name+' Copy';
      this.copy_label = 'New Workout Name';
      this.$router.push({ path: 'copy-workout' });
    },

    change_week_summary(cal_date){
      this.$refs.calendardate_dialog.save(cal_date)
      // this.calendar_date=cal_date
      // console.log(this.calendar_date)
      this.getWeekSumaryModel();
    },
    askdeloadingworkout(model){
      this.current_model=model
      if(model.user_workout_id==0 && this.is_workout_editable && this.current_cycle_week==4){
        this.current_workout_name=model.workout_name
        this.deload_dialog = true
      }else{
        this.setCurrentCalendar()
      }
    },
    conformdeloadingworkout(){
      this.current_deload_workout = true
      this.deload_dialog = false
      this.setCurrentCalendar()
    },
    setCurrentCalendar(){
      this.deload_dialog = false
      let model=this.current_model
      if(model.workout_id>0){
        this.calendar_type='AVIDO'
      //  this.calendar_date=model.workout_date
        this.current_workout_id=model.workout_id
        this.current_user_cycle_id=model.user_cycle_id
        this.current_user_workout_completed=model.workout_completed
        this.current_user_workout_id=model.user_workout_id
        this.current_personal_uw_id=model.personal_uw_id
        this.current_workout_name=model.workout_name
        this.generate_user_workout=(model.user_workout_id==0 && this.is_workout_editable) ? 1 : 0
        this.get_for_team_id=model.team_id      
      }

      if(model.library_workout_id>0){
        this.calendar_type='LIBRARY'
      //  this.calendar_date=model.workout_date
        this.current_workout_id=model.library_workout_id
        this.current_user_cycle_id=model.ul_cycle_id
        this.current_user_workout_completed=model.workout_completed
        this.current_user_workout_id=model.ul_workout_id
        this.current_personal_uw_id=model.personal_uw_id
        this.current_workout_name=model.workout_name
        this.generate_user_workout=(model.ul_workout_id==0 && this.is_workout_editable) ? 1 : 0
        this.get_for_team_id=model.team_id  
      }

      if(model.personal_uw_id>0){
        this.calendar_type='PERSONAL'
      // this.calendar_date=model.workout_date
        this.current_workout_id=model.library_workout_id
        this.current_user_cycle_id=model.ul_cycle_id
        this.current_user_workout_completed=model.workout_completed
        this.current_user_workout_id=model.personal_uw_id
        this.current_personal_uw_id=model.personal_uw_id
        this.current_workout_name=model.workout_name
        this.generate_user_workout=0
        this.get_for_team_id=model.team_id  
      }

      // console.log(this.current_user_workout_id)
      this.data_change=false
      if(model.workout_completed){
        this.$router.push({ path: 'workoutexercise?cw=1&br=1' });
      }else{
        this.$router.push({ path: 'workoutexercise?br=1' });
      }   
    },  

  },
  watch: {
    '$route.query.my'(my_workout) {
        if(my_workout!=undefined && my_workout==1){
          this.get_for_user_id=localStorage.getItem('user_id');
          this.get_for_team_id=0;
        }
        this.$store.commit('changeHeaderTitle','My Workouts');
        this.getWeekSumaryModel();
    },
  },
  created() {
    if(this.get_user_id==undefined && this.get_team_id==undefined){
      this.get_for_user_id=localStorage.getItem('user_id');
      this.get_for_team_id=0;
    }
    if(this.get_user_id!=undefined && this.get_team_id!=undefined){
      this.get_for_user_id=this.get_user_id;
      this.get_for_team_id=this.get_team_id;
    }
    this.getWeekSumaryModel();    
  },
};
</script>


<style scoped>
    .centered-input >>> input {
      text-align: center
    }
</style>